import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  maintenance: {},
  loaded: false,
  website_id: null,
};

const maintenanceSlice = createSlice({
  name: "maintenance",
  initialState,
  reducers: {
    setMaintenanceSetting(state, action) {
      const { website_id, maintenance } = action.payload;
      state.maintenance = maintenance;
      state.loaded = true;
      state.website_id = website_id;
    },
  },
});

export const { setMaintenanceSetting } = maintenanceSlice.actions;

export default maintenanceSlice.reducer;
