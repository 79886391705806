import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "helpers";

const initialState = {
  notifications: [],
  loaded: false,
  website_id: null,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotificationsSettingAction: (state, action) => {
      const { notifications, website_id } = action.payload;
      state.notifications = notifications;
      state.website_id = website_id;
      state.loaded = true;
    },
    updateNotificationSettingAction: (state, action) => {
      const index = state.notifications.findIndex((item) =>
        isEqual(item.website_id, action.payload.website_id)
      );

      if (index !== -1) {
        state.notifications[index] = {
          ...state.notifications[index],
          ...action.payload,
          noti_settings: action.payload.notifications_settings,
        };
      } else {
        state.notifications.push(action.payload);
      }
    },
  },
});

export const {
  setNotificationsSettingAction,
  updateNotificationSettingAction,
} = notificationsSlice.actions;
export default notificationsSlice.reducer;
