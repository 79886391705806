import { useState } from "react";
import { compact, useSearch } from "helpers";
import "./index.css";
import { SiteSettingsMenu } from "enums";
import { useUserPermissions } from "hooks/user";

export default function SecondaryMenu({isLibraryTemplateAllowed,...props}) {
  const [activeMenu, setActiveMenu] = useState(
    props.activeMenu ?? SiteSettingsMenu.GENERAL_SETTINGS
  );
  const {
    add_items_from_template_library,
  } = useUserPermissions();
  const { web } = useSearch();
  const menuItems = [
    {
      title: "General Settings",
      key: SiteSettingsMenu.GENERAL_SETTINGS,
    },
    {
      title: "Feature Settings",
      key: SiteSettingsMenu.FEATURES_SETTINGS,
    },
    !web && isLibraryTemplateAllowed && {
      title: "User Preferences",
      key: SiteSettingsMenu.USER_PREFRENCES,
    },
    !web && isLibraryTemplateAllowed && add_items_from_template_library && {
      title: "Library Templates",
      key: SiteSettingsMenu.LIBRARY_TEMPLATES,
    },
  ];

  function handleClick(value) {
    props.setActiveMenu(value);
    setActiveMenu(value);
  }

  return (
    <div className="main-menu-list">
      {compact(menuItems).map((item) => (
        <div
          className={`main-menu-list-tab ${
            activeMenu === item.key ? "active-menu" : ""
          }`}
          onClick={() => handleClick(item.key)}
        >
          <h2>{item.title}</h2>
        </div>
      ))}
    </div>
  );
}
