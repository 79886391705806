import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "helpers";

const initialState = {
  checklists: [],
  loaded: false,
  defaultSite: {
    checklists: [],
    loaded: false,
  },
};

const checklistsSlice = createSlice({
  name: "checklists",
  initialState,
  reducers: {
    setTemplateChecklists(state, action) {
      state.checklists = action.payload;
      state.loaded = true;
    },
    createChecklistTemplate(state, action) {
      state.checklists.push(action.payload);
    },
    editChecklistTemplate(state, action) {
      const index = state.checklists.findIndex((_) =>
        isEqual(_.id, action.payload.id)
      );
      if (index !== -1) {
        for (const key in action.payload) {
          if (state.checklists[index][key]) {
            state.checklists[index][key] = action.payload[key];
          }
        }
      }
    },
    removeChecklistTemplate(state, action) {
      state.checklists = state.checklists.filter(
        (_) => !isEqual(_.id, action.payload)
      );
    },
    setDefaultChecklists(state, action) {
      state.defaultSite.checklists = action.payload;
      state.defaultSite.loaded = true;
    },
    addToDefaultChecklists(state, action) {
      const index = state.checklists.findIndex((_) =>
        isEqual(_.id, action.payload.id)
      );
      if (index !== -1) {
        state.checklists[index].default_tem = 1;
        state.defaultSite.checklists.unshift(state.checklists[index]);
      }
    },
    removeFromDefaultChecklists(state, action) {
      const index = state.checklists.findIndex((_) =>
        isEqual(_.id, action.payload)
      );
      if (index !== -1) {
        state.checklists[index].default_tem = 0;
      }
      state.defaultSite.checklists = state.defaultSite.checklists.filter(
        (_) => !isEqual(_.id, action.payload)
      );
    },
  },
});

export const {
  setTemplateChecklists,
  createChecklistTemplate,
  editChecklistTemplate,
  removeChecklistTemplate,
  setDefaultChecklists,
  addToDefaultChecklists,
  removeFromDefaultChecklists,
} = checklistsSlice.actions;

export default checklistsSlice.reducer;
