export default function MainButton({className, children, buttonType='primary', ...props}) {
  return (
    <>
      <button
        {...props}
        className={`save-button ${className ?? ""} ${buttonType === 'primary' ? '' : 'bg-theme-card-bg text-black'}`}
      >
        {children}
      </button>
    </>
  );
}
