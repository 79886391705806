import { Children, useState } from "react";
import { AddIcon, ProposalIcon } from "allIcons";
import {
  BodyContainer,
  HeaderContainer,
  InputField,
  ListCard,
  ListIconWrapper,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
} from "commonUI";

export default function ProposalTemplates() {
  const [proposalList, setProposalList] = useState([
    "[Proposal Title]",
    "[Proposal Title]",
    "[Proposal Title]",
    "[Proposal Title]",
  ]);
  return (
    <>
      <RightMainContainer>
        <HeaderContainer>
          <PrimaryHeading>Proposals</PrimaryHeading>
          <MainButton>
            <AddIcon />
            Add
          </MainButton>
        </HeaderContainer>
        <BodyContainer>
          <InputField placeholder="Search" searchIcon={true} filter={true} />
          {Children.toArray(
            proposalList.map((task, index) => {
              return (
                <ListCard
                  editIcon={false}
                  data={task}
                  onDelete={() => {
                    setProposalList(() =>
                      proposalList.filter((item, i) => i !== index)
                    );
                  }}
                  icon={
                    <ListIconWrapper>
                      <ProposalIcon fill="white" />
                    </ListIconWrapper>
                  }
                  deleteTitle="Delete This Template?"
                  deleteButtonText="Confirm Delete"
                  deleteMessage="You are about to delete this template. It will be permanently removed from your account library. Are you sure you want to do this?"
                />
              );
            })
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
