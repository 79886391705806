export default function CourseIcon(props) {
  function HandleClick() {
    props.onClick && props.onClick();
  }
  return (
    <>
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`cursor-pointer ${props.className ?? ""}`}
        onClick={HandleClick}
      >
        <path
          d="M16.3796 6.80981C16.6423 6.69952 16.8658 6.5129 17.021 6.27393C17.1761 6.03495 17.2558 5.75483 17.2496 5.46997C17.2591 5.17785 17.1763 4.89006 17.013 4.64771C16.8496 4.40535 16.614 4.22065 16.3396 4.11987L9.63962 0.949951C9.43613 0.84867 9.21189 0.795898 8.98459 0.795898C8.75729 0.795898 8.53311 0.84867 8.32962 0.949951L1.6196 4.12988C1.35527 4.2408 1.13072 4.42921 0.975434 4.67017C0.82015 4.91112 0.741409 5.19344 0.749604 5.47998C0.742217 5.77042 0.826065 6.05579 0.989289 6.29614C1.15251 6.53649 1.38694 6.71959 1.65964 6.81982L4.37961 8.10986L3.75961 12.3699C3.73469 12.5331 3.76429 12.6998 3.84384 12.8445C3.92339 12.9891 4.04846 13.1036 4.19962 13.1699C4.80962 13.4299 7.8996 14.73 8.9996 14.73C10.0996 14.73 13.1896 13.4299 13.7996 13.1699C13.9507 13.1036 14.0758 12.9891 14.1554 12.8445C14.2349 12.6998 14.2645 12.5331 14.2396 12.3699L13.6196 8.12988L14.7496 7.59985V12.98C14.5932 13.0953 14.4663 13.2459 14.3794 13.4197C14.2925 13.5935 14.248 13.7856 14.2496 13.98C14.2496 14.3115 14.3813 14.6293 14.6158 14.8638C14.8502 15.0982 15.1681 15.23 15.4996 15.23C15.8311 15.23 16.1491 15.0982 16.3835 14.8638C16.6179 14.6293 16.7496 14.3115 16.7496 13.98C16.7488 13.7861 16.7033 13.5948 16.6166 13.4214C16.5299 13.2479 16.4043 13.0969 16.2496 12.98V6.87988L16.3796 6.80981ZM8.9996 13.22C7.73601 12.9463 6.50559 12.537 5.32962 11.9998L5.79959 8.76978L8.35959 9.98975C8.56901 10.0889 8.79793 10.1403 9.02963 10.1399C9.25044 10.1388 9.46853 10.0909 9.66965 9.99976L12.2096 8.78979L12.6696 11.9998C11.4937 12.537 10.2632 12.9463 8.9996 13.22ZM12.5596 6.95996L12.3796 7.0498L9.01962 8.63989L2.29959 5.45996L8.97965 2.2998L15.6996 5.47998L12.5596 6.95996Z"
          fill={props.fill ?? "white"}
        />
      </svg>
    </>
  );
}
