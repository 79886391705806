export default function EditIcon(props) {
  function HandleClick() {
    props.onClick && props.onClick();
  }
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${props.className ?? ""}`}
        onClick={HandleClick}
      >
        <path
          d="M14.32 2.29005L13.73 1.70008C13.437 1.40774 13.0892 1.17593 12.7065 1.01807C12.3239 0.860214 11.9139 0.77935 11.5 0.780037C10.661 0.778193 9.85551 1.10907 9.26001 1.70008L7.47003 3.50001L0.970032 10C0.829339 10.1405 0.750175 10.3312 0.75 10.53V14.53C0.75 14.7289 0.829013 14.9198 0.969666 15.0604C1.11032 15.2011 1.30109 15.28 1.5 15.28H5.5C5.69841 15.2777 5.88831 15.1989 6.03003 15.0601L12.51 8.57008L14.28 6.80006C14.5755 6.50885 14.81 6.1616 14.9697 5.77869C15.1294 5.39579 15.2111 4.98494 15.21 4.57008C15.2219 4.14981 15.1492 3.73138 14.9963 3.33973C14.8435 2.94808 14.6134 2.59115 14.32 2.29005ZM5.19 13.78H2.25V10.8401L8 5.0901L10.94 8.03004L5.19 13.78ZM13.26 5.70008L12 6.9701L9.06 4.03004L10.32 2.76002C10.6375 2.45972 11.058 2.29237 11.495 2.29237C11.932 2.29237 12.3525 2.45972 12.67 2.76002L13.26 3.3401C13.5724 3.65354 13.7484 4.07751 13.75 4.52003C13.7428 4.95256 13.5669 5.36521 13.26 5.67005V5.70008Z"
          fill={props.fill ?? "#32373C"}
        />
      </svg>
    </>
  );
}
