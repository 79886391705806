import { axiosInstance } from "helpers";

export async function templateTaskShow(parent_type) {
  return axiosInstance
    .post("defaulttaskshow", {
      apikey: localStorage.getItem("apikey"),
      parent_type,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
}

export async function templateTaskDelete(id) {
  return axiosInstance
    .post("defaulttaskdelete", {
      apikey: localStorage.getItem("apikey"),
      id,
    })
    .then((res) => ({ ...res.data, id }))
    .catch((err) => {
      throw new Error(err);
    });
}

export async function templateTaskEdit(data) {
  return axiosInstance
    .post("defaulttaskedit", {
      apikey: localStorage.getItem("apikey"),
      wpsl_tem: localStorage.getItem("apikey"),
      ...data,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
}

export async function templateTaskAdd(data) {
  return axiosInstance
    .post("addlibrarytemplate", {
      apikey: localStorage.getItem("apikey"),
      wpsl_tem: localStorage.getItem("apikey"),
      ...data,
      system_status: "0",
    })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
}

export async function defaultTaskRemove(id) {
  return axiosInstance
    .post("defaulttaskremove", {
      apikey: localStorage.getItem("apikey"),
      id,
    })
    .then((res) => ({ ...res.data, id }))
    .catch((err) => {
      throw new Error(err);
    });
}

export async function defaultTaskAdd(data) {
  return axiosInstance
    .post("adddefaulttemplate", {
      apikey: localStorage.getItem("apikey"),
      ...data,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
}

export async function defaultTaskShow(parent_type) {
  return axiosInstance
    .post("getwpsltasktemp", {
      apikey: localStorage.getItem("apikey"),
      parent_type,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
}
