import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  environments: [],
  loaded: false,
  website_id: undefined,
};

const environmentsSlice = createSlice({
  name: "environments",
  initialState,
  reducers: {
    setEnvironmentsAction: (state, action) => {
      const { environments, website_id } = action.payload;
      state.environments = environments;
      state.loaded = true;
      state.website_id = website_id;
    },
    updateEnvironmentAction: (state, action) => {
      const { website_id } = action.payload;
      const index = state.environments.findIndex(
        (x) => x.website_id === website_id
      );
      if (index !== -1) {
        state.environments[index] = action.payload;
      }
    },
  },
});

export const { setEnvironmentsAction, updateEnvironmentAction } = environmentsSlice.actions;

export default environmentsSlice.reducer;
