export default function Loader({ loader = "global", show = true, ...props }) {
  return show ? (
    <div
      className={` ${
        loader.toLowerCase() === "block" ? "block-loader" : "loader-container"
      } ${props.className ?? ""}`}
    >
      <span className="loader"></span>
    </div>
  ) : (
    ""
  );
}
