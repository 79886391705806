import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  DeleteIcon,
  DuplicateIcon,
  EditIcon,
  AddIcon,
  CrossIcon,
} from "allIcons";
import { SecondaryHeading, DeleteModal } from "commonUI";
import { textParser } from "helpers";

export default function ListCard({
  editIcon = true,
  editLink = "",
  backUrlOnEdit = true,
  onEditClick,
  link = "",
  onClick,
  id,
  selected = false,
  addIcon = null,
  onCross = null,
  defaultLink = window.location.pathname + window.location.search || "",
  deleteIcon,
  secondaryData = "",
  showModalonCross = false,
  deleteMessage,
  deleteButtonText,
  deleteTitle,
  className,
  crossIcon = false,
  showTitle = false,
  showDeleteResult = false,
  deleteResponseMessage = "",
  deleteResponseTitle = "",
  opacity = "",
  ...props
}) {
  const [showDelete, setShowDelete] = useState({
    show: false,
    position: "bottom",
  });
  const [showDeleteResultModal, setShowDeleteResultModal] = useState({
    show: false,
    position: "bottom",
  });
  const { pathname, search } = useLocation();

  const getEditLink = () => {
    if (editLink) {
      return backUrlOnEdit
        ? { pathname: editLink, search: `?backUrl=${pathname}${search}` }
        : `${editLink}${search}`;
    }
    return `${
      props.strictLink ? `${link}${search}` : `${link}/${props.index}${search}`
    }`;
  };

  return (
    <>
      <div
        className={`list-card-container ${className ?? ""} ${
          selected ? "list-card-container-selected" : ""
        }`}
        key={props.index}
        title={showTitle ? props.data : ""}
      >
        <Link
          onClick={(e) => {
            if (onClick) {
              e.preventDefault();
              onClick(id);
            }
          }}
          to={`${
            link
              ? `${props.strictLink}${search}`
                ? `${link}${search}`
                : `${link}/${props.index}${search}`
              : defaultLink
          }`}
          className={`flex items-center flex-1 ${
            link || onClick ? "cursor-pointer" : "cursor-default"
          } ${opacity}
          `}
        >
          {props.icon}
          <SecondaryHeading className="ml-2 capitalize text-13 text-[#4C5866] font-semibold clamp-text">
            {textParser(props.data)}
          </SecondaryHeading>
        </Link>
        <div className="list-card-right-container">
          {secondaryData && (
            <Link
              onClick={(e) => {
                if (onClick) {
                  e.preventDefault();
                  onClick(id);
                }
              }}
              to={`${
                link
                  ? `${props.strictLink}${search}`
                    ? `${link}${search}`
                    : `${link}/${props.index}${search}`
                  : defaultLink
              }`}
              className={`h-full flex items-center justify-center ${opacity}`}
            >
              <p className="list-card-secondary-data">{secondaryData}</p>
            </Link>
          )}
          {editIcon && (
            <Link
              to={getEditLink()}
              className={`list-card-icon ${opacity}`}
              onClick={() => onEditClick && onEditClick()}
            >
              <EditIcon fill="#4C5866" />
            </Link>
          )}
          {props.duplicate && (
            <div
              className="list-card-icon"
              onClick={() => {
                props.duplicate && props.onDuplicate(props.data);
              }}
            >
              <DuplicateIcon fill="#4C5866" />
            </div>
          )}
          {addIcon && (
            <div
              className={`list-card-icon ${opacity}`}
              onClick={() => {
                props.onAddClick();
              }}
            >
              {addIcon ?? <AddIcon fill="#4C5866" />}
            </div>
          )}
          <div className={!deleteIcon || crossIcon ? "list-card-icon" : ""}>
            <div
              className={`relative  ${opacity}`}
              onClick={(e) => {
                if (onCross && !showModalonCross) {
                  onCross();
                  return;
                }
                setShowDelete((pre) => ({
                  show: !pre.show,
                  position: e.clientY >= 484 ? "top" : "bottom",
                }));
              }}
            >
              {!deleteIcon ? <DeleteIcon /> : ""}
              {deleteIcon && crossIcon ? <CrossIcon fill="#4C5866" /> : ""}
            </div>
            {showDelete.show || showDeleteResultModal.show ? (
              <>
                <div className="delete-icon-layer" />
                <DeleteModal
                  onClose={() => {
                    setShowDelete((pre) => ({
                      ...pre,
                      show: false,
                      position: "bottom",
                    }));
                    setShowDeleteResultModal((pre) => ({
                      ...pre,
                      show: false,
                      position: "bottom",
                    }));
                  }}
                  setOpen={() => {
                    setShowDelete((pre) => ({
                      ...pre,
                      show: false,
                      position: "bottom",
                    }));
                  }}
                  open={showDelete.show || showDeleteResultModal.show}
                  position={
                    showDelete.position || showDeleteResultModal.position
                  }
                  deleteMessage={
                    showDeleteResultModal.show
                      ? deleteResponseMessage
                      : deleteMessage
                  }
                  deleteButtonText={
                    showDeleteResultModal.show ? "Okay" : deleteButtonText
                  }
                  deleteTitle={
                    showDeleteResultModal.show
                      ? deleteResponseTitle
                      : deleteTitle
                  }
                  isDeleteButton={
                    showDeleteResultModal.show ? false : !deleteIcon
                  }
                  onDelete={async () => {
                    if (showDeleteResultModal.show) {
                      setShowDeleteResultModal((pre) => ({
                        ...pre,
                        show: false,
                        position: "bottom",
                      }));
                      return;
                    }
                    if (onCross) {
                      const isRemoveSucessfully = await onCross();
                      if (!isRemoveSucessfully && showDeleteResult) {
                        setShowDeleteResultModal({
                          show: true,
                          position: showDelete.position,
                        });
                      }
                    }
                    if (props.onDelete) {
                      const isDeletedSucessfully = await props.onDelete();
                      if (!isDeletedSucessfully && showDeleteResult) {
                        setShowDeleteResultModal({
                          show: true,
                          position: showDelete.position,
                        });
                      }
                    }
                    setShowDelete((pre) => ({
                      ...pre,
                      show: false,
                      position: "bottom",
                    }));
                  }}
                />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}
