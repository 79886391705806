import { Link } from "react-router-dom";
import { SecondaryHeading } from "commonUI";

export default function TemplateNotFound({
  link,
  label,
  linkLabel,
  onClick,
  filter = true,
}) {
  return (
    <div className="w-full flex items-center justify-center">
      <div className="template-notfound-containter">
        <SecondaryHeading className="mt-1">
          No {label ?? "Template"} To Show
        </SecondaryHeading>
        <p className="text-theme-placeholder text-theme-blue text-13 font-semibold m-0 pt-2 text-center">
          {filter ? "Try Adjusting The Filter" : "Try To"}{" "}
          {filter && (link || onClick) ? "Or" : ""} &nbsp;
          {link || onClick ? (
            <Link
              onClick={(e) => {
                if (onClick) {
                  e.preventDefault();
                  onClick();
                }
              }}
              to={link ?? "/"}
              className="text-theme-blue text-13 font-semibold underline"
            >
              Add A New {linkLabel ?? "Template"}
            </Link>
          ) : (
            ""
          )}
        </p>
      </div>
    </div>
  );
}
