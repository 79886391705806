import { Children, useDeferredValue, useEffect, useState } from "react";
import { AddIcon, GlossaryIcon } from "allIcons";
import {
  AddFromTemplateLibrary,
  BodyContainer,
  FiltersList,
  HeaderContainer,
  InputField,
  ListCard,
  ListIconWrapper,
  Loader,
  LoadingListCards,
  MainButton,
  PopOver,
  PrimaryHeading,
  RightMainContainer,
  TemplateNotFound,
} from "commonUI";
import {
  useDefaultGlossary,
  useGlosssary,
  useDeleteDefaultGlossary,
  useAddDefaultGlossary,
} from "api/templateLibrary/glossary";
import { useTags } from "api/siteSettings/Tags";
import { search } from "helpers";
import {
  libraryItemFiltering,
  libraryItemSorting,
} from "filterAndSort/templateLibrary";
import { useUserPermissions } from "hooks/user";

export default function Glossary() {
  const { data: defaultGlossary, isLoading: loadingDefaultGlossary } =
    useDefaultGlossary();
  const { mutate: addDefaultGlossary, isLoading: addingDefaultGlossary } =
    useAddDefaultGlossary();
  const { mutate: deleteDefaultGlossary, isLoading: deletingDefaultGlossary } =
    useDeleteDefaultGlossary();

  const { data: glossary } = useGlosssary();
  const [addGlossary, setAddGlossary] = useState(false);

  const { data: tags, isLoading: tagsLoading, error: tagsError } = useTags();

  const [searchText, setSearchText] = useState("");
  const defferedSearch = useDeferredValue(searchText);
  const [filteredDefaultGlossary, setFilteredDefaultGlossary] = useState([]);
  const [filterData, setFilterData] = useState({});
  const {
    remove_items_from_site_template,
  } = useUserPermissions();

  useEffect(() => {
    setFilteredDefaultGlossary(
      defaultGlossary.filter((tutorial) =>
        search(tutorial.glossary_title, defferedSearch)
      )
    );
  }, [defaultGlossary, defferedSearch]);

  const applySorting = (order, orderBy) => {
    setFilteredDefaultGlossary((prevSearchResult) =>
      libraryItemSorting(order, orderBy, prevSearchResult, "glossary_title")
    );
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setFilteredDefaultGlossary(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        defaultGlossary
      )
    );
  };

  return (
    <>
      <RightMainContainer>
        {deletingDefaultGlossary || addingDefaultGlossary ? (
          <Loader loader="block" />
        ) : (
          ""
        )}
        <HeaderContainer>
          <PrimaryHeading>Glossary</PrimaryHeading>
          <div className="relative">
            <MainButton onClick={() => setAddGlossary(true)}>
              <AddIcon />
              Add
            </MainButton>
            <PopOver
              open={addGlossary}
              onClose={() => setAddGlossary(false)}
              style={{ right: "0" }}
              toolTip={{
                right: "35px",
                vertical: "top",
              }}
            >
              <AddFromTemplateLibrary
                data={glossary
                  .filter((_) => String(_.default_tem) !== "1")
                  .map((_) => ({
                    ..._,
                    title: _.glossary_title,
                    icon: <GlossaryIcon fill="white" />,
                    onClick: () => addDefaultGlossary(_.id),
                  }))}
                emptyTemplate={{
                  label: "Glossary",
                  link: "/template-library/glossary/add",
                  linkLabel: "Glossary",
                }}
              />
            </PopOver>
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            placeholder="Search"
            searchIcon={true}
            filter={true}
            value={searchText}
            setValue={(value) => setSearchText(value)}
            showLibrarySource={false}
            tags={tags}
            onApplyFilter={applyFilter}
            applySorting={applySorting}
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          {loadingDefaultGlossary ? (
            <LoadingListCards />
          ) : filteredDefaultGlossary.length === 0 ? (
            <TemplateNotFound
              label="Glossary"
              linkLabel="Glossary"
              onClick={() => setAddGlossary(true)}
            />
          ) : (
            Children.toArray(
              filteredDefaultGlossary.map((glossary, index) => {
                return (
                  <ListCard
                    editIcon={false}
                    data={glossary.glossary_title}
                    onCross={() => {
                      deleteDefaultGlossary(glossary.id);
                    }}
                    icon={
                      <ListIconWrapper>
                        <GlossaryIcon fill="white" />
                      </ListIconWrapper>
                    }
                    deleteIcon
                    crossIcon={remove_items_from_site_template}
                    showModalonCross
                    deleteTitle="Remove This Template?"
                    deleteButtonText="Confirm"
                    deleteMessage="You are about to remove this template from your default site template. Are you sure you want to do this?"
                  />
                );
              })
            )
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
