import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "helpers";

const initialState = {
  groups: [],
  loaded: false,
  form: {},
  defaultTemplate: {
    groups: [],
    loaded: false,
  },
};

const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    getAllGroups: (state, action) => {
      state.groups = action.payload;
      state.loaded = true;
      state.form = {};
    },
    addSingleGroup: (state, action) => {
      state.groups.push(action.payload);
      state.form = {};
    },
    updateSingleGroup: (state, action) => {
      const index = state.groups.findIndex((ele) =>
        isEqual(ele.id, action.payload.group_id)
      );
      if (index !== -1) {
        for (const key in action.payload) {
          state.groups[index][key] = action.payload[key];
        }
      }
      state.form = {};
      const defaultGroupIndex = state.defaultTemplate.groups.findIndex((ele) =>
        isEqual(ele.id, action.payload.group_id)
      );
      if (defaultGroupIndex !== -1) {
        for (const key in action.payload) {
          state.defaultTemplate.groups[defaultGroupIndex][key] = action.payload[key];
        }
      }
    },
    updateform: (state, action) => {
      state.form = action.payload;
    },
    deleteSingleGroup: (state, action) => {
      state.groups = state.groups.filter((_) => !isEqual(_.id, action.payload));
      state.defaultTemplate.groups = state.defaultTemplate.groups.filter((_) => !isEqual(_.id, action.payload));
      state.form = {};
    },
    getAllDefaultGroups: (state, action) => {
      state.defaultTemplate.groups = action.payload;
      state.defaultTemplate.loaded = true;
    },
    addSingleDefaultGroup: (state, action) => {
      const index = state.groups.findIndex((group) =>
        isEqual(group.id, action.payload.id)
      );
      if (!isEqual(index, -1)) {
        state.groups[index].default_tem = "1";
        state.defaultTemplate.groups.push(state.groups[index]);
      }
    },
    deleteSingleDefaultGroup: (state, action) => {
      const index = state.groups.findIndex((group) =>
        isEqual(group.id, action.payload)
      );
      if (!isEqual(index, -1)) {
        state.groups[index].default_tem = "0";
      }
      state.defaultTemplate.groups = state.defaultTemplate.groups.filter(
        (group) => !isEqual(group.id, action.payload)
      );
    },
  },
});

export const {
  getAllGroups,
  addSingleGroup,
  updateSingleGroup,
  updateform,
  deleteSingleGroup,
  getAllDefaultGroups,
  addSingleDefaultGroup,
  deleteSingleDefaultGroup,
} = groupsSlice.actions;

export default groupsSlice.reducer;
