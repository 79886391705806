export default function DuplicateIcon(props) {
  function HandleClick() {
    props.onClick && props.onClick();
  }
  return (
    <>
      <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${props.className ?? ""}`}
        onClick={HandleClick}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.37012 8.51001H11.5302C11.7291 8.51001 11.9198 8.431 12.0605 8.29034C12.2011 8.14969 12.2802 7.95892 12.2802 7.76001V0.76001C12.2776 0.561903 12.1977 0.372639 12.0576 0.232544C11.9175 0.0924492 11.7283 0.0125999 11.5302 0.0100098H1.37012C1.17201 0.0125999 0.982746 0.0924492 0.842651 0.232544C0.702557 0.372639 0.622707 0.561903 0.620117 0.76001V7.76001C0.620117 7.95892 0.699131 8.14969 0.839783 8.29034C0.980435 8.431 1.1712 8.51001 1.37012 8.51001ZM10.7802 7.01001H2.12012V1.51001H10.7802V7.01001ZM4.45996 12H14.6199C14.7205 12.0041 14.8208 11.9876 14.9147 11.9517C15.0087 11.9157 15.0943 11.8609 15.1664 11.7907C15.2384 11.7205 15.2954 11.6364 15.3339 11.5434C15.3723 11.4504 15.3914 11.3506 15.39 11.25V4.25C15.39 4.05109 15.3109 3.86032 15.1703 3.71967C15.0296 3.57901 14.8389 3.5 14.64 3.5C14.4418 3.50259 14.2526 3.58244 14.1125 3.72253C13.9724 3.86263 13.8925 4.05189 13.89 4.25V10.5H4.45996C4.26185 10.5026 4.07259 10.5824 3.9325 10.7225C3.7924 10.8626 3.71255 11.0519 3.70996 11.25C3.70996 11.4489 3.78897 11.6397 3.92963 11.7803C4.07028 11.921 4.26105 12 4.45996 12Z"
          fill={props.fill ?? "#4C5866"}
        />
      </svg>
    </>
  );
}
