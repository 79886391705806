export default function GridIcon(props) {
  function HandleClick() {
    props.onClick && props.onClick();
  }
  return (
    <>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`cursor-pointer ${props.className ?? ""}`}
        onClick={HandleClick}>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.93104 7.17242H5.24138C6.30621 7.17242 7.17242 6.30621 7.17242 5.24138V1.93104C7.17242 0.866207 6.30621 0 5.24138 0H1.93104C0.866207 0 0 0.866207 0 1.93104V5.24138C0 6.30621 0.866207 7.17242 1.93104 7.17242ZM1.65517 1.93104C1.65517 1.77655 1.77655 1.65517 1.93104 1.65517H5.24138C5.39586 1.65517 5.51724 1.77655 5.51724 1.93104V5.24138C5.51724 5.39586 5.39586 5.51724 5.24138 5.51724H1.93104C1.77655 5.51724 1.65517 5.39586 1.65517 5.24138V1.93104ZM10.7582 7.17242H14.0685C15.1334 7.17242 15.9996 6.30621 15.9996 5.24138V1.93104C15.9996 0.866207 15.1334 0 14.0685 0H10.7582C9.69336 0 8.82715 0.866207 8.82715 1.93104V5.24138C8.82715 6.30621 9.69336 7.17242 10.7582 7.17242ZM10.4823 1.93104C10.4823 1.77655 10.6037 1.65517 10.7582 1.65517H14.0685C14.223 1.65517 14.3444 1.77655 14.3444 1.93104V5.24138C14.3444 5.39586 14.223 5.51724 14.0685 5.51724H10.7582C10.6037 5.51724 10.4823 5.39586 10.4823 5.24138V1.93104ZM5.24138 16H1.93104C0.866207 16 0 15.1338 0 14.069V10.7586C0 9.6938 0.866207 8.82759 1.93104 8.82759H5.24138C6.30621 8.82759 7.17242 9.6938 7.17242 10.7586V14.069C7.17242 15.1338 6.30621 16 5.24138 16ZM1.93104 10.4828C1.77655 10.4828 1.65517 10.6041 1.65517 10.7586V14.069C1.65517 14.2235 1.77655 14.3448 1.93104 14.3448H5.24138C5.39586 14.3448 5.51724 14.2235 5.51724 14.069V10.7586C5.51724 10.6041 5.39586 10.4828 5.24138 10.4828H1.93104ZM10.7582 16H14.0685C15.1334 16 15.9996 15.1338 15.9996 14.069V10.7586C15.9996 9.6938 15.1334 8.82759 14.0685 8.82759H10.7582C9.69336 8.82759 8.82715 9.6938 8.82715 10.7586V14.069C8.82715 15.1338 9.69336 16 10.7582 16ZM10.4823 10.7586C10.4823 10.6041 10.6037 10.4828 10.7582 10.4828H14.0685C14.223 10.4828 14.3444 10.6041 14.3444 10.7586V14.069C14.3444 14.2235 14.223 14.3448 14.0685 14.3448H10.7582C10.6037 14.3448 10.4823 14.2235 10.4823 14.069V10.7586Z" fill={props.fill ?? "#A3B0C2"} />
      </svg>
    </>
  );
}
