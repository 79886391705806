import { printError, useUtilities } from "helpers";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  defaultTaskAdd,
  defaultTaskRemove,
  defaultTaskShow,
  templateTaskAdd,
  templateTaskDelete,
  templateTaskEdit,
  templateTaskShow,
} from "./modularApi";
import { ApiResponseType, NotificationType } from "enums";
import {
  addTemplateNote,
  deleteTemplateNote,
  getAllTemplateNotes,
  updateTemplateNote,
  addDefaultSiteNote,
  removeDefaultSiteNote,
  getDefaultSiteNotes,
} from "redux/reducers/templateLibrary/notes";
import { useEffect } from "react";

export function useAllNotes() {
  return useQuery(["getallnotes"], () => templateTaskShow("notes"));
}

export function useTemplateNotes() {
  const { data, isLoading, error } = useAllNotes();
  const {
    dispatch,
    notes: { notes, loaded },
  } = useUtilities();
  useEffect(() => {
    if (isLoading || loaded) return;
    if (data) {
      dispatch(getAllTemplateNotes(data.results));
    }
  }, [data, isLoading, loaded]);
  return { data: notes, isLoading: isLoading || !loaded, error };
}

export function useAddNoteTemplate(redirect = "") {
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(
    (params) =>
      templateTaskAdd({
        ...params,
        parent_type: "notes",
        module_hint: "notes",
      }),
    {
      onSuccess: (data) => {
        if (data.StatusCode === ApiResponseType.SUCCESS) {
          dispatch(addTemplateNote(data.Data));
          notification({
            message: "Note Added Successfully",
            type: NotificationType.SUCCESS,
          });
          if (redirect) navigate(redirect + data.Data.id);
        } else {
          notification({
            message: printError(data.Message),
            type: NotificationType.ERROR,
          });
        }
      },
      onError: (error) => {
        notification({
          message: printError(error.message),
          type: NotificationType.ERROR,
        });
      },
    }
  );
}

export function useUpdateNoteTemplate() {
  const { dispatch, notification } = useUtilities();
  return useMutation(
    (params) =>
      templateTaskEdit({
        ...params,
        parent_type: "notes",
        module_hint: "notes",
      }),
    {
      onSuccess: (data) => {
        if (data.Data.id) {
          dispatch(updateTemplateNote(data.Data));
          notification({
            message: "Note Updated Successfully",
            type: "success",
          });
        } else {
          notification({
            message: printError(data.Message),
            type: "error",
          });
        }
      },
      onError: (error) => {
        notification({
          message: printError(error.message),
          type: "error",
        });
      },
    }
  );
}

export function useDeleteNoteTemplate(redirect = "") {
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(templateTaskDelete, {
    onSuccess: (data) => {
      if (data.Status_Code === 200) {
        dispatch(deleteTemplateNote({ id: data.id }));
        notification({
          message: "Note deleted successfully",
          type: "success",
        });
        if (redirect) navigate(redirect);
      } else {
        notification({
          message: printError(data.Message),
          type: "error",
        });
      }
    },
    onError: (error) => {
      notification({
        message: printError(error.message),
        type: "error",
      });
    },
  });
}

export function useAllDefaultNote() {
  return useQuery(["getalldefaultnote"], () => defaultTaskShow("notes"));
}

export function useDefaultNotes() {
  const { data, isLoading, error } = useAllDefaultNote();
  const {
    dispatch,
    notes: {
      defaultSite: { notes, loaded },
    },
  } = useUtilities();

  useEffect(() => {
    if (isLoading || loaded) return;
    if (data) {
      dispatch(getDefaultSiteNotes(data.Data));
    }
  }, [data, isLoading, loaded]);

  return { data: notes, isLoading: isLoading || !loaded, error };
}

export function useAddDefaultNote() {
  const { dispatch, notification } = useUtilities();
  return useMutation(defaultTaskAdd, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(addDefaultSiteNote(data.Data));
        notification({
          message: "Note Added in New Site Default Configuration Successfully",
          type: "success",
        });
      } else {
        notification({
          message: printError(
            data.Message ||
              "Can't Add Note in New Site Default Configuration, Try Agian Later"
          ),
          type: "error",
        });
      }
    },
    onError: (error) => {
      notification({
        message:
          error.message ||
          "Can't Add Note in New Site Default Configuration, Try Agian Later",
        type: "error",
      });
    },
  });
}

export function useRemoveDefaultNote() {
  const { dispatch, notification } = useUtilities();
  return useMutation(defaultTaskRemove, {
    onSuccess: (data) => {
      if (data.Status_Code === 200) {
        dispatch(removeDefaultSiteNote({ id: data.id }));
        notification({
          type: "success",
          message:
            "Note Removed from New Site Default Configuration Successfully",
        });
      } else {
        notification({
          type: "error",
          meessage: printError(
            data.Message ||
              "Can't Remove Note from New Site Default Configuration, Try Again Later"
          ),
        });
      }
    },
    onError: (error) => {
      notification({
        type: "error",
        meessage: printError(
          error.meessage ||
            "Can't Remove Note from New Site Default Configuration, Try Again Later"
        ),
      });
    },
  });
}
