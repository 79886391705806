import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    emailTemplates: [],
    loaded: false,
}

const emailTemplatesSlice = createSlice({
    name: "emailTemplates",
    initialState,
    reducers: {
        setEmailTemplatesAction: (state, action) => {
            state.emailTemplates = action.payload;
            state.loaded = true;
        }
    }
})

export const { setEmailTemplatesAction } = emailTemplatesSlice.actions;
export default emailTemplatesSlice.reducer;