
export default function AccordionBody({ children, show }) {

  return (
    <div
      className="accordion-body"
      aria-expanded={show}
      style={{
        maxHeight: `${
          show ? `unset` : "0px"
        }`,
      }}
    >
      {children}
    </div>
  );
}
