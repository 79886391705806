export default function CalenderIcon({ fill = "#4C5866", className = "" }) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ""}
    >
      <path
        d="M12.3387 1.45161H11.129V0.725806C11.129 0.533311 11.0526 0.348699 10.9164 0.212584C10.7803 0.0764687 10.5957 0 10.4032 0C10.2107 0 10.0261 0.0764687 9.89 0.212584C9.75389 0.348699 9.67742 0.533311 9.67742 0.725806V1.45161H5.32258V0.725806C5.32258 0.533311 5.24611 0.348699 5.11 0.212584C4.97388 0.0764687 4.78927 0 4.59677 0C4.40428 0 4.21967 0.0764687 4.08355 0.212584C3.94744 0.348699 3.87097 0.533311 3.87097 0.725806V1.45161H2.66129C1.95587 1.45289 1.2797 1.73369 0.780887 2.2325C0.282075 2.73131 0.00127911 3.40748 0 4.1129L0 12.3387C0 13.0445 0.280385 13.7214 0.779474 14.2205C1.27856 14.7196 1.95547 15 2.66129 15H12.3387C13.0445 15 13.7214 14.7196 14.2205 14.2205C14.7196 13.7214 15 13.0445 15 12.3387V4.1129C14.9987 3.40748 14.7179 2.73131 14.2191 2.2325C13.7203 1.73369 13.0441 1.45289 12.3387 1.45161ZM13.5484 12.3387C13.5484 12.6595 13.4209 12.9672 13.1941 13.1941C12.9672 13.4209 12.6595 13.5484 12.3387 13.5484H2.66129C2.34046 13.5484 2.03278 13.4209 1.80592 13.1941C1.57906 12.9672 1.45161 12.6595 1.45161 12.3387V7.25806H13.5484V12.3387ZM13.5484 5.80645H1.45161V4.1129C1.45289 3.79247 1.58074 3.48552 1.80733 3.25894C2.03391 3.03236 2.34086 2.9045 2.66129 2.90323H3.87097V3.62903C3.87097 3.82153 3.94744 4.00614 4.08355 4.14225C4.21967 4.27837 4.40428 4.35484 4.59677 4.35484C4.78927 4.35484 4.97388 4.27837 5.11 4.14225C5.24611 4.00614 5.32258 3.82153 5.32258 3.62903V2.90323H9.67742V3.62903C9.67742 3.82153 9.75389 4.00614 9.89 4.14225C10.0261 4.27837 10.2107 4.35484 10.4032 4.35484C10.5957 4.35484 10.7803 4.27837 10.9164 4.14225C11.0526 4.00614 11.129 3.82153 11.129 3.62903V2.90323H12.3387C12.6591 2.9045 12.9661 3.03236 13.1927 3.25894C13.4193 3.48552 13.5471 3.79247 13.5484 4.1129V5.80645Z"
        fill={fill ?? "#4C5866"}
      />
      <path
        d="M4.59589 8.70966H3.62815C3.2273 8.70966 2.90234 9.03461 2.90234 9.43546V10.4032C2.90234 10.8041 3.2273 11.129 3.62815 11.129H4.59589C4.99674 11.129 5.3217 10.8041 5.3217 10.4032V9.43546C5.3217 9.03461 4.99674 8.70966 4.59589 8.70966Z"
        fill={fill ?? "#4C5866"}
      />
    </svg>
  );
}
