export default function ColorPickerIcon({
  size = 16,
  fill = "white",
  ...rest
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.5677 0.69638C14.3469 0.475603 14.0849 0.300471 13.7965 0.180985C13.508 0.0614989 13.1989 0 12.8867 0C12.5745 0 12.2653 0.0614989 11.9769 0.180985C11.6885 0.300471 11.4264 0.475603 11.2057 0.69638L9.52427 2.37699L8.96406 1.816C8.89044 1.74243 8.80305 1.68408 8.70688 1.64429C8.61072 1.60449 8.50765 1.58403 8.40358 1.58407C8.2995 1.5841 8.19645 1.60464 8.10031 1.6445C8.00417 1.68437 7.91682 1.74277 7.84326 1.81639C7.76969 1.89001 7.71134 1.9774 7.67155 2.07357C7.63175 2.16974 7.61129 2.2728 7.61133 2.37688C7.61136 2.48095 7.6319 2.584 7.67176 2.68014C7.71163 2.77628 7.77003 2.86363 7.84365 2.9372L12.3261 7.41883C12.4761 7.55971 12.675 7.63669 12.8808 7.63349C13.0866 7.6303 13.283 7.54717 13.4286 7.40171C13.5742 7.25624 13.6574 7.05985 13.6608 6.85409C13.6641 6.64832 13.5873 6.44933 13.4465 6.29921L12.8863 5.73901L14.5677 4.05761C15.0133 3.61183 15.2637 3.00732 15.2637 2.37699C15.2637 1.74667 15.0133 1.14215 14.5677 0.69638Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.86822 4.03381L1.70518 10.1976C1.35739 10.5452 1.12592 10.9921 1.04262 11.4767C0.959322 11.9613 1.0283 12.4598 1.24006 12.9036L0 14.1436L1.12041 15.2648L2.36046 14.024C2.80416 14.2358 3.30262 14.305 3.78721 14.2218C4.27181 14.1386 4.71872 13.9073 5.0664 13.5597L11.2294 7.39662L7.86822 4.03302V4.03381ZM8.98862 7.39583L7.86822 6.27542L2.82558 11.3181C2.68125 11.4675 2.60138 11.6676 2.60319 11.8754C2.60499 12.0832 2.68833 12.2819 2.83524 12.4288C2.98215 12.5757 3.18088 12.6591 3.38864 12.6609C3.5964 12.6627 3.79655 12.5828 3.94599 12.4385L8.98862 7.39583Z"
        fill={fill}
      />
    </svg>
  );
}
