import {
  MainButton,
  LimitReachedCard,
  ModuleLimitDetail,
} from "commonUI";
import { OpenLinkIcon } from "allIcons";
import "./Sites.css";
import { useSites } from "api/sites";
import { isEqual } from "helpers";

export default function SiteCard(props) {
  const { data = {} } = useSites();
  const { storage_details, site_details } = data;
  return (
    <>
      <div className="site-card">
        <LimitReachedCard
          show={isEqual(
            site_details?.sites_limit,
            site_details?.total_used_site
          )}
          title="Sites Limit Reached"
          description="You've reached the maximum number of sites allowed under your current plan. To add more sites, please consider upgrading your account."
        />
        <ModuleLimitDetail
          title="Sites"
          isLoading={props.isLoading}
          description={props.sites.description}
          progress={props.sites.line}
        />
        <LimitReachedCard
          show={isEqual(
            storage_details?.storage_limit,
            storage_details?.total_used_storage
          )}
          title="Storage Limit Reached"
          description="You've reached the maximum storage capacity allowed under your current plan. To increase storage capacity, please consider upgrading your account."
        />
        <ModuleLimitDetail
          title="Storage"
          isLoading={props.isLoading}
          description={props.storage.description}
          progress={props.storage.line}
        />
        <a
          href="https://wpsitelauncher.com/customer-dashboard/?action=index&model=subscription"
          target="_blank"
          rel="noreferrer"
        >
          <MainButton>
            <OpenLinkIcon />
            Manage Plan
          </MainButton>
        </a>
      </div>
    </>
  );
}
