import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "helpers";

const initialState = {
  plugins: [],
  loaded: false,
};

const pluginsSlice = createSlice({
  name: "plugins",
  initialState,
  reducers: {
    getAllTemplatePlugins: (state, action) => {
      state.plugins = action.payload;
      state.loaded = true;
    },
    deleteTemplatePlugin: (state, action) => {
      state.plugins = state.plugins.filter(
        (plugin) => plugin.id !== action.payload
      );
    },
    addTemplatePlugin: (state, action) => {
      state.plugins.push(action.payload);
    },
    updateTemplatePlugin: (state, action) => {
      state.plugins = state.plugins.map((plugin) =>
        isEqual(plugin.id, action.payload.id) ? action.payload : plugin
      );
    },
  },
});

export const { getAllTemplatePlugins, deleteTemplatePlugin, addTemplatePlugin, updateTemplatePlugin } = pluginsSlice.actions;

export default pluginsSlice.reducer;
