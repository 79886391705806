export default function NotesIcon({ fill, ...props }) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.3387 0H2.66129C1.95586 0.0012791 1.2797 0.282075 0.780886 0.780886C0.282075 1.2797 0.0012791 1.95586 0 2.66129L0 12.3387C0.0012791 13.0441 0.282075 13.7203 0.780886 14.2191C1.2797 14.7179 1.95586 14.9987 2.66129 15H7.66451C8.01419 15.0012 8.36063 14.9329 8.68374 14.7992C9.00685 14.6655 9.30019 14.4689 9.54677 14.221L14.221 9.54677C14.4689 9.30019 14.6655 9.00685 14.7992 8.68374C14.9329 8.36063 15.0012 8.01419 15 7.66451V2.66129C14.9987 1.95586 14.7179 1.2797 14.2191 0.780886C13.7203 0.282075 13.0441 0.0012791 12.3387 0V0ZM13.1951 8.52096L8.52096 13.1951C8.43343 13.2814 8.33404 13.3547 8.2258 13.4129V9.43547C8.2258 9.11465 8.35325 8.80696 8.5801 8.5801C8.80696 8.35325 9.11465 8.2258 9.43547 8.2258H13.4129C13.3547 8.33404 13.2814 8.43343 13.1951 8.52096ZM13.5484 6.77419H9.43547C8.73005 6.77547 8.05388 7.05626 7.55507 7.55507C7.05626 8.05388 6.77547 8.73005 6.77419 9.43547V13.5484H2.66129C2.34046 13.5484 2.03278 13.4209 1.80592 13.1941C1.57906 12.9672 1.45161 12.6595 1.45161 12.3387V2.66129C1.45161 2.34046 1.57906 2.03278 1.80592 1.80592C2.03278 1.57906 2.34046 1.45161 2.66129 1.45161H12.3387C12.6595 1.45161 12.9672 1.57906 13.1941 1.80592C13.4209 2.03278 13.5484 2.34046 13.5484 2.66129V6.77419Z"
        fill={fill ?? "white"}
      />
    </svg>
  );
}
