export default function SiteIcon(props) {
  function HandleClick() {
    props.onClick && props.onClick();
  }
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`cursor-pointer ${props.className ?? ""}`}
        onClick={HandleClick}
      >
        <path
          d="M9 0.75C6.81278 0.752646 4.71591 1.62271 3.16931 3.16931C1.62271 4.71591 0.752646 6.81278 0.75 9C0.752646 11.1872 1.62271 13.2841 3.16931 14.8307C4.71591 16.3773 6.81278 17.2474 9 17.25C11.1872 17.2474 13.2841 16.3773 14.8307 14.8307C16.3774 13.2841 17.2474 11.1872 17.25 9C17.2474 6.81278 16.3774 4.71591 14.8307 3.16931C13.2841 1.62271 11.1872 0.752646 9 0.75ZM14.91 5.75H12.45C12.2608 4.7076 11.9241 3.69746 11.45 2.75C12.9089 3.32237 14.1245 4.38248 14.89 5.75H14.91ZM9 15.75C8.35 15.75 7.5 14.45 7.06 12.25H10.94C10.49 14.45 9.64 15.75 9 15.75ZM9 2.25C9.64 2.25 10.49 3.55 10.94 5.75H7.06C7.5 3.55 8.35 2.25 9 2.25ZM6.83002 7.25H11.16C11.2218 7.83127 11.2518 8.41547 11.25 9C11.2518 9.58453 11.2218 10.1687 11.16 10.75H6.83002C6.78002 10.2 6.75 9.62 6.75 9C6.75 8.38 6.78002 7.8 6.83002 7.25ZM6.5 2.72998C6.02596 3.67745 5.68925 4.68758 5.5 5.72998H3.04004C3.81313 4.36108 5.03537 3.3013 6.5 2.72998ZM2.25 9C2.24939 8.40909 2.32677 7.82068 2.48004 7.25H5.33002C5.28002 7.83 5.25 8.41 5.25 9C5.25 9.59 5.25002 10.17 5.33002 10.75H2.48004C2.32734 10.1792 2.24997 9.59087 2.25 9ZM3.08002 12.25H5.54004C5.73085 13.292 6.06749 14.3019 6.54004 15.25C5.07541 14.6787 3.8531 13.6189 3.08002 12.25ZM11.49 15.25C11.9625 14.3019 12.2992 13.292 12.49 12.25H14.89C14.1381 13.6181 12.9372 14.6848 11.49 15.27V15.25ZM12.66 10.73C12.7199 10.1486 12.7499 9.56447 12.75 8.97998C12.7501 8.39549 12.7201 7.81138 12.66 7.22998H15.51C15.6715 7.7992 15.7523 8.38829 15.75 8.97998C15.7517 9.57163 15.6709 10.1606 15.51 10.73H12.66Z"
          fill={props.fill ?? "white"}
        />
      </svg>
    </>
  );
}
