export default function ErrorIcon({ fill }) {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 16C6.41778 16 4.87102 15.5308 3.55544 14.6517C2.23985 13.7727 1.21447 12.5233 0.608968 11.0615C0.0034656 9.59963 -0.154961 7.99112 0.153723 6.43927C0.462399 4.88741 1.22433 3.46197 2.34315 2.34315C3.46196 1.22433 4.88747 0.462405 6.43929 0.153722C7.99111 -0.15496 9.59964 0.00346536 11.0615 0.608968C12.5233 1.21447 13.7727 2.23984 14.6517 3.55544C15.5308 4.87104 16 6.41775 16 8.00004C15.9974 10.1209 15.1538 12.1543 13.654 13.654C12.1543 15.1537 10.121 15.9974 8 16ZM8 1.45455C6.70542 1.45455 5.43991 1.83843 4.36355 2.55765C3.28715 3.27687 2.4482 4.29914 1.95279 5.49512C1.45738 6.69118 1.32776 8.00724 1.58032 9.27692C1.83288 10.5466 2.45627 11.7129 3.37167 12.6283C4.28707 13.5437 5.45333 14.1671 6.72302 14.4197C7.99271 14.6722 9.3088 14.5426 10.5048 14.0472C11.7009 13.5518 12.7231 12.7129 13.4423 11.6365C14.1616 10.5601 14.5454 9.29458 14.5454 8.00004C14.5429 6.26484 13.8524 4.60147 12.6255 3.37449C11.3986 2.14755 9.7352 1.45711 8 1.45455Z"
          fill={fill ?? "white"}
        />
        <path
          d="M8.11556 11.6412C7.93342 11.6412 7.75876 11.5714 7.62987 11.4472C7.50107 11.323 7.42871 11.1545 7.42871 10.9788V7.8879C7.42871 7.71227 7.50107 7.54376 7.62987 7.41956C7.75876 7.29536 7.93342 7.22559 8.11556 7.22559C8.29778 7.22559 8.47244 7.29536 8.60133 7.41956C8.73013 7.54376 8.80249 7.71227 8.80249 7.8879V10.9788C8.80249 11.1545 8.73013 11.323 8.60133 11.4472C8.47244 11.5714 8.29778 11.6412 8.11556 11.6412Z"
          fill={fill ?? "white"}
        />
        <path
          d="M8.11553 6.12272C8.62131 6.12272 9.03135 5.72732 9.03135 5.2396C9.03135 4.7518 8.62131 4.35645 8.11553 4.35645C7.60975 4.35645 7.19971 4.7518 7.19971 5.2396C7.19971 5.72732 7.60975 6.12272 8.11553 6.12272Z"
          fill={fill ?? "white"}
        />
      </svg>
    </>
  );
}
