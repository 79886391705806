import { axiosInstance, useUtilities } from "helpers";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getAllSites, updateSite } from "redux/reducers/sites";
import { useEffect } from "react";

export async function getUserSites() {
  return axiosInstance
    .get(`usersitestoragedetails?user_id=${localStorage.getItem("user_id")}`)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
}

export function useGetUserSites() {
  return useQuery(["sitesData"], getUserSites);
}

export function useSites() {
  const { data, isLoading, error, isRefetching } = useGetUserSites();
  const {
    dispatch,
    sites: { sites, loaded },
  } = useUtilities();

  useEffect(() => {
    if (isLoading || loaded || isRefetching) return;
    dispatch(getAllSites(data));
  }, [data, isLoading, error, loaded, isRefetching]);

  return { data: sites, isLoading: isLoading || !loaded || isRefetching, error };
}

export async function updateSiteDetails(siteData) {
  return axiosInstance
    .post("updatewebsitestatus", {
      ...siteData,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUpdateSite(showNotification = true) {
  const { dispatch, notification } = useUtilities();
  return useMutation(updateSiteDetails, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(updateSite(data.Data[0]));
        if (showNotification) {
          notification({
            type: "SUCCESS",
            message: "Site data updated successfully",
          });
        }
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (err) =>
      notification({
        type: "error",
        message: err.message,
      }),
  });
}
