import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "helpers";

const initialState = {
  approvals: [],
  loaded: false,
  website_id: null,
};

const approvalsSlice = createSlice({
  name: "approvals",
  initialState,
  reducers: {
    setApprovalsAction(state, action) {
      const { website_id, approvals } = action.payload;
      state.approvals = approvals;
      state.loaded = true;
      state.website_id = website_id;
    },
    updateApprovalAction(state, action) {
      const index = state.approvals.findIndex((_) =>
        isEqual(_.id, action.payload.id)
      );
      if (index !== -1) {
        for (const key in action.payload) {
          state.approvals[index][key] = action.payload[key];
        }
      }
    },
  },
});

export const { setApprovalsAction, updateApprovalAction } = approvalsSlice.actions;

export default approvalsSlice.reducer;