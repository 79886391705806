export const ModulePrefixes = {
  SITE_SETTINGS: "site-settings",
  TEMPLATE_LIBRARY: "template-library",
  SITE_SETTINGS_LIBRARY_ITEM: "site-settings/library-item",
  USERS: "users",
  SITES: "sites",
};

export const SiteSettingsMenu = {
  GENERAL_SETTINGS: "general-settings",
  FEATURES_SETTINGS: "features-settings",
  USER_PREFRENCES: "user-prefrences",
  LIBRARY_TEMPLATES: "library-templates",
};

export const featureModules = {
  BRANDS_AND_COLORING: "Brand",
}