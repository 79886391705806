export default function SecondaryHeading(props) {
    return (
      <>
        <h2 className={`secondary-heading transition-all ${props.className ?? ''} capitalize`}
        onClick={props.onClick}>
        {props.children}
        </h2>
      </>
    );
  }
  