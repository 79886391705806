import { useSites } from "api/sites";

export default function useUserPlan() {
  const { data, isLoading, error } = useSites();
  return {
    data: data.wpsl_plan || {},
    isLoading,
    error,
    user_details: data?.user_details,
  };
}

export const useUserLimitReached = () => {
  const { isLoading, user_details } = useUserPlan();
  const usagePercentage =
    (Number(user_details?.total_used_users) /
      Number(user_details?.users_limit)) *
    100;
  return {
    isLoading: isLoading,
    isLimitReached:
      Number(user_details?.users_limit) <= Number(user_details?.total_used_users),
    usagePercentage: (usagePercentage > 100 ? 100 : usagePercentage).toFixed(2),
    totalUsers: user_details?.users_limit,
    members: user_details?.total_used_users,
  };
};
