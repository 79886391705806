import { Children } from "react";
export function LoadingCard({ className, style = {} }) {
  return (
    <>
      <div
        className={`w-full animate-pulse bg-theme-card-bg rounded-5 h-10 ${
          className ?? ""
        }`}
        style={style}
      />
    </>
  );
}

export function LoadingGridCards({ className, listClass, rows = 4 }) {
  return (
    <>
      <div className={`flex flex-row gap-10 ${className ?? ""}`}>
        {Children.toArray(
          new Array(rows)
            .fill(0)
            .map((_, index) => (
              <LoadingCard
                className={`w-60 h-44 ${listClass ?? ""}`}
                key={index}
              />
            ))
        )}
      </div>
    </>
  );
}

export function LoadingListCards({ className, listClass, rows = 4 }) {
  return (
    <>
      <div className={`flex flex-col gap-10 ${className ?? ""}`}>
        {Children.toArray(
          new Array(rows)
            .fill(0)
            .map((_, index) => (
              <LoadingCard className={listClass} key={index} />
            ))
        )}
      </div>
    </>
  );
}
