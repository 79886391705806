import {
  HeaderContainer,
  PrimaryHeading,
  RightMainContainer,
  IconWrapper,
  BodyContainer,
  InputField,
  ListCard,
  ListIconWrapper,
  LoadingListCards,
  TemplateNotFound,
  Loader,
} from "commonUI";
import { AddIcon, BackArrow, CheckIcon, PluginIcon } from "allIcons";
import usePluginSearch from "./usePluginSearch";
import { useState, useDeferredValue } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAddPlugin, usePlugins } from "api/templateLibrary/plugins";
import { textParser, useUtilities } from "helpers";
import { useUserPermissions } from "hooks/user";

export default function AddWPPlugin() {
  const {
    notification,
    user: { userData },
  } = useUtilities();
  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const defferdsearch = useDeferredValue(searchText, {
    timeoutMs: 500,
  });
  const { add_new_templates } = useUserPermissions();

  const { loading, error, plugins, hasMore } = usePluginSearch(
    defferdsearch,
    pageNumber
  );

  const { mutate: addPlugin, isLoading } = useAddPlugin();
  const { data: pluginsList } = usePlugins();

  return (
    <>
      <RightMainContainer>
        {isLoading ? <Loader loader="block" /> : ""}
        <HeaderContainer>
          <IconWrapper>
            <BackArrow />
            <PrimaryHeading>
              Search For Plugin In WordPress Repository
            </PrimaryHeading>
          </IconWrapper>
        </HeaderContainer>
        <BodyContainer id="scrollableDiv">
          <InputField
            placeholder="Search"
            value={searchText}
            setValue={(value) => {
              setSearchText(value);
              setPageNumber(1);
            }}
            searchIcon
            className="mb-1"
            padding={{ padding: `9px 10px` }}
          />
          {loading && <LoadingListCards />}
          {error ? (
            <TemplateNotFound label="Plugin" />
          ) : (
            <InfiniteScroll
              dataLength={plugins.length}
              next={() => {
                setPageNumber((prev) => prev + 1);
              }}
              hasMore={hasMore}
              loader={<LoadingListCards />}
              scrollableTarget="scrollableDiv"
              endMessage={
                !loading &&
                !hasMore &&
                plugins.length > 0 && (
                  <p className="body-text" style={{ textAlign: "center" }}>
                    <b>No more Plugins</b>
                  </p>
                )
              }
            >
              {plugins.map((plugin) => {
                return (
                  <ListCard
                    key={plugin.id}
                    data={plugin.name}
                    secondaryData={
                      pluginsList?.filter((_) => _.plugin_slug === plugin.slug)
                        .length > 0
                        ? "Added"
                        : ""
                    }
                    editIcon={false}
                    deleteIcon={true}
                    addIcon={
                      add_new_templates ? (
                        pluginsList?.filter(
                          (_) => _.plugin_slug === plugin.slug
                        ).length > 0 ? (
                          <CheckIcon fill="#4C5866" />
                        ) : (
                          <AddIcon fill="#4C5866" />
                        )
                      ) : null
                    }
                    onAddClick={() => {
                      if (
                        pluginsList?.filter(
                          (_) => _.plugin_slug === plugin.slug
                        ).length > 0
                      ) {
                        notification({
                          type: "error",
                          message: "Plugin already added",
                        });
                        return;
                      }
                      addPlugin({
                        plugin_title: plugin.name,
                        plugin_slug: plugin.slug,
                        plugin_icon: Object.values(plugin.icons)[0] ?? null,
                        added_by: userData.id,
                        plugin_type: 0,
                        tags: [],
                      });
                    }}
                    icon={
                      <ListIconWrapper className="bg-white">
                        {Object.keys(plugin.icons).length > 0 ? (
                          <img
                            src={Object.values(plugin.icons)[0]}
                            alt="plugin icon"
                            className="avatar"
                          />
                        ) : (
                          <PluginIcon />
                        )}
                      </ListIconWrapper>
                    }
                  />
                );
              })}
            </InfiniteScroll>
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
