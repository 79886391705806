import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { axiosInstance } from "helpers";

export async function userLogin(data) {
  return axios
    .post(`${process.env.REACT_APP_API_URL}login`, {
      ...data,
      login_from: "app",
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
}

export async function getUserData(userId) {
  return axiosInstance
    .get(`getuserprofile?userId=${userId}`)
    .then((res) => ({ ...res.data.Data[0], rolesData: res.data.rolesData }))
    .catch((err) => Promise.reject(err));
}

export async function userLogout() {
  return axiosInstance
    .post("logout")
    .then((res) => res.data)
    .catch((err) => err);
}

export async function checkKeyStatus({apikey, token}) {
  return axios({
    url: `${process.env.REACT_APP_API_URL}checkkeystatus`,
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      apikey: apikey,
    },
  })
    .then((res) => res.data)
    .catch((err) => err);
}

export function useCheckKeyStatus() {
  return useMutation(checkKeyStatus);
}


export function activateLicense(params) {
  return axios
    ({
      url: `${process.env.REACT_APP_API_URL}activate_sc_license`,
      method: "post",
      data: {
        apiKey: params.apiKey,
      },
      headers: {
        Authorization: `Bearer ${params.token}`,
      }
    })
    .then((res) => res.data)
    .catch((err) => err);
  
}