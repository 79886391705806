import { Link } from "react-router-dom";
import { RightArrow } from "allIcons";
import { SecondaryHeading } from "commonUI";

export default function GridCard(props) {
  return (
    <>
      <Link to={`${props.link}`} title={props.data}>
        <div className="list-grid-card">
          <img src={props.image} />
          <div className="flex  flex-row items-center justify-between cursor-pointer">
            <div className="flex flex-row gap-10 items-center">
              {props.icon}
              <SecondaryHeading className="clamp-text">{props.data}</SecondaryHeading>
            </div>
            <div className="px-2">
              <RightArrow />
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}
