import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getMaintenanceSetting,
  getPagesList,
  updateMaintenanceSetting,
} from "api/siteSettings/maintenance";
import { ApiResponseType, NotificationType } from "enums";
import { printError, useSearch, useUtilities } from "helpers";
import { useEffect } from "react";
import { setMaintenanceSetting } from "redux/reducers/siteSettings/maintenance";

export function useGetMaintenanceSetting() {
  const { web = null } = useSearch();
  return useQuery(["maintenance", web], () => getMaintenanceSetting(web));
}

export function useMaintenance() {
  const { web = null } = useSearch();
  const { data, isLoading, error } = useGetMaintenanceSetting();
  const {
    dispatch,
    maintenance: { maintenance, loaded, website_id },
  } = useUtilities();

  useEffect(() => {
    if (data && (!loaded || website_id !== web)) {
      dispatch(
        setMaintenanceSetting({
          maintenance: data.Data[0],
          website_id: web,
        })
      );
    }
  }, [data]);

  return {
    data: maintenance,
    isLoading: isLoading || !loaded,
    error,
  };
}

export function usePagesList() {
  const { web } = useSearch();
  return useQuery(["pages", web], () => getPagesList(web), {
    enabled: Boolean(web),
  });
}

export function useUpdateMaintenanceSetting() {
  const { notification, dispatch } = useUtilities();
  const { web = null } = useSearch();

  return useMutation(updateMaintenanceSetting, {
    onSuccess: (data) => {
      if (data.StatusCode === ApiResponseType.SUCCESS) {
        dispatch(
          setMaintenanceSetting({
            maintenance: data.Data,
            website_id: web,
          })
        );
        notification({
          type: NotificationType.SUCCESS,
          message: "Maintenance settings updated successfully",
        });
      } else {
        notification({
          type: NotificationType.ERROR,
          message: printError(data.Message),
        });
      }
    },
    onError: (error) => {
      notification({
        type: NotificationType.ERROR,
        message: printError(error.message),
      });
    },
  });
}
