import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Companies from "./Companies/Companies";
import Member from "./Members/Member";
import AddEditMember from "./Members/AddEditMember";
import AddEditCompany from "./Companies/AddEditCompany";
import Roles from "./Roles";
import Groups from "./Groups";
import AddEditGroup from "./Groups/AddEditGroup";
import AddEditRole from "./Roles/AddEditRole";
import { useUserPermissions } from "hooks/user";
export default function Index() {
  const { app_users_view_users } = useUserPermissions();
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to={app_users_view_users ? "/users/team-members":"/users/roles" } />} />
        <Route path="/team-members">
          <Route index element={<Member />} />
          <Route path={"/team-members/:id"} element={<AddEditMember />} />
        </Route>
        <Route exact path="/clients">
          <Route index element={<Member />} />
          <Route path={"/clients/:id"} element={<AddEditMember />} />
        </Route>
        <Route path="/companies">
          <Route index element={<Companies />} />
          <Route path={"/companies/:id"} element={<AddEditCompany />} />
        </Route>
        <Route exact path={`/groups`}>
          <Route exact index element={<Groups />} />
          <Route exact path="/groups/:id" element={<AddEditGroup />} />
        </Route>
        <Route path="/roles">
          <Route index element={<Roles />} />
          <Route path={"/roles/:id"} element={<AddEditRole />} />
        </Route>
        <Route path="*" element={<div>Not Found</div>} />
      </Routes>
    </>
  );
}
