export default function OpenLinkIcon({ fill, className }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ""}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7496 5.50007V1.00007C15.7496 0.801156 15.6706 0.610386 15.53 0.469734C15.3893 0.329082 15.1985 0.250069 14.9996 0.250069H10.4996C10.4008 0.248723 10.3027 0.267175 10.2111 0.30439C10.1195 0.341605 10.0362 0.39683 9.96631 0.466743C9.89639 0.536657 9.84123 0.619832 9.80402 0.711433C9.7668 0.803035 9.74829 0.901205 9.74963 1.00007C9.74963 1.19898 9.82865 1.38975 9.9693 1.5304C10.11 1.67106 10.3007 1.75007 10.4996 1.75007H13.1896L6.46967 8.47004C6.39891 8.53899 6.34266 8.62138 6.30426 8.71241C6.26586 8.80344 6.24609 8.90128 6.24609 9.00007C6.24609 9.09886 6.26586 9.19664 6.30426 9.28767C6.34266 9.37869 6.39891 9.46109 6.46967 9.53004C6.61139 9.66892 6.80122 9.74776 6.99963 9.75007C7.19763 9.74576 7.38682 9.66721 7.52966 9.53004L14.2496 2.81007V5.50007C14.2496 5.69898 14.3286 5.88975 14.4693 6.0304C14.61 6.17105 14.8007 6.25007 14.9996 6.25007C15.1977 6.24748 15.387 6.16763 15.5271 6.02753C15.6672 5.88744 15.747 5.69818 15.7496 5.50007ZM3 15.75H11.5C12.2285 15.7474 12.9265 15.4567 13.4417 14.9416C13.9568 14.4264 14.2474 13.7285 14.25 13V8.5C14.25 8.30109 14.171 8.11032 14.0303 7.96967C13.8897 7.82901 13.6989 7.75 13.5 7.75C13.4011 7.74866 13.303 7.76711 13.2114 7.80432C13.1198 7.84154 13.0366 7.89676 12.9667 7.96667C12.8968 8.03659 12.8416 8.11976 12.8044 8.21136C12.7672 8.30297 12.7487 8.40114 12.75 8.5V13C12.7474 13.3307 12.6148 13.6471 12.381 13.881C12.1471 14.1148 11.8307 14.2474 11.5 14.25H3C2.66929 14.2474 2.35287 14.1148 2.11902 13.881C1.88516 13.6471 1.75261 13.3307 1.75 13V4.5C1.75 4.16848 1.88173 3.85051 2.11615 3.61609C2.35057 3.38167 2.66848 3.25 3 3.25H7.5C7.69811 3.24741 7.88737 3.16756 8.02747 3.02747C8.16756 2.88737 8.24741 2.69811 8.25 2.5C8.25 2.30109 8.17099 2.11032 8.03033 1.96967C7.88968 1.82901 7.69891 1.75 7.5 1.75H3C2.27065 1.75 1.57121 2.03969 1.05548 2.55542C0.539756 3.07115 0.25 3.77065 0.25 4.5V13C0.25 13.7293 0.539756 14.4288 1.05548 14.9445C1.57121 15.4602 2.27065 15.75 3 15.75Z"
        fill={fill ?? "white"}
      />
    </svg>
  );
}
