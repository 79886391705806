import { useMutation, useQuery } from "@tanstack/react-query";
import { axiosInstance, useUtilities } from "helpers";
import { useEffect } from "react";
import {
  getAllTemplateGlossary,
  addTemplateGlossary,
  deleteTemplateGlossary,
  updateTemplateGlossary,
  getAllDefaultSiteGlossary,
  addDefaultSiteGlossary,
  deleteDefaultSiteGlossary,
} from "redux/reducers/templateLibrary/glossary";

export async function getTemplateGlossary() {
  return axiosInstance
    .get("allglossary", {
      params: {
        apiKey: localStorage.getItem("apikey"),
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function useGetTemplateGlossary() {
  return useQuery(["templateGlossary"], getTemplateGlossary);
}

export function useGlosssary() {
  const {
    dispatch,
    glossary: { loaded, glossary },
  } = useUtilities();
  const { data: templateGlossary, error, isLoading } = useGetTemplateGlossary();

  useEffect(() => {
    if (isLoading || loaded) return;
    if (templateGlossary) {
      dispatch(getAllTemplateGlossary(templateGlossary.Data));
    }
  }, [templateGlossary, isLoading]);
  return { data: glossary, isLoading, error };
}

export async function addTemplateGlossaryApi({
  glossary_title,
  glossary_body,
  glossary_integration,
  tags,
}) {
  return axiosInstance
    .post("addglossary", {
      apiKey: localStorage.getItem("apikey"),
      glossary_title,
      glossary_body,
      glossary_integration,
      tags,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useAddGlossary(redirect = true) {
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(addTemplateGlossaryApi, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(addTemplateGlossary(data.Data));
        notification({
          type: "success",
          message: "Glossary added successfully",
        });
        if (redirect) {
          navigate(`/template-library/glossary/${data.Data.id}`);
        }
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: err.message,
      });
    },
  });
}

export async function updateTemplateGlossaryApi({
  glossary_title,
  glossary_body,
  glossary_integration,
  tags,
  glossary_id,
}) {
  return axiosInstance
    .post("updateglossary", {
      apiKey: localStorage.getItem("apikey"),
      glossary_title,
      glossary_body,
      glossary_integration,
      tags,
      glossary_id,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUpdateGlossary() {
  const { dispatch, notification } = useUtilities();
  return useMutation(updateTemplateGlossaryApi, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(updateTemplateGlossary(data.Data));
        notification({
          type: "success",
          message: "Glossary updated successfully",
        });
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: err.message,
      });
    },
  });
}

export async function deleteTemplateGlossaryApi(glossary_id) {
  return axiosInstance
    .post("delglossary", {
      apiKey: localStorage.getItem("apikey"),
      glossary_id,
    })
    .then((res) => ({ ...res.data, glossary_id }))
    .catch((err) => new Error(err));
}

export function useDeleteGlossary(redirect = true) {
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(deleteTemplateGlossaryApi, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(deleteTemplateGlossary(data.glossary_id));
        notification({
          type: "success",
          message: "Glossary deleted successfully",
        });
        if (redirect) {
          navigate("/template-library/glossary");
        }
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: err.message,
      });
    },
  });
}

export async function getDefaultSiteGlossary() {
  return axiosInstance
    .post("getwpslglossarytemp", {
      apikey: localStorage.getItem("apikey"),
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useGetDefaultGlossary() {
  return useQuery(["defaultSiteGlossary"], getDefaultSiteGlossary);
}

export function useDefaultGlossary() {
  const {
    dispatch,
    glossary: {
      defaultSite: { loaded, glossary },
    },
  } = useUtilities();
  const { data: defaultGlossary, error, isLoading } = useGetDefaultGlossary();

  useEffect(() => {
    if (isLoading || loaded) return;
    if (defaultGlossary) {
      dispatch(getAllDefaultSiteGlossary(defaultGlossary.Data));
    }
  }, [defaultGlossary, isLoading, loaded]);

  return { data: glossary, isLoading: isLoading && !loaded, error };
}

export async function addDefaultGlossary(id) {
  return axiosInstance
    .post("adddefaulttemplateglossary", {
      apikey: localStorage.getItem("apikey"),
      id,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useAddDefaultGlossary() {
  const { notification, dispatch } = useUtilities();
  return useMutation(addDefaultGlossary, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(addDefaultSiteGlossary(data.Data));
        notification({
          type: "success",
          message: "Glossary added successfully",
        });
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: err.message,
      });
    },
  });
}

export async function deleteDefaultGlossary(id) {
  return axiosInstance
    .post("defaultglossaryremove", {
      apikey: localStorage.getItem("apikey"),
      id,
    })
    .then((res) => ({ ...res.data, id }))
    .catch((err) => new Error(err));
}

export function useDeleteDefaultGlossary() {
  const { dispatch, notification } = useUtilities();
  return useMutation(deleteDefaultGlossary, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(deleteDefaultSiteGlossary(data.id));
        notification({
          type: "success",
          message: "Glossary deleted successfully",
        });
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: err.message,
      });
    },
  });
}
