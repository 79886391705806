import { Link, useParams, useNavigate } from "react-router-dom";
import { useGetStatus, deleteStatus } from "api/siteSettings/status";
import { useEffect, useState } from "react";
import { queryClient } from "client";
import { useMutation } from "@tanstack/react-query";
import {
  Loader,
  LoadingListCards,
  TemplateNotFound,
  IconWrapper,
  ListIconWrapper,
  BodyContainer,
  MainButton,
  PrimaryHeading,
  HeaderContainer,
  ListCard,
  RightMainContainer,
} from "commonUI";
import { BackArrow, AddIcon, StatusIcon } from "allIcons";
import { useNotification } from "notification";
import { isEqual, printError, useSearch } from "helpers";
import { useUserPermissions } from "hooks/user";
import { ApiResponseType } from "enums";

export default function StatusOptions() {
  const { web = null } = useSearch();
  const { option } = useParams();
  const { isLoading, error, data } = useGetStatus(option);
  const navigate = useNavigate();
  const [status, setStatus] = useState([]);
  const notification = useNotification();
  const {
    add_new_items_to_site_template,
    remove_items_from_site_template,
    edit_item_details_in_site_template,
  } = useUserPermissions();
  useEffect(() => {
    if (isLoading) return;
    setStatus(
      data?.Data?.filter((ele) =>
        isEqual(ele.parent_type.toLowerCase(), option)
      )
    );
  }, [isLoading, option, data]);

  const deleteMutation = useMutation(deleteStatus, {
    onSuccess: (data) => {
      if (data?.StatusCode === ApiResponseType.SUCCESS) {
      queryClient.invalidateQueries([`status-${option}`, web]);
      notification({
        type: "success",
        message: `Status ${option.slice(
          0,
          option.length - 1
        )} deleted Successfull`,
      });
    } else {
      notification({
        type: "error",
        message: printError(data?.Message)
      });
    }
    },
    onError: (error) => {
      console.log(error);
      notification({
        type: "error",
        message: "Something went wrong, please try again later",
      });
    },
  });

  const getDeletePermission = (ele) => {
    if (isEqual(ele.system_status, 0)) {
      return true;
    }
    if (!web || !remove_items_from_site_template) {
      return true;
    }
    return false;
  }

  const getEditPermission = (ele) => {
    if (isEqual(ele.system_status, 1)) {
      return true;
    }
    if (!edit_item_details_in_site_template) {
      return false;
    }
    return false;
  }

  return (
    <>
      {deleteMutation.isLoading && <Loader loader="block" />}
      <RightMainContainer>
        <HeaderContainer>
          <IconWrapper>
            <BackArrow
              onClick={() =>
                navigate(`/site-settings/status${web ? `?web=${web}` : ""}`)
              }
            />
            <PrimaryHeading>
              {option.split("-").join(" ")} Status Options
            </PrimaryHeading>
          </IconWrapper>
          {add_new_items_to_site_template ? (
            <Link to={`add${web ? `?web=${web}` : ""}`}>
              <MainButton>
                <AddIcon />
                Add
              </MainButton>
            </Link>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          <div className="">
            {isLoading ? (
              <LoadingListCards />
            ) : status.length > 0 ? (
              status?.map((iner, index) => {
                return (
                  <ListCard
                    secondaryData={
                      isEqual(iner.system_status, 1) ? "Custom" : "System"
                    }
                    key={"status-"+index}
                    index={index}
                    data={iner.status_title}
                    link={
                      edit_item_details_in_site_template
                        ? `/site-settings/status/${option}/${iner.id}`
                        : ""
                    }
                    defaultLink={
                      edit_item_details_in_site_template
                        ? `/site-settings/status/${option}${
                            web ? `?web=${web}` : ""
                          }`
                        : ""
                    }
                    onClick={iner.system_status == 1 ? null : () => {}}
                    strictLink={true}
                    deleteIcon={getDeletePermission(iner)}
                    editIcon={getEditPermission(iner)}
                    onDelete={() => {
                      deleteMutation.mutate(iner.id);
                    }}
                    crossIcon={isEqual(iner.system_status, 1)}
                    showModalonCross
                    icon={
                      <ListIconWrapper>
                        <StatusIcon />
                      </ListIconWrapper>
                    }
                    deleteTitle={
                      web ? "Delete This Status" : "Remove This Status"
                    }
                    deleteButtonText={web ? "Confirm Delete" : "Confirm"}
                    deleteMessage={
                      web
                        ? "You are about to delete this status from your site. This action cannot be undone! Any item using this status will revert to the system status. Are you sure you want to do this?"
                        : "You are about to remove this status from your default site template. Are you sure you want to do this?"
                    }
                  />
                );
              })
            ) : (
              <TemplateNotFound
                label={option.split("-").join(" ")}
                linkLabel={option.split("-").join(" ")}
                link={
                  add_new_items_to_site_template
                    ? `/site-settings/status/${option}/add${
                        web ? `?web=${web}` : ""
                      }`
                    : ""
                }
              />
            )}
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
