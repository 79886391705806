import { OutsideClickHandler } from "commonUI";
import { ChevronFilled } from "allIcons";

const POSITIONS = {
  top: "flex-start",
  bottom: "flex-end",
  left: "flex-start",
  right: "flex-end",
  center: "center",
};

export default function PopOver({
  children,
  open,
  onClose,
  className = "",
  style = {},
  toolTip = {},
}) {
  return open ? (
    <>
      <div className="absolute top-0 right-0 h-full w-full z-1" />
      <OutsideClickHandler
        className={`list-pop-up ${className}`}
        style={style}
        onClickOutside={() => {
          onClose(false);
        }}
      >
        <div className="relative cursor-default">
          {children}
          {Object.keys(toolTip).length > 0 ? (
            <div
              style={{
                position: "absolute",
                zIndex: "-1",
                left: "-14px",
                top: "-9px",
                height: "calc(100% + 18px)",
                width: `calc(100% + ${
                  toolTip.horizontal === "right" ||
                  toolTip.horizontal === "left"
                    ? "14px"
                    : "28px"
                })`,
                display: "flex",
                alignItems: POSITIONS[toolTip.vertical], // flex-end, flex-start
              }}
            >
              <ChevronFilled
                style={{
                  filter: "drop-shadow(0px 5px 30px rgba(43, 135, 218, 0.2))",
                  transform: `rotate(${
                    toolTip.vertical === "top"
                      ? "-180"
                      : toolTip.horizontal === "left"
                      ? "-90"
                      : toolTip.horizontal === "right"
                      ? "90"
                      : "0"
                    }deg)`,
                  marginRight: toolTip.horizontal === "right" ? "14px" : toolTip.right ?? "auto",
                  marginLeft: toolTip.horizontal === "left" ? "14px" : toolTip.left ?? "auto",
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </OutsideClickHandler>
    </>
  ) : (
    ""
  );
}
