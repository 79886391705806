import { InterfaceIcon } from "allIcons";
import NotificationIcon from "allIcons/NotificationIcon";

export const userPrefrencesMenu = [
  // {
  //   module: "interface",
  //   title: "Interface",
  //   icon: <InterfaceIcon />,
  // },
  {
    module: "notifications",
    title: "Notifications",
    icon: <NotificationIcon />,
  },
];
