import { Link } from "react-router-dom";
import {
  LoadingListCards,
  ListIconWrapper,
  BodyContainer,
  MainButton,
  ListCard,
  RightMainContainer,
  HeaderContainer,
  PrimaryHeading,
  TemplateNotFound,
  Loader,
} from "commonUI";
import { AddIcon, PriorityIcon } from "allIcons";
import { useDeletePriority, usePriority } from "hooks/priority";
import { useSearch } from "helpers";
import { useUserPermissions } from "hooks/user";

export default function PriorityTab() {
  const { web = null } = useSearch();
  const { isLoading, data: priorities } = usePriority();
  const deleteMutation = useDeletePriority();
  const {
    add_new_items_to_site_template,
    remove_items_from_site_template,
    edit_item_details_in_site_template,
  } = useUserPermissions();

  return (
    <>
      <RightMainContainer>
        <Loader show={deleteMutation.isLoading} loader="block" />
        <HeaderContainer>
          <PrimaryHeading>Priority</PrimaryHeading>
          {add_new_items_to_site_template ? (
            <Link to={`add${web ? `?web=${web}` : ""}`}>
              <MainButton>
                <AddIcon />
                Add
              </MainButton>
            </Link>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          {isLoading ? (
            <LoadingListCards />
          ) : priorities.length === 0 ? (
            <TemplateNotFound
              label="Priority"
              linkLabel="Priority"
              link={add_new_items_to_site_template ? `add${web ? `?web=${web}` : ""}` : ""}
            />
          ) : (
            priorities.map((iner, index) => {
              return (
                <ListCard
                  data={iner.priority_title}
                  key={index}
                  index={index}
                  link={
                    edit_item_details_in_site_template
                      ? `/site-settings/priority/${iner.id}`
                      : ""
                  }
                  strictLink={true}
                  editIcon={edit_item_details_in_site_template}
                  deleteIcon={!web || !remove_items_from_site_template}
                  crossIcon={!web && remove_items_from_site_template}
                  onDelete={() => {
                    deleteMutation.mutate({
                      id: iner.id,
                      website_id: web,
                    });
                  }}
                  showModalonCross
                  icon={
                    <ListIconWrapper>
                      <PriorityIcon />
                    </ListIconWrapper>
                  }
                  deleteButtonText={web ? 'Confirm Delete' : 'Confirm'}
                  deleteTitle={web ? "Delete This Priority?" : "Remove This Priority?"}
                  deleteMessage={
                    web
                      ? "You are about to delete this priority option from your site. This action cannot be undone! Any item using this priority option will revert to the one before it. Are you sure you want to do this?"
                      : "You are about to remove this priority option from your default site template. Are you sure you want to do this?"
                  }
                />
              );
            })
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
