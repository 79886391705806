import { useMutation } from "@tanstack/react-query";
import { deleteSite } from "api/siteSettings/advanced";
import { printError, useUtilities } from "helpers";
import { queryClient } from "client";
import { ApiResponseType, NotificationType } from "enums";
import { unloadSites } from "redux/reducers/sites";
import { unuploadMembers } from "redux/reducers/usersModule/members";

export default function useDeleteSite() {
  const { notification, navigate, dispatch } = useUtilities();

  return useMutation(deleteSite, {
    onSuccess: (data) => {
      if (data.status_code === ApiResponseType.SUCCESS) {
        queryClient.invalidateQueries(["sitesData"]);
        queryClient.invalidateQueries(["members"]);
        dispatch(unloadSites());
        dispatch(unuploadMembers());
        notification({
          type: NotificationType.SUCCESS,
          message: "Site deleted successfully",
        });
        navigate("/sites");
      } else {
        notification({
          type: NotificationType.ERROR,
          message: printError("Sorry, Can't delete site at the moment."),
        });
      }
    },
    onError: (error) => {
      notification({
        type: NotificationType.ERROR,
        message: "Something went wrong",
      });
    },
  });
}
