import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notes: [],
  loaded: false,
  defaultSite: {
    notes: [],
    loaded: false,
  },
};

const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    getAllTemplateNotes: (state, action) => {
      state.notes = action.payload;
      state.loaded = true;
    },
    addTemplateNote: (state, action) => {
      state.notes.unshift(action.payload);
    },
    updateTemplateNote: (state, action) => {
      const index = state.notes.findIndex(
        (note) => Number(note.id) === Number(action.payload.id)
      );
      for (let key in action.payload) {
        state.notes[index][key] = action.payload[key];
      }
    },
    deleteTemplateNote: (state, action) => {
      const index = state.notes.findIndex(
        (note) => Number(note.id) === Number(action.payload.id)
      );
      if (index !== -1) state.notes.splice(index, 1);
    },
    getDefaultSiteNotes: (state, action) => {
      state.defaultSite.notes = action.payload;
      state.defaultSite.loaded = true;
    },
    addDefaultSiteNote: (state, action) => {
      const index = state.notes.findIndex(
        (note) => Number(note.id) === Number(action.payload.id)
      );
      if (index !== -1) {
        state.notes[index].default_tem = 1;
        state.defaultSite.notes.unshift(state.notes[index]);
      }
    },
    removeDefaultSiteNote: (state, action) => {
      const index = state.defaultSite.notes.findIndex(
        (note) => Number(note.id) === Number(action.payload.id)
      );
      if (index !== -1) state.defaultSite.notes.splice(index, 1);
      state.notes.forEach((note) => {
        if (note.id === action.payload.id) {
          note.default_tem = null;
        }
      });
    },
  },
});

export const {
  getAllTemplateNotes,
  addTemplateNote,
  updateTemplateNote,
  deleteTemplateNote,
  getDefaultSiteNotes,
  addDefaultSiteNote,
  removeDefaultSiteNote,
} = notesSlice.actions;

export default notesSlice.reducer;
