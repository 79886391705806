import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import {
  getAllTemplatePlugins,
  deleteTemplatePlugin,
  addTemplatePlugin,
  updateTemplatePlugin,
} from "redux/reducers/templateLibrary/plugins";
import { axiosInstance, printError, useUtilities } from "helpers";

export async function getPlugins() {
  return axiosInstance
    .get("allPlugins", {
      params: {
        apiKey: localStorage.getItem("apikey"),
      },
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useGetAllPlugins() {
  return useQuery(["allPlugins"], getPlugins);
}

export function usePlugins() {
  const {
    dispatch,
    plugins: { plugins, loaded },
  } = useUtilities();
  const { data: pluginData, isLoading, error } = useGetAllPlugins();

  useEffect(() => {
    if (isLoading || error || loaded || !pluginData) return;
    if (pluginData.StatusCode !== 200) return;
    dispatch(getAllTemplatePlugins(pluginData.Data));
  }, [pluginData, isLoading, error, loaded]);

  return { data: plugins, isLoading, error };
}

export async function addPlugin(data) {
  return axiosInstance
    .post("addplugin", {
      ...data,
      apiKey: localStorage.getItem("apikey"),
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useAddPlugin(
  redirect = "/template-library/plugins",
  showNotification = true
) {
  const { notification, dispatch, navigate } = useUtilities();

  return useMutation(addPlugin, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(addTemplatePlugin(data.Data));
        if (showNotification)
          notification({
            type: "success",
            message: "Plugin added successfully",
          });
        if (redirect) navigate(redirect);
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: err.message,
      });
    },
  });
}

export async function deletePlugin(plugin_id) {
  return axiosInstance
    .post("delplugin", {
      plugin_id,
      apiKey: localStorage.getItem("apikey"),
    })
    .then((res) => ({ ...res.data, id: plugin_id }))
    .catch((err) => new Error(err));
}

export function useDeletePlugin(redirect = "") {
  const { notification, dispatch, navigate } = useUtilities();
  return useMutation(deletePlugin, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        notification({
          type: "success",
          message: "Plugin deleted successfully",
        });
        dispatch(deleteTemplatePlugin(data.id));
        if (redirect) navigate(redirect);
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (err) =>
      notification({
        type: "error",
        message: err.message,
      }),
  });
}

function updatePlugin(data) {
  return axiosInstance
    .post("updateplugin", {
      ...data,
      apiKey: localStorage.getItem("apikey"),
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUpdatePlugin() {
  const { notification, dispatch, navigate } = useUtilities();

  return useMutation(updatePlugin, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(updateTemplatePlugin(data.Data));
        notification({
          type: "success",
          message: "Plugin updated successfully",
        });
      } else {
        notification({
          type: "error",
          message: printError(data.Message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: printError(err.message),
      });
    },
  });
}
