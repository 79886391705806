export default function SMTPIcon({ fill = "white", className = "" }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16.56 1.45995C16.0991 1.02498 15.4936 0.775668 14.86 0.759936C14.5572 0.714157 14.2478 0.745129 13.96 0.849963L2.09005 4.93993C1.68581 5.07172 1.33405 5.32893 1.0859 5.67418C0.83775 6.01944 0.706114 6.43475 0.710047 6.85991C0.706206 7.34974 0.882231 7.82397 1.2048 8.19261C1.52736 8.56126 1.97404 8.7987 2.46005 8.85991L8.33004 9.63994L9.12002 15.5399C9.18572 16.0223 9.42511 16.4642 9.7933 16.7826C10.1615 17.1011 10.6332 17.2744 11.12 17.2699C11.5363 17.2645 11.9411 17.1324 12.2805 16.8912C12.62 16.6501 12.8779 16.3113 13.02 15.9199L17.18 4.00994C17.2619 3.77126 17.3091 3.52205 17.32 3.26995V3.21996C17.3274 2.88919 17.2633 2.56074 17.1322 2.25701C17.001 1.95327 16.8058 1.68139 16.56 1.45995ZM15.8 3.25994C15.8 3.25994 15.8 3.39994 15.74 3.50994L11.6001 15.3799C11.5631 15.4853 11.496 15.5775 11.4071 15.645C11.3182 15.7126 11.2115 15.7525 11.1001 15.7599C10.9758 15.7567 10.8566 15.7098 10.7633 15.6276C10.6701 15.5453 10.6088 15.4328 10.5901 15.3099L9.78005 9.21996L12.9801 6.01995C13.1205 5.87932 13.1994 5.68867 13.1994 5.48992C13.1994 5.29117 13.1205 5.10057 12.9801 4.95995C12.8365 4.82125 12.6447 4.7437 12.445 4.7437C12.2454 4.7437 12.0536 4.82125 11.9101 4.95995L8.68002 8.19994L2.68002 7.39995C2.5518 7.3922 2.43176 7.33439 2.34573 7.239C2.25969 7.14362 2.21458 7.01828 2.22006 6.88994C2.21658 6.78258 2.24775 6.67693 2.30898 6.58867C2.37022 6.50042 2.45823 6.43427 2.56002 6.39995L14.46 2.29991C14.5281 2.27629 14.6021 2.27629 14.6701 2.29991H14.87C15.1171 2.30847 15.3522 2.40824 15.5301 2.57994C15.61 2.66371 15.6723 2.76254 15.7135 2.87071C15.7547 2.97889 15.774 3.09425 15.77 3.20995L15.8 3.25994Z"
        fill={fill}
      />
    </svg>
  );
}
