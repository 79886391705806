export default function BrandingIcon(props) {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`cursor-pointer ${props.className ?? ""}`}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.2402 9.28003H15.0003C15.1984 9.28262 15.3876 9.36247 15.5277 9.50256C15.6678 9.64266 15.7477 9.83192 15.7503 10.03V15.03C15.7503 15.2289 15.6712 15.4197 15.5306 15.5604C15.3899 15.701 15.1992 15.78 15.0003 15.78H3.50025C3.07197 15.7854 2.64693 15.705 2.25019 15.5436C1.85346 15.3822 1.49306 15.143 1.19019 14.8401C0.887327 14.5372 0.648129 14.1768 0.486703 13.7801C0.325277 13.3834 0.244881 12.9583 0.250252 12.53V1.03003C0.250252 0.831117 0.329266 0.640347 0.469918 0.499695C0.61057 0.359043 0.80134 0.280029 1.00025 0.280029H6.00025C6.19836 0.282619 6.38762 0.362469 6.52772 0.502563C6.66781 0.642658 6.74766 0.831922 6.75025 1.03003V4.57001L9.82026 1.5C9.96078 1.35931 10.1514 1.2802 10.3503 1.28003C10.5491 1.2802 10.7397 1.35931 10.8803 1.5L14.4302 5.05005C14.5709 5.19057 14.6501 5.38117 14.6503 5.58002C14.6501 5.77886 14.5709 5.96953 14.4302 6.11005L11.2402 9.28003ZM5.12251 13.2029C5.2095 12.9894 5.25293 12.7606 5.25025 12.53V1.75H1.75025V12.53C1.74757 12.7606 1.791 12.9894 1.878 13.2029C1.965 13.4164 2.0938 13.6104 2.25684 13.7734C2.41988 13.9365 2.61387 14.0653 2.8274 14.1523C3.04093 14.2393 3.2697 14.2827 3.50025 14.28C3.73081 14.2827 3.95957 14.2393 4.1731 14.1523C4.38663 14.0653 4.58062 13.9365 4.74366 13.7734C4.9067 13.6104 5.03551 13.4164 5.12251 13.2029ZM10.3503 3.09003L6.75025 6.69V11.63L12.8303 5.58002L10.3503 3.09003ZM6.31025 14.28H14.2503L14.3503 10.78H9.83027L6.40028 14.19L6.31025 14.28ZM4.5 12.5C4.5 13.0523 4.05228 13.5 3.5 13.5C2.94772 13.5 2.5 13.0523 2.5 12.5C2.5 11.9477 2.94772 11.5 3.5 11.5C4.05228 11.5 4.5 11.9477 4.5 12.5Z"
          fill={props.fill ?? "#0066FF"}
        />
      </svg>
    </>
  );
}
