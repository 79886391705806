export default function DefaultSiteTemplateIcon({fill, className}) {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className ?? ""}
      >
        <path
          d="M14.2352 16H1.76485C0.790303 16 0 15.1321 0 14.0606V1.93939C0 0.867879 0.790303 0 1.76485 0H14.2352C15.2097 0 16 0.867879 16 1.93939V14.0606C16 15.1321 15.2097 16 14.2352 16ZM1.76485 1.45455C1.61939 1.45455 1.45455 1.66303 1.45455 1.93939V14.0606C1.45455 14.337 1.61939 14.5455 1.76485 14.5455H14.2352C14.3806 14.5455 14.5455 14.337 14.5455 14.0606V1.93939C14.5455 1.66303 14.3806 1.45455 14.2352 1.45455H1.76485Z"
          fill={fill ?? "white"}
        />
        <path
          d="M12.4655 8.72727H3.53456C3.13214 8.72727 2.80729 8.40242 2.80729 8C2.80729 7.59758 3.13214 7.27273 3.53456 7.27273H12.4655C12.8679 7.27273 13.1927 7.59758 13.1927 8C13.1927 8.40242 12.8679 8.72727 12.4655 8.72727Z"
          fill={fill ?? "white"}
        />
        <path
          d="M12.4655 12.3636H3.53456C3.13214 12.3636 2.80729 12.0388 2.80729 11.6364C2.80729 11.2339 3.13214 10.9091 3.53456 10.9091H12.4655C12.8679 10.9091 13.1927 11.2339 13.1927 11.6364C13.1927 12.0388 12.8679 12.3636 12.4655 12.3636Z"
          fill={fill ?? "white"}
        />
        <path
          d="M7.75759 5.09091H3.53456C3.13214 5.09091 2.80729 4.76606 2.80729 4.36364C2.80729 3.96121 3.13214 3.63636 3.53456 3.63636H7.75759C8.16002 3.63636 8.48487 3.96121 8.48487 4.36364C8.48487 4.76606 8.16002 5.09091 7.75759 5.09091Z"
          fill={fill ?? "white"}
        />
      </svg>
    </>
  );
}
