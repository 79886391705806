import { QueryClient } from '@tanstack/react-query'
export const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000 * 60 * 60 * 24, // 1 day
      },
    },
  });

export const useGetFetchQuery = (key) => {
    return queryClient
};