export default function Divider({
  height = 1,
  color = "#F1F5F9",
  className = "",
}) {
  return (
    <div
      className={`w-full ${className}`}
      style={{
        height: `${height}px`,
        backgroundColor: `${color}`,
      }}
    />
  );
}
