import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import { ErrorIcon } from "allIcons";

export default function InvalidURL() {
  const navigate = useNavigate();
  const [count, setCount] = useState(7);
  setTimeout(() => {
    setCount(count - 1);
    if (count === 0) {
      navigate(-1);
    }
  }, 1000);

  return (
    <div className="invalid-url">
      <div
        className="notification-icon"
        style={{
          background: "#F12424",
        }}
      >
        <ErrorIcon />
      </div>
      <p className="body-text">
        The Page you are looking for does't exist. You will be redirected back
        in {count} sec.
      </p>
      <Link to="/">Home</Link>
    </div>
  );
}
