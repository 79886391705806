import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tasks: [],
  loaded: false,
  templateLibrary: {
    tasks: [],
    loaded: false,
  },
};

const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    addSingleTask: (state, action) => {
      state.tasks.unshift(action.payload);
    },
    getTemplateTasks: (state, action) => {
      state.tasks = action.payload;
      state.loaded = true;
    },
    deleteSingleTask: (state, action) => {
      state.tasks = state.tasks.filter(
        (task) => Number(task.id) !== Number(action.payload)
      );
      state.templateLibrary.tasks = state.templateLibrary.tasks.filter(
        (task) => Number(task.id) !== Number(action.payload)
      );
    },
    updateSingleTask: (state, action) => {
      state.tasks = state.tasks.map((task) => {
        if (Number(task.id) === Number(action.payload.id)) {
          return action.payload;
        }
        return task;
      });
      state.templateLibrary.tasks =
        state.templateLibrary?.tasks?.map((task) => {
          if (Number(task.id) === Number(action.payload.id)) {
            return action.payload;
          }
          return task;
        }) || [];
    },
    getTemplateLibraryTasks: (state, action) => {
      state.templateLibrary.tasks = action.payload;
      state.templateLibrary.loaded = true;
    },
    addTemplateLibraryTask: (state, action) => {
      state.templateLibrary.tasks = [
        {
          ...action.payload,
          default_tem: 1,
        },
        ...state.templateLibrary.tasks,
      ];
      state.tasks = state.tasks.map((task) => {
        if (Number(task.id) === Number(action.payload.id)) {
          return {
            ...task,
            default_tem: 1,
          };
        }
        return task;
      });
    },
    removeTemplateLibraryTask: (state, action) => {
      state.templateLibrary.tasks = state.templateLibrary.tasks.filter(
        (task) => task.id !== action.payload
      );
      state.tasks = state.tasks.map((task) => {
        if (Number(task.id) === Number(action.payload)) {
          return {
            ...task,
            default_tem: null,
          };
        }
        return task;
      });
    },
  },
});

export const {
  addSingleTask,
  getTemplateTasks,
  deleteSingleTask,
  updateSingleTask,
  getTemplateLibraryTasks,
  addTemplateLibraryTask,
  removeTemplateLibraryTask,
} = tasksSlice.actions;
export default tasksSlice.reducer;
