import { useQuery, useMutation } from "@tanstack/react-query";
import { NotificationType } from "enums";
import { ApiResponseType } from "enums";
import { printError, useSearch, useUtilities } from "helpers";
import {
  getAllEnvironments,
  updateEnvironment,
} from "api/siteSettings/environments";
import { useEffect } from "react";
import {
  setEnvironmentsAction,
  updateEnvironmentAction,
} from "redux/reducers/siteSettings/environments";

export function useGetAllEnvironments() {
  const { web = null } = useSearch();
  return useQuery(["environmentList", web], () => getAllEnvironments(web));
}

export function useEnvironments() {
  const { web = null } = useSearch();
  const { data, isLoading, error } = useGetAllEnvironments();
  const {
    dispatch,
    environments: { environments, loaded, website_id },
  } = useUtilities();

  useEffect(() => {
    if (data && (!loaded || website_id !== web)) {
      dispatch(setEnvironmentsAction({
        environments: data.Data,
        website_id: web,
      }));
    }
  }, [data, isLoading, error, loaded, web]);

  return {
    data: environments,
    isLoading: isLoading || !loaded || website_id !== web,
    error,
  };
}

export function useUpdateEnvironment() {
  const { notification, dispatch } = useUtilities();
  return useMutation(updateEnvironment, {
    onSuccess: (data) => {
      if (data.StatusCode === ApiResponseType.SUCCESS) {
        dispatch(updateEnvironmentAction(data.Data));
        notification({
          type: NotificationType.SUCCESS,
          message: data.Message,
        });
      } else {
        notification({
          type: NotificationType.ERROR,
          message: printError(data.Message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: NotificationType.ERROR,
        message: printError(err.message),
      });
    },
  });
}
