import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  launchpadShortcuts: {},
  loaded: false,
  website_id: null,
};

const launchpadShortcutsSlice = createSlice({
  name: "launchpadShortcuts",
  initialState,
  reducers: {
    setLaunchpadShortcutsAction(state, action) {
      const { launchpadShortcuts, website_id } = action.payload;
      state.launchpadShortcuts = launchpadShortcuts;
      state.website_id = website_id;
      state.loaded = true;
    },
  },
});

export const { setLaunchpadShortcutsAction } = launchpadShortcutsSlice.actions;

export default launchpadShortcutsSlice.reducer;
