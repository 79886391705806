export default function ChevronFilled({ fill, style, ...rest }) {
  return (
    <svg
      width="19"
      height="9"
      viewBox="0 0 19 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{...style}}
      {...rest}
    >
      <path
        d="M18.5361 9.53674e-07L9.26784 9L-0.000452852 2.52726e-06L18.5361 9.53674e-07Z"
        fill={fill ?? "white"}
      />
    </svg>
  );
}
