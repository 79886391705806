export default function CloudIcon(props) {
  function HandleClick() {
    props.onClick && props.onClick();
  }
  return (
    <>
      <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`cursor-pointer ${props.className ?? ""}`}
        onClick={HandleClick}
      >
        <path
          d="M13.2501 13.25H5.00008C3.87291 13.25 2.79192 12.8022 1.99489 12.0052C1.19786 11.2082 0.750077 10.1272 0.750077 9C0.745709 8.27587 0.927356 7.56276 1.27754 6.92892C1.62773 6.29509 2.13473 5.76175 2.75008 5.38C2.74036 5.25352 2.74036 5.12648 2.75008 5C2.75008 3.87283 3.19786 2.79183 3.99489 1.9948C4.79192 1.19777 5.87291 0.75 7.00008 0.75C7.74264 0.751334 8.47198 0.946748 9.11574 1.31686C9.7595 1.68698 10.2954 2.21894 10.6701 2.86C10.94 2.78352 11.2196 2.74647 11.5001 2.75C12.2802 2.74932 13.0344 3.03011 13.624 3.5408C14.2137 4.0515 14.5993 4.75781 14.7101 5.53C15.4581 5.82325 16.1004 6.33496 16.5535 6.99847C17.0065 7.66199 17.2493 8.44657 17.2501 9.25C17.2501 10.3109 16.8287 11.3283 16.0785 12.0784C15.3284 12.8286 14.3109 13.25 13.2501 13.25ZM13.0001 11.73H13.2501C13.9123 11.7274 14.5467 11.4631 15.015 10.9949C15.4832 10.5266 15.7474 9.89224 15.7501 9.23001C15.7528 8.66585 15.563 8.11762 15.2121 7.67584C14.8612 7.23406 14.3702 6.92513 13.8201 6.80002C13.6561 6.76004 13.51 6.66658 13.405 6.53439C13.3 6.40221 13.2419 6.23882 13.2401 6.07001C13.2355 6.02679 13.2355 5.98322 13.2401 5.94C13.2296 5.48284 13.0406 5.04792 12.7136 4.72833C12.3865 4.40875 11.9473 4.22989 11.4901 4.23001C11.1957 4.22939 10.9063 4.3052 10.6501 4.45001C10.5555 4.50502 10.4498 4.53807 10.3408 4.54672C10.2317 4.55538 10.1221 4.5394 10.0201 4.5C9.91672 4.46455 9.82306 4.40538 9.74666 4.32724C9.67025 4.2491 9.61322 4.15417 9.58009 4.05002C9.38723 3.5176 9.03524 3.05737 8.57192 2.7318C8.10859 2.40622 7.55634 2.23104 6.99007 2.23001C6.26072 2.23001 5.56127 2.51974 5.04555 3.03546C4.52982 3.55119 4.24007 4.25067 4.24007 4.98001C4.23918 5.1887 4.26268 5.39677 4.31007 5.60001C4.35257 5.77108 4.33263 5.95168 4.2538 6.10934C4.17497 6.26701 4.04244 6.39135 3.88008 6.45999C3.39005 6.67338 2.97343 7.02591 2.6819 7.47388C2.39037 7.92184 2.23674 8.44555 2.24007 8.98001C2.24007 9.70936 2.52982 10.4088 3.04555 10.9246C3.56127 11.4403 4.26072 11.73 4.99007 11.73H12.8901H13.0001Z"
          fill={props.fill ?? "white"}
        />
      </svg>
    </>
  );
}
