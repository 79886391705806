import { ModulePrefixes } from "enums";
import LeftMenuCard from "./LeftMenuCard";
import { useLocation } from "react-router-dom";
import { userPrefrencesMenu } from "staticData";

export default function UserPrefrencesMenu() {
  const { search } = useLocation();
  return (
    <>
      <div className="card-div">
        {userPrefrencesMenu.map((item, index) => (
          <LeftMenuCard
            key={`${index}-${item.module}`}
            {...item}
            prefix={ModulePrefixes.SITE_SETTINGS}
            link={`${ModulePrefixes.SITE_SETTINGS}/${item.module}${
              search ?? ""
            }`}
          />
        ))}
      </div>
    </>
  );
}
