import { Link } from "react-router-dom";
import { AddIcon, GroupIcon } from "allIcons";
import {
  BodyContainer,
  HeaderContainer,
  ListCard,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
  ListIconWrapper,
  InputField,
  LoadingListCards,
  TemplateNotFound,
  FiltersList,
} from "commonUI";
import { useGroups } from "api/usersModule/groups";
import { useDeferredValue, useEffect, useState } from "react";
import { parse, search } from "helpers";
import { useMembers } from "api/usersModule/members";
import { libraryItemFiltering, libraryItemSorting } from "filterAndSort/templateLibrary";
import userPic from "assets/pic.png";

export default function Groups() {
  const { data: groups, isLoading } = useGroups();
  const { data: members } = useMembers();
  const [searchText, setSearchText] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const defferedSearch = useDeferredValue(searchText);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    setSearchedData(
      groups.filter((item) => search(item.group_title, defferedSearch))
    );
  }, [defferedSearch, groups]);

  const applySorting = (oder, orderBy) => {
    setSearchedData(libraryItemSorting(oder, orderBy, searchedData, "name"));
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setSearchedData(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        groups.map((item) => ({...item, users: parse(item.users).map((user) => user.id)})),
        'users'
      )
    );
  };

  return (
    <RightMainContainer>
      <HeaderContainer>
        <PrimaryHeading>Groups</PrimaryHeading>
        <Link to="add">
          <MainButton>
            <AddIcon />
            Add
          </MainButton>
        </Link>
      </HeaderContainer>
      <BodyContainer>
        <InputField
          placeholder="Search"
          searchIcon
          filter
          value={searchText}
          setValue={(value) => setSearchText(value)}
          showTags={false}
          filterAddTitle="Add User"
          filterTitle="Users"
          filterData={filterData}
          filterList={members?.map((item) => ({
            title: `${item.name} ${item.last_name}`,
            id: item.id,
            users: item.id,
            icon: <ListIconWrapper>{<img src={userPic} alt="user" width={30} height={30}/>}</ListIconWrapper>,
          }))}
          onApplyFilter={applyFilter}
          applySorting={applySorting}
        />
        <FiltersList filterData={filterData} updateFilter={applyFilter} />
        {isLoading ? (
          <LoadingListCards />
        ) : searchedData.length === 0 ? (
          <TemplateNotFound label="Group" linkLabel="Group" link="add" />
        ) : (
          searchedData.map((item, index) => (
            <ListCard
              key={index}
              data={item.group_title}
              index={index}
              link={`/users/groups/${item.id}`}
              strictLink={true}
              deleteIcon={true}
              icon={
                <ListIconWrapper>
                  <GroupIcon fill="white" />
                </ListIconWrapper>
              }
            />
          ))
        )}
      </BodyContainer>
    </RightMainContainer>
  );
}
