import { Children, useDeferredValue, useEffect, useState } from "react";
import { AddIcon, CheckListIcon } from "allIcons";
import {
  BodyContainer,
  HeaderContainer,
  InputField,
  ListCard,
  ListIconWrapper,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
  PopOver,
  AddFromTemplateLibrary,
  LoadingListCards,
  TemplateNotFound,
  Loader,
  FiltersList,
} from "commonUI";
import {
  useCheckListTemplates,
  useChecklistDefaultTemplates,
  useAddChecklistToDefaultTemplate,
  useRemoveChecklistDefault,
} from "api/templateLibrary/checklists";
import { isEqual, parse, search } from "helpers";
import {
  libraryItemFiltering,
  libraryItemSorting,
} from "filterAndSort/templateLibrary";
import { useTags } from "api/siteSettings/Tags";
import { useUserPermissions } from "hooks/user";

export default function Checklist() {
  const { data: DefaultChecklists, isLoading: loadingDefaultChecklists } =
    useChecklistDefaultTemplates();
  const { data: checklist, isLoading: loadingChecklistTemplate } =
    useCheckListTemplates();
  const { mutate: addToDefaultChecklist, isLoading: addingDefaultChecklist } =
    useAddChecklistToDefaultTemplate();
  const {
    mutate: removeFromDefaultChecklist,
    isLoading: removingDefaultChecklist,
  } = useRemoveChecklistDefault();
  const [addPopup, setAddPopup] = useState(false);
  const { data: tags, isLoading: tagsLoading, error: tagsError } = useTags();

  const [searchText, setSearchText] = useState("");
  const defferedSearch = useDeferredValue(searchText);
  const [searchResult, setSearchResult] = useState([]);
  const [filterData, setFilterData] = useState({});
  const {
    remove_items_from_site_template,
  } = useUserPermissions();

  useEffect(() => {
    setSearchResult(
      DefaultChecklists.filter((checklist) =>
        search(checklist.title, defferedSearch)
      )
    );
  }, [loadingDefaultChecklists, DefaultChecklists, defferedSearch]);

  const applySorting = (order, orderBy) => {
    setSearchResult((prevSearchResult) =>
      libraryItemSorting(order, orderBy, prevSearchResult)
    );
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setSearchResult(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        DefaultChecklists
      )
    );
  };

  return (
    <>
      <RightMainContainer>
        <Loader
          show={addingDefaultChecklist || removingDefaultChecklist}
          loader="block"
        />
        <HeaderContainer>
          <PrimaryHeading>Checklist</PrimaryHeading>
          <div className="relative">
            <MainButton onClick={() => setAddPopup(!addPopup)}>
              <AddIcon />
              Add
            </MainButton>
            <PopOver open={addPopup} onClose={setAddPopup}>
              <AddFromTemplateLibrary
                data={checklist
                  .filter((item) => !isEqual(item.default_tem, 1))
                  .map((item) => ({
                    title: item.title,
                    icon: <CheckListIcon fill="white" />,
                    onClick: () =>
                      addToDefaultChecklist({
                        id: item.id,
                        requested_by: parse(item.requested_from),
                      }),
                  }))}
                isLoading={loadingChecklistTemplate}
                emptyTemplate={{
                  label: "Checklist",
                  link: "/template-library/checklists/add",
                  linkLabel: "Checklist",
                }}
              />
            </PopOver>
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            placeholder="Search"
            searchIcon={true}
            filter={true}
            value={searchText}
            setValue={(val) => setSearchText(val)}
            tags={tags ?? []}
            onApplyFilter={applyFilter}
            applySorting={applySorting}
            filterData={filterData}
            showLibrarySource={false}
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          {loadingDefaultChecklists ? (
            <LoadingListCards />
          ) : searchResult.length === 0 ? (
            <TemplateNotFound
              label="Checklist"
              onClick={() => setAddPopup(true)}
              linkLabel="Checklist"
            />
          ) : (
            Children.toArray(
              searchResult.map((checklist) => {
                return (
                  <ListCard
                    editIcon={false}
                    data={checklist.title}
                    onCross={() => removeFromDefaultChecklist(checklist.id)}
                    secondaryData={
                      isEqual(checklist.system_status, 1) ? "System" : ""
                    }
                    icon={
                      <ListIconWrapper>
                        <CheckListIcon fill="white" />
                      </ListIconWrapper>
                    }
                    deleteIcon
                    crossIcon={remove_items_from_site_template}
                    showModalonCross
                    deleteTitle="Remove This Template?"
                    deleteButtonText="Confirm"
                    deleteMessage="You are about to remove this template from your default site template. Are you sure you want to do this?"
                  />
                );
              })
            )
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
