import { OutletProps } from "react-router-dom";

export default function HeaderContainer(props) {
  return (
    <>
      <div className={`header-container ${props.className ?? ""}`}>
        {props.children}
      </div>
    </>
  );
}
