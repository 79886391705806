import { axiosInstance } from "helpers";

export async function getSmtpSetting(website_id) {
  return axiosInstance
    .get("allfeaturesettings", {
      params: {
        apiKey: localStorage.getItem("apikey"),
        added_by: localStorage.getItem("user_id"),
        tab_hint: "SMTP",
        website_id,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export async function updateSmtpSetting({ website_id, data }) {
  return axiosInstance
    .post("updatefeaturesettings", {
      apiKey: localStorage.getItem("apikey"),
      added_by: localStorage.getItem("user_id"),
      system_status: 0,
      website_id,
      tab_hint: "SMTP",
      smtp_settings: {
        ...data,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}
