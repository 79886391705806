import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contentRequestsItem: [],
  loaded: false,
  defaultSite: {
    contentRequestsItem: [],
    loaded: false,
  },
};

const contentRequestsItemSlice = createSlice({
  name: "contentRequestsItem",
  initialState,
  reducers: {
    getAllTemplatecontentRequestsItem: (state, action) => {
      state.contentRequestsItem = action.payload;
      state.loaded = true;
    },
    addTemplateContentRequestItem: (state, action) => {
      state.contentRequestsItem = [
        ...state.contentRequestsItem,
        action.payload,
      ];
    },
    updateTemplateContentRequest: (state, action) => {
      const index = state.contentRequestsItem.findIndex(
        (_) => String(_.id) === String(action.payload.id)
      );
      if (index !== -1) {
        for (const key in action.payload) {
          state.contentRequestsItem[index][key] = action.payload[key];
        }
      }
    },
    deleteTemplateContentRequest: (state, action) => {
      state.contentRequestsItem = state.contentRequestsItem.filter(
        (_) => String(_.id) !== String(action.payload)
      );
    },
    getAllDefaultSiteContentRequestsItem: (state, action) => {
      state.defaultSite.contentRequestsItem = action.payload;
      state.defaultSite.loaded = true;
    },
    addDefaultSiteContentRequestItem: (state, action) => {
      const index = state.contentRequestsItem.findIndex(
        (_) => String(_.id) === String(action.payload.id)
      );
      if (index !== -1) {
        state.contentRequestsItem[index].default_tem = "1";
        state.defaultSite.contentRequestsItem = [
          ...state.defaultSite.contentRequestsItem,
          state.contentRequestsItem[index],
        ];
      }
    },
    deleteDefaultSiteContentRequestItem: (state, action) => {
      const index = state.contentRequestsItem.findIndex(
        (_) => String(_.id) === String(action.payload)
      );
      if (index !== -1) {
        state.contentRequestsItem[index].default_tem = "0";
      }
      state.defaultSite.contentRequestsItem =
        state.defaultSite.contentRequestsItem.filter(
          (_) => String(_.id) !== String(action.payload)
        );
    },
  },
});

export const {
  getAllTemplatecontentRequestsItem,
  addTemplateContentRequestItem,
  updateTemplateContentRequest,
  deleteTemplateContentRequest,
  getAllDefaultSiteContentRequestsItem,
  addDefaultSiteContentRequestItem,
  deleteDefaultSiteContentRequestItem,
} = contentRequestsItemSlice.actions;

export default contentRequestsItemSlice.reducer;
