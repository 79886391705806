import { Children } from "react";
import { OpenIcon } from "allIcons";
import { Link } from "react-router-dom";

export function List({ icon, title, arrow }) {
  return (
    <>
      <div className="add-pop-up-content-icon">{icon}</div>
      <h3 className="add-pop-up-content-title">{title}</h3>
      {arrow ? (
        <div className="add-pop-up-content-arrow">
          <OpenIcon />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export function PopUpListing({ data = [] }) {
  return (
    <>
      <div className="list-pop-up-content">
        {Children.toArray(
          data.map((item) =>
            item.htmlFor ? (
              <label className="add-pop-up-content" htmlFor={item.htmlFor}>
                <List icon={item.icon} title={item.title} arrow={item.arrow} />
              </label>
            ) : item.to ? (
              <Link className="add-pop-up-content" to={item.to}>
                <List icon={item.icon} title={item.title} arrow={item.arrow} />
              </Link>
            ) : (
              <div className="add-pop-up-content" onClick={item.onClick}>
                <List icon={item.icon} title={item.title} arrow={item.arrow} />
              </div>
            )
          )
        )}
      </div>
    </>
  );
}
