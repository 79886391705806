import { useMutation } from "@tanstack/react-query";
import { axiosInstance, useUtilities } from "helpers";

export async function uploadFiles(data) {
  const formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key]);
  }
  formData.append("added_by", localStorage.getItem("user_id"));
  formData.append("apiKey", localStorage.getItem("apikey"));
  return axiosInstance
    .post("uploadFiles", formData)
    .then((res) => res.data)
    .catch((err) => err);
}

export function useUploadFiles() {
  const { notification } = useUtilities();
  return useMutation(uploadFiles, {
    onError: (err) =>
      notification({
        type: "error",
        message: err.message,
      }),
  });
}
