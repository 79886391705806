import { useState } from "react";
import { BackArrow, SaveIcon, FormSectionIcon } from "allIcons";
import {
  BodyContainer,
  TextEditor,
  HeaderContainer,
  IconWrapper,
  InputField,
  ListCard,
  PreviewCard,
  PrimaryHeading,
  RightMainContainer,
  SecondaryHeading,
  ListIconWrapper,
  AddComponent,
  MainButton,
  DeleteButton,
} from "commonUI";
import { useParams } from "react-router-dom";
import { isEqual } from "helpers";

export default function AddEditFormTemplate() {
  const { id } = useParams();
  const [forms, setForm] = useState([
    "[Form Section Title]",
    "[Form Section Title]",
    "[Form Section Title]",
    "[Form Section Title]",
  ]);

  return (
    <>
      <RightMainContainer>
        <HeaderContainer>
          <IconWrapper>
            <BackArrow />
            <PrimaryHeading>[Form Title]</PrimaryHeading>
          </IconWrapper>
          <div className="flex items-center justify-center gap-14">
            <DeleteButton
            show={!isEqual(id, "add")}
            deleteMessage="You are about to delete Content Request Item from your account. This
            action cannot be undone! All settings related to the WP Site
            Launcher Content Request Item will permanently removed. Do you wish to
            proceed and delete the Content Request Item?"
            />
            <MainButton>
              <SaveIcon />
              Save Changes
            </MainButton>
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField label="Title" placeholder="Write Title Here" />
          <TextEditor
            label="Description"
            editorClass="mt-2"
            option={false}
            rows={5}
          />
          <div className="mt-3">
            <SecondaryHeading>Sections</SecondaryHeading>
            {forms.map((item, index) => {
              return (
                <ListCard
                  key={index}
                  data={item}
                  duplicate={true}
                  edit={true}
                  link={`/template-library/form-sections/${index}`}
                  strictLink={true}
                  onDuplicate={(data) => {
                    setForm(() => [...forms, `Copy of ${data}`]);
                  }}
                  onDelete={() => {
                    setForm(() => forms.filter((_, i) => i !== index));
                  }}
                  icon={
                    <ListIconWrapper>
                      <FormSectionIcon />
                    </ListIconWrapper>
                  }
                  deleteTitle="Delete This Template?"
                  deleteButtonText="Confirm Delete"
                  deleteMessage="You are about to delete this template. It will be permanently removed from your account library. Are you sure you want to do this?"
                />
              );
            })}
          </div>
          <AddComponent title={"Add Section"} className="mt-3 mb-3" />
          <div className="mt-2">
            <SecondaryHeading>Tags</SecondaryHeading>
            <div className="flex items-center gap-5">
              <PreviewCard
                color="#F12424"
                title="Tag 1"
                icon={true}
                className="m-0"
              />
              <PreviewCard
                color="#7846FF"
                title="Tag 2"
                icon={true}
                className="m-0"
              />
              <PreviewCard
                color="#2EA3F2"
                title="Tag 3"
                icon={true}
                className="m-0"
              />
            </div>
          </div>
          <AddComponent />
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
