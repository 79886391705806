export default function FormSectionIcon(props) {
  return (
    <>
      <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${props.className ?? ""}`}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4658 1.45455H3.53489C3.13247 1.45455 2.80762 1.1297 2.80762 0.727273C2.80762 0.324848 3.13247 0 3.53489 0H12.4658C12.8682 0 13.1931 0.324848 13.1931 0.727273C13.1931 1.1297 12.8682 1.45455 12.4658 1.45455ZM12.4658 13.5758H3.53489C3.13247 13.5758 2.80762 13.2509 2.80762 12.8485C2.80762 12.4461 3.13247 12.1212 3.53489 12.1212H12.4658C12.8682 12.1212 13.1931 12.4461 13.1931 12.8485C13.1931 13.2509 12.8682 13.5758 12.4658 13.5758ZM1.69697 10.1818H14.303C15.2388 10.1818 16 9.42062 16 8.48486V5.09092C16 4.15516 15.2388 3.39395 14.303 3.39395H1.69697C0.761212 3.39395 0 4.15516 0 5.09092V8.48486C0 9.42062 0.761212 10.1818 1.69697 10.1818ZM1.45455 5.09092C1.45455 4.95516 1.56121 4.8485 1.69697 4.8485H14.303C14.4388 4.8485 14.5455 4.95516 14.5455 5.09092V8.48486C14.5455 8.62062 14.4388 8.72729 14.303 8.72729H1.69697C1.56121 8.72729 1.45455 8.62062 1.45455 8.48486V5.09092Z"
          fill={props.fill ?? "white"}
        />
      </svg>
    </>
  );
}
