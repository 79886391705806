export default function FilesIcon(props) {
  return (
    <>
      <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${props.className ?? ''}`}
        onClick={props.onClick}
      >
        <path
          d="M14.0001 13.7501H3.00009C2.70387 13.7528 2.41008 13.6964 2.13589 13.5843C1.86171 13.4722 1.61263 13.3065 1.40317 13.097C1.1937 12.8875 1.02805 12.6385 0.915923 12.3643C0.803795 12.0902 0.747422 11.7963 0.750091 11.5001V2.50009C0.747422 2.20387 0.803795 1.91002 0.915923 1.63583C1.02805 1.36164 1.1937 1.11263 1.40317 0.903167C1.61263 0.693701 1.86171 0.52799 2.13589 0.415862C2.41008 0.303734 2.70387 0.247422 3.00009 0.250091H6.00009C6.11652 0.250091 6.2314 0.277121 6.33554 0.329192C6.43968 0.381263 6.53027 0.456992 6.60013 0.550139L7.87009 2.25009H12.5001C12.7963 2.24742 13.0901 2.30373 13.3643 2.41586C13.6385 2.52799 13.8875 2.6937 14.097 2.90317C14.3065 3.11263 14.4721 3.36164 14.5843 3.63583C14.6964 3.91002 14.7528 4.20387 14.7501 4.50009V5.25009H16.5001C16.6199 5.25081 16.7379 5.28035 16.844 5.33603C16.9502 5.3917 17.0414 5.47196 17.1101 5.57016C17.1805 5.66707 17.2256 5.78004 17.2413 5.89877C17.257 6.01751 17.2428 6.1382 17.2001 6.25009L14.7001 13.2501C14.6501 13.396 14.5557 13.5225 14.4302 13.6122C14.3047 13.7018 14.1543 13.7501 14.0001 13.7501ZM3.56009 12.2501H13.4701L15.4701 6.75009H5.5701L3.56009 12.2501ZM3.00009 1.75009C2.90047 1.74585 2.80107 1.76224 2.70816 1.79843C2.61524 1.83462 2.53087 1.88979 2.46036 1.9603C2.38985 2.0308 2.33474 2.11518 2.29855 2.2081C2.26236 2.30101 2.24585 2.40047 2.25009 2.50009V11.45L4.25009 5.75009C4.3032 5.60421 4.39971 5.47815 4.52664 5.38876C4.65357 5.29937 4.80486 5.25094 4.96011 5.25009H13.2101V4.50009C13.2129 4.40085 13.1954 4.30205 13.1587 4.20981C13.1219 4.11757 13.0668 4.03392 12.9966 3.96371C12.9263 3.89351 12.8426 3.83832 12.7503 3.8016C12.6581 3.76489 12.5594 3.74733 12.4601 3.75009H7.46011C7.34354 3.75088 7.22844 3.7241 7.12418 3.67197C7.01991 3.61983 6.92939 3.54377 6.86008 3.45004L5.58011 1.75009H3.00009Z"
          fill={props.fill ?? "white"}
        />
      </svg>
    </>
  );
}
