import { useMutation, useQuery } from "@tanstack/react-query";
import { useUtilities, axiosInstance, useSearch } from "helpers";
import { useEffect } from "react";
import {
  getAllTemplatePhases,
  addTemplatePhase,
  deleteTemplatePhase,
  updateTemplatePhase,
  getAllDefaultPhases,
  addDefaultPhase,
  deleteDefaultPhase,
} from "redux/reducers/templateLibrary/phases";

export async function getTemplatePhases(web) {
  return axiosInstance
    .post("showphaseslibrarytemplate", {
      apikey: web ? web : localStorage.getItem("apikey"),
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useGetTemplatePhases(web) {
  return useQuery(["templatePhases", web], () => getTemplatePhases(web));
}

export function usePhases(web = "") {
  const {
    dispatch,
    phases: { phases, loaded },
  } = useUtilities();
  const { data, isLoading, error } = useGetTemplatePhases(web);

  useEffect(() => {
    if (isLoading || loaded) return;
    if (data) {
      dispatch(getAllTemplatePhases(data.results));
    }
  }, [data, isLoading, loaded]);

  return { data: phases, isLoading: isLoading || !loaded, error };
}

export async function addNewPhase(phase) {
  return axiosInstance
    .post("addphaseslibrarytemplate", {
      apikey: localStorage.getItem("apikey"),
      website_id: localStorage.getItem("apikey"),
      wpsl_tem: localStorage.getItem("apikey"),
      target_date: "",
      default_tem: "",
      status: [],
      ...phase,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useAddNewPhase(redirect = "", updateDefaultState = false) {
  const { web = null } = useSearch();
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(addNewPhase, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        if (updateDefaultState) {
          dispatch(addDefaultPhase(data.Data));
        } else {
          dispatch(addTemplatePhase(data.Data));
        }
        notification({
          type: "success",
          message: "Phase added successfully",
        });
        if (redirect)
          navigate(`${redirect}${data.Data.id}${web ? "?web=" + web : ""}`);
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (error) => {
      notification({
        type: "error",
        message: error.message,
      });
    },
  });
}

export async function deletePhase(id) {
  return axiosInstance
    .post("deletelibrarytemplaphases", {
      apikey: localStorage.getItem("apikey"),
      id,
    })
    .then((res) => ({ ...res.data, id }))
    .catch((err) => new Error(err));
}

export function useDeletePhase(redirect = "") {
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(deletePhase, {
    onSuccess: (data) => {
      if (data.Status_Code === 200) {
        dispatch(deleteTemplatePhase(data.id));
        notification({
          type: "success",
          message: "Phase deleted successfully",
        });
        if (redirect) navigate(redirect);
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (error) => {
      notification({
        type: "error",
        message: error.message,
      });
    },
  });
}

export async function updatePhase(phase) {
  return axiosInstance
    .post("editphaseslibrarytemplate", {
      apikey: localStorage.getItem("apikey"),
      ...phase,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUpdatePhase() {
  const { dispatch, notification } = useUtilities();
  return useMutation(updatePhase, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(updateTemplatePhase(data.Data));
        notification({
          type: "success",
          message: "Phase updated successfully",
        });
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (error) => {
      notification({
        type: "error",
        message: error.message,
      });
    },
  });
}

export async function getDefaultPhase(web) {
  return axiosInstance
    .post("showdefaulttemplatephases", {
      apikey: localStorage.getItem("apikey"),
      website_id: web,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useGetDefaultPhase() {
  const { web = null } = useSearch();
  return useQuery(["defaultPhase", web], () => getDefaultPhase(web));
}

export function useDefaultPhases() {
  const { web = null } = useSearch();
  const { data, isLoading, error } = useGetDefaultPhase();
  const {
    dispatch,
    phases: {
      defaultSite: { phases, loaded, website_id },
    },
  } = useUtilities();

  useEffect(() => {
    if (data && (website_id !== web || !loaded)) {
      dispatch(getAllDefaultPhases({ phases: data.results, website_id: web }));
    }
  }, [data, isLoading, loaded, web, website_id]);
  return {
    data: phases,
    isLoading: isLoading || !loaded || web !== website_id,
    error,
  };
}

export async function addDefaultTemplatePhase(data) {
  return axiosInstance
    .post("adddefaulttemplatephases", {
      apikey: localStorage.getItem("apikey"),
      ...data,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useAddDefaultPhase() {
  const { dispatch, notification } = useUtilities();
  return useMutation(addDefaultTemplatePhase, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(addDefaultPhase(data.Data));
        notification({
          type: "success",
          message: "Phase added to default settings successfully",
        });
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (error) =>
      notification({
        type: "error",
        message: error.message,
      }),
  });
}

export async function deleteDefaultTemplatePhase(data) {
  return axiosInstance
    .post("defaultphasesremove", {
      apikey: localStorage.getItem("apikey"),
      ...data,
    })
    .then((res) => ({ ...res.data, ...data }))
    .catch((err) => new Error(err));
}

export function useDeleteDefaultPhase() {
  const { dispatch, notification } = useUtilities();
  return useMutation(deleteDefaultTemplatePhase, {
    onSuccess: (data) => {
      if (data.Status_Code === 200) {
        dispatch(deleteDefaultPhase(data.id));
        notification({
          type: "success",
          message: "Phase removed from default settings successfully",
        });
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (error) =>
      notification({
        type: "error",
        message: error.message,
      }),
  });
}
