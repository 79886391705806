export default function PluginIcon({ fill, ...props }) {
  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.903 3.64087C12.6192 3.35699 12.1542 3.35699 11.8654 3.64087L9.5699 5.93638L7.15693 3.52341L9.42796 1.25237C9.71673 0.968496 9.71673 0.503522 9.42796 0.214749C9.13919 -0.0740248 8.67911 -0.0691304 8.39033 0.214749L6.1193 2.48578L5.26767 1.63414C4.99358 1.36005 4.50902 1.36005 4.23004 1.63414L2.17437 3.68982C0.353625 5.51545 0.192108 8.37871 1.69471 10.3854L0.803916 11.2762C-0.267972 12.353 -0.267972 14.0318 0.803916 15.1037L1.48914 15.7889C1.63597 15.9358 1.82196 16.0043 2.00795 16.0043C2.19394 16.0043 2.38483 15.9358 2.52677 15.7889C2.81554 15.5051 2.81554 15.0401 2.52677 14.7513L1.84154 14.0661C1.34231 13.5668 1.34231 12.8131 1.84154 12.3139L2.73233 11.428C3.63781 12.1083 4.71949 12.446 5.80116 12.446C7.11777 12.446 8.42949 11.9468 9.42796 10.9434L11.4836 8.88774C11.6256 8.75069 11.699 8.5647 11.699 8.36893C11.699 8.17315 11.6256 7.98716 11.4836 7.85011L10.6075 6.974L12.903 4.6785C13.1918 4.39462 13.1918 3.92965 12.903 3.64087ZM8.39033 9.90579C6.96605 11.335 4.64118 11.335 3.21689 9.90579C1.7877 8.4815 1.7877 6.15663 3.21689 4.72744L4.74885 3.19548L9.9272 8.36893L8.39033 9.90579Z"
        fill={fill ?? "white"}
      />
    </svg>
  );
}
