import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "helpers";

const initialState = {
  generalSettings: [],
  loaded: false,
  website_id: '',
};

const generalSettingsSlice = createSlice({
  name: "generalSettings",
  initialState,
  reducers: {
    setGeneralSettingAction: (state, action) => {
      const { website_id, generalSettings } = action.payload;
      state.generalSettings = generalSettings;
      state.loaded = true;
      state.website_id = website_id;
    },
    updateGeneralSettingAction: (state, action) => {
      const index = state.generalSettings.findIndex((_) =>
        isEqual(_.id, action.payload.id)
      );
      if (index !== -1) {
        for (const key in action.payload) {
          state.generalSettings[index][key] = action.payload[key];
        }
      }
    },
  },
});

export const { setGeneralSettingAction, updateGeneralSettingAction } =
  generalSettingsSlice.actions;

export default generalSettingsSlice.reducer;
