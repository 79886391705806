import { BackArrow, SaveIcon } from "allIcons";
import {
  BodyContainer,
  TextEditor,
  HeaderContainer,
  IconWrapper,
  InputField,
  PreviewCard,
  PrimaryHeading,
  RightMainContainer,
  SecondaryHeading,
  AddComponent,
  SelectField,
  MainButton,
  DeleteButton,
} from "commonUI";
import { useParams } from "react-router-dom";

export default function AddEditService() {
  const { id } = useParams();

  return (
    <>
      <RightMainContainer>
        <HeaderContainer>
          <IconWrapper>
            <BackArrow />
            <PrimaryHeading>[Services Title]</PrimaryHeading>
          </IconWrapper>
          <div className="flex items-center justify-center gap-14">
            <DeleteButton
            show={id !== "add"}
              deleteMessage="You are about to delete Service Item from your account. This
            action cannot be undone! All settings related to the WP Site
            Launcher Service Item will permanently removed. Do you wish to
            proceed and delete the Service Item?"
            />
            <MainButton>
              <SaveIcon />
              Save Changes
            </MainButton>
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField label="Title" placeholder="Write Title Here" />
          <TextEditor
            label="Description"
            editorClass="mt-2"
            option={false}
            rows={5}
          />
          <InputField
            label="Price"
            placeholder="Write Price Here"
            className="mt-3"
          />
          <SelectField label="Frequency" className="">
            <option value="text">Once</option>
          </SelectField>
          <div className="mt-2">
            <SecondaryHeading>Tags</SecondaryHeading>
            <div className="flex items-center gap-5">
              <PreviewCard
                color="#F12424"
                title="Tag 1"
                icon={true}
                className="m-0"
              />
              <PreviewCard
                color="#7846FF"
                title="Tag 2"
                icon={true}
                className="m-0"
              />
              <PreviewCard
                color="#2EA3F2"
                title="Tag 3"
                icon={true}
                className="m-0"
              />
            </div>
          </div>
          <AddComponent />
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
