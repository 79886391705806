import { useMutation, useQuery } from "@tanstack/react-query";
import { useUtilities, axiosInstance } from "helpers";
import { useEffect } from "react";
import {
  addTemplateContentRequest,
  getAllTemplateContentRequests,
  updateTemplateContentRequest,
  deleteTemplateContentRequest,
  getAllDefaultContentRequest,
  addDefaultContentRequest,
  deleteDefaultContentRequest,
} from "redux/reducers/templateLibrary/contentRequests";

export async function getContentRequests() {
  return axiosInstance
    .post("defaulttaskshow", {
      apikey: localStorage.getItem("apikey"),
      parent_type: "content",
      contentitems: true,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useGetContentRequests() {
  return useQuery(["contentRequests"], getContentRequests);
}

export function useContentRequests() {
  const {
    dispatch,
    contentRequests: { contentRequests, loaded },
  } = useUtilities();

  const { data, isLoading, error } = useGetContentRequests();

  useEffect(() => {
    if (isLoading || loaded) return;
    if (data) dispatch(getAllTemplateContentRequests(data.results));
  }, [isLoading, data, loaded]);

  return { data: contentRequests, isLoading, error };
}

export async function addContentRequest(request) {
  return axiosInstance
    .post("addlibrarytemplate", {
      apikey: localStorage.getItem("apikey"),
      parent_type: "content",
      module_hint: "content",
      wpsl_tem: localStorage.getItem("apikey"),
      system_status: "0",
      ...request,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useAddContentRequest(redirect = true) {
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(addContentRequest, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(addTemplateContentRequest(data.Data));
        notification({
          type: "success",
          message: "Content Request Added Successfully",
        });
        if (redirect)
          navigate(
            `/template-library/content-request-templates/${data.Data.id}`
          );
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: err.message,
      });
    },
  });
}

export async function updateContentRequest(request) {
  return axiosInstance
    .post("defaulttaskedit", {
      apikey: localStorage.getItem("apikey"),
      ...request,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUpdateContentRequest() {
  const { dispatch, notification } = useUtilities();
  return useMutation(updateContentRequest, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(updateTemplateContentRequest(data.Data));
        notification({
          type: "success",
          message: "Content Request Updated Successfully",
        });
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: err.message,
      });
    },
  });
}

export async function deleteContentRequest(id) {
  return axiosInstance
    .post("defaulttaskdelete", {
      apikey: localStorage.getItem("apikey"),
      id,
    })
    .then((res) => ({ ...res.data, id }))
    .catch((err) => new Error(err));
}

export function useDeleteContentRequest(redirect = false) {
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(deleteContentRequest, {
    onSuccess: (data) => {
      if (data.Status_Code === 200) {
        dispatch(deleteTemplateContentRequest(data.id));
        notification({
          type: "success",
          message: "Content Request Deleted Successfully",
        });
        if (redirect) navigate("/template-library/content-request-templates");
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: err.message,
      });
    },
  });
}

export async function getDefaultContentRequest() {
  return axiosInstance
    .post("getwpsltasktemp", {
      apikey: localStorage.getItem("apikey"),
      parent_type: "content",
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useGetDefaultContentRequest() {
  return useQuery(["defaultContentRequest"], getDefaultContentRequest);
}

export function useDefaultContentRequest() {
  const {
    dispatch,
    contentRequests: {
      defaultSite: { contentRequests, loaded },
    },
  } = useUtilities();
  const { data, isLoading, error } = useGetDefaultContentRequest();

  useEffect(() => {
    if (isLoading || loaded) return;
    if (data) {
      dispatch(getAllDefaultContentRequest(data.Data));
    }
  }, [isLoading, loaded, data]);

  return { data: contentRequests, isLoading: isLoading || !loaded, error };
}

export async function addDefaultContent(id) {
  return axiosInstance
    .post("adddefaulttemplate", {
      apikey: localStorage.getItem("apikey"),
      id,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useAddDefaultContentRequest() {
  const { notification, dispatch } = useUtilities();
  return useMutation(addDefaultContent, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(addDefaultContentRequest(data.Data));
        notification({
          type: "success",
          message: "Content Request added to default successfull",
        });
      } else {
        notification({
          message: data.Message,
          type: "error",
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: err.message,
      });
    },
  });
}

export async function deleteDefaultContent(id) {
  return axiosInstance
    .post("defaulttaskremove", {
      apikey: localStorage.getItem("apikey"),
      id,
    })
    .then((res) => ({ ...res.data, id }))
    .catch((err) => new Error(err));
}

export function useDeleteDefaultContentRequest() {
  const { notification, dispatch } = useUtilities();
  return useMutation(deleteDefaultContent, {
    onSuccess: (data) => {
      if (data.Status_Code === 200) {
        dispatch(deleteDefaultContentRequest(data.id));
        notification({
          type: "success",
          message: "Default Content Request removed successfull",
        });
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: err.message,
      });
    },
  });
}
