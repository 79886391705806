export default function PriorityIcon({ fill, ...props }) {
  return (
    <>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M11.9616 16.3697C11.6901 16.3697 11.4137 16.3018 11.1567 16.166L8.00036 14.5078L4.844 16.166C4.27188 16.4666 3.60279 16.4278 3.10339 16.0642C2.604 15.7006 2.36157 15.08 2.46824 14.44L3.0743 10.9248L0.519149 8.44239C0.0536946 7.99148 -0.116002 7.34663 0.077937 6.75512C0.267028 6.16845 0.785816 5.74663 1.42582 5.64966L4.95551 5.13572L6.53612 1.93572C6.82218 1.35391 7.3846 0.995117 8.00036 0.995117C8.61612 0.995117 9.17854 1.35391 9.4646 1.93572L11.0452 5.13572L14.5749 5.64966C15.2149 5.74178 15.7337 6.1636 15.9228 6.75027C16.1119 7.33694 15.9422 7.98663 15.4816 8.43754L12.9264 10.9248L13.5276 14.44C13.6391 15.08 13.3967 15.7006 12.8973 16.0642C12.621 16.263 12.2961 16.3648 11.9616 16.3648V16.3697ZM8.00036 12.9612C8.11672 12.9612 8.23309 12.9903 8.33976 13.0436L11.8355 14.8812C11.9179 14.9248 11.9955 14.9297 12.044 14.8909C12.0925 14.8521 12.1119 14.7842 12.0973 14.6872L11.4282 10.7939C11.3895 10.5563 11.467 10.3188 11.6367 10.1491L14.4634 7.39027C14.5313 7.32239 14.5555 7.25451 14.5361 7.19633C14.5167 7.13815 14.4537 7.09936 14.3616 7.08481L10.4537 6.51754C10.2161 6.4836 10.0125 6.3333 9.90582 6.11997L8.16036 2.58057C8.07794 2.41572 7.92279 2.41087 7.83551 2.58057L6.09006 6.11997C5.98339 6.3333 5.77976 6.4836 5.54218 6.51754L1.6343 7.08481C1.54218 7.09936 1.47915 7.13815 1.45976 7.19633C1.44036 7.25451 1.46945 7.32239 1.53248 7.39027L4.35915 10.1491C4.52885 10.3139 4.60642 10.5563 4.56763 10.7939L3.89854 14.6872C3.884 14.7794 3.89854 14.8521 3.95188 14.886C4.00521 14.9248 4.07794 14.92 4.16036 14.8763L7.65612 13.0388C7.76279 12.9854 7.87915 12.9563 7.99551 12.9563L8.00036 12.9612Z"
          fill={fill ?? "white"}
        />
      </svg>
    </>
  );
}
