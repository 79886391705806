import { useQuery } from "@tanstack/react-query";
import { axiosInstance, useSearch } from "helpers";

export async function getFeatureModuleSetting(website_id, tab_hint) {
  return axiosInstance
    .get("allfeaturesettings", {
      params: {
        apiKey: localStorage.getItem("apikey"),
        added_by: localStorage.getItem("user_id"),
        tab_hint,
        website_id,
      },
    })
    .then((response) => response.data)
    .catch((error) => error);
}

export function useGetFeatureModuleSetting(module) {
  const { web = null } = useSearch();
  return useQuery([module, web], () => getFeatureModuleSetting(web, module));
}
