import { useState } from "react";
import { BackArrow, ServiceIcon, SaveIcon } from "allIcons";
import {
  BodyContainer,
  TextEditor,
  HeaderContainer,
  IconWrapper,
  InputField,
  ListCard,
  PreviewCard,
  PrimaryHeading,
  RightMainContainer,
  SecondaryHeading,
  ListIconWrapper,
  AddComponent,
  MainButton,
  DeleteButton,
} from "commonUI";
import { useParams } from "react-router-dom";
import { isEqual } from "helpers";

export default function AddEditFormSection() {
  const { id } = useParams();
  const [forms, setForm] = useState(["[Field Title]", "[Service Title]"]);

  return (
    <>
      <RightMainContainer>
        <HeaderContainer>
          <IconWrapper>
            <BackArrow />
            <PrimaryHeading>[Form Section Title]</PrimaryHeading>
          </IconWrapper>
          <div className="flex items-center justify-center gap-14">
            <DeleteButton
              show={!isEqual(id, "add")}
              deleteMessage="You are about to delete Content Request Item from your account. This
                action cannot be undone! All settings related to the WP Site
                Launcher Content Request Item will permanently removed. Do you wish to
                proceed and delete the Content Request Item?"
            />
            <MainButton>
              <SaveIcon />
              Save Changes
            </MainButton>
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField label="Title" placeholder="Write Title Here" />
          <TextEditor
            label="Description"
            editorClass="mt-2"
            option={false}
            rows={5}
          />
          <div className="mt-3">
            <SecondaryHeading>Fields</SecondaryHeading>
            {forms.map((item, index) => {
              return (
                <ListCard
                  key={index}
                  data={item}
                  duplicate={false}
                  secondaryData="Price"
                  edit={true}
                  link={`/template-library/form-fields/${index}`}
                  strictLink={true}
                  onDuplicate={(data) => {
                    setForm(() => [...forms, `Copy of ${data}`]);
                  }}
                  deleteIcon={true}
                  icon={
                    <ListIconWrapper>
                      <ServiceIcon />
                    </ListIconWrapper>
                  }
                />
              );
            })}
          </div>
          <AddComponent title={"Add Fields"} className="mt-3 mb-3" />
          <div className="mt-2">
            <SecondaryHeading>Tags</SecondaryHeading>
            <div className="flex items-center gap-5">
              <PreviewCard
                color="#F12424"
                title="Tag 1"
                icon={true}
                className="m-0"
              />
              <PreviewCard
                color="#7846FF"
                title="Tag 2"
                icon={true}
                className="m-0"
              />
              <PreviewCard
                color="#2EA3F2"
                title="Tag 3"
                icon={true}
                className="m-0"
              />
            </div>
          </div>
          <AddComponent />
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
