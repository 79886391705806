import { Children, useState, useEffect, useDeferredValue } from "react";
import { AddIcon, TaskIcon } from "allIcons";
import {
  BodyContainer,
  HeaderContainer,
  InputField,
  ListCard,
  ListIconWrapper,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
  TemplateNotFound,
  LoadingListCards,
  Loader,
  FiltersList,
} from "commonUI";
import { useMutation } from "@tanstack/react-query";
import { useSelector, useDispatch } from "react-redux";
import {
  addSingleTask,
  deleteSingleTask,
} from "redux/reducers/templateLibrary/tasks";
import { addTask, deleteTask, useTasks } from "api/templateLibrary/tasks";
import { Link } from "react-router-dom";
import { useNotification } from "notification";
import { useTags } from "api/siteSettings/Tags";
import { useUserPermissions } from "hooks/user";
import {
  libraryItemSorting,
  libraryItemFiltering,
} from "filterAndSort/templateLibrary";

export default function Task() {
  const [filterData, setFilterData] = useState({});
  const notification = useNotification();
  const { userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { data: tasks, isLoading } = useTasks();
  const { data: tags, isLoading: tagsLoading, error: tagsError } = useTags();
  const [searchText, setSearchText] = useState("");
  const defferedSearch = useDeferredValue(searchText);
  const [searchResult, setSearchResult] = useState([]);
  const { add_new_templates, edit_templates, delete_templates } =
    useUserPermissions();

  useEffect(() => {
    setSearchResult(
      tasks.filter((task) =>
        task.title.toLowerCase().includes(defferedSearch.toLowerCase().trim())
      )
    );
  }, [defferedSearch, tasks]);
  const addMutation = useMutation(addTask, {
    onSuccess: (data) => {
      notification({
        type: "success",
        message: "Task Dupilcated Successfully",
      });
      dispatch(addSingleTask(data.Data));
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const deleteMutation = useMutation(deleteTask, {
    onSuccess: (data) => {
      notification({
        type: "success",
        message: "Task Deleted Successfully",
      });
      dispatch(deleteSingleTask(data.id));
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const applySorting = (order, orderBy) => {
    setSearchResult((prevSearchResult) =>
      libraryItemSorting(order, orderBy, prevSearchResult)
    );
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setSearchResult(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        tasks
      )
    );
  };

  return (
    <>
      <RightMainContainer>
        {(addMutation.isLoading || deleteMutation.isLoading) && (
          <Loader loader="block" />
        )}
        <HeaderContainer>
          <PrimaryHeading>Tasks</PrimaryHeading>
          {add_new_templates ? (
            <Link to="/template-library/tasks/add">
              <MainButton>
                <AddIcon />
                Add
              </MainButton>
            </Link>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          <InputField
            placeholder="Search"
            searchIcon={true}
            filter={true}
            value={searchText}
            setValue={(val) => setSearchText(val)}
            tags={tags ?? []}
            onApplyFilter={applyFilter}
            applySorting={applySorting}
            filterData={filterData}
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          {isLoading ? (
            <LoadingListCards />
          ) : tasks.length === 0 || searchResult.length === 0 ? (
            <TemplateNotFound
              label="Tasks"
              linkLabel="Task"
              link={add_new_templates ? `/template-library/tasks/add` : ""}
            />
          ) : (
            Children.toArray(
              searchResult.map((task, index) => {
                return (
                  <ListCard
                    data={task.title}
                    duplicate={add_new_templates}
                    editIcon={edit_templates}
                    secondaryData={task.system_status == 1 ? "System" : ""}
                    link={
                      edit_templates ? `/template-library/tasks/${task.id}` : ""
                    }
                    strictLink={true}
                    onDuplicate={() => {
                      addMutation.mutate({
                        ...task,
                        description: task.message ?? task.description,
                        tags:
                          typeof task.tags != "object"
                            ? JSON.parse(task.tags)
                            : task.tags,
                        title: `Copy of ${task.title}`,
                        name: userData?.name,
                      });
                    }}
                    onDelete={() => {
                      if (!delete_templates) return;
                      if (task.system_status == 1) return;
                      deleteMutation.mutate(task.id);
                    }}
                    deleteIcon={
                      delete_templates ? task.system_status == 1 : true
                    }
                    icon={
                      <ListIconWrapper>
                        <TaskIcon />
                      </ListIconWrapper>
                    }
                    deleteTitle="Delete This Template?"
                    deleteButtonText="Confirm Delete"
                    deleteMessage="You are about to delete this template from your account. This action cannot be undone! All template content will be permanently removed. Are you sure you want to do this?"
                  />
                );
              })
            )
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
