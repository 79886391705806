import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "notification";
import { useNavigate } from "react-router-dom";

export default function useUtilities() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const notification = useNotification();
  const navigate = useNavigate();

  return { dispatch, notification, navigate, ...state};
}
