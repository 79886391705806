import { axiosInstance, printError, useSearch, useUtilities } from "helpers";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getAllRoles,
  addNewRole,
  updateSingleRole,
  deleteSingleRole,
  getAllDefaultRoles,
  deleteSingleDefaultRole,
  addNewDefaultRole,
} from "redux/reducers/usersModule/roles";
import { useEffect } from "react";

async function getRoles() {
  return axiosInstance
    .get("allroles", {
      params: {
        apiKey: localStorage.getItem("apikey"),
        added_by: localStorage.getItem("user_id"),
      },
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

function useGetRoles() {
  return useQuery(["roles"], getRoles);
}

export function useRoles() {
  const { data, isLoading, error } = useGetRoles();
  const {
    dispatch,
    roles: { roles, loaded },
  } = useUtilities();

  useEffect(() => {
    if (isLoading || loaded) return;
    if (data) {
      dispatch(getAllRoles(data.Data));
    }
  }, [data, isLoading, loaded]);
  return { data: roles, isLoading: isLoading || !loaded, error };
}

export async function addRole({ data, callback }) {
  return axiosInstance
    .post("addrole", {
      apiKey: localStorage.getItem("apikey"),
      added_by: localStorage.getItem("user_id"),
      ...data,
    })
    .then((res) => {
      callback && res.data.Data.id && callback(res.data.Data.id);
      return res.data;
    })
    .catch((err) => new Error(err));
}

export function useAddRole(redirect, updatedDefault = false) {
  const { web = null } = useSearch();
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(addRole, {
    onSuccess: (data) => {
      if (data?.StatusCode === 200) {
        if (updatedDefault) dispatch(addNewDefaultRole(data.Data));
        else dispatch(addNewRole(data.Data));
        notification({
          type: "success",
          message: "Role added successfully",
        });
        if (redirect)
          navigate(`${redirect}${data.Data.id}${web ? `?web=${web}` : ""}`);
      } else {
        notification({
          type: "error",
          message: data?.Message || data?.message || "Something went wrong",
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: err?.message || "Something went wrong",
      });
    },
  });
}

export async function updateRole(data) {
  return axiosInstance
    .post("updaterole", {
      apiKey: localStorage.getItem("apikey"),
      ...data,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUpdateRole() {
  const { dispatch, notification } = useUtilities();
  return useMutation(updateRole, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(updateSingleRole(data.Data));
        notification({
          type: "success",
          message: "Role updated successfully",
        });
      } else {
        notification({
          type: "error",
          message: data?.Message || "Something went wrong!",
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: err?.message || "Something went wrong!",
      });
    },
  });
}

async function deleteRole(id) {
  return axiosInstance
    .post("delrole", {
      apiKey: localStorage.getItem("apikey"),
      role_id: id,
    })
    .then((res) => ({ ...res.data, id }))
    .catch((err) => new Error(err));
}

export function useDeleteRole(redirect = "") {
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(deleteRole, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(deleteSingleRole(data.id));
        notification({
          type: "success",
          message: "Role deleted successfully",
        });
        if (redirect) navigate(redirect);
      } else {
        notification({
          type: "error",
          message: data?.Message || "Something went wrong!",
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: err?.message || "Something went wrong!",
      });
    },
  });
}

async function getDefaultRoles(website_id) {
  return axiosInstance
    .post("getdefaultrole", {
      apikey: localStorage.getItem("apikey"),
      website_id,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useGetDefaultRoles() {
  const { web = null } = useSearch();
  return useQuery(["defaultRoles", web], () => getDefaultRoles(web));
}

export function useDefaultRoles() {
  const { web = null } = useSearch();
  const { data, isLoading, error } = useGetDefaultRoles();
  const {
    dispatch,
    roles: {
      defaultTemplate: { roles, website_id },
    },
  } = useUtilities();

  useEffect(() => {
    if (isLoading || web === website_id) return;
    if (data) {
      dispatch(
        getAllDefaultRoles({
          data: data.Data,
          website_id: web,
        })
      );
    }
  }, [data, isLoading, website_id]);

  return {
    data: roles,
    isLoading: isLoading || website_id === undefined,
    error,
  };
}

async function addDefaultRole(data) {
  return axiosInstance
    .post("adddefaulttemplaterole", {
      apikey: localStorage.getItem("apikey"),
      ...data,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useAddDefaultRole(notify = true) {
  const { dispatch, notification } = useUtilities();
  return useMutation(addDefaultRole, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(addNewDefaultRole(data.Data));
        if (notify) {
          notification({
            type: "success",
            message: "Role added to default template successfully",
          });
        }
      } else {
        notification({
          type: "error",
          message: printError(data.Message || data.message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: printError(err?.message),
      });
    },
  });
}

async function deleteDefaultRole(data) {
  return axiosInstance
    .post("defaultroleremove", {
      apikey: localStorage.getItem("apikey"),
      ...data,
    })
    .then((res) => ({ ...res.data, id: data.id }))
    .catch((err) => new Error(err));
}

export function useDeleteDefaultRole() {
  const { dispatch, notification } = useUtilities();
  return useMutation(deleteDefaultRole, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(deleteSingleDefaultRole(data.id));
        notification({
          type: "success",
          message: "Role removed from default template successfully",
        });
      } else {
        notification({
          type: "error",
          message: printError(data.Message || data.message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: printError(err?.message),
      });
    },
  });
}

export async function checkRoleBeforeDelete(role_id) {
  return axiosInstance
    .post("checkbeforedelrole", {
      apikey: localStorage.getItem("apikey"),
      role_id,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}


export function useCheckRoleBeforeDelete() {
  return useMutation(checkRoleBeforeDelete);
}