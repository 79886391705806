import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  glossary: [],
  loaded: false,
  defaultSite: {
    glossary: [],
    loaded: false,
  },
};

const glossarySlice = createSlice({
  name: "glossary",
  initialState,
  reducers: {
    getAllTemplateGlossary: (state, action) => {
      state.glossary = action.payload;
      state.loaded = true;
    },
    addTemplateGlossary: (state, action) => {
      state.glossary.unshift(action.payload);
    },
    deleteTemplateGlossary: (state, action) => {
      state.glossary = state.glossary.filter(
        (_) => String(_.id) !== String(action.payload)
      );
    },
    updateTemplateGlossary: (state, action) => {
      const index = state.glossary.findIndex(
        (_) => String(_.id) === String(action.payload.glossary_id)
      );
      if (index !== -1) {
        for (let key in action.payload) {
          state.glossary[index][key] = action.payload[key];
        }
      }
    },
    getAllDefaultSiteGlossary: (state, action) => {
      state.defaultSite.glossary = action.payload;
      state.defaultSite.loaded = true;
    },
    addDefaultSiteGlossary: (state, action) => {
      const glossaryIndex = state.glossary.findIndex(
        (_) => String(_.id) === String(action.payload.id)
      );
      if (glossaryIndex === -1) return;
      state.glossary[glossaryIndex].default_tem = "1";
      state.defaultSite.glossary.unshift(state.glossary[glossaryIndex]);
    },
    deleteDefaultSiteGlossary: (state, action) => {
      const glossaryIndex = state.glossary.findIndex(
        (_) => String(_.id) === String(action.payload)
      );
      if (glossaryIndex === -1) return;
      state.glossary[glossaryIndex].default_tem = null;
      state.defaultSite.glossary = state.defaultSite.glossary.filter(
        (_) => String(_.id) !== String(action.payload)
      );
    },
  },
});

export const {
  getAllTemplateGlossary,
  addTemplateGlossary,
  deleteTemplateGlossary,
  updateTemplateGlossary,
  getAllDefaultSiteGlossary,
  addDefaultSiteGlossary,
  deleteDefaultSiteGlossary,
} = glossarySlice.actions;

export default glossarySlice.reducer;
