import {
  ListCard,
  RightMainContainer,
  ListIconWrapper,
  GridCard,
  LoadingListCards,
  LoadingGridCards,
  InputField,
  SecondaryHeading,
  MainButton,
  LimitReachedCard,
} from "commonUI";
import SiteCard from "./SiteCard";
import {
  GridIcon,
  ListIcon,
  SiteIcon,
  DefaultSiteTemplateIcon,
} from "allIcons";
import { useDeferredValue, useEffect, useState } from "react";
import { useSites } from "api/sites";
import ApiKeyCard from "./ApiKeyCard";
import { Link } from "react-router-dom";
import { useUserPermissions } from "hooks/user";
import { isEqual } from "helpers";
import useUserPlan from "hooks/plan";
import { planTypes } from "enums/plans";
import { libraryItemSorting } from "filterAndSort/templateLibrary";

export default function SiteTab() {
  const [viewMode, setViewMode] = useState("grid");
  const { data, isLoading, error } = useSites();
  const [sites, setSites] = useState(data?.site_details?.total_sites ?? []);
  const [searchText, setSearchText] = useState("");
  const defferedSearchText = useDeferredValue(searchText);
  const { view_site_template, view_sites } = useUserPermissions();
  const { data: wpslPlan = {}, isLoading: loadingPlans } = useUserPlan();
  const siteTemplateAllowed = isEqual(wpslPlan.site_template, 1);

  useEffect(() => {
    if (isLoading) return;
    setSites(data?.site_details?.total_sites ?? []);
  }, [data]);

  useEffect(() => {
    if (isLoading) return;
    if (defferedSearchText === "") {
      setSites(data?.site_details?.total_sites ?? []);
      return;
    }
    const tempSiteState = data?.site_details?.total_sites.filter((site) => {
      return site.web_name
        .toLowerCase()
        .includes(defferedSearchText.toLowerCase());
    });
    setSites(tempSiteState);
  }, [defferedSearchText]);

  const applySorting = (order, orderBy) => {
    setSites((prevSites) =>
      libraryItemSorting(order, orderBy, prevSites, "web_name")
    );
  };
  return (
    <>
      <RightMainContainer className="bg-transparent">
        <div className="flex flex-row justify-between gap-15">
          <SiteCard
            isLoading={isLoading}
            sites={{
              description: `${data?.site_details?.total_used_site} of ${
                isEqual(data?.site_details?.sites_limit, "1000000000")
                  ? "Unlimited sites"
                  : data?.site_details?.sites_limit
              } ${
                isEqual(data?.site_details?.sites_limit, "1000000000")
                  ? ""
                  : `(${Number(data?.site_details?.site_percentage).toFixed(
                      2
                    )}% Used)`
              }`,
              line: data?.site_details?.site_percentage,
            }}
            storage={{
              description: `${data?.storage_details?.total_used_storage}GB of ${
                data?.storage_details?.storage_limit
              }GB (${Number(data?.storage_details?.storage_percentage).toFixed(
                2
              )}% Used)`,
              line: data?.storage_details?.storage_percentage,
            }}
          />
          <ApiKeyCard
            isLoading={isLoading}
            apikey={localStorage.getItem("apikey") ?? ""}
          />
        </div>
        <div className="flex flex-col p-5 rounded-5 bg-white mt-3">
          <SecondaryHeading>
            Configure Your Default New Site Template
          </SecondaryHeading>
          <p className="body-text text-theme-placeholder mt-1">
            Manage the users, settings, user preferences, and saved templates
            that will be configured by default for all new sites.
          </p>
          {view_site_template && siteTemplateAllowed && !loadingPlans ? (
            <Link to="/site-settings">
              <MainButton className={"w-fit"}>
                <DefaultSiteTemplateIcon /> Manage Site Template
              </MainButton>
            </Link>
          ) : (
            <LimitReachedCard
              show
              title="Site Template Not Included"
              description="The Site Template feature is not available in your current plan. To access this feature, please consider upgrading your account."
            />
          )}
        </div>
        {view_sites ? (
          <div className="bg-white mt-3 rounded-5">
            <div className="px-5 mt-5">
              <InputField
                placeholder="Search"
                value={searchText}
                setValue={(value) => setSearchText(value)}
                searchIcon={true}
                sort
                sortOptions={[
                  {
                    label: "Date Added",
                    value: "date-added",
                  },
                  {
                    label: "Storage Size",
                    value: "single_web_storage",
                  },
                  {
                    label: "Alphabetical By Title",
                    value: "alphabetical-by-title",
                  },
                ]}
                applySorting={applySorting}
              />
            </div>
            <div className="flex justify-end items-center pt-1 px-5">
              <div className="flex flex-row gap-10 items-center justify-center">
                <p className="body-text">View Mode</p>
                <GridIcon
                  fill={viewMode === "grid" ? "#4C5866" : "#A3B0C2"}
                  onClick={() => setViewMode("grid")}
                />
                <ListIcon
                  fill={viewMode === "list" ? "#4C5866" : "#A3B0C2"}
                  onClick={() => setViewMode("list")}
                />
              </div>
            </div>
            <div
              className={`flex ${
                viewMode === "list" ? "flex-col" : "flex-row flex-wrap gap-15"
              } px-4 pt-3 pb-4 bg-white `}
            >
              {sites.length > 0 ? (
                sites.map((iner) => {
                  if (viewMode === "list") {
                    return (
                      <ListCard
                        showTitle
                        data={iner.web_name}
                        key={iner.id}
                        index={iner.id}
                        link={`/site-settings?web=${iner.dl_website_id}`}
                        strictLink={true}
                        deleteIcon={true}
                        className={"mb-1 mt-1"}
                        icon={
                          <ListIconWrapper>
                            <SiteIcon />
                          </ListIconWrapper>
                        }
                        deleteMessage="You are about to delete the site from your account. This
                    action cannot be undone! All site data related to WP Site
                    Launcher will be lost. We highly recommend exporting site
                    data first before proceeding. Are you ready to proceed and
                    delete the site and all WP SiteLauncher site data?"
                        deleteConfirmations={"DELETE SITE"}
                      />
                    );
                  } else {
                    return (
                      <GridCard
                        data={iner.web_name}
                        key={iner.id}
                        index={iner.id}
                        link={`/site-settings?web=${iner.dl_website_id}`}
                        strictLink={true}
                        icon={
                          <ListIconWrapper>
                            <SiteIcon />
                          </ListIconWrapper>
                        }
                        image={require("assets/SiteImage.png")}
                        deleteMessage="You are about to delete the site from your account. This
                    action cannot be undone! All site data related to WP Site
                    Launcher will be lost. We highly recommend exporting site
                    data first before proceeding. Are you ready to proceed and
                    delete the site and all WP SiteLauncher site data?"
                        deleteConfirmations={"DELETE SITE"}
                      />
                    );
                  }
                })
              ) : isLoading ? (
                viewMode === "list" ? (
                  <LoadingListCards />
                ) : (
                  <LoadingGridCards />
                )
              ) : (
                <p className="body-text">No Sites Found</p>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </RightMainContainer>
    </>
  );
}
