import { Children, useState, useEffect, useDeferredValue } from "react";
import { useMutation } from "@tanstack/react-query";
import { useSelector, useDispatch } from "react-redux";
import {
  addSingleTask,
  deleteSingleTask,
  getTemplateLibraryTasks,
  getTemplateTasks,
  addTemplateLibraryTask,
  updateSingleTask,
  removeTemplateLibraryTask,
} from "redux/reducers/templateLibrary/tasks";
import {
  addTask,
  deleteTask,
  useGetWpslTasks,
  addTemplateTask,
  updateTask,
  removeDefaultTask,
  useGetTasks,
} from "api/templateLibrary/tasks";
import { useNotification } from "notification";
import { useTags } from "api/siteSettings/Tags";
import "../library.css";
import { AddIcon, TaskIcon } from "allIcons";
import {
  PopOver,
  PreviewCard,
  Loader,
  LoadingListCards,
  BodyContainer,
  HeaderContainer,
  InputField,
  ListCard,
  ListIconWrapper,
  MainButton,
  RightMainContainer,
  PrimaryHeading,
  TemplateNotFound,
  AddFromTemplateLibrary,
  FiltersList,
} from "commonUI";
import { isEqual, parse, search } from "helpers";
import {
  libraryItemFiltering,
  libraryItemSorting,
} from "filterAndSort/templateLibrary";
import { useUserPermissions } from "hooks/user";

export default function Task() {
  const { data, isLoading, error } = useGetWpslTasks();
  const {
    data: wpslData,
    isLoading: wpslLoading,
    error: wpslError,
  } = useGetTasks();
  const [filterData, setFilterData] = useState({});
  const notification = useNotification();
  const dispatch = useDispatch();
  const { tasks, loaded, templateLibrary } = useSelector(
    (state) => state.siteTasks
  );
  const { data: tags, isLoading: tagsLoading, error: tagsError } = useTags();
  const [searchText, setSearchText] = useState("");
  const defferedSearch = useDeferredValue(searchText);
  const [searchResult, setSearchResult] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const {
    remove_items_from_site_template,
  } = useUserPermissions();

  useEffect(() => {
    if (data && !templateLibrary.loaded)
      dispatch(getTemplateLibraryTasks(data.Data));
  }, [isLoading]);
  useEffect(() => {
    if (wpslData && !loaded) dispatch(getTemplateTasks(wpslData.results));
  }, [wpslLoading]);
  useEffect(() => {
    setSearchResult(
      templateLibrary?.tasks?.filter((task) =>
        search(task.title, defferedSearch)
      ) || []
    );
  }, [defferedSearch, templateLibrary.tasks]);

  const addMutation = useMutation(addTask, {
    onSuccess: (data) => {
      notification({
        type: "success",
        message: "Task Dupilcated Successfully",
      });
      dispatch(addSingleTask(data.Data));
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const deleteMutation = useMutation(deleteTask, {
    onSuccess: (data) => {
      notification({
        type: "success",
        message: "Task Deleted Successfully",
      });
      dispatch(deleteSingleTask(data.id));
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const removeTaskMutation = useMutation(removeDefaultTask, {
    onSuccess: (data) => {
      notification({
        type: "success",
        message: "Task removed Successfully",
      });
      dispatch(removeTemplateLibraryTask(data.id));
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const updateMutation = useMutation(updateTask, {
    onSuccess: (data) => {
      notification({
        type: "success",
        message: "Task Updated Successfully",
      });
      dispatch(updateSingleTask(data.Data));
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const applySorting = (order, orderBy) => {
    setSearchResult((prevSearchResult) =>
      libraryItemSorting(order, orderBy, prevSearchResult)
    );
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setSearchResult(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        templateLibrary.tasks
      )
    );
  };

  const addTaskMutation = useMutation(addTemplateTask, {
    onSuccess: (data) => {
      notification({
        type: "SUCCESS",
        message: "Task Added Successfully",
      });
      dispatch(
        addTemplateLibraryTask(
          tasks.filter((item) => item.id == data.Data.id)[0]
        )
      );
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <>
      <RightMainContainer>
        {(addMutation.isLoading ||
          deleteMutation.isLoading ||
          updateMutation.isLoading ||
          addTaskMutation.isLoading) && <Loader loader="block" />}
        <HeaderContainer>
          <PrimaryHeading>Tasks</PrimaryHeading>
          <div className="relative">
            <MainButton onClick={() => setOpenDropdown(!openDropdown)}>
              <AddIcon />
              Add
            </MainButton>
            <PopOver open={openDropdown} onClose={() => setOpenDropdown(false)}>
              <AddFromTemplateLibrary
                data={tasks
                  .filter((item) => !isEqual(item.default_tem, 1))
                  .map((item) => ({
                    ...item,
                    onClick: () =>
                      addTaskMutation.mutate({
                        id: item.id,
                        requested_by: parse(item.requested_from),
                      }),
                    icon: <TaskIcon fill="white" />,
                    secondaryData: item.system_status == 1 ? "System" : "",
                  }))}
              />
            </PopOver>
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            placeholder="Search"
            searchIcon={true}
            filter={true}
            value={searchText}
            setValue={(val) => setSearchText(val)}
            tags={tags ?? []}
            onApplyFilter={applyFilter}
            applySorting={applySorting}
            filterData={filterData}
            showLibrarySource={false}
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          {isLoading ? (
            <LoadingListCards />
          ) : templateLibrary.tasks.length === 0 ||
            searchResult.length === 0 ? (
            <TemplateNotFound
              label="Tasks"
              linkLabel="Task"
              onClick={() => setOpenDropdown(!openDropdown)}
            />
          ) : (
            Children.toArray(
              searchResult.map((task, index) => {
                return (
                  <ListCard
                    data={task.title}
                    edit={true}
                    editIcon={false}
                    secondaryData={task.system_status == 1 ? "System" : ""}
                    deleteIcon={true}
                    showModalonCross
                    onCross={() => {
                      removeTaskMutation.mutate(task.id);
                    }}
                    crossIcon={remove_items_from_site_template}
                    icon={
                      <ListIconWrapper>
                        <TaskIcon />
                      </ListIconWrapper>
                    }
                    deleteTitle="Remove This Template?"
                    deleteButtonText="Confirm"
                    deleteMessage="You are about to remove this template from your default site template. Are you sure you want to do this?"
                  />
                );
              })
            )
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
