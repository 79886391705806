import {
  BodyContainer,
  HeaderContainer,
  PrimaryHeading,
  RightMainContainer,
  InputField,
  IconWrapper,
  MainButton,
  TextEditor,
  Loader,
  DeleteButton,
} from "commonUI";
import { useEffect, useState } from "react";
import { BackArrow, SaveIcon } from "allIcons";
import { isEqual, parse, useUtilities } from "helpers";
import {
  useAddNewPhase,
  usePhases,
  useUpdatePhase,
  useDeletePhase,
} from "api/templateLibrary/phases";
import { useParams } from "react-router-dom";
import { useUserPermissions } from "hooks/user";

export default function AddEditPhase() {
  const { id } = useParams();
  const { navigate, notification } = useUtilities();
  const [values, setValues] = useState({
    title: "",
    description: "",
    status: { text: "In Progress", color: "#FFD000" },
  });
  const { data: phases, isLoading: loadingPhases } = usePhases();
  const { mutate: addPhase, isLoading: isAdding } = useAddNewPhase(
    "/template-library/phases/"
  );
  const { mutate: updatePhase, isLoading: isUpdating } = useUpdatePhase();
  const { mutate: deletePhase, isLoading: isDeleting } = useDeletePhase(
    "/template-library/phases/"
  );
  const { add_new_templates, edit_templates, delete_templates } =
    useUserPermissions();

  useEffect(() => {
    if (
      (!add_new_templates && id === "add") ||
      (!edit_templates && id !== "add")
    ) {
      return navigate("/template-library/phases");
    }
    if (isEqual(id, "add") || loadingPhases) return;

    const phase = phases.filter((ele) => isEqual(ele.id, id))[0];
    if (!phase) {
      navigate("/template-library/phases");
      return;
    }
    setValues({
      title: phase?.title ?? "",
      description: phase?.description ?? "",
    });
  }, [loadingPhases, phases]);

  const handleSaveChanges = () => {
    if (!values.title) {
      notification({
        type: "ERROR",
        message: "Please enter title",
      });
      return;
    }
    if (isEqual(id, "add")) {
      addPhase(values);
      return;
    }
    const phase = phases.filter((ele) => isEqual(ele.id, id))[0];
    updatePhase({ ...phase, status: parse(phase.status), ...values });
  };

  return (
    <>
      <RightMainContainer>
        <Loader loader="block" show={isAdding || isUpdating || isDeleting} />
        <HeaderContainer>
          <IconWrapper>
            <BackArrow onClick={() => navigate("/template-library/phases")} />
            <PrimaryHeading>
              {isEqual(id, "add") ? "Add Phase" : values.title}
            </PrimaryHeading>
          </IconWrapper>
          <div className="flex items-center justify-center gap-14">
            <DeleteButton
              show={id !== "add" && delete_templates}
              onDelete={() => deletePhase(id)}
              deleteTitle="Delete This Template?"
              deleteButtonText="Confirm Delete"
              deleteMessage="You are about to delete this template from your account. This action cannot be undone! All template content will be permanently removed. Are you sure you want to do this?"
            />
            <MainButton onClick={handleSaveChanges}>
              <SaveIcon />
              Save Changes
            </MainButton>
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            label="Title"
            placeholder="Write Title Here"
            value={values.title}
            setValue={(val) => setValues({ ...values, title: val })}
          />
          <TextEditor
            option={false}
            rows={5}
            label="Description"
            placeholder="Write Description Here"
            value={values.description}
            setValue={(val) => setValues({ ...values, description: val })}
          />
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
