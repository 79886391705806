import { MainButton, SecondaryHeading } from "commonUI";
import { AddIcon } from "allIcons";

function AddComponent({ onClick, className, value, title, children }) {
  function handleClick(event) {
    onClick && onClick(event);
  }
  return (
    <>
      <div className={`w-full mt-2 mb-2 ${className ?? ""}`}>
        <div className="relative w-max mx-auto flex items-center justify-center">
          <div
            onClick={handleClick}
            className="flex flex-col items-center justify-center gap-5 cursor-pointer "
          >
            {value ? (
              <div className="absolute top-0 left-0 w-full h-full z-1 bg-transparent" />
            ) : (
              ""
            )}
            <MainButton className="p-[6px]">
              <AddIcon />
            </MainButton>
            <SecondaryHeading className="text-13 text-theme-placeholder">
              {title ?? "Add Tag"}
            </SecondaryHeading>
          </div>
          {children}
        </div>
      </div>
    </>
  );
}

export default AddComponent;
