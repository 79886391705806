import { BillingIcon, ErrorIcon } from "allIcons";
import "./index.css";
import PrimaryHeading from "commonUI/PrimaryHeading";
import MainButton from "commonUI/MainButton";

export default function LimitReachedCard({ title, description, show = false }) {
  if (!show) return <></>;
  return (
    <>
      <div className="limit-reached">
        <div className="limit-reached-icon">
          <ErrorIcon />
        </div>
        <div className="text-center">
          <PrimaryHeading className="text-14">{title}</PrimaryHeading>
          <p className="body-text m-0 text-11 text-center">{description}</p>
        </div>
        <a
          href="https://wpsitelauncher.com/customer-dashboard/?action=index&model=subscription"
          target="_blank"
          rel="noreferrer"
        >
          <MainButton className="limit-reached-manage-plan-button">
            <BillingIcon fill="white" />
            Manage Plan
          </MainButton>
        </a>
      </div>
    </>
  );
}
