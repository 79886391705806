export default function SortIcon(props) {
  return (
    <>
      <svg
        width="16"
        height="13"
        viewBox="0 0 16 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`cursor-pointer ${props.className ?? ""}`}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7013 12.3637L15.3589 9.73255H15.3426C15.4954 9.58026 15.5809 9.37399 15.5804 9.15912C15.5799 8.94425 15.4934 8.73839 15.3399 8.58681C15.1864 8.43523 14.9785 8.35036 14.7619 8.35087C14.5453 8.35137 14.3378 8.43721 14.185 8.58951L12.9404 9.84038V1.00877C12.9404 0.794273 12.8545 0.588562 12.7017 0.436891C12.5488 0.28522 12.3414 0.200012 12.1252 0.200012C11.909 0.200012 11.7016 0.28522 11.5488 0.436891C11.3959 0.588562 11.31 0.794273 11.31 1.00877V9.84038L10.06 8.58951C9.98434 8.5141 9.89445 8.45421 9.79547 8.41327C9.69649 8.37232 9.59035 8.35112 9.48311 8.35087C9.26652 8.35036 9.05861 8.43523 8.9051 8.58681C8.75159 8.73839 8.66506 8.94425 8.66455 9.15912C8.6643 9.26551 8.68517 9.37091 8.72598 9.4693C8.76679 9.56769 8.82673 9.65714 8.90238 9.73255L11.5546 12.3637C11.6292 12.4387 11.718 12.4982 11.816 12.5389C11.9139 12.5796 12.019 12.6007 12.1252 12.6009C12.1971 12.6012 12.2686 12.5903 12.3372 12.5686C12.373 12.5627 12.4077 12.5518 12.4404 12.5362C12.5399 12.5001 12.6294 12.4409 12.7013 12.3637ZM6.71245 4.58344C6.93012 4.58292 7.1389 4.49774 7.29397 4.3462C7.37219 4.27281 7.43497 4.18478 7.47871 4.08719C7.52245 3.9896 7.54627 3.88438 7.54879 3.7776C7.55132 3.67083 7.5325 3.56462 7.49343 3.4651C7.45436 3.36558 7.3958 3.27472 7.32115 3.19777L4.66354 0.57201C4.59058 0.495982 4.50147 0.437041 4.40267 0.399475C4.19319 0.3172 3.95998 0.3172 3.75049 0.399475C3.65169 0.437041 3.56258 0.495982 3.48962 0.57201L0.832015 3.19777C0.682865 3.35232 0.599609 3.55802 0.599609 3.77199C0.599609 3.98595 0.682865 4.19165 0.832015 4.3462C0.985563 4.49635 1.1925 4.58052 1.4081 4.58052C1.6237 4.58052 1.83064 4.49635 1.98419 4.3462L3.25049 3.09533V11.9269C3.25049 12.1414 3.33638 12.3471 3.48926 12.4988C3.64215 12.6505 3.8495 12.7357 4.06571 12.7357C4.28192 12.7357 4.48927 12.6505 4.64216 12.4988C4.79504 12.3471 4.88093 12.1414 4.88093 11.9269V3.09533L6.1418 4.3462C6.29286 4.4971 6.49808 4.58241 6.71245 4.58344Z"
          fill={props.fill ?? "white"}
        />
      </svg>
    </>
  );
}
