import { Children, useDeferredValue, useEffect, useState } from "react";
import { AddIcon, CheckListIcon } from "allIcons";
import {
  BodyContainer,
  FiltersList,
  HeaderContainer,
  InputField,
  ListCard,
  ListIconWrapper,
  Loader,
  LoadingListCards,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
  TemplateNotFound,
} from "commonUI";
import {
  useCheckListTemplates,
  useAddChecklistTemplate,
  useDeleteChecklistTemplate,
} from "api/templateLibrary/checklists";
import { Link } from "react-router-dom";
import { isEqual, omit, parse, search } from "helpers";
import { useUserPermissions } from "hooks/user";
import { libraryItemFiltering, libraryItemSorting } from "filterAndSort/templateLibrary";
import { useTags } from "api/siteSettings/Tags";

export default function Checklist() {
  const { data: checklists, isLoading } = useCheckListTemplates();
  const { data: tags, isLoading: tagsLoading, error: tagsError } = useTags();
  const { mutateAsync: addChecklist, isLoading: addingChecklist } =
    useAddChecklistTemplate();
  const { mutateAsync: deleteChecklist, isLoading: deletingChecklist } =
    useDeleteChecklistTemplate();
  const { add_new_templates, edit_templates, delete_templates } =
    useUserPermissions();
  const [searchText, setSearchText] = useState("");
  const defferedSearch = useDeferredValue(searchText);
  const [searchResult, setSearchResult] = useState([]);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    setSearchResult(checklists.filter((task) => search(task.title, defferedSearch)));
  }, [defferedSearch, checklists]);

  const applySorting = (order, orderBy) => {
    setSearchResult((prevSearchResult) =>
      libraryItemSorting(order, orderBy, prevSearchResult)
    );
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setSearchResult(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        checklists
      )
    );
  };

  return (
    <>
      <RightMainContainer>
        <Loader show={addingChecklist || deletingChecklist} />
        <HeaderContainer>
          <PrimaryHeading>Checklist</PrimaryHeading>
          {add_new_templates ? (
            <Link to="/template-library/checklists/add">
              <MainButton>
                <AddIcon />
                Add
              </MainButton>
            </Link>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          <InputField
            placeholder="Search"
            searchIcon={true}
            filter={true}
            value={searchText}
            setValue={(val) => setSearchText(val)}
            tags={tags ?? []}
            onApplyFilter={applyFilter}
            applySorting={applySorting}
            filterData={filterData}
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          {isLoading ? (
            <LoadingListCards />
          ) : searchResult.length === 0 ? (
            <TemplateNotFound
              label="Checklist"
              link={add_new_templates ? "/template-library/checklists/add" : ""}
              linkLabel="Checklist"
            />
          ) : (
            Children.toArray(
              searchResult.map((checklist) => {
                return (
                  <ListCard
                    data={checklist.title}
                    duplicate={add_new_templates}
                    editIcon={edit_templates}
                    deleteIcon={delete_templates ? isEqual(checklist.system_status, 1) : true}
                    secondaryData={
                      isEqual(checklist.system_status, 1) ? "System" : ""
                    }
                    link={
                      edit_templates
                        ? `/template-library/checklists/${checklist.id}`
                        : ""
                    }
                    strictLink={true}
                    onDuplicate={() => {
                      addChecklist(
                        omit(["id"], {
                          ...checklist,
                          title: `Copy Of ${checklist.title}`,
                          tasks: parse(checklist.tasks),
                          tags: parse(checklist.tags),
                          priority: parse(checklist.priority),
                        })
                      );
                    }}
                    onDelete={() => {
                      if (!delete_templates) return;
                      if (isEqual(checklist.system_status, 1)) return;
                      deleteChecklist(checklist.id);
                    }}
                    icon={
                      <ListIconWrapper>
                        <CheckListIcon fill="white" />
                      </ListIconWrapper>
                    }
                    deleteTitle="Delete This Template?"
                    deleteButtonText="Confirm Delete"
                    deleteMessage="You are about to delete this template from your account. This action cannot be undone! All template content will be permanently removed. Are you sure you want to do this?"
                  />
                );
              })
            )
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
