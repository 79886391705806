import { useQuery, useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { axiosInstance, useUtilities } from "helpers";
import {
  addTemplatePluginList,
  getAllTemplatePluginList,
  deleteTemplatePluginList,
  updateTemplatePluginList,
} from "redux/reducers/templateLibrary/pluginList";

export async function getPluginList() {
  return axiosInstance
    .get("allPluginslist", {
      params: {
        apiKey: localStorage.getItem("apikey"),
      },
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useGetPluginList() {
  return useQuery(["allPluginslist"], getPluginList);
}

export function usePluginList() {
  const { data: pluginListData, isLoading, error } = useGetPluginList();
  const {
    pluginList: { loaded, pluginList },
  } = useUtilities();
  const { dispatch } = useUtilities();
  useEffect(() => {
    if (isLoading || error || loaded || !pluginListData) return;
    dispatch(getAllTemplatePluginList(pluginListData.Data));
  }, [pluginListData, isLoading, error, loaded]);

  return { data: pluginList, isLoading, error };
}

export async function addPluginList({ list_name, plugin_list, tags }) {
  return axiosInstance
    .post("addpluginlist", {
      apiKey: localStorage.getItem("apikey"),
      list_name,
      plugin_list,
      tags,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useAddPluginList(redirect = true) {
  const { notification, dispatch, navigate } = useUtilities();
  return useMutation(addPluginList, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(addTemplatePluginList(data.Data));
        notification({
          type: "success",
          message: "Plugin List Added Successfully",
        });
        if (redirect) {
          navigate(`/template-library/plugin-lists/${data.Data.id}`);
        }
      } else {
        notification({
          type: "error",
          message: "Sorry can't add plugin list, please try again later",
        });
      }
    },
    onError: (error) => {
      notification({
        type: "error",
        message: "Sorry can't add plugin list, please try again later",
      });
    },
  });
}

export async function deletePluginList(id) {
  return axiosInstance
    .post("delpluginlist", {
      apiKey: localStorage.getItem("apikey"),
      pluginlist_id: id,
    })
    .then((res) => ({ ...res.data, id }))
    .catch((err) => new Error(err));
}

export function useDeletePluginList(redirect = true) {
  const { notification, dispatch, navigate } = useUtilities();
  return useMutation(deletePluginList, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        if (redirect) {
          navigate("/template-library/plugin-lists");
        }
        notification({
          type: "success",
          message: "Plugin List Deleted Successfully",
        });
        dispatch(deleteTemplatePluginList(data.id));
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (error) => {
      notification({
        type: "error",
        message: "Sorry can't delete plugin list, please try again later",
      });
    },
  });
}

export async function updatePluginList({
  pluginlist_id,
  list_name,
  plugin_list,
  tags,
}) {
  return axiosInstance
    .post("updatepluginlist", {
      apiKey: localStorage.getItem("apikey"),
      pluginlist_id,
      list_name,
      plugin_list,
      tags,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUpdatePluginList() {
  const { notification, dispatch } = useUtilities();

  return useMutation(updatePluginList, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        notification({
          type: "success",
          message: "Plugin List Updated Successfully",
        });
        dispatch(updateTemplatePluginList(data.Data));
      } else {
        notification({
          type: "error",
          message: "Sorry can't update plugin list, please try again later",
        });
      }
    },
    onError: (error) => {
      notification({
        type: "error",
        message: "Sorry can't update Plugin List, please try again later",
      });
    },
  });
}
