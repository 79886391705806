import { useEffect, useRef } from "react";

export default function OutsideClickHandler({
  children,
  onClickOutside,
  ...props
}) {
  const wrapperRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClickOutside(event);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);
  return (
    <div ref={wrapperRef} {...props} onClick={(e) => e.stopPropagation()}>
      {children}
    </div>
  );
}
