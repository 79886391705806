export default function SuccessIcon(props) {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.34798 13.6574C3.84891 15.1574 5.88351 16 8.00486 16C10.1245 15.9974 12.1566 15.1538 13.6554 13.654C15.1543 12.1543 15.9975 10.121 16 7.99998C15.9975 5.87903 15.1543 3.84563 13.6554 2.3459C12.1566 0.84616 10.1245 0.00258147 8.00486 0C5.88351 0 3.84891 0.84255 2.34798 2.34256C0.847047 3.84258 0.0025838 5.87735 0 7.99998C0.0025838 10.1226 0.847047 12.1574 2.34798 13.6574ZM3.31949 3.31175C4.56231 2.06816 6.24723 1.36835 8.00486 1.36576C9.76154 1.36835 11.4455 2.06859 12.6868 3.31246C13.9281 4.55633 14.6252 6.24218 14.6252 7.99998C14.6252 9.75774 13.9281 11.4436 12.6868 12.6875C11.4455 13.9313 9.76154 14.6316 8.00486 14.6341C6.24723 14.6316 4.56231 13.9318 3.31949 12.6882C2.07666 11.4446 1.37733 9.75871 1.37475 7.99998C1.37733 6.24128 2.07666 4.55533 3.31949 3.31175ZM6.74913 10.6286C6.83794 10.6654 6.93332 10.6839 7.02948 10.6829C7.12556 10.6834 7.2208 10.6645 7.30954 10.6277C7.39828 10.5908 7.47876 10.5367 7.54627 10.4683L11.4464 6.56585C11.5833 6.42865 11.6602 6.24265 11.6602 6.04875C11.6602 5.85485 11.5833 5.66885 11.4464 5.53165C11.3092 5.39462 11.1234 5.31775 10.9295 5.31775C10.7358 5.31775 10.5499 5.39462 10.4129 5.53165L7.02948 8.91699L5.59623 7.48287C5.45912 7.34585 5.27323 7.26898 5.07944 7.26898C4.88566 7.26898 4.69983 7.34585 4.56272 7.48287C4.49373 7.55014 4.43888 7.63059 4.40144 7.71939C4.364 7.8082 4.34473 7.9036 4.34473 7.99998C4.34473 8.09636 4.364 8.19175 4.40144 8.28056C4.43888 8.36936 4.49373 8.44981 4.56272 8.51708L6.51276 10.4683C6.57993 10.5372 6.66032 10.5917 6.74913 10.6286Z"
          fill={props.fill ?? "white"}
        />
      </svg>
    </>
  );
}
