import { LoadingCard } from "./LoadingListCards";
import SecondaryHeading from "./SecondaryHeading";
import "./util.css";

export default function ToggleCard({
  value,
  setValue,
  label = "",
  description = "",
  isLoading = false,
}) {
  return (
    <>
      {label && <SecondaryHeading>{label}</SecondaryHeading>}
      {description ? (
        <p className="body-text text-theme-placeholder text-11 m-0">
          {description}
        </p>
      ) : (
        ""
      )}
        {isLoading ? (
          <LoadingCard className="h-4-5 toggle-card rounded-full" />
        ) : (
      <div
        className={`toggle-card ${!value ? "bg-disabled" : "bg-active"}`}
        onClick={() => setValue(!value)}
      >
          <div className="flex flex-row items-center justify-between w-full">
            <h3 className={`yes-text ${!value ? "opacity-0" : "opacity-100"}`}>
              Yes
            </h3>
            <div
              className={`white-move-button 
          ${!value ? "translate-x-0" : "translate-x-full"}
          `}
            ></div>
            <h3 className={`no-text ${value ? "opacity-0" : "opacity-100"}`}>
              No
            </h3>
          </div>
      </div>
        )}
    </>
  );
}
