import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "helpers";

const initialState = {
  tutorials: [],
  loaded: false,
  defaultSite: {
    tutorials: [],
    loaded: false,
  },
};

const tutorialsSlice = createSlice({
  name: "tutorials",
  initialState,
  reducers: {
    getTemplateTutorialsAction: (state, action) => {
      state.tutorials = action.payload;
      state.loaded = true;
    },
    addTemplateTutorialAction: (state, action) => {
      state.tutorials.unshift(action.payload);
    },
    deleteTemplateTutorialAction: (state, action) => {
      state.tutorials = state.tutorials.filter(
        (_) => !isEqual(_.id, action.payload)
      );
      state.defaultSite.tutorials = state.defaultSite.tutorials.filter(
        (_) => !isEqual(_.id, action.payload)
      );
    },
    updateTemplateTutorialAction: (state, action) => {
      const index = state.tutorials.findIndex((_) =>
        isEqual(_.id, action.payload.id)
      );
      if (!isEqual(index, -1)) {
        for (const key in action.payload) {
          state.tutorials[index][key] = action.payload[key];
        }
      }
      const defaultStateIndex = state.defaultSite.tutorials.findIndex((_) =>
        isEqual(_.id, action.payload.id)
      );
      if (!isEqual(defaultStateIndex, -1)) {
        for (const key in action.payload) {
          state.defaultSite.tutorials[defaultStateIndex][key] =
            action.payload[key];
        }
      }
    },
    getDefaultTutorialsAction: (state, action) => {
      state.defaultSite.tutorials = action.payload;
      state.defaultSite.loaded = true;
    },
    addDefaultTutorialAction: (state, action) => {
      const index = state.tutorials.findIndex((_) =>
        isEqual(_.id, action.payload.id)
      );
      if (!isEqual(index, -1)) {
        state.tutorials[index].default_tem = "1";
        state.defaultSite.tutorials.unshift(state.tutorials[index]);
      }
    },
    deleteDefaultTutorialAction: (state, action) => {
      const index = state.tutorials.findIndex((_) =>
        isEqual(_.id, action.payload)
      );
      if (!isEqual(index, -1)) {
        state.tutorials[index].default_tem = "0";
        state.defaultSite.tutorials = state.defaultSite.tutorials.filter(
          (_) => !isEqual(_.id, action.payload)
        );
      }
    },
  },
});

export const {
  getTemplateTutorialsAction,
  addTemplateTutorialAction,
  updateTemplateTutorialAction,
  deleteTemplateTutorialAction,
  getDefaultTutorialsAction,
  addDefaultTutorialAction,
  deleteDefaultTutorialAction,
} = tutorialsSlice.actions;

export default tutorialsSlice.reducer;
