export default function Logo(props) {
  function HandleClick() {
    props.onClick && props.onClick();
  }
  return (
    <>
      <svg
        id="Layer_2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 514.81 75.94"
        className={`cursor-pointer ${props.className ?? ""}`}
        onClick={HandleClick}
      >
        <g id="Layer_1-2">
          <g>
            <path
              className="cls-2"
              d="M67.94,20c-6.62,0-12-5.38-12-12v-.06H8C3.58,7.94,0,11.52,0,15.94V51.94c0,4.42,3.58,8,8,8h2.34L42.34,27.94h-6.34c-2.21,0-4-1.79-4-4s1.79-4,4-4h16c2.21,0,4,1.79,4,4v16c0,2.21-1.79,4-4,4s-4-1.79-4-4v-6.34L15.94,65.66v6.28c0,1.1,.45,2.1,1.17,2.83,.73,.72,1.73,1.17,2.83,1.17,1.63,0,3.2-.65,4.35-1.8l14.2-14.2h21.51c4.42,0,8-3.58,8-8V20h-.06Z"
            />
            <circle className="cls-1" cx="67.94" cy="8" r="8" />
          </g>
          <g className={`${props.textColor ?? ''}`}>
            <path d="M145.03,23.52l-8.3,33.84h-11.57l-4.66-20.74-4.75,20.74h-11.57l-8.21-33.84h10.18l3.94,23.14,5.33-23.14h10.32l5.18,22.94,3.94-22.94h10.18Z" />
            <path d="M174.33,40.39c-.94,1.68-2.37,3.02-4.27,4.03-1.9,1.01-4.23,1.51-6.98,1.51h-4.66v11.42h-9.41V23.52h14.06c2.72,0,5.03,.48,6.94,1.44s3.34,2.29,4.3,3.98c.96,1.7,1.44,3.65,1.44,5.86,0,2.05-.47,3.91-1.42,5.59Zm-8.18-5.59c0-2.46-1.33-3.7-3.98-3.7h-3.74v7.34h3.74c2.66,0,3.98-1.22,3.98-3.65Z" />
            <path d="M191.78,54.89c-2.53-1.87-3.89-4.55-4.08-8.04h10.03c.1,1.18,.45,2.05,1.06,2.59,.61,.54,1.39,.82,2.35,.82,.86,0,1.58-.22,2.14-.65,.56-.43,.84-1.03,.84-1.8,0-.99-.46-1.76-1.39-2.3-.93-.54-2.43-1.15-4.51-1.82-2.21-.74-3.99-1.45-5.35-2.14-1.36-.69-2.54-1.7-3.55-3.02-1.01-1.33-1.51-3.06-1.51-5.21s.54-4.04,1.63-5.59c1.09-1.55,2.59-2.73,4.51-3.53,1.92-.8,4.1-1.2,6.53-1.2,3.94,0,7.08,.92,9.43,2.76,2.35,1.84,3.61,4.42,3.77,7.75h-10.22c-.03-1.02-.34-1.79-.94-2.3-.59-.51-1.35-.77-2.28-.77-.7,0-1.28,.21-1.73,.62-.45,.42-.67,1.01-.67,1.78,0,.64,.25,1.19,.74,1.66,.5,.46,1.11,.86,1.85,1.2,.74,.34,1.82,.76,3.26,1.27,2.14,.74,3.91,1.46,5.3,2.18,1.39,.72,2.59,1.73,3.6,3.02,1.01,1.3,1.51,2.94,1.51,4.92s-.5,3.82-1.51,5.42c-1.01,1.6-2.46,2.86-4.37,3.79-1.9,.93-4.15,1.39-6.74,1.39-3.94,0-7.17-.94-9.7-2.81Z" />
            <path d="M219.21,26.57c-1.01-.91-1.51-2.06-1.51-3.43s.5-2.58,1.51-3.5c1.01-.93,2.34-1.39,4.01-1.39s2.95,.46,3.96,1.39c1.01,.93,1.51,2.1,1.51,3.5s-.5,2.52-1.51,3.43c-1.01,.91-2.33,1.37-3.96,1.37s-3-.46-4.01-1.37Zm8.66,3.86v26.93h-9.41V30.43h9.41Z" />
            <path d="M249.14,49.35v8.02h-4.08c-6.88,0-10.32-3.41-10.32-10.22v-8.88h-3.31v-7.82h3.31v-6.53h9.46v6.53h4.9v7.82h-4.9v9.02c0,.74,.17,1.26,.5,1.58,.34,.32,.9,.48,1.7,.48h2.74Z" />
            <path d="M279.04,45.79h-17.81c.1,1.47,.5,2.57,1.22,3.29,.72,.72,1.64,1.08,2.76,1.08,1.57,0,2.69-.7,3.36-2.11h10.03c-.42,1.86-1.22,3.51-2.42,4.97-1.2,1.46-2.71,2.6-4.54,3.43-1.82,.83-3.84,1.25-6.05,1.25-2.66,0-5.02-.56-7.08-1.68-2.06-1.12-3.68-2.72-4.85-4.8-1.17-2.08-1.75-4.53-1.75-7.34s.58-5.26,1.73-7.32,2.76-3.66,4.82-4.78c2.06-1.12,4.44-1.68,7.13-1.68s5.01,.54,7.06,1.63c2.05,1.09,3.65,2.65,4.8,4.68,1.15,2.03,1.73,4.42,1.73,7.18,0,.74-.05,1.47-.14,2.21Zm-9.46-4.56c0-1.15-.38-2.06-1.15-2.71-.77-.66-1.73-.98-2.88-.98s-2.1,.31-2.83,.94-1.22,1.54-1.44,2.76h8.3Z" />
            <path d="M292.43,50.16h10.51v7.2h-19.92V23.52h9.41v26.64Z" />
            <path d="M306.57,36.58c1.01-2.08,2.38-3.68,4.13-4.8,1.74-1.12,3.69-1.68,5.83-1.68,1.86,0,3.46,.37,4.8,1.1,1.34,.74,2.38,1.73,3.12,2.98v-3.74h9.41v26.93h-9.41v-3.74c-.74,1.25-1.78,2.24-3.14,2.98-1.36,.74-2.95,1.1-4.78,1.1-2.14,0-4.09-.56-5.83-1.68-1.75-1.12-3.12-2.73-4.13-4.82-1.01-2.1-1.51-4.54-1.51-7.32s.5-5.22,1.51-7.3Zm16.46,3.22c-.94-.99-2.1-1.49-3.48-1.49s-2.58,.49-3.5,1.46c-.93,.98-1.39,2.34-1.39,4.1s.46,3.1,1.39,4.1c.93,1.01,2.09,1.51,3.5,1.51s2.54-.5,3.48-1.49c.94-.99,1.42-2.37,1.42-4.13s-.47-3.09-1.42-4.08Z" />
            <path d="M366.78,30.43v26.93h-9.46v-3.84c-.8,1.22-1.9,2.2-3.31,2.95-1.41,.75-3.02,1.13-4.85,1.13-3.14,0-5.61-1.03-7.42-3.1-1.81-2.06-2.71-4.87-2.71-8.42v-15.65h9.36v14.45c0,1.54,.41,2.74,1.22,3.62,.82,.88,1.9,1.32,3.24,1.32s2.46-.44,3.26-1.32c.8-.88,1.2-2.09,1.2-3.62v-14.45h9.46Z" />
            <path d="M397.17,33.29c1.82,2.06,2.74,4.86,2.74,8.38v15.7h-9.41v-14.45c0-1.54-.4-2.74-1.2-3.62-.8-.88-1.87-1.32-3.22-1.32s-2.51,.44-3.31,1.32c-.8,.88-1.2,2.09-1.2,3.62v14.45h-9.41V30.43h9.41v3.84c.83-1.22,1.95-2.2,3.36-2.95,1.41-.75,3.02-1.13,4.85-1.13,3.1,0,5.57,1.03,7.39,3.1Z" />
            <path d="M405.28,36.58c1.15-2.08,2.76-3.68,4.82-4.8,2.06-1.12,4.42-1.68,7.08-1.68,3.42,0,6.3,.94,8.64,2.81s3.84,4.49,4.51,7.85h-9.98c-.58-1.76-1.7-2.64-3.36-2.64-1.18,0-2.12,.49-2.81,1.46-.69,.98-1.03,2.41-1.03,4.3s.34,3.33,1.03,4.32c.69,.99,1.62,1.49,2.81,1.49,1.7,0,2.82-.88,3.36-2.64h9.98c-.67,3.33-2.18,5.94-4.51,7.82-2.34,1.89-5.22,2.83-8.64,2.83-2.66,0-5.02-.56-7.08-1.68-2.06-1.12-3.67-2.72-4.82-4.8-1.15-2.08-1.73-4.53-1.73-7.34s.58-5.22,1.73-7.3Z" />
            <path d="M459.25,33.29c1.81,2.06,2.71,4.86,2.71,8.38v15.7h-9.41v-14.45c0-1.54-.4-2.74-1.2-3.62-.8-.88-1.87-1.32-3.22-1.32s-2.51,.44-3.31,1.32c-.8,.88-1.2,2.09-1.2,3.62v14.45h-9.41V21.84h9.41v12.43c.83-1.22,1.96-2.2,3.38-2.95,1.42-.75,3.06-1.13,4.92-1.13,3.07,0,5.51,1.03,7.32,3.1Z" />
            <path d="M492.73,45.79h-17.81c.1,1.47,.5,2.57,1.22,3.29,.72,.72,1.64,1.08,2.76,1.08,1.57,0,2.69-.7,3.36-2.11h10.03c-.42,1.86-1.22,3.51-2.42,4.97-1.2,1.46-2.71,2.6-4.54,3.43-1.82,.83-3.84,1.25-6.05,1.25-2.66,0-5.02-.56-7.08-1.68-2.06-1.12-3.68-2.72-4.85-4.8-1.17-2.08-1.75-4.53-1.75-7.34s.58-5.26,1.73-7.32c1.15-2.06,2.76-3.66,4.82-4.78,2.06-1.12,4.44-1.68,7.13-1.68s5.01,.54,7.06,1.63c2.05,1.09,3.65,2.65,4.8,4.68,1.15,2.03,1.73,4.42,1.73,7.18,0,.74-.05,1.47-.14,2.21Zm-9.46-4.56c0-1.15-.38-2.06-1.15-2.71-.77-.66-1.73-.98-2.88-.98s-2.1,.31-2.83,.94-1.22,1.54-1.44,2.76h8.3Z" />
            <path d="M509.96,31.54c1.5-.9,3.12-1.34,4.85-1.34v10.03h-2.64c-2.05,0-3.57,.4-4.56,1.2-.99,.8-1.49,2.19-1.49,4.18v11.76h-9.41V30.43h9.41v4.75c1.06-1.54,2.33-2.75,3.84-3.65Z" />
          </g>
        </g>
      </svg>
    </>
  );
}
