import { useEffect, useState } from "react";
import {
  RightMainContainer,
  HeaderContainer,
  PrimaryHeading,
  MainButton,
  BodyContainer,
  InputField,
  TextEditor,
  SecondaryHeading,
  ListCard,
  ListIconWrapper,
  IconWrapper,
  AddComponent,
  Loader,
  PopOver,
  AddFromTemplateLibrary,
  DeleteButton,
} from "commonUI";
import { SaveIcon, BackArrow } from "allIcons";
import { isEmpty, isEqual, parse, useUtilities } from "helpers";
import { useParams } from "react-router-dom";
import {
  useAddGroup,
  useDeleteGroup,
  useGroups,
  useUpdateGroup,
} from "api/usersModule/groups";
import { useMembers } from "api/usersModule/members";
import { updateform } from "redux/reducers/usersModule/groups";

export default function AddEditGroup() {
  const { id } = useParams();
  const {
    notification,
    navigate,
    dispatch,
    groups: { form },
  } = useUtilities();
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [inputValue, setInputValue] = useState({
    group_title: "",
    group_description: "",
    website_id: "",
    system_status: 0,
    users: [],
  });

  const { mutate: addGroup, isLoading: addingGroup } =
    useAddGroup("/users/groups");
  const { mutate: deleteGroup, isLoading: deletingGroup } =
    useDeleteGroup("/users/groups");
  const { data: groups } = useGroups();
  const { mutate: updateGroup, isLoading: updatingGroup } = useUpdateGroup();
  const { data: members, isLoading: loadingMembers } = useMembers();

  useEffect(() => {
    if (isEqual(id, "add")) return;
    if (!isEmpty(form) && isEqual(id, form.id)) {
      setInputValue(form);
      dispatch(updateform({}));
      return;
    }
    const group = groups.find((item) => isEqual(item.id, id));
    if (group) {
      setInputValue({
        ...group,
        users: parse(group.users).map((_) => ({
          ..._,
          ...members.find((item) => isEqual(item.id, _.id)),
        })),
        group_description: group.group_description || group.description,
      });
    }
  }, [id, groups, members]);

  const handleSave = () => {
    if (isEqual(inputValue.group_title, "")) {
      notification({
        type: "error",
        message: "Please enter group title",
      });
      return;
    }
    if (isEqual(id, "add")) {
      addGroup({
        data: {
          ...inputValue,
          users: inputValue.users.map((_) => ({ id: _.id })),
        },
      });
    } else {
      updateGroup({
        ...inputValue,
        group_id: id,
        users: inputValue.users.map((_) => ({ id: _.id })),
      });
    }
  };

  return (
    <RightMainContainer>
      <Loader
        show={addingGroup || updatingGroup || deletingGroup}
        loader="block"
      />
      <HeaderContainer>
        <IconWrapper>
          <BackArrow onClick={() => navigate("/users/groups")} />
          <PrimaryHeading>
            {isEqual(id, "add") ? "Add Group" : inputValue.group_title}
          </PrimaryHeading>
        </IconWrapper>
        <MainButton onClick={handleSave}>
          <SaveIcon />
          Save Changes
        </MainButton>
      </HeaderContainer>
      <BodyContainer>
        <InputField
          label="Title"
          placeholder="Write Title Here"
          value={inputValue.group_title}
          setValue={(value) =>
            setInputValue((pre) => ({
              ...pre,
              group_title: value,
            }))
          }
        />
        <TextEditor
          label="Description"
          placeholder="Write Description Here"
          rows={6}
          option={false}
          value={inputValue.group_description}
          setValue={(value) =>
            setInputValue((pre) => ({
              ...pre,
              group_description: value,
            }))
          }
          reRender={id !== "add"}
        />
        <SecondaryHeading className="mt-3">Users In Group</SecondaryHeading>
        {inputValue.users.map((_, index) => (
          <ListCard
            key={index}
            data={`${_.name} ${_.last_name}`}
            secondaryData={_.user_type}
            icon={
              <ListIconWrapper className="bg-white">
                <img
                  src={require("assets/pic.png")}
                  className="avatar"
                  alt={_}
                />
              </ListIconWrapper>
            }
            onCross={() => {
              setInputValue({
                ...inputValue,
                users: inputValue.users.filter((__, i) => i !== index),
              });
            }}
            deleteIcon={true}
            edit={true}
            editLink={`/users/${
              _.user_type === "Client" ? "clients" : "team-members"
            }/${_.id}`}
            onEditClick={() => {
              dispatch(updateform({ ...inputValue, id }));
            }}
          />
        ))}
        <AddComponent
          title="Add User"
          onClick={() => setOpenAddUserModal(true)}
        >
          <PopOver
            open={openAddUserModal}
            onClose={setOpenAddUserModal}
            style={{
              top: "auto",
              bottom: "calc(100% + 15px)",
              right: "auto",
            }}
            toolTip={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <AddFromTemplateLibrary
              heading="Add User"
              isLoading={loadingMembers}
              data={members
                .filter(
                  (_) =>
                    inputValue.users.findIndex((ele) =>
                      isEqual(ele.id, _.id)
                    ) === -1
                )
                .map((item) => ({
                  ...item,
                  title: `${item.name} ${item.last_name}`,
                  icon: (
                    <img
                      src={require("assets/pic.png")}
                      className="avatar"
                      alt={item.first_name}
                    />
                  ),
                  iconWrapperClass: "bg-white",
                  onClick: () =>
                    setInputValue({
                      ...inputValue,
                      users: [...inputValue.users, item],
                    }),
                }))}
              emptyTemplate={{
                label: "User",
                linkLabel: "User",
                link: "/users/team-members/add",
              }}
            />
          </PopOver>
        </AddComponent>
        {!isEqual(id, "add") ? (
          <DeleteButton
            type="button"
            deleteMessage={`You are about to delete this group from your account. This action cannot be undone! All related settings and data will be permanently removed. Are you sure you want to do this?`}
            deleteTitle={`Delete This Group?`}
            deleteButtonText="Confirm"
            className="mt-3"
            style={{ left: "0" }}
            position="top"
            title={`Delete Group`}
            message={
              "If you no longer need this user group, you can choose to delete it from your account. This will permanently remove the user group and all of its associated data."
            }
            buttonText={`Delete Group`}
            onDelete={() => deleteGroup(id)}
          />
        ) : (
          ""
        )}
      </BodyContainer>
    </RightMainContainer>
  );
}
