import {
  ListCard,
  MainButton,
  HeaderContainer,
  PrimaryHeading,
  BodyContainer,
  RightMainContainer,
  ListIconWrapper,
  LoadingListCards,
  TemplateNotFound,
  Loader,
  InputField,
} from "commonUI";
import { Link } from "react-router-dom";
import { AddIcon, PhaseIcon } from "allIcons";
import {
  usePhases,
  useDeletePhase,
  useAddNewPhase,
} from "api/templateLibrary/phases";
import { Children, useDeferredValue, useEffect, useState } from "react";
import { useUserPermissions } from "hooks/user";
import { libraryItemSorting } from "filterAndSort/templateLibrary";
import { search } from "helpers";

export default function Phases() {
  const { data: phases, isLoading: loadingPhases } = usePhases();
  const { mutate: deletePhase, isLoading: isDeleting } = useDeletePhase();
  const { mutate: duplicatePhase, isLoading: isDuplicating } = useAddNewPhase();
  const { add_new_templates, edit_templates, delete_templates } =
    useUserPermissions();

  const [searchText, setSearchText] = useState("");
  const defferedSearch = useDeferredValue(searchText);
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    setSearchResult(
      phases.filter((task) => search(task.title, defferedSearch))
    );
  }, [defferedSearch, phases]);

  const applySorting = (order, orderBy) => {
    setSearchResult((prevSearchResult) =>
      libraryItemSorting(order, orderBy, prevSearchResult)
    );
  };

  return (
    <>
      <RightMainContainer>
        <Loader loader="block" show={isDeleting || isDuplicating} />
        <HeaderContainer>
          <PrimaryHeading>Phases</PrimaryHeading>
          {add_new_templates ? (
            <Link to="add">
              <MainButton>
                <AddIcon />
                Add
              </MainButton>
            </Link>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          <InputField
            placeholder="Search"
            searchIcon
            value={searchText}
            setValue={setSearchText}
            applySorting={applySorting}
            filter={false}
            sort
          />
          <div className="">
            {loadingPhases ? (
              <LoadingListCards />
            ) : searchResult.length === 0 ? (
              <TemplateNotFound
                label={"Phases"}
                linkLabel={"Phase"}
                link={add_new_templates ? "add" : ""}
              />
            ) : (
              Children.toArray(
                searchResult.map((ele, index) => {
                  return (
                    <ListCard
                      data={ele.title}
                      key={index}
                      index={index}
                      link={
                        edit_templates
                          ? `/template-library/phases/${ele.id}`
                          : ""
                      }
                      strictLink={true}
                      duplicate={add_new_templates}
                      editIcon={edit_templates}
                      onDuplicate={() =>
                        duplicatePhase({
                          ...ele,
                          title: `Copy of ${ele.title}`,
                        })
                      }
                      deleteIcon={!delete_templates}
                      onDelete={() => deletePhase(ele.id)}
                      icon={
                        <ListIconWrapper>
                          <PhaseIcon />
                        </ListIconWrapper>
                      }
                      deleteTitle="Delete This Template?"
                      deleteButtonText="Confirm Delete"
                      deleteMessage="You are about to delete this template from your account. This action cannot be undone! All template content will be permanently removed. Are you sure you want to do this?"
                    />
                  );
                })
              )
            )}
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
