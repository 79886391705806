import { axiosInstance, printError, useUtilities } from "helpers";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import {
  createChecklistTemplate,
  editChecklistTemplate,
  removeChecklistTemplate,
  setTemplateChecklists,
  setDefaultChecklists,
  addToDefaultChecklists,
  removeFromDefaultChecklists,
} from "redux/reducers/templateLibrary/checklists";
import { ApiResponseType, NotificationType } from "enums";
import {
  defaultTaskAdd,
  defaultTaskRemove,
  defaultTaskShow,
  templateTaskAdd,
  templateTaskDelete,
  templateTaskEdit,
  templateTaskShow,
} from "./modularApi";

export function useGetChecklistTemplates() {
  return useQuery(["checklistTemplates"], () => templateTaskShow("checklist"));
}

export const useCheckListTemplates = () => {
  const { data, isLoading, error } = useGetChecklistTemplates();
  const {
    checklists: { checklists, loaded },
    dispatch,
  } = useUtilities();

  useEffect(() => {
    if (isLoading || loaded) return;
    if (data) {
      dispatch(setTemplateChecklists(data.results));
    }
  }, [isLoading, data, loaded]);

  return { data: checklists, isLoading: isLoading || !loaded, error };
};

export function useAddChecklistTemplate(redirect = "") {
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(
    (params) =>
      templateTaskAdd({
        ...params,
        parent_type: "checklist",
        module_hint: "checklist",
      }),
    {
      onSuccess: (data) => {
        if (data.StatusCode === ApiResponseType.SUCCESS) {
          dispatch(createChecklistTemplate(data.Data));
          notification({
            type: NotificationType.SUCCESS,
            message: "Checklist template added successfully",
          });
          if (redirect && data?.Data?.id) navigate(redirect + data.Data.id);
        } else {
          notification({
            type: NotificationType.ERROR,
            message: printError(data.Message),
          });
        }
      },
      onError: (error) => {
        console.log(error);
        notification({
          type: NotificationType.ERROR,
          message: printError(error.message),
        });
      },
    }
  );
}

export function useUpdateChecklistTemplate() {
  const { dispatch, notification } = useUtilities();
  return useMutation(
    (params) =>
      templateTaskEdit({
        ...params,
        parent_type: "checklist",
        module_hint: "checklist",
      }),
    {
      onSuccess: (data) => {
        if (data.StatusCode === ApiResponseType.SUCCESS) {
          dispatch(editChecklistTemplate(data.Data));
          notification({
            type: NotificationType.SUCCESS,
            message: "Checklist template updated successfully",
          });
        } else {
          notification({
            type: NotificationType.ERROR,
            message: printError(data.Message),
          });
        }
      },
      onError: (error) => {
        console.log(error);
        notification({
          type: NotificationType.ERROR,
          message: printError(error.message),
        });
      },
    }
  );
}

export function useDeleteChecklistTemplate() {
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(templateTaskDelete, {
    onSuccess: (data) => {
      if (data.Status_Code === ApiResponseType.SUCCESS) {
        dispatch(removeChecklistTemplate(data.id));
        notification({
          type: NotificationType.SUCCESS,
          message: "Checklist template deleted successfully",
        });
        navigate("/template-library/checklists");
      } else {
        notification({
          type: NotificationType.ERROR,
          message: printError(data.Message),
        });
      }
    },
    onError: (error) => {
      console.log(error);
      notification({
        type: NotificationType.ERROR,
        message: printError(error.message),
      });
    },
  });
}

export function useGetChecklistDefaultTemplates() {
  return useQuery(["checklistDefaultTemplates"], () => defaultTaskShow("checklist"));
}

export const useChecklistDefaultTemplates = () => {
  const { data, isLoading, error } = useGetChecklistDefaultTemplates();
  const {
    checklists: {
      defaultSite: { checklists, loaded },
    },
    dispatch,
  } = useUtilities();

  useEffect(() => {
    if (isLoading || loaded) return;
    if (data) {
      dispatch(setDefaultChecklists(data.Data));
    }
  }, [isLoading, data, loaded]);

  return { data: checklists, isLoading: isLoading || !loaded, error };
};

export function useAddChecklistToDefaultTemplate() {
  const { dispatch, notification } = useUtilities();
  return useMutation(defaultTaskAdd, {
    onSuccess: (data) => {
      if (data.StatusCode === ApiResponseType.SUCCESS) {
        dispatch(addToDefaultChecklists(data.Data));
        notification({
          type: NotificationType.SUCCESS,
          message: "Checklist added to Site Template successfully",
        });
      } else {
        notification({
          type: NotificationType.ERROR,
          message: printError(data.Message),
        });
      }
    },
    onError: (error) => {
      console.log(error);
      notification({
        type: NotificationType.ERROR,
        message: printError(error.message),
      });
    },
  });
}

export function useRemoveChecklistDefault() {
  const { dispatch, notification } = useUtilities();
  return useMutation(defaultTaskRemove, {
    onSuccess: (data) => {
      if (data.Status_Code === ApiResponseType.SUCCESS) {
        dispatch(removeFromDefaultChecklists(data.id));
        notification({
          type: NotificationType.SUCCESS,
          message: "Checklist removed from Site Template successfully",
        });
      } else {
        notification({
          type: NotificationType.ERROR,
          message: printError(data.Message),
        });
      }
    },
    onError: (error) => {
      notification({
        type: NotificationType.ERROR,
        message: printError(error.message),
      });
    },
  });
}
