import { useMutation, useQuery } from "@tanstack/react-query";
import { useUtilities } from "helpers";
import { axiosInstance } from "helpers";
import { useEffect } from "react";
import { updateContentRequestItemInContentRequest } from "redux/reducers/templateLibrary/contentRequests";
import {
  addDefaultSiteContentRequestItem,
  addTemplateContentRequestItem,
  deleteDefaultSiteContentRequestItem,
  deleteTemplateContentRequest,
  getAllDefaultSiteContentRequestsItem,
  getAllTemplatecontentRequestsItem,
  updateTemplateContentRequest,
} from "redux/reducers/templateLibrary/contentRequestsItem";

export async function getContentRequestsItem() {
  return axiosInstance
    .post("showlibrarytemplatecontentlist", {
      apikey: localStorage.getItem("apikey"),
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useGetContentRequestsItem() {
  return useQuery(["contentRequestsItem"], getContentRequestsItem);
}

export function useContentRequestsItem() {
  const {
    dispatch,
    contentRequestsItem: { contentRequestsItem, loaded },
  } = useUtilities();
  const { data, isLoading, error } = useGetContentRequestsItem();

  useEffect(() => {
    if (isLoading || loaded) return;
    if (data) dispatch(getAllTemplatecontentRequestsItem(data.results));
  }, [isLoading, data, loaded]);

  return { data: contentRequestsItem, isLoading, error };
}

export async function addContentRequestItem(request) {
  return axiosInstance
    .post("addcontentlistlibrarytemplate", {
      apikey: localStorage.getItem("apikey"),
      wpsl_tem: localStorage.getItem("apikey"),
      ...request,
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function useAddContentRequestItem(redirect = true) {
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(addContentRequestItem, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(addTemplateContentRequestItem(data.Data));
        notification({
          type: "success",
          message: "Content Request Item added successfully",
        });
        if (redirect) {
          navigate(`/template-library/content-request-items/${data.Data.id}`);
        }
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (error) => {
      notification({
        type: "error",
        message: error.message,
      });
    },
  });
}

export async function updateContentRequestItem(request) {
  return axiosInstance
    .post("editlibrarytemplatecontentlist", {
      apikey: localStorage.getItem("apikey"),
      ...request,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUpdateContentRequestItem() {
  const { dispatch, notification } = useUtilities();
  return useMutation(updateContentRequestItem, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(updateTemplateContentRequest(data.Data));
        dispatch(updateContentRequestItemInContentRequest(data.Data));
        notification({
          type: "success",
          message: "Content Request Item updated successfully",
        });
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (error) => {
      notification({
        type: "error",
        message: error.message,
      });
    },
  });
}

export async function deleteContentRequestItem(id) {
  return axiosInstance
    .post("deletecontentlistlibrarytemplate", {
      apikey: localStorage.getItem("apikey"),
      id,
    })
    .then((res) => ({ ...res.data, id }))
    .catch((err) => new Error(err));
}

export function useDeleteContentRequestItem(redirect = false) {
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(deleteContentRequestItem, {
    onSuccess: (data) => {
      if (data.Status_Code === 200) {
        dispatch(deleteTemplateContentRequest(data.id));
        notification({
          type: "success",
          message: "Content Request Item deleted successfully",
        });
        if (redirect) navigate("/template-library/content-request-items");
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (error) => {
      notification({
        type: "error",
        message: error.message,
      });
    },
  });
}

export async function getDefaultContentRequestItem() {
  return axiosInstance
    .post("getwpslcontentlisttemp", {
      apikey: localStorage.getItem("apikey"),
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useGetDefaultContentRequestItem() {
  return useQuery(["defaultContentRequestItem"], getDefaultContentRequestItem);
}

export function useDefaultContentRequestItem() {
  const {
    dispatch,
    contentRequestsItem: {
      defaultSite: { contentRequestsItem, loaded },
    },
  } = useUtilities();
  const { data, isLoading, error } = useGetDefaultContentRequestItem();

  useEffect(() => {
    if (isLoading || loaded) return;
    if (data) dispatch(getAllDefaultSiteContentRequestsItem(data.Data));
  }, [data, isLoading, error]);

  return { data: contentRequestsItem, isLoading: isLoading || !loaded, error };
}

export async function addDefaultContentRequestItem(id) {
  return axiosInstance
    .post("adddefaulttemplatecontentlist", {
      apikey: localStorage.getItem("apikey"),
      id,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useAddDefaultContentRequestItem() {
  const { dispatch, notification } = useUtilities();
  return useMutation(addDefaultContentRequestItem, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(addDefaultSiteContentRequestItem(data.Data));
        notification({
          type: "success",
          message: "Content Request Item added successfully",
        });
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (error) => {
      notification({
        type: "error",
        message: error.message,
      });
    },
  });
}

export async function deleteDefaultContentRequestItem(id) {
  return axiosInstance
    .post("defaultcontentlistremove", {
      apikey: localStorage.getItem("apikey"),
      id,
    })
    .then((res) => ({ ...res.data, id }))
    .catch((err) => new Error(err));
}

export function useDeleteDefaultContentRequestItem() {
  const { dispatch, notification } = useUtilities();
  return useMutation(deleteDefaultContentRequestItem, {
    onSuccess: (data) => {
      if (data.Status_Code === 200) {
        dispatch(deleteDefaultSiteContentRequestItem(data.id));
        notification({
          type: "success",
          message: "Content Request Item deleted successfully",
        });
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (error) => {
      notification({
        type: "error",
        message: error.message,
      });
    },
  });
}
