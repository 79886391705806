import { LaunchPadIcon, ApprovalIcon, BrandingIcon } from "allIcons";

export const featureMenu = [
  {
    module: "launchpad-widgets",
    title: "Launchpad Widgets",
    icon: <LaunchPadIcon />,
  },
  {
    module: "branding-and-colors",
    title: "Branding & Colors",
    icon: <BrandingIcon />,
  },
  {
    module: "approvals",
    title: "Approvals",
    icon: <ApprovalIcon />,
  },
];
