import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "helpers";

const initialState = {
  roles: [],
  loaded: false,
  defaultTemplate: {
    roles: [],
    website_id: undefined,
  },
};

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    getAllRoles: (state, action) => {
      state.roles = action.payload;
      state.loaded = true;
    },
    addNewRole: (state, action) => {
      state.roles.push(action.payload);
    },
    updateSingleRole: (state, action) => {
      const index = state.roles.findIndex((role) =>
        isEqual(role.id, action.payload.role_id)
      );
      if (index !== -1) {
        for (const key in action.payload) {
          if (Object.hasOwnProperty.call(action.payload, key)) {
            state.roles[index][key] = action.payload[key];
          }
        }
      }
      const defaultRoleIndex = state.defaultTemplate.roles.findIndex((role) =>
        isEqual(role.id, action.payload.role_id)
      );
      if (defaultRoleIndex !== -1) {
        for (const key in action.payload) {
          if (Object.hasOwnProperty.call(action.payload, key)) {
            state.defaultTemplate.roles[defaultRoleIndex][key] =
              action.payload[key];
          }
        }
      }
    },
    deleteSingleRole: (state, action) => {
      state.roles = state.roles.filter(
        (role) => !isEqual(role.id, action.payload)
      );
      state.defaultTemplate.roles = state.defaultTemplate.roles.filter(
        (role) => !isEqual(role.id, action.payload)
      );
    },
    getAllDefaultRoles: (state, action) => {
      const { data, website_id } = action.payload;
      state.defaultTemplate.roles = data;
      state.defaultTemplate.website_id = website_id;
    },
    addNewDefaultRole: (state, action) => {
      const index = state.roles.findIndex((role) =>
        isEqual(role.id, action.payload.id)
      );
      if (!isEqual(index, -1)) {
        state.roles[index].default_tem = "1";
        state.defaultTemplate.roles.push(state.roles[index]);
      } else {
        state.defaultTemplate.roles.push(action.payload);
      }
    },
    deleteSingleDefaultRole: (state, action) => {
      const index = state.roles.findIndex((role) =>
        isEqual(role.id, action.payload)
      );
      if (!isEqual(index, -1)) {
        state.roles[index].default_tem = "0";
      }
      state.defaultTemplate.roles = state.defaultTemplate.roles.filter(
        (role) => !isEqual(role.id, action.payload)
      );
    },
  },
});

export const {
  getAllRoles,
  addNewRole,
  updateSingleRole,
  deleteSingleRole,
  getAllDefaultRoles,
  addNewDefaultRole,
  deleteSingleDefaultRole,
} = rolesSlice.actions;

export default rolesSlice.reducer;
