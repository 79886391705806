import { useEffect, useState } from "react";
import { FilterIcon, SearchIcon, SortIcon } from "allIcons";
import {
  AddComponent,
  DropDownCard,
  LoadingCard,
  MainButton,
  PrimaryHeading,
  SecondaryHeading,
  PreviewCard,
  SelectField,
  PopOver,
} from "commonUI";
import { isEmpty } from "helpers";

export default function InputField({
  tags = [],
  setValue,
  value,
  label,
  labelClass,
  description,
  applySorting = () => {},
  onApplyFilter = () => {},
  disabled = false,
  filterData = {},
  showLibrarySource = true,
  showTags = true,
  filterList,
  filterTitle = "Library Source",
  filterAddTitle = "Add Source",
  sort = false,
  filter = false,
  style = {},
  ...props
}) {
  const [values, setValues] = useState({
    sortOpen: false,
    filterOpen: false,
    searchOpen: false,
    addLibraryOpen: false,
    addTagOpen: false,
    selectedFilter: "and",
    selectedLibrarySource: [],
    selectedTags: [],
    order: "asc",
    orderBy: props.sortOptions?.[0]?.value ?? "due_date",
  });
  const handleChange = (event) => {
    setValue && setValue(event.target.value);
  };

  useEffect(() => {
    if (!isEmpty(filterData)) {
      setValues((pre) => ({
        ...pre,
        selectedFilter: filterData.selectedFilter,
        selectedLibrarySource: filterData.selectedLibrarySource,
        selectedTags: filterData.selectedTags,
      }));
    }
  }, [filterData]);

  return (
    <>
      <div
        className={`flex flex-col mb-2 relative ${props.className ?? ""}`}
        style={style}
      >
        {label && (
          <h3 className={`secondary-heading ${labelClass}`}>{label}</h3>
        )}
        {description ? (
          <p className="body-text text-theme-placeholder text-11 m-0">
            {description}
          </p>
        ) : (
          ""
        )}
        <div
          className={`input-field-div ${props.inputDivClass ?? ""} `}
          style={filter ? { padding: `4px 10px` } : props.padding ?? {}}
        >
          {props.searchIcon && (
            <SearchIcon className="input-search-icon" fill="#A3B0C2" />
          )}
          {props.isLoading ? (
            <LoadingCard className="h-6" />
          ) : (
            <input
              type={props.type ?? "text"}
              value={value}
              onChange={handleChange}
              id={props.id ?? ""}
              placeholder={props.placeholder ?? `Write ${label} Here`}
              className={`input-field ${props.inputClass ?? ""} ${
                disabled ? "disabled" : ""
              }`}
              list={props.list ?? ""}
              disabled={disabled}
            />
          )}
          {(filter || sort) && (
            <>
              <div className="flex items-center justify-center gap-8">
                <MainButton
                  onClick={() =>
                    setValues((pre) => ({
                      ...pre,
                      sortOpen: !pre.sortOpen,
                    }))
                  }
                >
                  <SortIcon />
                  Sort
                </MainButton>
                {filter ? (
                  <MainButton
                    className="filter-button"
                    onClick={() =>
                      setValues((pre) => ({
                        ...pre,
                        filterOpen: !pre.filterOpen,
                      }))
                    }
                  >
                    <FilterIcon />
                    {props.filterText ?? "Filter"}
                  </MainButton>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </div>
        <PopOver
          open={values.sortOpen}
          style={{ width: "350px", top: "calc(100% + 5px)", right: "0px" }}
          toolTip={{ right: "120px", vertical: "top" }}
          onClose={() => setValues((pre) => ({ ...pre, sortOpen: false }))}
        >
          <div className="flex items-center justify-between p-5 border-b-theme-card-bg">
            <PrimaryHeading>Sort</PrimaryHeading>
          </div>
          <div className="p-5">
            <div>
              <SecondaryHeading>Order By</SecondaryHeading>
              <SelectField
                className="mt-2"
                value={values.orderBy}
                setValue={(value) =>
                  setValues((pre) => ({ ...pre, orderBy: value }))
                }
              >
                {props.sortOptions?.length > 0
                  ? props.sortOptions.map((item, index) => (
                      <option value={item.value} key={index}>
                        {item.label}
                      </option>
                    ))
                  : ""}
              </SelectField>
            </div>
            <div>
              <SecondaryHeading>Order</SecondaryHeading>
              <SelectField
                className="mt-2"
                value={values.order}
                setValue={(value) =>
                  setValues((pre) => ({ ...pre, order: value }))
                }
              >
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </SelectField>
            </div>
            <MainButton
              className="w-full"
              onClick={() => {
                setValues((pre) => ({ ...pre, sortOpen: false }));
                applySorting(values.order, values.orderBy);
              }}
            >
              Apply
            </MainButton>
          </div>
        </PopOver>

        <PopOver
          open={values.filterOpen}
          style={{ width: "350px", top: "calc(100% + 5px)", right: "0px" }}
          toolTip={{ right: "50px", vertical: "top" }}
          onClose={() => setValues((pre) => ({ ...pre, filterOpen: false }))}
        >
          <div className="flex items-center justify-between p-5 border-b-theme-card-bg">
            <PrimaryHeading>Filters</PrimaryHeading>
            {showTags && showLibrarySource ? (
              <div className="flex items-center gap-6 justify-start">
                <MainButton
                  buttonType={`${
                    values.selectedFilter === "or" ? "primary" : "secondary"
                  }`}
                  onClick={() =>
                    setValues((pre) => ({ ...pre, selectedFilter: "or" }))
                  }
                >
                  Or
                </MainButton>
                <MainButton
                  buttonType={`${
                    values.selectedFilter === "and" ? "primary" : "secondary"
                  }`}
                  onClick={() =>
                    setValues((pre) => ({ ...pre, selectedFilter: "and" }))
                  }
                >
                  And
                </MainButton>
              </div>
            ) : (
              ""
            )}
          </div>
          <div>
            <div className="p-5">
              {showLibrarySource ? (
                <div>
                  <SecondaryHeading>{filterTitle}</SecondaryHeading>
                  <div className="relative">
                    <div className="flex gap-5 items-center flex-wrap mt-2">
                      {values.selectedLibrarySource.map((item, index) => (
                        <PreviewCard
                          title={item.title}
                          icon={true}
                          className="m-0"
                          cardClass="m-0"
                          onCross={() =>
                            setValues((pre) => ({
                              ...pre,
                              selectedLibrarySource:
                                pre.selectedLibrarySource.filter(
                                  (_, i) => i !== index
                                ),
                            }))
                          }
                        />
                      ))}
                    </div>
                    <AddComponent
                      title={filterAddTitle}
                      className="mt-3 mb-3 relative"
                      value={values.addLibraryOpen}
                      onClick={() =>
                        setValues((pre) => ({
                          ...pre,
                          addLibraryOpen: !pre.addLibraryOpen,
                        }))
                      }
                    >
                      <PopOver
                        open={values.addLibraryOpen}
                        onClose={() =>
                          setValues((pre) => ({
                            ...pre,
                            addLibraryOpen: false,
                          }))
                        }
                        style={{
                          width: "300px",
                          top: "auto",
                          bottom: "calc(100% + 15px)",
                          right: "auto",
                          left: "unset",
                        }}
                        toolTip={{ horizontal: "center", vertical: "bottom" }}
                      >
                        <DropDownCard
                          title={filterTitle}
                          selectedValues={values.selectedLibrarySource.map(
                            (item) => item.id
                          )}
                          list={filterList}
                          onSelect={(item) => {
                            if (
                              values.selectedLibrarySource.find(
                                (i) => Number(i.id) === Number(item.id)
                              )
                            ) {
                              return;
                            }
                            setValues((pre) => ({
                              ...pre,
                              selectedLibrarySource: [
                                ...pre.selectedLibrarySource,
                                {
                                  ...item,
                                },
                              ],
                            }));
                          }}
                          className="max-h-40"
                        />
                      </PopOver>
                    </AddComponent>
                  </div>
                </div>
              ) : (
                ""
              )}
              {showTags ? (
                <>
                  <SecondaryHeading>Tags</SecondaryHeading>
                  <div className="relative">
                    <div className="flex gap-5 items-center flex-wrap mt-2">
                      {values.selectedTags.map((item, index) => (
                        <PreviewCard
                          key={index}
                          color={item.color}
                          title={item.title}
                          icon={true}
                          className="m-0"
                          cardClass="m-0"
                          onCross={() =>
                            setValues((pre) => ({
                              ...pre,
                              selectedTags: pre.selectedTags.filter(
                                (_, i) => i !== index
                              ),
                            }))
                          }
                        />
                      ))}
                    </div>
                    <AddComponent
                      value={values.addTagOpen}
                      title="Add Tags"
                      className="mt-3 mb-3"
                      onClick={() =>
                        setValues((pre) => ({
                          ...pre,
                          addTagOpen: !pre.addTagOpen,
                        }))
                      }
                    >
                      <PopOver
                        open={values.addTagOpen}
                        onClose={() =>
                          setValues((pre) => ({
                            ...pre,
                            addTagOpen: false,
                          }))
                        }
                        style={{
                          width: "300px",
                          top: "auto",
                          bottom: "calc(100% + 15px)",
                          right: "auto",
                          left: "unset",
                        }}
                        toolTip={{ horizontal: "center", vertical: "bottom" }}
                      >
                        <DropDownCard
                          title="Tags"
                          listStyle={true}
                          selectedValues={values.selectedTags.map(
                            (item) => item.id
                          )}
                          list={tags.map((item) => ({
                            ...item,
                            title: item.tag_title,
                          }))}
                          onSelect={(item) => {
                            if (
                              values.selectedTags.find(
                                (i) => Number(i.id) === Number(item.id)
                              )
                            ) {
                              return;
                            }
                            setValues((pre) => ({
                              ...pre,
                              selectedTags: [...pre.selectedTags, item],
                            }));
                          }}
                        />
                      </PopOver>
                    </AddComponent>
                  </div>
                </>
              ) : (
                ""
              )}
              <MainButton
                className="w-full py-2-5"
                onClick={() => {
                  onApplyFilter({
                    ...values,
                  });
                  setValues((pre) => ({ ...pre, filterOpen: false }));
                }}
              >
                Apply Filter
              </MainButton>
            </div>
          </div>
        </PopOver>
      </div>
    </>
  );
}

InputField.defaultProps = {
  sortOptions: [
    {
      label: "Alphabetical By Title",
      value: "alphabetical-by-title",
    },
    {
      label: "Date Added",
      value: "date-added",
    },
  ],
  filterList: [
    {
      id: 1,
      title: "Official - Included By Default",
      system_status: 1,
    },
    {
      id: 2,
      title: "Custom - Created By Users",
      system_status: 0,
    },
  ],
};
