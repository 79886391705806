import {
  ListIconWrapper,
  BodyContainer,
  PrimaryHeading,
  HeaderContainer,
  ListCard,
  RightMainContainer,
} from "commonUI";
import { StatusIcon } from "allIcons";

export default function StatusTab() {
  return (
    <>
      <RightMainContainer>
        <HeaderContainer>
          <PrimaryHeading>Status</PrimaryHeading>
        </HeaderContainer>
        <BodyContainer>
          <div className="">
            {["Tasks", "Requests", "Phases", "Comments"]?.map((iner, index) => {
              return (
                <ListCard
                  key={index}
                  index={index}
                  data={iner}
                  link={`/site-settings/status/${iner
                    .toLowerCase()
                    .split(" ")
                    .join("-")}`}
                  strictLink={true}
                  deleteIcon={true}
                  icon={
                    <ListIconWrapper>
                      <StatusIcon />
                    </ListIconWrapper>
                  }
                  deleteMessage="You are about to delete Status from your account. This
                    action cannot be undone! All settings related to the WP Site
                    Launcher user role will permanently removed. Do you wish to
                    proceed and delete the user role?"
                />
              );
            })}
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
