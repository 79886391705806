export default function ListIcon(props) {
  function HandleClick() {
    props.onClick && props.onClick();
  }
  return (
    <>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`cursor-pointer ${props.className ?? ""}`}
        onClick={HandleClick}>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.93103 7.17242H14.069C15.1338 7.17242 16 6.30621 16 5.24138V1.93104C16 0.866207 15.1338 0 14.069 0H1.93103C0.866207 0 0 0.866207 0 1.93104V5.24138C0 6.30621 0.866207 7.17242 1.93103 7.17242ZM1.65517 1.93104C1.65517 1.77655 1.77655 1.65517 1.93103 1.65517H14.069C14.2234 1.65517 14.3448 1.77655 14.3448 1.93104V5.24138C14.3448 5.39586 14.2234 5.51724 14.069 5.51724H1.93103C1.77655 5.51724 1.65517 5.39586 1.65517 5.24138V1.93104ZM1.93103 16H14.069C15.1338 16 16 15.1338 16 14.069V10.7586C16 9.69379 15.1338 8.82758 14.069 8.82758H1.93103C0.866207 8.82758 0 9.69379 0 10.7586V14.069C0 15.1338 0.866207 16 1.93103 16ZM1.65517 10.7586C1.65517 10.6041 1.77655 10.4828 1.93103 10.4828H14.069C14.2234 10.4828 14.3448 10.6041 14.3448 10.7586V14.069C14.3448 14.2234 14.2234 14.3448 14.069 14.3448H1.93103C1.77655 14.3448 1.65517 14.2234 1.65517 14.069V10.7586Z" fill={props.fill ?? "#A3B0C2"} />
      </svg>
    </>
  );
}
