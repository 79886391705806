export default function StatusIcon(props) {
 
  return (
    <>
      <svg
        width="18"
        height="12"
        viewBox="0 0 18 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`cursor-pointer ${props.className ?? ""}`}
      >
        <path
          d="M8.50007 11.2503C8.35227 11.2513 8.20758 11.208 8.0846 11.126C7.96162 11.0441 7.86599 10.927 7.81007 10.7902L5.99006 6.43023L5.19007 8.29022C5.13304 8.42625 5.03716 8.54251 4.91444 8.62433C4.79171 8.70615 4.64757 8.74998 4.50007 8.7503H1.50007C1.30116 8.7503 1.11039 8.67123 0.969734 8.53058C0.829082 8.38992 0.750068 8.19922 0.750068 8.0003C0.748723 7.90144 0.767236 7.80333 0.804451 7.71173C0.841665 7.62013 0.89683 7.53689 0.966743 7.46698C1.03666 7.39706 1.11989 7.34184 1.21149 7.30462C1.3031 7.26741 1.4012 7.24896 1.50007 7.2503H4.00007L5.31007 4.20025C5.36862 4.0661 5.46517 3.95197 5.58778 3.87201C5.71038 3.79205 5.85369 3.74979 6.00007 3.7503C6.14757 3.75063 6.29171 3.79446 6.41444 3.87628C6.53716 3.9581 6.63304 4.07424 6.69007 4.21026L8.42011 8.36029L10.7901 1.26031C10.8408 1.11012 10.9378 0.979871 11.0671 0.888243C11.1965 0.796615 11.3516 0.748313 11.5101 0.750303C11.668 0.752352 11.8212 0.80484 11.9472 0.900206C12.0731 0.995571 12.1653 1.12872 12.2101 1.28021L14.0501 7.28021H16.5001C16.699 7.28021 16.8898 7.35928 17.0304 7.49994C17.1711 7.64059 17.2501 7.8313 17.2501 8.03021C17.2475 8.22832 17.1676 8.41758 17.0275 8.55768C16.8874 8.69777 16.6982 8.77762 16.5001 8.78021H13.5001C13.3394 8.77929 13.1833 8.7276 13.0539 8.63238C12.9245 8.53716 12.8287 8.4034 12.7801 8.2503L11.4701 3.99029L9.21009 10.7703C9.16165 10.9141 9.0707 11.0399 8.94929 11.1309C8.82788 11.222 8.68173 11.274 8.5301 11.2802L8.50007 11.2503Z"
          fill={props.fill ?? "white"}
        />
      </svg>
    </>
  );
}
