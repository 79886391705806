import DatePicker from "sassy-datepicker";
import { LoadingCard } from "./LoadingListCards";
import { useMemo, useState } from "react";
import { CalenderIcon } from "allIcons";
import OutsideClickHandler from "./OutsideClickHandler";

const dateRangeConstrant = 6 * 30 * 24 * 60 * 60 * 1000; // 6 months

export default function DateInput({
  label,
  labelClass,
  description,
  style,
  value,
  disabled,
  ...props
}) {
  // 6 months date range
  const minDate = useMemo(() => new Date().getTime() - dateRangeConstrant, []);
  const maxDate = useMemo(() => new Date().getTime() + dateRangeConstrant, []);

  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleChange = (date) => {
    props.setValue(new Date(date).toLocaleDateString("en-US"));
  };

  return (
    <div
      className={`flex flex-col mb-2 relative ${props.className ?? ""}`}
      style={style}
    >
      {label && (
        <h3 className={`secondary-heading ${labelClass ?? ""}`}>{label}</h3>
      )}
      {description ? (
        <p className="body-text text-theme-placeholder text-11 m-0">
          {description}
        </p>
      ) : (
        ""
      )}
      <div
        className={`input-field-div ${props.inputDivClass ?? ""} ${
          disabled ? "disabled" : ""
        } relative cursor-pointer`}
        style={props.padding ?? {}}
        onClick={() => setShowDatePicker(!showDatePicker)}
        disabled={disabled}
        role="button"
      >
        {props.isLoading ? (
          <LoadingCard className="h-6" />
        ) : (
          <div className="flex items-center justify-between w-full p-[1px]">
            <p className="input-field m-0">{value ?? props.placeholder}</p>
            <CalenderIcon />
          </div>
        )}
        {showDatePicker && (
          <div className="absolute top-0 left-0 w-full h-full"></div>
        )}
        {showDatePicker && (
          <OutsideClickHandler
            className="absolute top-full mt-2 pb-2"
            onClickOutside={() => setShowDatePicker(false)}
          >
            <DatePicker
              minDate={new Date(minDate) ?? ""}
              maxDate={new Date(maxDate) ?? ""}
              value={value}
              onChange={handleChange}
              id={props.id ?? ""}
              placeholder={props.placeholder ?? `Write ${label} Here`}
              className={`input-field ${props.inputClass ?? ""} ${
                disabled ? "disabled" : ""
              }`}
            />
          </OutsideClickHandler>
        )}
      </div>
    </div>
  );
}
