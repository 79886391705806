import { CompaniesIcon, GroupIcon, RolesIcon, UserIcon } from "allIcons";

export const usersMenu = (app_users_view_users) =>
  [
    app_users_view_users && {
      title: "Team Members",
      module: "team-members",
      icon: <UserIcon />,
    },
    app_users_view_users && {
      title: "Clients",
      module: "clients",
      icon: <UserIcon />,
    },
    {
      title: "Roles",
      module: "roles",
      icon: <RolesIcon />,
    },
    // {
    //   title: "Groups",
    //   module: "groups",
    //   icon: <GroupIcon />,
    // },
    {
      title: "Companies",
      module: "companies",
      icon: <CompaniesIcon />,
    },
  ].filter((_) => _);
