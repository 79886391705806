import { useMutation, useQuery } from "@tanstack/react-query";
import { getUserData, userLogout } from "api/userAuth";
import { queryClient } from "client";
import { useUserAuth } from "context/UserAuth";
import { isEmpty, parse, useUtilities } from "helpers";
import { useEffect } from "react";
import { getUser, logout } from "redux/reducers/userAuth";

export const useUserPermissions = () => {
  const {
    user: { userData },
  } = useUtilities();

  const { rolesData = [] } = userData || {};

  const parsedPermissions = rolesData[0]?.app_permissions
    ? JSON.parse(rolesData[0]?.app_permissions)
    : {};

  return parsedPermissions;
};

export const useUserDetails = () => {
  const userId = localStorage.getItem("user_id");
  const { setUserAuth } = useUserAuth();
  const {
    dispatch,
    navigate,
    user: { userData },
  } = useUtilities();
  const { data, isLoading, error } = useQuery(
    ["userData", userId],
    () => getUserData(userId),
    {
      enabled: !!userId,
    }
  );

  useEffect(() => {
    if (data && !isLoading && isEmpty(userData)) {
      const appPermission = parse(data.rolesData[0].app_permissions);
      if (appPermission.app_general_log_into_app) {
        dispatch(getUser(data));
      } else {
        dispatch(logout());
        localStorage.clear();
        setUserAuth((pre) => ({
          ...pre,
          token: "",
        }));
        navigate("/login");
        queryClient.invalidateQueries();
      }
    }
  }, [data, isLoading, userData]);
  return { isLoading, data, error };
};

export const useUserLogout = () => {
  const { dispatch, navigate } = useUtilities();
  const { setUserAuth } = useUserAuth();

  return useMutation(userLogout, {
    onSuccess: (data) => {
      dispatch(logout());
      localStorage.clear();
      setUserAuth((pre) => ({
        ...pre,
        token: "",
      }));
      navigate("/login");
      queryClient.invalidateQueries();
    },
    onError: (error) => {
      navigate("/login");
    },
  });
};
