export default function OpenIcon(props) {
  function HandleClick() {
    props.onClick && props.onClick();
  }
  return (
    <>
      <svg
        width="8"
        height="15"
        viewBox="0 0 8 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`cursor-pointer ${props.className ?? ""}`}
        onClick={HandleClick}
      >
        <path
          d="M0.952015 15.0002C0.710745 15.0002 0.463126 14.8989 0.278999 14.7029C-0.0956039 14.3043 -0.0956039 13.6624 0.278999 13.2705L5.70122 7.50022L0.278999 1.72995C-0.0956039 1.33806 -0.0956039 0.689414 0.278999 0.297522C0.653602 -0.101127 1.25043 -0.101127 1.62503 0.297522L7.72027 6.78401C8.09487 7.18266 8.09487 7.82455 7.72027 8.21644L1.62503 14.7029C1.4409 14.8989 1.19328 15.0002 0.952015 15.0002Z"
          fill={props.fill ?? "white"}
        />
      </svg>
    </>
  );
}
