export default function StartupIcon({ fill, ...props }) {
  return (
    <>
      <svg
        width="12"
        height="18"
        viewBox="0 0 12 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`cursor-pointer ${props.className ?? ""}`}
      >
        <path
          d="M5.65002 17.25C5.27872 17.25 4.92265 17.1025 4.6601 16.84C4.39754 16.5774 4.25 16.2213 4.25 15.85V10.75H1.65002C1.27872 10.75 0.922646 10.6025 0.660095 10.34C0.397544 10.0774 0.25 9.72128 0.25 9.34998V9.20007C0.250925 8.92661 0.330832 8.65922 0.480042 8.43005L5.17999 1.43005C5.30821 1.23697 5.48208 1.07839 5.68616 0.968506C5.89023 0.858619 6.11826 0.800807 6.35004 0.800049C6.72134 0.800049 7.07741 0.947532 7.33997 1.21008C7.60252 1.47263 7.75 1.82877 7.75 2.20007V7.30005H10.35C10.7213 7.30005 11.0774 7.44753 11.34 7.71008C11.6025 7.97263 11.75 8.32877 11.75 8.70007V8.85999C11.7503 9.13511 11.6666 9.40379 11.51 9.63L6.81 16.63C6.68351 16.8217 6.51122 16.9789 6.30865 17.0872C6.10609 17.1954 5.8797 17.2514 5.65002 17.25ZM1.75 9.25H5C5.19811 9.25259 5.38737 9.33244 5.52747 9.47253C5.66756 9.61263 5.74741 9.80189 5.75 10V15.52L10.25 8.75H7C6.90114 8.75135 6.80303 8.73289 6.71143 8.69568C6.61982 8.65846 6.53659 8.60324 6.46667 8.53333C6.39676 8.46341 6.3416 8.38018 6.30438 8.28857C6.26717 8.19697 6.24865 8.09886 6.25 8V2.47998L1.75 9.25Z"
          fill={fill ?? "white"}
        />
      </svg>
    </>
  );
}
