import { useQuery, useMutation } from "@tanstack/react-query";
import { NotificationType } from "enums";
import { axiosInstance, printError, useSearch, useUtilities } from "helpers";
import { useEffect } from "react";
import {
  setNotificationsSettingAction,
  updateNotificationSettingAction,
} from "redux/reducers/siteSettings/notifications";

async function getNotificationsSetting(website_id) {
  return axiosInstance
    .get("allfeaturesettings", {
      params: {
        apiKey: localStorage.getItem("apikey"),
        added_by: localStorage.getItem("user_id"),
        tab_hint: "Notifications",
        website_id: JSON.stringify(website_id),
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

function useGetNotificationsSetting() {
  const { web = null } = useSearch();
  return useQuery(["notifications"], () => getNotificationsSetting(web));
}

export function useNotifications() {
  const { web = null } = useSearch();
  const { data, isLoading, error } = useGetNotificationsSetting();
  const {
    dispatch,
    notifications: { notifications, loaded, website_id },
  } = useUtilities();

  useEffect(() => {
    if (data && (web !== website_id || !loaded)) {
      dispatch(
        setNotificationsSettingAction({
          notifications: data.Data,
          website_id: web,
        })
      );
    }
  }, [data, isLoading, error, loaded, web]);

  return {
    data: notifications,
    isLoading: isLoading || !loaded || web !== website_id,
    error,
  };
}

async function updateNotificationSetting(data) {
  return axiosInstance
    .post("updatefeaturesettings", {
      ...data,
      tab_hint: "Notifications"
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUpdateNotificationSetting() {
  const { notification, dispatch } = useUtilities();
  return useMutation(updateNotificationSetting, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(updateNotificationSettingAction(data.Data));
        notification({
          type: NotificationType.SUCCESS,
          message: "Notification settings updated successfully.",
        });
      } else {
        notification({
          type: NotificationType.ERROR,
          message: printError(data.Message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: NotificationType.ERROR,
        message: printError(err.message),
      });
    },
  });
}
