import { BackArrow, DeleteIcon, SaveIcon } from "allIcons";
import {
  BodyContainer,
  TextEditor,
  HeaderContainer,
  IconWrapper,
  InputField,
  PreviewCard,
  PrimaryHeading,
  RightMainContainer,
  SecondaryHeading,
  AddComponent,
  MainButton,
  DeleteModal,
  DeleteButton,
} from "commonUI";
import { useParams } from "react-router-dom";
import { useState } from "react";

export default function AddEditScreenRecording() {
  const { id } = useParams();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <>
      <RightMainContainer>
        <HeaderContainer>
          <IconWrapper>
            <BackArrow />
            <PrimaryHeading>[Screen Recording Title]</PrimaryHeading>
          </IconWrapper>
          <div className="flex items-center justify-center gap-14">
            <DeleteButton
              show={id !== "add"}
              deleteMessage="You are about to delete Content Request Item from your account. This
            action cannot be undone! All settings related to the WP Site
            Launcher Content Request Item will permanently removed. Do you wish to
            proceed and delete the Content Request Item?"
            />
            <MainButton>
              <SaveIcon />
              Save Changes
            </MainButton>
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField label="Title" placeholder="Write Title Here" />
          <TextEditor
            label="Description"
            editorClass="mt-2"
            option={false}
            rows={5}
          />
          <div className="mt-2">
            <SecondaryHeading>Tags</SecondaryHeading>
            <div className="flex items-center gap-5">
              <PreviewCard
                color="#F12424"
                title="Tag 1"
                icon={true}
                className="m-0"
              />
              <PreviewCard
                color="#7846FF"
                title="Tag 2"
                icon={true}
                className="m-0"
              />
              <PreviewCard
                color="#2EA3F2"
                title="Tag 3"
                icon={true}
                className="m-0"
              />
            </div>
          </div>
          <AddComponent />
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
