import { useLocation } from "react-router-dom";

export function useSearch() {
  const { search } = useLocation();
  const filteredSearch = search.replaceAll("+", "[ADD]");
  const searchParams = new URLSearchParams(filteredSearch);
  const searchParamsObj = Object.fromEntries(searchParams.entries());
  for (const key in searchParamsObj) {
    searchParamsObj[key] = searchParamsObj[key]
      .split("?")[0]
      .replaceAll("[ADD]", "+");
  }
  return { ...searchParamsObj };
}

export function usePathname() {
  const { pathname } = useLocation();
  let path = pathname.split("/").filter((item) => item);
  return path.length > 0 ? path : [""];
}
