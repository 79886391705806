import { Children, useState } from "react";
import { AddIcon, ServiceIcon } from "allIcons";
import {
  BodyContainer,
  HeaderContainer,
  InputField,
  ListCard,
  ListIconWrapper,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
} from "commonUI";

export default function Services() {
  const [services, setServices] = useState([
    "[Service Title]",
    "[Service Title]",
    "[Service Title]",
    "[Service Title]",
  ]);
  return (
    <>
      <RightMainContainer>
        <HeaderContainer>
          <PrimaryHeading>Services</PrimaryHeading>
          <MainButton>
            <AddIcon />
            Add
          </MainButton>
        </HeaderContainer>
        <BodyContainer>
          <InputField placeholder="Search" searchIcon={true} filter={true} />
          {Children.toArray(
            services.map((task, index) => {
              return (
                <ListCard
                  editIcon={false}
                  data={task}
                  onDelete={() => {
                    setServices(() =>
                      services.filter((item, i) => i !== index)
                    );
                  }}
                  icon={
                    <ListIconWrapper>
                      <ServiceIcon fill="white" />
                    </ListIconWrapper>
                  }
                  deleteTitle="Delete This Template?"
                  deleteButtonText="Confirm Delete"
                  deleteMessage="You are about to delete this template. It will be permanently removed from your account library. Are you sure you want to do this?"
                />
              );
            })
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
