import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    status : [],
};

export const statusslice = createSlice({
    name: "status",
    initialState,
    reducers: {
        getStatus: (state, action) => {
            state.status = action.payload;
        }
    },
})

export const { getStatus } = statusslice.actions;
export default statusslice.reducer;