export function parse(str = "") {
  if (!str) {
    return str;
  }
  if (typeof str !== "string") {
    return str;
  }
  let parsedStr = JSON.parse(str);
  if (typeof parsedStr === "string") {
    return parse(parsedStr);
  }
  return parsedStr;
}

export function textParser(text = "") {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html:
          typeof text === "string"
            ? text?.replace(/&#(\d+);/g, (match, dec) =>
                String.fromCharCode(dec)
              )
            : text,
      }}
    />
  );
}

export function isEmpty(obj = {}) {
  return Object.keys(obj).length === 0;
}

export function isArray(arr) {
  return Array.isArray(arr);
}

export function isObject(obj) {
  return typeof obj === "object" && !isArray(obj);
}

export function isEqual(...args) {
  return args.every((arg) => String(arg) === String(args[0]));
}

export function objectKeys(obj) {
  return [...new Set(Object.keys(obj))];
}

export function objectValues(obj) {
  return [...new Set(Object.values(obj))];
}

export function compact(arr = []) {
  return arr.filter((ele) => ele);
}

export function extractTextFromHtml(html) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.innerText;
}

export const objectDeepCompare = (obj1, obj2) => {
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }
  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
};
