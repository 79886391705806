import { CheckIcon } from "allIcons";
import SecondaryHeading from "./SecondaryHeading";

export default function CheckBox({
  label = "",
  value = false,
  setValue = () => {},
  className = "",
  disabled = false,
}) {
  return (
    <div className="flex items-center gap-9 h-full py-[2px]">
      <div
        className={`checkbox ${value ? "selected" : ""} ${className} ${
          disabled ? "disabled" : ""
        }`}
        onClick={() => !disabled && setValue(!value)}
      >
        <CheckIcon width="16px" height="12px" fill="white" />
      </div>
      {label ? (
        <SecondaryHeading
          className={`text-13 font-semibold text-theme-secondary ${
            disabled ? "disabled" : ""
          } `}
        >
          {label}
        </SecondaryHeading>
      ) : (
        ""
      )}
    </div>
  );
}
