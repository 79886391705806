import { useMutation, useQuery } from "@tanstack/react-query";
import { axiosInstance, printError, useUtilities } from "helpers";
import { useEffect } from "react";
import {
  addSingleDefaultGroup,
  addSingleGroup,
  deleteSingleDefaultGroup,
  deleteSingleGroup,
  getAllDefaultGroups,
  getAllGroups,
  updateSingleGroup,
} from "redux/reducers/usersModule/groups";

async function getGroups() {
  return axiosInstance
    .get("allgroups", {
      params: {
        apiKey: localStorage.getItem("apikey"),
        added_by: localStorage.getItem("user_id"),
      },
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

function useGetGroups() {
  return useQuery(["groups"], getGroups);
}

export function useGroups() {
  const { data, isLoading, error } = useGetGroups();
  const {
    dispatch,
    groups: { groups, loaded },
  } = useUtilities();

  useEffect(() => {
    if (data && !isLoading && !error && !loaded) {
      dispatch(getAllGroups(data.Data));
    }
  }, [data, isLoading, error]);

  return { data: groups, isLoading: isLoading || !loaded, error };
}

async function addGroup({ data, callback }) {
  return axiosInstance
    .post("addgroup", {
      apiKey: localStorage.getItem("apikey"),
      added_by: localStorage.getItem("user_id"),
      ...data,
    })
    .then((res) => {
      callback && res.data.Data.id && callback(res.data.Data.id);
      return res.data;
    })
    .catch((err) => new Error(err));
}

export function useAddGroup(redirect = "") {
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(addGroup, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(addSingleGroup(data.Data));
        notification({
          type: "success",
          message: "Group added successfully",
        });
        if (redirect) navigate(`${redirect}/${data.Data.id}`);
      } else {
        notification({
          type: "error",
          message: printError(data.Message || data.message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: printError(err.message),
      });
    },
  });
}

async function updateGroup(data) {
  return axiosInstance
    .post("updategroup", {
      apiKey: localStorage.getItem("apikey"),
      added_by: localStorage.getItem("user_id"),
      ...data,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUpdateGroup() {
  const { dispatch, notification } = useUtilities();
  return useMutation(updateGroup, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(updateSingleGroup(data.Data));
        notification({
          type: "success",
          message: "Group updated successfully",
        });
      } else {
        notification({
          type: "error",
          message: printError(data.Message || data.message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: printError(err.message),
      });
    },
  });
}

async function deleteGroup(id) {
  return axiosInstance
    .post("delgroup", {
      apiKey: localStorage.getItem("apikey"),
      group_id: id,
    })
    .then((res) => ({ ...res.data, id }))
    .catch((err) => new Error(err));
}

export function useDeleteGroup(redirect = "") {
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(deleteGroup, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(deleteSingleGroup(data.id));
        notification({
          type: "success",
          message: "Group deleted successfully",
        });
        if (redirect) navigate(redirect);
      } else {
        notification({
          type: "error",
          message: printError(data.Message || data.message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: printError(err.message),
      });
    },
  });
}

async function getDefaultGroups() {
  return axiosInstance
    .post("getdefaultgroups", {
      apikey: localStorage.getItem("apikey"),
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

function useGetDefaultGroups() {
  return useQuery(["defaultGroups"], getDefaultGroups);
}

export function useDefaultGroups() {
  const { data, isLoading, error } = useGetDefaultGroups();
  const {
    dispatch,
    groups: {
      defaultTemplate: { groups, loaded },
    },
  } = useUtilities();

  useEffect(() => {
    if (data && !isLoading && !error && !loaded) {
      dispatch(getAllDefaultGroups(data.Data));
    }
  }, [data, isLoading, error]);

  return { data: groups, isLoading: isLoading || !loaded, error };
}

async function addDefaultGroup(id) {
  return axiosInstance
    .post("adddefaulttemplategroup", {
      apikey: localStorage.getItem("apikey"),
      id,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useAddDefaultGroup() {
  const { dispatch, notification } = useUtilities();
  return useMutation(addDefaultGroup, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(addSingleDefaultGroup(data.Data));
        notification({
          type: "success",
          message: "Group added to default template successfully",
        });
      } else {
        notification({
          type: "error",
          message: printError(data.Message || data.message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: printError(err.message),
      });
    },
  });
}

async function removeDefaultGroup(id) {
  return axiosInstance
    .post("defaultgroupremove", {
      apikey: localStorage.getItem("apikey"),
      id,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useRemoveDefaultGroup() {
  const { dispatch, notification } = useUtilities();
  return useMutation(removeDefaultGroup, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(deleteSingleDefaultGroup(data.Data.id));
        notification({
          type: "success",
          message: "Group removed from default template successfully",
        });
      } else {
        notification({
          type: "error",
          message: printError(data.Message || data.message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: printError(err.message),
      });
    },
  });
}
