import { Children, useState } from "react";
import { AddIcon, FormFieldOptionIcon } from "allIcons";
import {
  BodyContainer,
  HeaderContainer,
  InputField,
  ListCard,
  ListIconWrapper,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
} from "commonUI";

export default function FormFieldOptions() {
  const [formOption, setFormOption] = useState([
    "[Form Field Option Title]",
    "[Form Field Option Title]",
  ]);
  return (
    <>
      <RightMainContainer>
        <HeaderContainer>
          <PrimaryHeading>Form Fields</PrimaryHeading>
          <MainButton>
            <AddIcon />
            Add
          </MainButton>
        </HeaderContainer>
        <BodyContainer>
          <InputField placeholder="Search" searchIcon={true} filter={true} />
          {Children.toArray(
            formOption.map((task, index) => {
              return (
                <ListCard
                  editIcon={false}
                  data={task}
                  onDelete={() => {
                    setFormOption(() =>
                      formOption.filter((item, i) => i !== index)
                    );
                  }}
                  icon={
                    <ListIconWrapper>
                      <FormFieldOptionIcon fill="white" />
                    </ListIconWrapper>
                  }
                  deleteTitle="Delete This Template?"
                  deleteButtonText="Confirm Delete"
                  deleteMessage="You are about to delete this template. It will be permanently removed from your account library. Are you sure you want to do this?"
                />
              );
            })
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
