import { ModulePrefixes } from "enums";
import { useUserPermissions } from "hooks/user";
import LeftMenuCard from "leftMenu/LeftMenuCard";
import { useLocation } from "react-router-dom";
import { getGeneralMenu } from "staticData";

export default function GeneralMenu() {
  const { search } = useLocation();
  const { delete_site } = useUserPermissions();
  return (
    <>
      <div className="card-div">
        {getGeneralMenu({ delete_site })
          .filter((item) => item)
          .map((item, index) => (
            <LeftMenuCard
              key={`${index}-${item.module}`}
              {...item}
              prefix={ModulePrefixes.SITE_SETTINGS}
              link={`${ModulePrefixes.SITE_SETTINGS}/${item.module}${
                search ?? ""
              }`}
            />
          ))}
      </div>
    </>
  );
}
