import { axiosInstance } from "helpers";

export async function getAllPriorities(website_id) {
  return axiosInstance
    .get(`getPriorites`, {
      params: {
        apiKey: localStorage.getItem("apikey"),
        website_id,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export async function deletePriority(params) {
  return axiosInstance
    .post(
      `delpriority?priority_id=${params.id}&apiKey=${localStorage.getItem(
        "apikey"
      )}`
    )
    .then((res) => ({ ...res.data, id: params.id }))
    .catch((err) => err);
}

export async function addPriority(data) {
  return axiosInstance
    .post(`addpriority`, {
      added_by: localStorage.getItem("user_id"),
      apiKey: localStorage.getItem("apikey"),
      ...data,
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export async function updatePriority(data) {
  return axiosInstance
    .post(`updatepriority`, {
      apiKey: localStorage.getItem("apikey"),
      ...data,
    })
    .then((res) => res.data)
    .catch((err) => err);
}
