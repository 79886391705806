import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import rootReducer from "./reducers/rootReducer";
import thunk from "redux-thunk";

const store = configureStore({
  reducer: (rootState, action) => {
    let state = rootState;
    if (action.type === "user/logout") {
      state = undefined;
    }
    return rootReducer(state, action);
  },

  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;

setupListeners(store.dispatch);
