export default function ImportIcon(props) {
  function HandleClick() {
    props.onClick && props.onClick();
  }
  return (
    <>
      <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`cursor-pointer ${props.className ?? ""}`}
        onClick={HandleClick}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7875 4.20337C10.8785 4.16559 10.9611 4.11003 11.0303 4.03992C11.1045 3.96982 11.1636 3.88534 11.2039 3.79163C11.2443 3.69792 11.2651 3.59694 11.2651 3.49491C11.2651 3.39287 11.2443 3.29192 11.2039 3.19821C11.1636 3.1045 11.1045 3.02002 11.0303 2.94992L8.53033 0.449923C8.46098 0.382132 8.3796 0.327868 8.29034 0.289919H8.19031C8.12734 0.280313 8.06327 0.280313 8.00031 0.289919C7.90053 0.289608 7.80179 0.310038 7.71033 0.349916C7.62108 0.387865 7.53969 0.44213 7.47034 0.50992L4.97034 3.00992C4.82989 3.15055 4.75098 3.34117 4.75098 3.53992C4.75098 3.73867 4.82989 3.92929 4.97034 4.06992C5.03928 4.14067 5.12168 4.19693 5.21271 4.23532C5.30373 4.27372 5.40151 4.29349 5.50031 4.29349C5.5991 4.29349 5.69688 4.27372 5.7879 4.23532C5.87893 4.19693 5.96139 4.14067 6.03033 4.06992L7.25031 2.84992V9.03992C7.25031 9.23883 7.32932 9.4296 7.46997 9.57025C7.61062 9.71091 7.80139 9.78992 8.00031 9.78992C8.19922 9.78992 8.38999 9.71091 8.53064 9.57025C8.67129 9.4296 8.75031 9.23883 8.75031 9.03992V2.81992L9.97034 4.03992C10.0396 4.11003 10.1221 4.16559 10.2131 4.20337C10.3041 4.24115 10.4018 4.26039 10.5003 4.25992C10.5988 4.26039 10.6965 4.24115 10.7875 4.20337ZM3.5 13.75H12.5C13.2293 13.75 13.9289 13.4603 14.4446 12.9445C14.9603 12.4288 15.25 11.7293 15.25 11V7C15.2474 6.80189 15.1676 6.61263 15.0275 6.47253C14.8874 6.33244 14.6981 6.25259 14.5 6.25C14.3011 6.25 14.1103 6.32901 13.9697 6.46967C13.829 6.61032 13.75 6.80109 13.75 7V11C13.75 11.3315 13.6183 11.6495 13.3839 11.8839C13.1495 12.1183 12.8315 12.25 12.5 12.25H3.5C3.16848 12.25 2.85057 12.1183 2.61615 11.8839C2.38173 11.6495 2.25 11.3315 2.25 11V7C2.24741 6.80189 2.16756 6.61263 2.02747 6.47253C1.88737 6.33244 1.69811 6.25259 1.5 6.25C1.30109 6.25 1.11032 6.32901 0.969666 6.46967C0.829013 6.61032 0.75 6.80109 0.75 7V11C0.75 11.7293 1.03976 12.4288 1.55548 12.9445C2.07121 13.4603 2.77065 13.75 3.5 13.75Z"
          fill={props.fill ?? "white"}
        />
      </svg>
    </>
  );
}
