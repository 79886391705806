import { useQuery, useMutation } from "@tanstack/react-query";
import {
  getApprovalSettings,
  updateApprovalSettings,
} from "api/siteSettings/approvals";
import { ApiResponseType, NotificationType } from "enums";
import { printError, useSearch, useUtilities } from "helpers";
import { useEffect } from "react";
import {
  setApprovalsAction,
  updateApprovalAction,
} from "redux/reducers/siteSettings/approvals";

function useGetApprovalSettings() {
  const { web = null } = useSearch();
  return useQuery(["approvalSettings", web], () => getApprovalSettings(web));
}

export function useApprovals() {
  const { web = null } = useSearch();
  const { data, isLoading, error } = useGetApprovalSettings();
  const {
    dispatch,
    approvals: { approvals, loaded, website_id },
  } = useUtilities();

  useEffect(() => {
    if (data && (web !== website_id || !loaded)) {
      dispatch(setApprovalsAction({ approvals: data.Data, website_id: web }));
    }
  }, [data, loaded, web, website_id]);

  return {
    data: approvals,
    isLoading: isLoading || !loaded,
    error,
  };
}

export function useUpdateApprovalSetting() {
  const { notification, dispatch } = useUtilities();
  return useMutation(updateApprovalSettings, {
    onSuccess: (data) => {
      if (data.StatusCode === ApiResponseType.SUCCESS) {
        dispatch(updateApprovalAction(data.Data));
        notification({
          type: NotificationType.SUCCESS,
          message: "Approval settings updated successfully",
        });
      } else {
        notification({
          type: NotificationType.ERROR,
          message: printError(data.Message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: NotificationType.ERROR,
        message: printError(err.message),
      });
    },
  });
}
