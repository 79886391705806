import PreviewCard from "./PreviewCard";

export default function FiltersList({ filterData, updateFilter }) {
  return (
    <>
      {Object.keys(filterData).length > 0 ? (
        <div className="flex items-center gap-8 flex-wrap">
          {filterData.selectedTags.map((item, index) => (
            <PreviewCard
              key={index}
              color={item.color || "#f15a24"}
              title={item.title}
              icon={true}
              className="m-0"
              cardClass="m-0"
              onCross={() => {
                updateFilter({
                  ...filterData,
                  selectedTags: filterData.selectedTags.filter(
                    (ele) => ele.id !== item.id
                  ),
                });
              }}
            />
          ))}
          {filterData.selectedLibrarySource.map((item, index) => (
            <PreviewCard
              title={item.title}
              dataIcon={item?.icon}
              icon={true}
              className="m-0"
              cardClass="m-0"
              onCross={() => {
                updateFilter({
                  ...filterData,
                  selectedLibrarySource:
                    filterData.selectedLibrarySource.filter(
                      (ele) => ele.id !== item.id
                    ),
                });
              }}
            />
          ))}
        </div>
      ): ""}
    </>
  );
}
