import { useEffect } from "react";
import { createContext, useState, useContext } from "react";

export const UserMenuContext = createContext();

export default function UserMenu({ children }) {
  const [userMenu, setUserMenu] = useState(sessionStorage.getItem("userMenu") ?? 'General');
  useEffect(() => {
    sessionStorage.setItem("userMenu", userMenu);
  }, [userMenu]);
  return (
    <UserMenuContext.Provider value={{ userMenu, setUserMenu }}>
      {children}
    </UserMenuContext.Provider>
  );
}

export function useUserMenu() {
  return useContext(UserMenuContext);
}
