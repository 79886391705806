import LeftMenuCard from "leftMenu/LeftMenuCard";
import { usersMenu } from "staticData";
import { ModulePrefixes } from "enums";
import { useUserLimitReached } from "hooks/plan";
import {
  LimitReachedCard,
  Loader,
  MainButton,
  ModuleLimitDetail,
} from "commonUI";
import { BillingIcon } from "allIcons";
import { useUserPermissions } from "hooks/user";
import { isEqual } from "helpers";

export default function UserMenu() {
  const { isLoading, isLimitReached, usagePercentage, totalUsers, members } =
    useUserLimitReached();
  const { app_users_view_users } = useUserPermissions();
  return (
    <>
      <div className="card-div">
        {usersMenu(app_users_view_users).map((item, index) => (
          <LeftMenuCard
            key={`${index}-${item.module}`}
            {...item}
            prefix={ModulePrefixes.USERS}
            link={`${ModulePrefixes.USERS}/${item.module}`}
            mathcingTab={app_users_view_users ? "roles" : "team-members"}
          />
        ))}
        {isLoading ? (
          ""
        ) : (
          <div className="users-limit-card">
            <Loader show={isLoading} loader="block" />
            <LimitReachedCard
              show={isLimitReached}
              title="User Limit Reached"
              description="You've reached the maximum number of users allowed under your current plan. To add more users, please consider upgrading your account."
            />
            <ModuleLimitDetail
              title="Users"
              description={`${members} of ${
                isEqual(totalUsers, 10000000000)
                  ? "Unlimited users"
                  : `${totalUsers} (${Number(usagePercentage).toFixed(
                      2
                    )}% Used)`
              }`}
              progress={usagePercentage}
            />
            <a
              href="https://wpsitelauncher.com/customer-dashboard/?action=index&model=subscription"
              target="_blank"
              rel="noreferrer"
            >
              <MainButton>
                <BillingIcon fill="white" />
                Manage Plan
              </MainButton>
            </a>
          </div>
        )}
      </div>
    </>
  );
}
