

export const pageTitles = {
    sites: {
      title: "Sites",
      explainerText:
        "Here you can manage all the WordPress sites associated with your account, view storage limits, and access the API key.",
    },
    "template-library": {
      title: "Template Library",
      explainerText:
        "These are WP SiteLauncher templates that are saved to your account for future use.",
    },
    users: {
      title: "Users",
      explainerText:
        "These are WP SiteLauncher users and their associated roles, groups, and companies that are saved to your account for future use.",
    },
    "site-settings": {
      title: "Site Template",
      explainerText:
        "Manage the users, settings, user preferences, and saved templates that will be configured by default for all new sites where the WP SiteLauncher is installed.",
    },
    "": {
      title: "Sites",
      explainerText:
        "Here you can manage all the WordPress sites associated with your account, view storage limits, and access the API key.",
    },
  };