import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "helpers";

const initialState = {
  phases: [],
  loaded: false,
  defaultSite: {
    phases: [],
    loaded: false,
    website_id: null,
  },
};

const phasesSlice = createSlice({
  name: "phases",
  initialState,
  reducers: {
    getAllTemplatePhases: (state, action) => {
      state.phases = action.payload;
      state.loaded = true;
    },
    addTemplatePhase: (state, action) => {
      state.phases.unshift(action.payload);
    },
    deleteTemplatePhase: (state, action) => {
      state.phases = state.phases.filter((_) => !isEqual(_.id, action.payload));
    },
    updateTemplatePhase: (state, action) => {
      const index = state.phases.findIndex((_) =>
        isEqual(_.id, action.payload.id)
      );
      if (index !== -1) {
        for (const key in action.payload) {
          state.phases[index][key] = action.payload[key];
        }
      }
      state.defaultSite.phases = state.defaultSite.phases.map((_) => {
        if (isEqual(_.id, action.payload.id)) {
          for (const key in action.payload) {
            _[key] = action.payload[key];
          }
        }
        return _;
      });
    },
    getAllDefaultPhases: (state, action) => {
      const { phases = [], website_id } = action.payload;
      state.defaultSite.phases = phases;
      state.defaultSite.loaded = true;
      state.defaultSite.website_id = website_id;
    },
    addDefaultPhase: (state, action) => {
      const index = state.phases.findIndex((_) =>
        isEqual(_.id, action.payload.id)
      );
      if (!isEqual(index, -1)) {
        state.phases[index].default_tem = "1";
        state.defaultSite.phases.push(state.phases[index]);
      } else {
        state.defaultSite.phases.push(action.payload);
      }
    },
    deleteDefaultPhase: (state, action) => {
      const index = state.phases.findIndex((_) =>
        isEqual(_.id, action.payload)
      );
      if (!isEqual(index, -1)) {
        state.phases[index].default_tem = "0";
      }
      state.defaultSite.phases = state.defaultSite.phases.filter(
        (_) => !isEqual(_.id, action.payload)
      );
    },
  },
});

export const {
  getAllTemplatePhases,
  addTemplatePhase,
  deleteTemplatePhase,
  updateTemplatePhase,
  getAllDefaultPhases,
  addDefaultPhase,
  deleteDefaultPhase,
} = phasesSlice.actions;

export default phasesSlice.reducer;
