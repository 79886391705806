import { axiosInstance } from "helpers";

export const deleteSite = async (data) => {
  return axiosInstance
    .post("delete_website", {
      user_id: localStorage.getItem("user_id"),
      ...data,
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
};
