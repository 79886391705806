export default function FormFieldOptionIcon(props) {
  return (
    <>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${props.className ?? ""}`}
      >
        <path
          d="M7 13.75C5.2106 13.7474 3.49528 13.0353 2.22998 11.77C0.964683 10.5047 0.252645 8.7894 0.25 7C0.252645 5.2106 0.964683 3.49528 2.22998 2.22998C3.49528 0.964683 5.2106 0.252645 7 0.25C8.7894 0.252645 10.5048 0.964683 11.7701 2.22998C13.0354 3.49528 13.7474 5.2106 13.75 7C13.7474 8.7894 13.0354 10.5047 11.7701 11.77C10.5048 13.0353 8.7894 13.7474 7 13.75ZM7 1.75C5.60843 1.75264 4.27464 2.30666 3.29065 3.29065C2.30666 4.27464 1.75264 5.60843 1.75 7C1.75264 8.39157 2.30666 9.72536 3.29065 10.7094C4.27464 11.6933 5.60843 12.2474 7 12.25C8.39157 12.2474 9.72542 11.6933 10.7094 10.7094C11.6934 9.72536 12.2474 8.39157 12.25 7C12.2474 5.60843 11.6934 4.27464 10.7094 3.29065C9.72542 2.30666 8.39157 1.75264 7 1.75Z"
          fill={props.fill ?? "white"}
        />
      </svg>
    </>
  );
}
