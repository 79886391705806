import {
  RightMainContainer,
  PrimaryHeading,
  ListIconWrapper,
  ListCard,
  InputField,
  HeaderContainer,
  BodyContainer,
  MainButton,
  TemplateNotFound,
  LoadingListCards,
  Loader,
  FiltersList,
} from "commonUI";
import { NotesIcon, AddIcon } from "allIcons";
import { Children, useDeferredValue, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useAddNoteTemplate,
  useDeleteNoteTemplate,
  useTemplateNotes,
} from "api/templateLibrary/notes";
import { parse, search } from "helpers";
import { useUserPermissions } from "hooks/user";
import { libraryItemFiltering, libraryItemSorting } from "filterAndSort/templateLibrary";
import { useTags } from "api/siteSettings/Tags";

export default function Notes() {
  const {
    data: notes,
    isLoading: notesLoading,
    error: notesError,
  } = useTemplateNotes();

  const { mutate: deleteMutation, isLoading: deleteMutationIsLoading } =
    useDeleteNoteTemplate();
  const { mutate: addNote, isLoading: addingNote } = useAddNoteTemplate();
  const { add_new_templates, edit_templates, delete_templates } = useUserPermissions();
  const { data: tags, isLoading: tagsLoading, error: tagsError } = useTags();
  const [searchText, setSearchText] = useState("");
  const defferedSearch = useDeferredValue(searchText);
  const [searchResult, setSearchResult] = useState([]);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    setSearchResult(notes.filter((task) => search(task.title, defferedSearch)));
  }, [defferedSearch, notes]);

  const applySorting = (order, orderBy) => {
    setSearchResult((prevSearchResult) =>
      libraryItemSorting(order, orderBy, prevSearchResult)
    );
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setSearchResult(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        notes
      )
    );
  };

  return (
    <>
      <RightMainContainer>
        {deleteMutationIsLoading || addingNote ? <Loader loader="block" /> : ""}
        <HeaderContainer>
          <PrimaryHeading>Notes</PrimaryHeading>
          {add_new_templates ? <Link to="/template-library/notes/add">
            <MainButton>
              <AddIcon fill="white" />
              Add
            </MainButton>
          </Link> : ""}
        </HeaderContainer>
        <BodyContainer>
        <InputField
            placeholder="Search"
            searchIcon={true}
            filter={true}
            value={searchText}
            setValue={(val) => setSearchText(val)}
            tags={tags ?? []}
            onApplyFilter={applyFilter}
            applySorting={applySorting}
            filterData={filterData}
            showLibrarySource={false}
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          {notesError ? (
            <p className="body-text text-center">Can't Load notes right now</p>
          ) : notesLoading ? (
            <LoadingListCards />
          ) : searchResult.length > 0 ? (
            Children.toArray(
              searchResult.map((note, index) => (
                <ListCard
                  data={note.title}
                  duplicate={add_new_templates}
                  editIcon={edit_templates}
                  link={edit_templates ? `/template-library/notes/${note.id}` : ""}
                  strictLink={true}
                  onDuplicate={(data) => {
                    addNote({
                      title: `Copy of ${note.title}`,
                      message: note.message,
                      tags: parse(note.tags),
                      requested_from: parse(note.requested_from),
                    });
                  }}
                  deleteIcon={!delete_templates}
                  onDelete={() => {
                    if (!delete_templates) return;
                    deleteMutation(note.id);
                  }}
                  icon={
                    <ListIconWrapper>
                      <NotesIcon fill="white" />
                    </ListIconWrapper>
                  }
                  deleteTitle="Delete This Template?"
                  deleteButtonText="Confirm Delete"
                  deleteMessage="You are about to delete this template from your account. This action cannot be undone! All template content will be permanently removed. Are you sure you want to do this?"
                />
              ))
            )
          ) : (
            <TemplateNotFound label="Notes" link={add_new_templates ? "add" : ""} linkLabel="Note" />
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
