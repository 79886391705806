export default function omit(keys, obj) {
  return Object.keys(obj)
    .filter((key) => !keys.includes(key))
    .reduce((acc, key) => ({ ...acc, [key]: obj[key] }), {});
}

export const maskKey = (key="") => {
  const firstThree = key.slice(0, 4);
  const lastFive = key.slice(-5);

  return firstThree + "*".repeat(key.length - 9) + lastFive;
};
