import { SecondaryHeading } from "commonUI";
import { useState, useEffect } from "react";
import { SuccessIcon, ErrorIcon, CrossIcon } from "allIcons";

export default function Notification(props) {
  const [exit, setExit] = useState(false);
  const [width, setWidth] = useState(0);
  const [intervalID, setIntervalID] = useState(null);

  const handleStartTimer = () => {
    const id = setInterval(() => {
      setWidth((prev) => {
        if (prev < 100) {
          return prev + 0.5;
        }

        clearInterval(id);
        return prev;
      });
    }, 20);

    setIntervalID(id);
  };

  const handlePauseTimer = () => {
    clearInterval(intervalID);
  };

  const handleCloseNotification = () => {
    handlePauseTimer();
    setExit(true);
    setTimeout(() => {
      props.dispatch({
        type: "REMOVE_NOTIFICATION",
        id: props.id,
      });
    }, 400);
  };

  useEffect(() => {
    if (width === 100) {
      // Close notification
      handleCloseNotification();
    }
  }, [width]);

  useEffect(() => {
    handleStartTimer();
  }, []);
  return (
    <>
      <div
        onMouseEnter={handlePauseTimer}
        onMouseLeave={handleStartTimer}
        className={`notification ${exit ? "hidden" : "open"}`}
      >
        <div className="flex items-center gap-10">
          <div
            className="notification-icon"
            style={{
              background:
                props.type.toUpperCase() !== "SUCCESS" ? "#F12424" : "#00D263",
            }}
          >
            {props.type.toUpperCase() !== "SUCCESS" ? (
              <ErrorIcon />
            ) : (
              <SuccessIcon />
            )}
          </div>
          <SecondaryHeading className="text-13">
            {props.message}
          </SecondaryHeading>
        </div>

        <CrossIcon
          className="w-4 h-4 cursor-pointer mr-1 min-w-4 min-h-4"
          fill="#4C5866"
          onClick={handleCloseNotification}
        />
      </div>
    </>
  );
}
