export default function VideoIcon(props) {
  return (
    <>
      <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${props.className ?? ""}`}
        onClick={props.onClick}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.75 6.99951C0.75 12.5495 2.21 13.7495 9 13.7495C15.78 13.7495 17.25 12.5495 17.25 6.99951C17.25 1.44951 15.79 0.249512 9 0.249512C2.21 0.249512 0.75 1.44951 0.75 6.99951ZM2.25 6.99951C2.25 2.54951 2.75 1.74951 9 1.74951C15.25 1.74951 15.75 2.53951 15.75 6.99951C15.75 11.4595 15.25 12.2495 9 12.2495C2.75 12.2495 2.25 11.4495 2.25 6.99951ZM7.14002 10.6597C7.25089 10.7186 7.37446 10.7493 7.5 10.7495C7.64959 10.7384 7.79245 10.6827 7.91004 10.5896L12.41 7.5896C12.5135 7.51997 12.5984 7.42639 12.6577 7.31665C12.7169 7.20691 12.7486 7.08441 12.75 6.95972C12.7487 6.83638 12.717 6.71508 12.6577 6.60693C12.5984 6.49879 12.5133 6.40702 12.41 6.3396L7.91004 3.3396C7.79332 3.27102 7.6604 3.23486 7.52503 3.23486C7.38966 3.23486 7.25673 3.27102 7.14002 3.3396C7.02235 3.40518 6.92424 3.50097 6.85572 3.61694C6.78719 3.73291 6.75069 3.86481 6.75 3.99951V9.99951C6.74963 10.1344 6.78567 10.2671 6.85431 10.3833C6.92295 10.4995 7.02166 10.5949 7.14002 10.6597ZM8.25 8.59961V5.39966L10.65 6.99951L8.25 8.59961Z"
          fill={props.fill ?? "white"}
        />
      </svg>
    </>
  );
}
