import { useEffect, useState } from "react";
import axios from "axios";

const usePluginSearch = (query, pageNumber) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [plugins, setPlugins] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setPlugins([]);
  }, [query]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "GET",
      url: "https://api.wordpress.org/plugins/info/1.2/",
      params: query
        ? {
            action: "query_plugins",
            "request[search]": query,
            "request[page]": pageNumber,
          }
        : {
            action: "query_plugins",
            "request[page]": pageNumber,
          },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        if (res?.data?.plugins?.length > 0) {
          setPlugins((prev) => [...new Set([...prev, ...res?.data?.plugins])]);
          setHasMore(res.data.info.page < res.data.info.pages);
          setLoading(false);
        } else {
          setHasMore(false);
          setLoading(false);
          setError(true);
        }
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => cancel();
  }, [query, pageNumber]);

  return { loading, error, plugins, hasMore };
};

export default usePluginSearch;
