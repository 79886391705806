import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "helpers";

const initialState = {
  sites: {},
  loaded: false,
};

const sitesSlice = createSlice({
  name: "sites",
  initialState,
  reducers: {
    getAllSites(state, action) {
      state.sites = action.payload;
      state.loaded = true;
    },
    updateSite(state, action) {
      state.sites.site_details.total_sites = state.sites.site_details.total_sites.map((site) => {
        if (isEqual(site.id, action.payload.id)) {
          return action.payload;
        }
        return site;
      });
    },
    unloadSites(state, action) {
      state.loaded = false;
    }
  },
});

export const { getAllSites, updateSite, unloadSites } = sitesSlice.actions;

export default sitesSlice.reducer;
