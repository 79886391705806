
export default function LaunchPadIcon({ fill, ...props }) {
    return (
        <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M16.9601 5.91032L10.3601 0.72032C9.97298 0.414244 9.49353 0.248545 9.00007 0.250318C8.50413 0.241804 8.02038 0.40424 7.63007 0.710325L1.03009 5.91032C0.874902 6.03585 0.775326 6.21739 0.752873 6.41572C0.73042 6.61406 0.786896 6.81328 0.9101 6.97032C1.03385 7.12486 1.21365 7.22419 1.41034 7.24667C1.60704 7.26915 1.80466 7.21295 1.96009 7.09031L2.88007 6.37033V13.9403C2.88007 14.4044 3.06446 14.8496 3.39264 15.1778C3.72083 15.506 4.16594 15.6903 4.63007 15.6903H13.6301C14.0942 15.6903 14.5393 15.506 14.8675 15.1778C15.1957 14.8496 15.3801 14.4044 15.3801 13.9403V6.61032L16.0301 7.12033C16.1637 7.2262 16.3296 7.2827 16.5001 7.28032C16.6129 7.27973 16.7243 7.25436 16.8262 7.20598C16.9282 7.15759 17.0183 7.08737 17.0901 7.00032C17.1552 6.9211 17.2037 6.82952 17.2325 6.73109C17.2613 6.63266 17.2699 6.52942 17.2578 6.42758C17.2456 6.32574 17.213 6.2274 17.1618 6.1385C17.1107 6.04961 17.042 5.97199 16.9601 5.91032ZM13.8801 13.9103C13.8801 13.9766 13.8537 14.0402 13.8068 14.0871C13.7599 14.134 13.6964 14.1603 13.6301 14.1603H4.63007C4.56377 14.1603 4.5002 14.134 4.45331 14.0871C4.40643 14.0402 4.38007 13.9766 4.38007 13.9103V5.16032L8.57007 1.86032C8.6951 1.7622 8.85134 1.71249 9.01008 1.72032C9.16429 1.72227 9.31426 1.7711 9.44007 1.86032L13.8901 5.37033L13.8801 13.9103Z"
                fill={fill ?? "white"}
            />
        </svg>
    );
}
