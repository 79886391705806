export const defaultNotificationSetting = {
  an_assigned_user_views_an_item_for_the_first_time_email: false,
  an_assigned_user_views_an_item_for_the_first_time_website: true,
  "a_user_is_@Mentioned_email": true,
  "a_user_is_@Mentioned_website": true,
  an_item_is_added_email: true,
  an_item_is_added_website: true,
  the_title_is_updated_email: true,
  the_title_is_updated_website: true,
  a_user_is_assigned_email: true,
  a_user_is_assigned_website: true,
  a_user_is_unassigned_email: true,
  a_user_is_unassigned_website: true,
  the_status_is_updated_email: true,
  the_status_is_updated_website: true,
  the_priority_is_updated_email: true,
  the_priority_is_updated_website: true,
  the_due_date_is_updated_email: true,
  the_due_date_is_updated_website: true,
  "7_days_before_due_date_email": true,
  "7_days_before_due_date_website": true,
  "3_days_before_due_date_email": true,
  "3_days_before_due_date_website": true,
  "1_day_before_due_date_email": true,
  "1_day_before_due_date_website": true,
  on_due_date_email: true,
  on_due_date_website: true,
  every_day_after_due_date_email: true,
  every_day_after_due_date_website: true,
  a_reply_is_added_email: true,
  a_reply_is_added_website: true,
  the_tags_is_updated_website: true,
  the_tags_is_updated_email: true,
};
