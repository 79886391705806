import { ModulePrefixes } from "enums";
import LeftMenuCard from "leftMenu/LeftMenuCard";
import { libraryMenu } from "staticData";

export default function LibraryMenu({ siteTemplate }) {
  return (
    <>
      <div className="card-div">
        {libraryMenu(siteTemplate).map((item, index) => (
          <LeftMenuCard
            key={`${index}-${item.module}`}
            {...item}
            prefix={
              siteTemplate
                ? ModulePrefixes.SITE_SETTINGS_LIBRARY_ITEM
                : ModulePrefixes.TEMPLATE_LIBRARY
            }
            link={`${
              siteTemplate
                ? ModulePrefixes.SITE_SETTINGS_LIBRARY_ITEM
                : ModulePrefixes.TEMPLATE_LIBRARY
            }/${item.module}`}
          />
        ))}
      </div>
    </>
  );
}
