import { HexColorPicker } from "react-colorful";
import { useState } from "react";
import { OutsideClickHandler } from "commonUI";
import { ColorPickerIcon } from "allIcons";

export default function ColorPicker({ setValue, value }) {
  const [showColor, setShowColor] = useState(false);
  return (
    <>
      <div className=" flex items-center gap-20 mt-2 mb-2">
        <label
          htmlFor="color-picker"
          className="relative rounded-full"
          style={{
            boxShadow:
              "0 0 0 5px #fff, 0 2px 12px 5px rgb(0 136 225 / 40%), inset 0 0 5px 0 rgb(0 0 0 / 20%)",
            height: "40px",
            width: "40px",
            filter: "drop-shadow(0px 5px 30px rgba(43, 135, 218, 0.40))",
          }}
        >
          <div
            onClick={() => setShowColor(!showColor)}
            className={` rounded-full relative flex items-center justify-center group cursor-pointer`}
            style={{
              background: `${value}`,
              height: "40px",
              width: "40px",
            }}
            title={`${value}`}
          >
            {showColor ? (
              <div className="absolute top-0 left-0 z-1 w-full h-full" />
            ) : (
              ""
            )}
            <ColorPickerIcon
              style={{ filter: "drop-shadow(0 0 6px rgba(0,0,0,.33))" }}
            />
            <OutsideClickHandler
              className={`absolute top-full left-2 ${
                showColor ? "max-box-full" : "color-box"
              } z-10 bg-white mt-2`}
              onClickOutside={(e) => {
                e.stopPropagation();
                setShowColor(false);
              }}
            >
              <div className="z-20" onClick={(e) => e.stopPropagation()}>
                <HexColorPicker color={value} onChange={setValue} />
              </div>
            </OutsideClickHandler>
          </div>
        </label>
        <div className=" flex items-center gap-5">
          <div
            className=" cursor-pointer rounded-full relative group"
            style={{
              boxShadow: "inset 0 0 5px 0 rgb(0 0 0 / 20%)",
              height: "25px",
              width: "25px",
              background: "black",
            }}
            onClick={() => setValue("#000000")}
            title={`#000000`}
          ></div>
          <div
            className=" cursor-pointer rounded-full  relative group"
            style={{
              boxShadow: "inset 0 0 5px 0 rgb(0 0 0 / 20%)",
              height: "25px",
              width: "25px",
              background: "white",
            }}
            onClick={() => setValue("#FFFFFF")}
            title={`#FFFFFF`}
          ></div>
          <div
            className=" cursor-pointer rounded-full relative group"
            style={{
              boxShadow: "inset 0 0 5px 0 rgb(0 0 0 / 20%)",
              height: "25px",
              width: "25px",
              background: "#e02b20",
            }}
            onClick={() => setValue("#e02b20")}
            title={`#e02b20`}
          ></div>
          <div
            className=" cursor-pointer rounded-full relative group"
            style={{
              boxShadow: "inset 0 0 5px 0 rgb(0 0 0 / 20%)",
              height: "25px",
              width: "25px",
              background: "#e09900",
            }}
            onClick={() => setValue("#e09900")}
            title={`#e09900`}
          ></div>
          <div
            className=" cursor-pointer rounded-full relative group"
            style={{
              boxShadow: "inset 0 0 5px 0 rgb(0 0 0 / 20%)",
              height: "25px",
              width: "25px",
              background: "#edf000",
            }}
            onClick={() => setValue("#edf000")}
            title={`#edf000`}
          ></div>
          <div
            className=" cursor-pointer rounded-full relative group"
            style={{
              boxShadow: "inset 0 0 5px 0 rgb(0 0 0 / 20%)",
              height: "25px",
              width: "25px",
              background: "#7cda24",
            }}
            onClick={() => setValue("#7cda24")}
            title={`#7cda24`}
          ></div>
          <div
            className=" cursor-pointer rounded-full relative group"
            style={{
              boxShadow: "inset 0 0 5px 0 rgb(0 0 0 / 20%)",
              height: "25px",
              width: "25px",
              background: "#0c71c3",
            }}
            onClick={() => setValue("#0c71c3")}
            title={`#0c71c3`}
          ></div>
          <div
            className=" cursor-pointer rounded-full relative group"
            style={{
              boxShadow: "inset 0 0 5px 0 rgb(0 0 0 / 20%)",
              height: "25px",
              width: "25px",
              background: "#8300e9",
            }}
            onClick={() => setValue("#8300e9")}
            title={`#8300e9`}
          ></div>
          <div
            className=" cursor-pointer rounded-full  bg-no-repeat bg-center bg-cover relative group"
            onClick={() => setValue("transparent")}
            style={{
              boxShadow: "inset 0 0 5px 0 rgb(0 0 0 / 20%)",
              backgroundImage: `url(${require("assets/no-color.svg").default})`,
              height: "25px",
              width: "25px",
              backgroundColor: "transparent",
            }}
            title={`transparent`}
          ></div>
        </div>
      </div>
    </>
  );
}
