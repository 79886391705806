import { SaveIcon } from "allIcons";
import {
  BodyContainer,
  HeaderContainer,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
  SelectField,
  ToggleCard,
} from "commonUI";
import { useState } from "react";

export default function Interface() {
  const [values, setValues] = useState({
    open_all_items_by_default_in: "Same Docked Window",
    open_items_from_docked_window_in: "Same Docked Window",
    open_items_from_floating_windows_in: "Same Floating Window",
    show_comments_outline: true,
  });
  return (
    <>
      <RightMainContainer>
        <HeaderContainer>
          <PrimaryHeading>Interface</PrimaryHeading>
          <MainButton>
            <SaveIcon /> Save Changes
          </MainButton>
        </HeaderContainer>
        <BodyContainer>
          <SelectField
            label="Open All Items By Default In"
            placeholder="Same Docked Window"
            value={values.open_all_items_by_default_in}
            setValue={(value) =>
              setValues({ ...values, open_all_items_by_default_in: value })
            }
          >
            <option value="Same Docked Window">Same Docked Window</option>
            <option value="Same Floating Window">Same Floating Window</option>
            <option value="New Floating Window">New Floating Window</option>
          </SelectField>
          <SelectField
            label="Open Items From Docked Window In"
            placeholder="Same Docked Window"
            value={values.open_items_from_docked_window_in}
            setValue={(value) =>
              setValues({ ...values, open_items_from_docked_window_in: value })
            }
          >
            <option value="Same Docked Window">Same Docked Window</option>
            <option value="Same Floating Window">Same Floating Window</option>
          </SelectField>
          <SelectField
            label="Open Items From Floating Windows In"
            placeholder="Same Floating Window"
            value={values.open_items_from_floating_windows_in}
            setValue={(value) =>
              setValues({
                ...values,
                open_items_from_floating_windows_in: value,
              })
            }
          >
            <option value="Same Floating Window">Same Floating Window</option>
            <option value="New Floating Window">New Floating Window</option>
          </SelectField>

          <ToggleCard
            label="Show Comments Outline"
            value={values.show_comments_outline}
            setValue={(value) =>
              setValues({ ...values, show_comments_outline: value })
            }
          />
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
