import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  smtp: [],
  loaded: false,
  website_id: null,
};

const smtpSlice = createSlice({
  name: "smtp",
  initialState,
  reducers: {
    setSmtpSetting(state, action) {
      const { website_id, smtp } = action.payload;
      state.smtp = smtp;
      state.loaded = true;
      state.website_id = website_id;
    },
    updateSmtp(state, action) {
      const index = state.smtp.findIndex(
        (_) => _.website_id === action.payload.website_id
      );
      if (index !== -1) {
        state.smtp[index] = action.payload;
      }
    },
  },
});

export const { setSmtpSetting, updateSmtp } = smtpSlice.actions;

export default smtpSlice.reducer;
