export default function PluginListIcon({ fill = "white", ...props }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.727273 1.45455H9.31394C9.71636 1.45455 10.0412 1.1297 10.0412 0.727273C10.0412 0.324849 9.71636 0 9.31394 0H0.727273C0.324849 0 0 0.324849 0 0.727273C0 1.1297 0.324849 1.45455 0.727273 1.45455ZM0.727273 4.88727H9.31394C9.71636 4.88727 10.0412 4.56242 10.0412 4.16C10.0412 3.75758 9.71636 3.43273 9.31394 3.43273H0.727273C0.324849 3.43273 0 3.75758 0 4.16C0 4.56242 0.324849 4.88727 0.727273 4.88727ZM5.01818 8.32H0.727273C0.324849 8.32 0 7.99515 0 7.59273C0 7.1903 0.324849 6.86545 0.727273 6.86545H5.01818C5.42061 6.86545 5.74545 7.1903 5.74545 7.59273C5.74545 7.99515 5.42061 8.32 5.01818 8.32ZM13.7988 7.75758L15.1467 9.48364C16.5188 11.2339 16.2036 13.7745 14.4533 15.1467C13.7358 15.7042 12.8727 16 11.9806 16C11.8109 16 11.6461 15.9903 11.4764 15.9709C10.4048 15.8352 9.4497 15.297 8.7903 14.4485L7.44242 12.7273C7.32121 12.5721 7.26788 12.383 7.29212 12.1891C7.31636 12 7.41333 11.8255 7.56848 11.7042L8.14061 11.2533L6.76364 9.48848C6.51636 9.17333 6.5697 8.71758 6.8897 8.46545C7.20485 8.22303 7.66061 8.27636 7.90788 8.59151L9.2897 10.3564L11.0352 8.99394L9.63879 7.2097C9.39151 6.8897 9.4497 6.43394 9.76485 6.18667C10.08 5.93939 10.5406 5.99758 10.7879 6.31273L12.1842 8.09697L12.7806 7.63152C13.0958 7.38424 13.5515 7.44242 13.7988 7.75758ZM11.6558 14.5261C12.3394 14.6085 13.0133 14.4242 13.5564 13.9976C14.6764 13.1248 14.8752 11.5006 14.0024 10.3758L13.1006 9.22667L9.03758 12.4024L9.93455 13.5564C10.3564 14.0945 10.9673 14.4388 11.6558 14.5261Z"
        fill={fill}
      />
    </svg>
  );
}
