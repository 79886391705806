export default function CrossIcon({ onClick, className, fill, size }) {
  function HandleClick() {
    onClick && onClick();
  }
  return (
    <>
      <svg
        width={size ?? "14"}
        height={size ?? "14"}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`cursor-pointer ${className ?? ""}`}
        onClick={HandleClick}
      >
        <path
          d="M13.0298 11.97C13.1702 12.1107 13.2491 12.3012 13.2491 12.5C13.2491 12.6988 13.1702 12.8894 13.0298 13.03C12.9605 13.1001 12.878 13.1557 12.787 13.1935C12.696 13.2313 12.5983 13.2505 12.4998 13.25C12.4012 13.2505 12.3036 13.2313 12.2126 13.1935C12.1216 13.1557 12.039 13.1001 11.9698 13.03L6.96979 8.03003L1.96979 13.03C1.90054 13.1001 1.818 13.1557 1.72699 13.1935C1.63598 13.2313 1.5383 13.2505 1.43976 13.25C1.34122 13.2505 1.2436 13.2313 1.15259 13.1935C1.06158 13.1557 0.979034 13.1001 0.90979 13.03C0.76934 12.8894 0.69043 12.6988 0.69043 12.5C0.69043 12.3012 0.76934 12.1107 0.90979 11.97L5.90979 6.97003L0.90979 1.97003C0.76934 1.82941 0.69043 1.63875 0.69043 1.44C0.69043 1.24125 0.76934 1.05066 0.90979 0.910034C1.05042 0.769584 1.24101 0.690674 1.43976 0.690674C1.63851 0.690674 1.82916 0.769584 1.96979 0.910034L6.96979 5.91003L11.9698 0.910034C12.1104 0.769584 12.301 0.690674 12.4998 0.690674C12.6985 0.690674 12.8892 0.769584 13.0298 0.910034C13.1702 1.05066 13.2491 1.24125 13.2491 1.44C13.2491 1.63875 13.1702 1.82941 13.0298 1.97003L8.02979 6.97003L13.0298 11.97Z"
          fill={fill ?? "#A3B0C2"}
        />
      </svg>
    </>
  );
}
