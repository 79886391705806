import { Route, Routes } from "react-router-dom";
import Task from "./Task/index";
import AddEditTask from "./Task/AddEditTask";
import CheckList from "./Checklist/index";
import AddEditChecklist from "./Checklist/AddEditChecklist";
import Glossary from "./Glossary/index";
import AddEditGlossary from "./Glossary/AddEditGlossary";
import Tutorials from "./Tutorials/index";
import AddEditTutorial from "./Tutorials/AddEditTutorial";
import Courses from "./Courses/index";
import AddEditCourse from "./Courses/AddEditCourse";
import ContentRequestTemplates from "./ContentRequestTemplates/index";
import AddEditContentRequestTemplate from "./ContentRequestTemplates/AddEditContentRequestTemplate";
import ContentRequestItems from "./ContentRequestItems/index";
import AddEditContentRequestItem from "./ContentRequestItems/AddEditContentRequestItem";
import FormTemplates from "./FormTemplates/index";
import FormSections from "./FormSections/index";
import FormFields from "./FormFields/index";
import FormFieldOptions from "./FormFieldOptions/index";
import ScreenRecordings from "./ScreenRecordings/index";
import AddEditFormTemplate from "./FormTemplates/AddEditFormTemplate";
import AddEditFormSection from "./FormSections/AddEditFormSection";
import AddEditFormField from "./FormFields/AddEditFormField";
import AddEditFormFieldOption from "./FormFieldOptions/AddEditFormFieldOption";
import AddEditScreenRecording from "./ScreenRecordings/AddEditScreenRecording";
import Services from "./Services/index";
import AddEditService from "./Services/AddEditService";
import ProposalTemplates from "./ProposalTemplates/index";
import AddEditProposalTemplate from "./ProposalTemplates/AddEditProposalTemplate";
import ContractTemplates from "./ContractTemplates/index";
import AddEditContract from "./ContractTemplates/AddEditContract";
import Phases from "./Phases";
import AddEditPhase from "./Phases/AddEditPhase";
import Plugins from "./Plugins";
import PluginLists from "./PluginLists";
import { InvalidURL } from "commonUI";
import AddEditPluginList from "./PluginLists/AddEditPluginList";
import Notes from "./Notes";
import AddEditNotes from "./Notes/AddEditNotes";
import AddWPPlugin from "./Plugins/AddWPPlugin";
import EditPlugin from "./Plugins/EditPlugin";

export default function Library() {
  return (
    <>
      <Routes>
        <Route exact path={`/`}>
          <Route exact index element={<Task />} />
          <Route exact path={`/tasks`}>
            <Route exact index element={<Task />} />
            <Route exact path="/tasks/:id" element={<AddEditTask />} />
          </Route>
          <Route exact path={`/checklists`}>
            <Route exact index element={<CheckList />} />
            <Route
              exact
              path="/checklists/:id"
              element={<AddEditChecklist />}
            />
          </Route>
          <Route exact path={`/phases`}>
            <Route exact index element={<Phases />} />
            <Route exact path="/phases/:id" element={<AddEditPhase />} />
          </Route>
        </Route>
        <Route exact path={`/glossary`}>
          <Route exact index element={<Glossary />} />
          <Route exact path="/glossary/:id" element={<AddEditGlossary />} />
        </Route>
        <Route exact path={`/tutorials`}>
          <Route exact index element={<Tutorials />} />
          <Route exact path="/tutorials/:id" element={<AddEditTutorial />} />
        </Route>
        <Route exact path={`/courses`}>
          <Route exact index element={<Courses />} />
          <Route exact path="/courses/:id" element={<AddEditCourse />} />
        </Route>
        <Route exact path={`/content-request-templates`}>
          <Route exact index element={<ContentRequestTemplates />} />
          <Route
            exact
            path="/content-request-templates/:id"
            element={<AddEditContentRequestTemplate />}
          />
        </Route>
        <Route exact path={`/content-request-items`}>
          <Route exact index element={<ContentRequestItems />} />
          <Route
            exact
            path="/content-request-items/:id"
            element={<AddEditContentRequestItem />}
          />
        </Route>
        <Route exact path={`/services`}>
          <Route exact index element={<Services />} />
          <Route exact path="/services/:id" element={<AddEditService />} />
        </Route>
        <Route exact path={`/proposals`}>
          <Route exact index element={<ProposalTemplates />} />
          <Route
            exact
            path="/proposals/:id"
            element={<AddEditProposalTemplate />}
          />
        </Route>
        <Route exact path={`/contract-template`}>
          <Route exact index element={<ContractTemplates />} />
          <Route
            exact
            path="/contract-template/:id"
            element={<AddEditContract />}
          />
        </Route>
        <Route exact path={`/forms`}>
          <Route exact index element={<FormTemplates />} />
          <Route
            exact
            path="/forms/:id"
            element={<AddEditFormTemplate />}
          />
        </Route>
        <Route exact path={`/form-sections`}>
          <Route exact index element={<FormSections />} />
          <Route
            exact
            path="/form-sections/:id"
            element={<AddEditFormSection />}
          />
        </Route>
        <Route exact path={`/form-fields`}>
          <Route exact index element={<FormFields />} />
          <Route exact path="/form-fields/:id" element={<AddEditFormField />} />
        </Route>
        <Route exact path={`/form-field-options`}>
          <Route exact index element={<FormFieldOptions />} />
          <Route
            exact
            path="/form-field-options/:id"
            element={<AddEditFormFieldOption />}
          />
        </Route>
        <Route exact path={`/screen-recordings`}>
          <Route exact index element={<ScreenRecordings />} />
          <Route
            exact
            path="/screen-recordings/:id"
            element={<AddEditScreenRecording />}
          />
        </Route>
        <Route exact path={`/plugins`}>
          <Route exact index element={<Plugins />} />
          <Route exact path="/plugins/add" element={<AddWPPlugin />} />
          <Route exact path="/plugins/:id" element={<EditPlugin />} />
        </Route>
        <Route exact path={`/plugin-lists`}>
          <Route exact index element={<PluginLists />} />
          <Route
            exact
            path="/plugin-lists/:id"
            element={<AddEditPluginList />}
          />
        </Route>
        <Route exact path={`/notes`}>
          <Route exact index element={<Notes />} />
          <Route exact path="/notes/:id" element={<AddEditNotes />} />
        </Route>
        <Route path={`/*`} element={<InvalidURL />} />
      </Routes>
    </>
  );
}
