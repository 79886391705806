import { isEqual, parse } from "helpers";

export const libraryItemSorting = (
  order,
  orderBy,
  data,
  titleKey = "title"
) => {
  let tempData = [...data];
  if (orderBy === "alphabetical-by-title") {
    return tempData.sort((a, b) => {
      return order === "asc"
        ? a[titleKey].localeCompare(b[titleKey])
        : b[titleKey].localeCompare(a[titleKey]);
    });
  } else if (orderBy === "date-added") {
    return tempData.sort((a, b) => {
      if (a.created_at < b.created_at) return order === "asc" ? -1 : 1;
      else if (a.created_at > b.created_at) return order === "asc" ? 1 : -1;
      else return 0;
    });
  } else if (orderBy) {
    return tempData.sort((a, b) => {
      if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
      else if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
      else return 0;
    });
  }
  return tempData;
};

export const libraryItemFiltering = (
  selectedFilter,
  selectedTags,
  selectedLibrarySource,
  data = [],
  filterKey = "system_status"
) => {
  let dataCopy = [...data];
  let filterTags = selectedTags.map((ele) => ele.id);

  if (selectedTags.length === 0 && selectedLibrarySource.length === 0) {
    return dataCopy;
  }

  return dataCopy.filter((item) => {
    let condition = false;
    if (filterTags.length === 0) {
      condition = true;
    } else {
      condition =
        parse(item.tags)?.findIndex((e) => filterTags.includes(e.id)) > -1;
    }
    let condition1 = false;
    if (selectedLibrarySource.length === 0) {
      condition1 = true;
    } else {
      let selectedFilterStatus = selectedLibrarySource.map(
        (ele) => ele[filterKey]
      );
      condition1 =
        selectedFilterStatus.findIndex((ele) => {
          if (Array.isArray(item[filterKey])) {
            return item[filterKey].includes(ele);
          } else if (
            typeof item[filterKey] === "object" &&
            item[filterKey] !== null
          ) {
            return item[filterKey].id === ele;
          } else if (ele === 0) {
            return (
              isEqual(item[filterKey], ele) || isEqual(item[filterKey], null)
            );
          }
          return isEqual(item[filterKey], ele);
        }) > -1;
    }
    return isEqual(selectedFilter, "and")
      ? condition && condition1
      : condition || condition1;
  });
};
