import { ModulePrefixes } from "enums";
import LeftMenuCard from "leftMenu/LeftMenuCard";
import { useLocation } from "react-router-dom";
import { featureMenu } from "staticData";

export default function FeatureMenu(props) {
  const { search } = useLocation();

  return (
    <>
      <div className="card-div">
        {featureMenu.map((item, index) => (
          <LeftMenuCard
            key={`${index}-${item.module}`}
            {...item}
            prefix={ModulePrefixes.SITE_SETTINGS}
            link={`${ModulePrefixes.SITE_SETTINGS}/${item.module}${
              search ?? ""
            }`}
          />
        ))}
      </div>
    </>
  );
}
