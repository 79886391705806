export default function CompaniesIcon(props) {
  function HandleClick() {
    props.onClick && props.onClick();
  }
  return (
    <>
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${props.className ?? ""}`}
        onClick={HandleClick}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5 2.61L14.87 3.51C15.24 3.57 15.5 3.89 15.5 4.25V14.25C15.5 14.66 15.16 15 14.75 15H0.75C0.34 15 0 14.66 0 14.25V0.75C0 0.33 0.34 0 0.75 0H8.75C9.16 0 9.5 0.33 9.5 0.75V2.61ZM8 1.5H1.5V13.5H8V1.5ZM9.5 13.5H14V4.89L9.5 4.14V13.5ZM2.75 11.75C2.75 12.16 3.09 12.5 3.5 12.5C3.91 12.5 4.25 12.16 4.25 11.75V3.5C4.25 3.09 3.91 2.75 3.5 2.75C3.09 2.75 2.75 3.09 2.75 3.5V11.75ZM12.25 9.5H11.25C10.84 9.5 10.5 9.16 10.5 8.75C10.5 8.34 10.84 8 11.25 8H12.25C12.66 8 13 8.34 13 8.75C13 9.16 12.66 9.5 12.25 9.5ZM11.25 12H12.25C12.66 12 13 11.66 13 11.25C13 10.84 12.66 10.5 12.25 10.5H11.25C10.84 10.5 10.5 10.84 10.5 11.25C10.5 11.66 10.84 12 11.25 12ZM12.25 7H11.25C10.84 7 10.5 6.66 10.5 6.25C10.5 5.84 10.84 5.5 11.25 5.5H12.25C12.66 5.5 13 5.84 13 6.25C13 6.66 12.66 7 12.25 7ZM5.25 11.75C5.25 12.16 5.59 12.5 6 12.5C6.41 12.5 6.75 12.16 6.75 11.75V3.5C6.75 3.09 6.41 2.75 6 2.75C5.59 2.75 5.25 3.09 5.25 3.5V11.75Z"
          fill={props.fill ?? "#0066FF"}
        />
      </svg>
    </>
  );
}
