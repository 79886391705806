import { axiosInstance, useSearch } from "helpers";
import { useQuery } from "@tanstack/react-query";

export async function getAllStatus({ params = "all", website_id = null }) {
  return axiosInstance
    .get(`allStatus`, {
      params: {
        apiKey: localStorage.getItem("apikey"),
        parent_type: params,
        website_id,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export async function deleteStatus(id) {
  return axiosInstance
    .post(`delstatus?status_id=${id}&apiKey=${localStorage.getItem("apikey")}`)
    .then((res) => res.data)
    .catch((err) => err);
}

export function useGetStatus(option) {
  const { web = null } = useSearch();
  return useQuery([`status-${option}`, web], () =>
    getAllStatus({
      params: option,
      website_id: web,
    })
  );
}

export async function addStatus({
  status_title,
  color,
  parent_type,
  system_status,
  website_id,
}) {
  return axiosInstance
    .post(`addstatus`, {
      status_title,
      added_by: localStorage.getItem("user_id"),
      apiKey: localStorage.getItem("apikey"),
      color,
      parent_type,
      system_status,
      website_id,
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export async function updateStatus({ status_title, color, id, website_id }) {
  return axiosInstance
    .post(`updatestatus`, {
      status_id: id,
      status_title,
      color,
      website_id,
      apiKey: localStorage.getItem("apikey"),
    })
    .then((res) => res.data)
    .catch((err) => err);
}
