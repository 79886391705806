import { useState } from "react";
import { CloudDownloadIcon, GroupIcon, AddIcon } from "allIcons";
import {
  BodyContainer,
  HeaderContainer,
  ListCard,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
  ListIconWrapper,
  PopOver,
  LoadingListCards,
  TemplateNotFound,
  PopUpListing,
  AddFromTemplateLibrary,
  Loader,
  InputField,
} from "commonUI";
import {
  useAddDefaultGroup,
  useDefaultGroups,
  useGroups,
  useRemoveDefaultGroup,
} from "api/usersModule/groups";
import { isEqual, useUtilities } from "helpers";

export default function Groups() {
  const { navigate } = useUtilities();
  const [open, setOpen] = useState(false);
  const [showList, setShowList] = useState(false);
  const { data: defaultGroups, isLoading: defaultGroupsLoading } =
    useDefaultGroups();
  const { data: groups, isLoading: groupsLoading } = useGroups();
  const { mutate: addDefaultGroup, isLoading: addingDefaultGroup } =
    useAddDefaultGroup();
  const { mutate: removeDefaultGroup, isLoading: removingDefaultGroup } = useRemoveDefaultGroup();

  return (
    <RightMainContainer>
      <Loader show={addingDefaultGroup || removingDefaultGroup} loader="block" />
      <HeaderContainer>
        <PrimaryHeading>Groups</PrimaryHeading>
        <div className="relative">
          <MainButton onClick={() => setOpen(!open)}>
            <AddIcon />
            Add
          </MainButton>
          <PopOver
            open={open}
            onClose={() => {
              setOpen(false);
              setShowList(false);
            }}
          >
            {showList ? (
              <AddFromTemplateLibrary
                heading="Add From Account"
                isLoading={groupsLoading}
                data={groups
                  ?.filter((_) => !isEqual(_.default_tem, 1))
                  .map((item) => ({
                    title: item.group_title,
                    icon: <GroupIcon fill="white" />,
                    onClick: () => {
                      addDefaultGroup(item.id);
                    },
                    arrow: true,
                  }))}
                emptyTemplate={{
                  label: "Groups",
                  linkLabel: "Group",
                  link: "add",
                }}
              />
            ) : (
              <PopUpListing
                data={[
                  {
                    title: "From Account",
                    icon: <CloudDownloadIcon />,
                    onClick: () => {
                      setShowList(true);
                    },
                    arrow: true,
                  },
                  {
                    title: "Create New Group",
                    icon: <AddIcon />,
                    onClick: () => {
                      navigate("add");
                    },
                  },
                ]}
              />
            )}
          </PopOver>
        </div>
      </HeaderContainer>
      <BodyContainer>
        <InputField placeholder='Search' searchIcon filter/>
        {defaultGroupsLoading ? (
          <LoadingListCards />
        ) : defaultGroups.length === 0 ? (
          <TemplateNotFound
            label="groups"
            linkLabel="group"
            onClick={() => setOpen(true)}
          />
        ) : (
          defaultGroups.map((item, index) => (
            <ListCard
              key={index}
              data={item.group_title}
              index={index}
              link={`/site-settings/groups/${item.id}`}
              strictLink={true}
              deleteIcon={true}
              icon={
                <ListIconWrapper>
                  <GroupIcon fill="white" />
                </ListIconWrapper>
              }
              onCross={() => {removeDefaultGroup(item.id);}}
            />
          ))
        )}
      </BodyContainer>
    </RightMainContainer>
  );
}
