import { useQuery, useMutation } from "@tanstack/react-query";
import { printError } from "helpers";
import {
  defaultTaskAdd,
  defaultTaskRemove,
  defaultTaskShow,
  templateTaskAdd,
  templateTaskDelete,
  templateTaskEdit,
  templateTaskShow,
} from "./modularApi";
import { useEffect } from "react";
import {
  addDefaultTutorialAction,
  addTemplateTutorialAction,
  deleteDefaultTutorialAction,
  deleteTemplateTutorialAction,
  getDefaultTutorialsAction,
  getTemplateTutorialsAction,
  updateTemplateTutorialAction,
} from "redux/reducers/templateLibrary/tutorials";
import { useUtilities } from "helpers";
import { ApiResponseType, NotificationType } from "enums";

export const useTutorialsTemplate = () => {
  return useQuery(["tutorialTemplate"], () => templateTaskShow("tutorial"));
};

export function useTemplateTutorials() {
  const { data, isLoading, error } = useTutorialsTemplate();
  const {
    dispatch,
    tutorials: { tutorials, loaded },
  } = useUtilities();

  useEffect(() => {
    if (isLoading || loaded) return;
    if (data) {
      dispatch(getTemplateTutorialsAction(data.results));
    }
  }, [data, isLoading, loaded]);

  return {
    data: tutorials,
    isLoading: isLoading || !loaded,
    error,
  };
}

export function useDeleteTemplateTutorial(redirect = "") {
  const { notification, dispatch, navigate } = useUtilities();
  return useMutation(templateTaskDelete, {
    onSuccess: (data) => {
      if (data.Status_Code === ApiResponseType.SUCCESS) {
        dispatch(deleteTemplateTutorialAction(data.id));
        notification({
          type: "success",
          message: "Tutorial deleted successfully",
        });
        if (redirect) {
          navigate(redirect);
        }
      } else {
        notification({
          type: "error",
          message: printError(data.Message),
        });
      }
    },
    onError: (error) => {
      console.log(error);
      notification({
        type: "error",
        message: "Something went wrong, please try again later",
      });
    },
  });
}

export function useAddTemplateTutorial(duplicate = false, redirect = "") {
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(
    (params) =>
      templateTaskAdd({
        ...params,
        parent_type: "tutorial",
        module_hint: "tutorial",
      }),
    {
      onSuccess: (data) => {
        if (data.StatusCode === ApiResponseType.SUCCESS) {
          dispatch(addTemplateTutorialAction(data.Data));
          notification({
            type: "success",
            message: `Tutorial ${duplicate ? "Duplicated": "Added"} successfully`,
          });
          if (redirect) {
            navigate(redirect + data.Data.id);
          }

        } else {
          notification({
            type: NotificationType.ERROR,
            message: printError(data.Message),
          });
        }
      },
      onError: () => {
        notification({
          type: "error",
          message: "Something went wrong, please try again later",
        });
      },
    }
  );
}

export function useUpdateTemplateTutorial() {
  const { notification, dispatch } = useUtilities();
  return useMutation(
    (params) =>
      templateTaskEdit({
        ...params,
        parent_type: "tutorial",
        module_hint: "tutorial",
      }),
    {
      onSuccess: (data) => {
        if (data.StatusCode === ApiResponseType.SUCCESS) {
          dispatch(updateTemplateTutorialAction(data.Data));
          notification({
            type: "success",
            message: "Tutorial updated successfully",
          });
        } else {
          notification({
            type: NotificationType.ERROR,
            message: printError(data.Message),
          });
        }
      },
      onError: () => {
        notification({
          type: NotificationType.ERROR,
          message: "Something went wrong, please try again later",
        });
      },
    }
  );
}

export const useGetDefaultTutorials = () => {
  return useQuery(["tutorialDefault"], () => defaultTaskShow("tutorial"));
};

export function useDefaultTutorials() {
  const { data, isLoading, error } = useGetDefaultTutorials();
  const {
    dispatch,
    tutorials: {
      defaultSite: { tutorials, loaded },
    },
  } = useUtilities();

  useEffect(() => {
    if (loaded || isLoading) return;
    if (data) dispatch(getDefaultTutorialsAction(data.Data));
  }, [loaded, isLoading, data]);

  return {
    data: tutorials,
    isLoading: isLoading || !loaded,
    error,
  };
}

export function useRemoveDefaultTutorial() {
  const { notification, dispatch } = useUtilities();
  return useMutation(defaultTaskRemove, {
    onSuccess: (data) => {
      if (data.Status_Code === ApiResponseType.SUCCESS) {
        dispatch(deleteDefaultTutorialAction(data.id));
        notification({
          type: NotificationType.SUCCESS,
          message: "Default Tutorial Deleted Successfully",
        });
      } else {
        notification({
          type: NotificationType.ERROR,
          message: printError(data.Message),
        });
      }
    },
    onError: (error) => {
      console.log("error", error);
      notification({
        type: NotificationType.ERROR,
        message: "Default Tutorial Deletion Failed",
      });
    },
  });
}

export function useAddDefaultTutorial() {
  const { notification, dispatch } = useUtilities();
  return useMutation(defaultTaskAdd, {
    onSuccess: (data) => {
      if (data.StatusCode === ApiResponseType.SUCCESS) {
        dispatch(addDefaultTutorialAction(data.Data));
        notification({
          type: NotificationType.SUCCESS,
          message: "Default Tutorial Added Successfully",
        });
      } else {
        notification({
          type: NotificationType.ERROR,
          message: printError(data.Message),
        });
      }
    },
    onError: (error) => {
      console.log("error", error);
      notification({
        type: NotificationType.ERROR,
        message: "Default Tutorial Addition Failed",
      });
    },
  });
}
