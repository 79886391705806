import { Children, useState, useDeferredValue, useEffect } from "react";
import {
  useDefaultTutorials,
  useTemplateTutorials,
  useAddDefaultTutorial,
  useRemoveDefaultTutorial,
} from "api/templateLibrary/tutorials";
import { AddIcon, TutorialIcon } from "allIcons";
import {
  PopOver,
  Loader,
  TemplateNotFound,
  LoadingListCards,
  BodyContainer,
  HeaderContainer,
  InputField,
  ListCard,
  ListIconWrapper,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
  AddFromTemplateLibrary,
  FiltersList,
} from "commonUI";
import { useTags } from "api/siteSettings/Tags";
import {
  libraryItemFiltering,
  libraryItemSorting,
} from "filterAndSort/templateLibrary";
import { isEqual, search } from "helpers";
import { useUserPermissions } from "hooks/user";

export default function Tutorials() {
  const { data: defaultTutorials, isLoading: defaultTutorialsLoading } =
    useDefaultTutorials();
  const { data: tutorials } = useTemplateTutorials();
  const [addDefaultTutorialModal, setAddDefaultTutorialModal] = useState(false);
  const addDefaultTutorialMutation = useAddDefaultTutorial();
  const deleteDefaultTutorialMutation = useRemoveDefaultTutorial();

  const { data: tags, isLoading: tagsLoading, error: tagsError } = useTags();

  const [searchText, setSearchText] = useState("");
  const defferedSearch = useDeferredValue(searchText);
  const [filteredDefaultTutorials, setFilteredDefaultTutorials] = useState([]);
  const [filterData, setFilterData] = useState({});
  const { remove_items_from_site_template } = useUserPermissions();

  useEffect(() => {
    setFilteredDefaultTutorials(
      defaultTutorials.filter((tutorial) =>
        search(tutorial.title, defferedSearch)
      )
    );
  }, [defaultTutorials, defferedSearch]);

  const applySorting = (order, orderBy) => {
    setFilteredDefaultTutorials((prevSearchResult) =>
      libraryItemSorting(order, orderBy, prevSearchResult)
    );
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setFilteredDefaultTutorials(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        defaultTutorials
      )
    );
  };

  return (
    <>
      <RightMainContainer>
        {deleteDefaultTutorialMutation.isLoading ||
        addDefaultTutorialMutation.isLoading ? (
          <Loader loader="block" />
        ) : (
          ""
        )}
        <HeaderContainer>
          <PrimaryHeading>Tutorials</PrimaryHeading>
          <div className="relative">
            <MainButton
              onClick={() =>
                setAddDefaultTutorialModal(!addDefaultTutorialModal)
              }
            >
              <AddIcon />
              Add
            </MainButton>
            <PopOver
              open={addDefaultTutorialModal}
              onClose={setAddDefaultTutorialModal}
            >
              <AddFromTemplateLibrary
                data={
                  tutorials
                    .filter(
                      (tutorial) => !isEqual(tutorial.default_tem, 1)
                    )
                    .map((tutorial) => ({
                      ...tutorial,
                      icon: <TutorialIcon fill="white" />,
                      onClick: () =>
                        addDefaultTutorialMutation.mutate({ id: tutorial.id }),
                    })) || []
                }
                emptyTemplate={{
                  label: "Tutorials",
                  linkLabel: "Tutorial",
                  link: `/template-library/tutorials/add`,
                }}
              />
            </PopOver>
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            placeholder="Search"
            searchIcon={true}
            filter={true}
            value={searchText}
            setValue={(value) => setSearchText(value)}
            showLibrarySource={false}
            tags={tags}
            onApplyFilter={applyFilter}
            applySorting={applySorting}
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          {Children.toArray(
            defaultTutorialsLoading ? (
              <LoadingListCards />
            ) : filteredDefaultTutorials?.length > 0 ? (
              filteredDefaultTutorials.map((tutorial, index) => {
                return (
                  <ListCard
                    data={tutorial.title}
                    onCross={() => {
                      deleteDefaultTutorialMutation.mutate(tutorial.id);
                    }}
                    editIcon={false}
                    icon={
                      <ListIconWrapper>
                        <TutorialIcon />
                      </ListIconWrapper>
                    }
                    deleteIcon
                    crossIcon={remove_items_from_site_template}
                    showModalonCross
                    deleteTitle="Remove This Template?"
                    deleteButtonText="Confirm"
                    deleteMessage="You are about to remove this template from your default site template. Are you sure you want to do this?"
                  />
                );
              })
            ) : (
              <TemplateNotFound
                label="Tutorials"
                linkLabel="Tutorial"
                onClick={() =>
                  setAddDefaultTutorialModal(!addDefaultTutorialModal)
                }
              />
            )
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
