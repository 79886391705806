import { axiosInstance } from "helpers";

export async function getApprovalSettings(website_id) {
  return axiosInstance
    .get("allfeaturesettings", {
      params: {
        apiKey: localStorage.getItem("apikey"),
        added_by: localStorage.getItem("user_id"),
        tab_hint: "Approvals",
        website_id: website_id,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err.response.data;
    });
}

export async function updateApprovalSettings(data) {
  return axiosInstance
    .post("updatefeaturesettings", {
      apiKey: localStorage.getItem("apikey"),
      added_by: localStorage.getItem("user_id"),
      system_status: 0,
      tab_hint: "Approvals",
      ...data,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err.response.data;
    });
}
