export default function SaveIcon(props) {
 
  return (
    <>
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`cursor-pointer ${props.className ?? ""}`}
      >
        <path
          d="M13.53 3.47009L10.53 0.470093C10.3895 0.3294 10.1988 0.250175 10 0.25H2C1.53587 0.25 1.09076 0.434385 0.762573 0.762573C0.434385 1.09076 0.25 1.53587 0.25 2V14C0.25 14.4641 0.434385 14.9092 0.762573 15.2374C1.09076 15.5656 1.53587 15.75 2 15.75H12C12.4641 15.75 12.9092 15.5656 13.2374 15.2374C13.5656 14.9092 13.75 14.4641 13.75 14V4C13.7498 3.80115 13.6707 3.61061 13.53 3.47009ZM5.25 1.75H8.75V4.25H5.25V1.75ZM8.75 14.25H5.25V9.75H8.75V14.25ZM12.25 14C12.25 14.0663 12.2237 14.13 12.1768 14.1769C12.1299 14.2238 12.0663 14.25 12 14.25H10.25V9C10.2474 8.80189 10.1676 8.61263 10.0275 8.47253C9.88737 8.33244 9.69811 8.25259 9.5 8.25H4.5C4.30109 8.25 4.11032 8.32907 3.96967 8.46973C3.82901 8.61038 3.75 8.80109 3.75 9V14.25H2C1.9337 14.25 1.87013 14.2238 1.82324 14.1769C1.77636 14.13 1.75 14.0663 1.75 14V2C1.75 1.9337 1.77636 1.87013 1.82324 1.82324C1.87013 1.77636 1.9337 1.75 2 1.75H3.75V5C3.74865 5.09886 3.76717 5.19709 3.80438 5.2887C3.8416 5.3803 3.89676 5.46341 3.96667 5.53333C4.03659 5.60324 4.11982 5.65846 4.21143 5.69568C4.30303 5.73289 4.40114 5.75135 4.5 5.75H9.5C9.69891 5.75 9.88968 5.67105 10.0303 5.5304C10.171 5.38974 10.25 5.19891 10.25 5V2.31006L12.25 4.31006V14Z"
          fill={props.fill ?? "white"}
        />
      </svg>
    </>
  );
}
