export default function TextIcon(props) {
  return (
    <>
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${props.className ?? ""}`}
        onClick={props.onClick}
      >
        <path
          d="M13.2501 1V3C13.2501 3.19891 13.1711 3.38974 13.0304 3.5304C12.8898 3.67105 12.699 3.75 12.5001 3.75C12.4012 3.75135 12.3031 3.73289 12.2115 3.69568C12.1199 3.65846 12.0367 3.60324 11.9667 3.53333C11.8968 3.46341 11.8417 3.38018 11.8045 3.28857C11.7672 3.19697 11.7487 3.09886 11.7501 3V1.76001H7.75007V14.26H8.00007C8.19818 14.2626 8.38744 14.3424 8.52753 14.4825C8.66763 14.6226 8.74748 14.8119 8.75007 15.01C8.75007 15.2089 8.67105 15.3996 8.5304 15.5403C8.38975 15.6809 8.19898 15.76 8.00007 15.76H6.00007C5.9012 15.7614 5.8031 15.7429 5.71149 15.7057C5.61989 15.6685 5.53666 15.6132 5.46674 15.5433C5.39683 15.4734 5.34167 15.3902 5.30445 15.2986C5.26724 15.207 5.24872 15.1089 5.25007 15.01C5.25007 14.8111 5.32908 14.6204 5.46973 14.4797C5.61039 14.3391 5.80116 14.26 6.00007 14.26H6.25007V1.76001H2.25007V3.01001C2.25007 3.20892 2.17106 3.39963 2.0304 3.54028C1.88975 3.68094 1.69898 3.76001 1.50007 3.76001C1.4012 3.76135 1.3031 3.7429 1.21149 3.70569C1.11989 3.66847 1.03666 3.61325 0.966743 3.54333C0.89683 3.47342 0.841665 3.39019 0.804451 3.29858C0.767236 3.20698 0.748723 3.10887 0.750068 3.01001V1.01001C0.750068 0.811097 0.829082 0.620389 0.969734 0.479736C1.11039 0.339084 1.30116 0.26001 1.50007 0.26001H12.5001C12.6965 0.262527 12.8843 0.341044 13.0241 0.479004C13.1639 0.616964 13.2449 0.80364 13.2501 1Z"
          fill={props.fill ?? "white"}
        />
      </svg>
    </>
  );
}
