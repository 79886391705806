import { useQuery, useMutation } from "@tanstack/react-query";
import {
  generalSettingShow,
  generalSettingUpdate,
} from "api/siteSettings/generalSetting";
import { useEffect } from "react";
import {
  setGeneralSettingAction,
  updateGeneralSettingAction,
} from "redux/reducers/siteSettings/generalSetting";
import { printError, useSearch, useUtilities } from "helpers";
import { ApiResponseType, NotificationType } from "enums";

function useGetGeneralSettings(web) {
  return useQuery(["generalSettings", web], () => generalSettingShow(web));
}

export function useGeneralSettings() {
  const { web = null } = useSearch();
  const { data, isLoading, error } = useGetGeneralSettings(web);
  const {
    dispatch,
    generalSettings: { generalSettings, loaded, website_id },
  } = useUtilities();

  useEffect(() => {
    if (data && (website_id !== web)) {
      dispatch(
        setGeneralSettingAction({ generalSettings: data.Data, website_id: web })
      );
    }
  }, [data, isLoading, error, loaded]);

  return {
    data: generalSettings,
    isLoading: (isLoading || !loaded || web !== website_id),
    error,
  };
}

export function useUpdateGeneralSettings() {
  const { notification, dispatch } = useUtilities();
  return useMutation(generalSettingUpdate, {
    onSuccess: (data) => {
      if (data.StatusCode === ApiResponseType.SUCCESS) {
        dispatch(updateGeneralSettingAction(data.Data));
        notification({
          type: NotificationType.SUCCESS,
          message: "General Settings updated successfully",
        });
      } else {
        notification({
          type: NotificationType.ERROR,
          message: printError(data.Message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: NotificationType.ERROR,
        message: printError(err.message),
      });
    },
  });
}
