import { useNavigate } from "react-router-dom";
export default function BackArrow({ className, onClick, fill }) {
  const navigate = useNavigate();
  function HandleClick() {
    onClick ? onClick() : navigate(-1);
  }
  return (
    <>
      <svg
        width="21"
        height="14"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`cursor-pointer ${className ?? ""}`}
        style={{ minWidth : "21px"}}
        onClick={HandleClick}
      >
        <path
          d="M15.1202 6.00012C15.1202 6.19903 15.0412 6.3898 14.9005 6.53046C14.7599 6.67111 14.5691 6.75012 14.3702 6.75012H3.4302L6.6802 10.0001C6.75095 10.0691 6.8072 10.1515 6.8456 10.2425C6.884 10.3335 6.90377 10.4313 6.90377 10.5301C6.90377 10.6289 6.884 10.7267 6.8456 10.8177C6.8072 10.9087 6.75095 10.9912 6.6802 11.0601C6.61203 11.1317 6.5297 11.1881 6.43844 11.226C6.34717 11.2639 6.24902 11.2823 6.15023 11.2801C6.05169 11.2806 5.95401 11.2614 5.863 11.2236C5.77198 11.1858 5.68944 11.1302 5.6202 11.0601L1.09023 6.53009C1.02244 6.46073 0.968145 6.37935 0.930196 6.2901C0.890318 6.19864 0.869888 6.0999 0.870199 6.00012C0.868651 5.90022 0.889134 5.80117 0.930196 5.71008C0.965472 5.61942 1.0201 5.53753 1.09023 5.47009L5.6202 0.940125C5.68914 0.869367 5.7716 0.813117 5.86263 0.774719C5.95366 0.736322 6.05143 0.716553 6.15023 0.716553C6.24902 0.716553 6.3468 0.736322 6.43783 0.774719C6.52885 0.813117 6.61125 0.869367 6.6802 0.940125C6.75095 1.00907 6.8072 1.09147 6.8456 1.1825C6.884 1.27352 6.90377 1.3713 6.90377 1.47009C6.90377 1.56889 6.884 1.66666 6.8456 1.75769C6.8072 1.84872 6.75095 1.93118 6.6802 2.00012L3.4302 5.25012H14.3702C14.4691 5.24878 14.5672 5.26723 14.6588 5.30444C14.7504 5.34166 14.8336 5.39688 14.9035 5.4668C14.9734 5.53671 15.0287 5.61988 15.0659 5.71149C15.1031 5.80309 15.1215 5.90126 15.1202 6.00012Z"
          fill={fill ?? "#4C5866"}
        />
      </svg>
    </>
  );
}
