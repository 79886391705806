import { axiosInstance } from "helpers";

export async function getLaunchpadShortcuts(website_id) {
  return axiosInstance
    .get("allfeaturesettings", {
      params: {
        apiKey: localStorage.getItem("apikey"),
        added_by: localStorage.getItem("user_id"),
        tab_hint: "Launchpad",
        website_id,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export async function updateLaunchpadShortcuts(data) {
  return axiosInstance
    .post("updatefeaturesettings", {
      ...data,
      apiKey: localStorage.getItem("apikey"),
      added_by: localStorage.getItem("user_id"),
      tab_hint: "Launchpad",
      system_status: "0",
      ...data,
    })
    .then((res) => res.data)
    .catch((err) => err);
}
