import {
  GroupIcon,
  MailIcon,
  RolesIcon,
  SiteIcon,
  StatusIcon,
  UserIcon,
  PriorityIcon,
  PhaseIcon,
  TagsIcon,
  SMTPIcon,
  EnvironmentIcon,
  ErrorIcon,
  MaintenanceIcon,
} from "allIcons";
import { compact } from "helpers";

export default function getGeneralMenu({ delete_site }) {
  const { search } = window.location;
  const generalMenu = [
    search && {
      module: "general-settings",
      title: "General",
      icon: <SiteIcon />,
    },
    {
      module: "users",
      title: "Users",
      icon: <UserIcon />,
    },
    {
      module: "roles",
      title: "Roles",
      icon: <RolesIcon />,
    },
    // {
    //   module: "groups",
    //   title: "Groups",
    //   icon: <GroupIcon />,
    // },
    {
      module: "phases",
      title: "Phases",
      icon: <PhaseIcon />,
    },
    {
      module: "status",
      title: "Status",
      icon: <StatusIcon />,
    },
    {
      module: "priority",
      title: "Priority",
      icon: <PriorityIcon />,
    },
    {
      module: "tags",
      title: "Tags",
      icon: <TagsIcon />,
    },
    {
      module: "smtp",
      title: "SMTP",
      icon: <SMTPIcon />,
    },
    // {
    //   module: "email-templates",
    //   title: "Email Templates",
    //   icon: <MailIcon />,
    // },
    {
      module: "environments",
      title: "Environments",
      icon: <EnvironmentIcon />,
    },
    search && {
      module: "maintenance",
      title: "Maintenance",
      icon: <MaintenanceIcon />,
    },
    search &&
      delete_site && {
        module: "advanced",
        title: "Advanced",
        icon: <ErrorIcon />,
      },
  ];

  return compact(generalMenu);
}
