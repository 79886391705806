import { useEffect, useMemo, useState } from "react";
import {
  AddFromTemplateLibrary,
  ListCard,
  MainButton,
  HeaderContainer,
  PrimaryHeading,
  BodyContainer,
  RightMainContainer,
  ListIconWrapper,
  PopOver,
  LoadingListCards,
  TemplateNotFound,
  Loader,
  PopUpListing,
  SecondaryHeading,
} from "commonUI";
import { AddIcon, PhaseIcon, CloudDownloadIcon } from "allIcons";
import {
  useAddDefaultPhase,
  useAddNewPhase,
  useDefaultPhases,
  useDeleteDefaultPhase,
  usePhases,
} from "api/templateLibrary/phases";
import { isEmpty, isEqual, parse, useSearch, useUtilities } from "helpers";
import { useUserPermissions } from "hooks/user";

const phasesList = ["Completed", "Current", "Future"];

export default function Phases() {
  const { navigate } = useUtilities();
  const { web } = useSearch();
  const { data: phases, isLoading } = usePhases();
  const { data: DefaultPhases, isLoading: loadingDefaultPhases } =
    useDefaultPhases();
  const { mutate: addPhase, isLoading: addingPhase } = useAddDefaultPhase();
  const { mutate: createPhase, isLoading: creatingPhase } = useAddNewPhase(
    "",
    true
  );
  const { mutate: deletePhase, isLoading: deletingPhase } =
    useDeleteDefaultPhase();
  const [addPopup, setAddPopup] = useState(false);
  const [libraryTemplate, setLibraryTemplate] = useState(false);
  const [filteredList, setFilteredList] = useState({});
  const {
    add_new_items_to_site_template,
    add_items_from_template_library,
    remove_items_from_site_template,
    edit_item_details_in_site_template,
  } = useUserPermissions();
  useEffect(() => {
    let temp = {};
    DefaultPhases.forEach((phase) => {
      let status = parse(phase.status).text;
      if (temp[status]) {
        temp[status].push(phase);
      } else {
        temp[status] = [phase];
      }
    });
    setFilteredList(temp);
  }, [DefaultPhases]);
  const defaultPhaseParentIDS = useMemo(
    () => DefaultPhases.map((e) => e.parent_item).filter((e) => e),
    [DefaultPhases]
  );

  const sortPhases = (phaseKeys) => {
    let tempPhaseKeys = phasesList.filter((phase) => phaseKeys.includes(phase));
    return [
      ...tempPhaseKeys,
      ...phaseKeys.filter((phase) => !tempPhaseKeys.includes(phase)),
    ];
  };

  const handleAddPhase = (phase) => {
    if (web) {
      createPhase({ ...phase, website_id: web, parent_item: phase.id });
      return;
    }
    addPhase({ id: phase.id });
  };

  return (
    <>
      <RightMainContainer>
        <Loader
          loader="block"
          show={addingPhase || deletingPhase || creatingPhase}
        />
        <HeaderContainer>
          <PrimaryHeading>Phases</PrimaryHeading>
          <div className="relative">
            {add_new_items_to_site_template ? (
              <MainButton onClick={() => setAddPopup(!addPopup)}>
                <AddIcon />
                Add
              </MainButton>
            ) : (
              ""
            )}
            <PopOver
              open={addPopup || libraryTemplate}
              onClose={() => {
                setAddPopup(false);
                setLibraryTemplate(false);
              }}
            >
              {addPopup ? (
                <PopUpListing
                  data={[
                    add_items_from_template_library && {
                      title: "From Template Library",
                      icon: <CloudDownloadIcon />,
                      onClick: () => {
                        setLibraryTemplate(true);
                        setAddPopup(false);
                      },
                      arrow: true,
                    },
                    {
                      title: "Create New Phase",
                      icon: <AddIcon />,
                      onClick: () => {
                        navigate(
                          `/site-settings/phases/add${web ? "?web=" + web : ""}`
                        );
                      },
                    },
                  ].filter((_) => _)}
                />
              ) : (
                <AddFromTemplateLibrary
                  isLoading={isLoading}
                  data={phases
                    .filter((_) => {
                      if (!web) return !isEqual(_.default_tem, "1");
                      return !defaultPhaseParentIDS.includes(String(_.id));
                    })
                    .map((phase) => ({
                      ...phase,
                      icon: <PhaseIcon />,
                      onClick: () => handleAddPhase(phase),
                    }))}
                  emptyTemplate={{
                    label: "Phase",
                    linkLabel: "Phase",
                    link: `/site-settings/phases/add${
                      web ? "?web=" + web : ""
                    }`,
                  }}
                />
              )}
            </PopOver>
          </div>
        </HeaderContainer>
        <BodyContainer>
          <div className="">
            {loadingDefaultPhases ? (
              <LoadingListCards />
            ) : isEmpty(filteredList) ? (
              <TemplateNotFound
                label="Phase"
                linkLabel="Phase"
                onClick={() => setAddPopup(true)}
                filter={false}
                link={`/site-settings/phases${web ? "?web=" + web : ""}`}
              />
            ) : (
              sortPhases(Object.keys(filteredList)).map((key, index) => {
                return (
                  <>
                    {web && key && !isEqual(key, undefined) ? (
                      <SecondaryHeading key={index}>{key}</SecondaryHeading>
                    ) : (
                      ""
                    )}
                    {filteredList[key].map((phase, index) => {
                      return (
                        <ListCard
                          key={filteredList.length + "-" + index}
                          opacity={web && key === "Completed" ? "opacity-50" : ""}
                          secondaryData={
                            web && phase.target_date
                              ? `End${
                                  key === "Completed" ? "ed" : "s"
                                } on ${new Date(
                                  phase.target_date
                                ).toLocaleDateString("en-ZA")}`
                              : ""
                          }
                          editIcon={edit_item_details_in_site_template}
                          editLink={edit_item_details_in_site_template ? `/site-settings/phases/${phase.id}` : ''}
                          link={edit_item_details_in_site_template ? `/site-settings/phases/${phase.id}` : ''}
                          backUrlOnEdit={false}
                          data={phase.title}
                          deleteIcon={!web || !remove_items_from_site_template}
                          crossIcon={!web && remove_items_from_site_template}
                          onCross={() =>
                            deletePhase({
                              id: phase.id,
                              website_id: web,
                            })
                          }
                          icon={
                            <ListIconWrapper>
                              <PhaseIcon />
                            </ListIconWrapper>
                          }
                          deleteTitle={
                            web ? "Delete This Phase?" : "Remove This Phase?"
                          }
                          showModalonCross
                          deleteButtonText={web ? "Confirm Delete" : "Confirm"}
                          deleteMessage={
                            web
                              ? "You are about to delete this phase from your site. This action cannot be undone! Any item using this phase option will revert to the one before it. Are you sure you want to do this?"
                              : "You are about to remove this phase from your default site template. Are you sure you want to do this?"
                          }
                        />
                      );
                    })}
                  </>
                );
              })
            )}
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
