import { useDeferredValue, useEffect, useState, Children } from "react";
import {
  SecondaryHeading,
  InputField,
  ListCard,
  ListIconWrapper,
  TemplateNotFound,
  LoadingListCards,
} from "commonUI";

export default function AddFromTemplateLibrary({
  heading = "Add From Template Library",
  placeholder = "Search",
  searchIcon = true,
  data = [],
  id = "template-library",
  onSearchValueChange = () => {},
  autoSearch = true,
  emptyTemplate = {},
  isLoading = false,
}) {
  const [searchText, setSearchText] = useState("");
  const defferedSearchText = useDeferredValue(searchText);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (autoSearch) {
      setFilteredData(
        data.filter((item) =>
          item.title.toLowerCase().includes(defferedSearchText.toLowerCase())
        )
      );
    } else {
      setFilteredData(data);
    }
  }, [defferedSearchText, data]);
  return (
    <>
      <div className="list-pop-up-content" style={{ padding: "0" }}>
        <div style={{ padding: "24px 24px 0px" }}>
          <SecondaryHeading>{heading}</SecondaryHeading>
        </div>
        <div className="mt-1 w-full">
          <div style={{ padding: "0px 24px" }}>
            <InputField
              searchIcon={searchIcon}
              placeholder={placeholder}
              className="mb-1"
              padding={{ padding: `9px 10px` }}
              value={searchText}
              setValue={(value) => {
                setSearchText(value);
                onSearchValueChange(value);
              }}
            />
          </div>
          <div
            style={{
              maxHeight: "25vh",
              overflowY: "auto",
              padding: "0px 24px",
              marginBottom: "10px",
            }}
            id={id}
          >
            {isLoading ? (
              <LoadingListCards rows={3} />
            ) : filteredData.length === 0 ? (
              emptyTemplate.label ? (
                <div className="pb-2">
                  <TemplateNotFound {...emptyTemplate} />
                </div>
              ) : (
                ""
              )
            ) : (
              Children.toArray(
                filteredData.map((item, index) => (
                  <ListCard
                    key={index}
                    data={item.title}
                    index={index}
                    secondaryData={item.secondaryData || ""}
                    id={index}
                    strictLink={true}
                    deleteIcon={true}
                    editIcon={false}
                    onClick={(value) => {
                      item.onClick && item.onClick(value);
                    }}
                    icon={
                      <ListIconWrapper
                        className={
                          "border-white " + item.iconWrapperClass ?? ""
                        }
                      >
                        {item.icon}
                      </ListIconWrapper>
                    }
                  />
                ))
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
}
