import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  brandingAndColors: {},
  loaded: false,
  website_id: null,
};

const brandingAndColorsSlice = createSlice({
  name: "brandingAndColors",
  initialState,
  reducers: {
    setBrandingAndColors: (state, action) => {
      const { website_id, brandingAndColors } = action.payload;
      state.brandingAndColors = brandingAndColors;
      state.loaded = true;
      state.website_id = website_id;
    },
    updateBrandingAndColorsAction: (state, action) => {
      const { website_id, brandingAndColors } = action.payload;
      state.brandingAndColors.brand_colors_settings = brandingAndColors;
      state.website_id = website_id;
    },
  },
});

export const { setBrandingAndColors, updateBrandingAndColorsAction } = brandingAndColorsSlice.actions;
export default brandingAndColorsSlice.reducer;
