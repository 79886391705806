import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  SelectField,
  IconWrapper,
  SecondaryHeading,
  InputField,
  BodyContainer,
  MainButton,
  PrimaryHeading,
  ToggleCard,
  RightMainContainer,
  HeaderContainer,
  CheckBox,
  Divider,
  Loader,
  DeleteButton,
} from "commonUI";
import { BackArrow, SaveIcon } from "allIcons";
import { isEqual, objectValues, parse, useSearch, useUtilities } from "helpers";
import {
  useAddRole,
  useDeleteRole,
  useUpdateRole,
  useAddDefaultRole,
  useDefaultRoles,
} from "api/usersModule/roles";
import { USERTYPE } from "usersModule/enums";
import { useUserPermissions } from "hooks/user";

export const PermissionCard = ({ value, setValue, heading, last }) => {
  return (
    <>
      <div className={`permission-card`}>
        <SecondaryHeading className="text-13 font-semibold text-theme-secondary">
          {heading}
        </SecondaryHeading>
        <ToggleCard value={value} setValue={setValue} />
      </div>
      {last ? "" : <Divider />}
    </>
  );
};

export default function AddEditRole() {
  const { web = null } = useSearch();
  const { id } = useParams();
  const { notification, navigate } = useUtilities();
  const [role, setRole] = useState({
    role_title: "",
    user_role: [...new Set(Object.values(USERTYPE))][0],
    system_default: 0,
    app_permissions: {
      app_general_log_into_app: true,
      app_users_view_users: true,
      app_users_edit_users: true,
      app_users_add_new_users: true,
      app_users_delete_users: true,
      view_templates: true,
      add_new_templates: true,
      edit_templates: true,
      delete_templates: true,
      view_sites: true,
      add_new_site: true,
      delete_site: true,
      view_site_template: true,
      add_new_items_to_site_template: true,
      add_items_from_template_library: true,
      edit_item_details_in_site_template: true,
      remove_items_from_site_template: true,
    },
    plugin_permissions: {
      view_site_settings: true,
      add_new_items_to_site_settings: true,
      add_items_from_template_library: true,
      edit_item_details_in_site_settings: true,
      save_items_as_templates: true,
      remove_items_from_site_settings: true,

      view_comments: true,
      add_new_comments: true,
      reply_to_discussion_in_comments: true,
      edit_comment_details: true,
      delete_comments_added_by_user: true,
      delete_comments_added_by_other_users: true,

      view_pages: true,
      add_current_page: true,
      reply_to_discussion_in_pages: true,
      edit_page_details: true,
      delete_pages: true,

      view_tasks: true,
      add_new_tasks: true,
      add_task_templates_from_template_library: true,
      reply_to_discussion_in_tasks: true,
      edit_task_details: true,
      save_tasks_as_templates: true,
      delete_tasks_added_by_user: true,
      delete_tasks_added_by_other_users: true,

      view_checklists: true,
      add_new_checklists: true,
      add_checklist_templates_from_template_library: true,
      reply_to_discussion_in_checklists: true,
      edit_checklist_details: true,
      save_checklists_as_templates: true,
      delete_checklist_added_by_user: true,
      delete_checklist_added_by_other_users: true,

      view_files: true,
      add_new_files: true,
      reply_to_discussion_in_files: true,
      edit_file_details: true,
      delete_files_added_by_user: true,
      delete_files_added_by_other_users: true,

      view_content_requests: true,
      add_new_content_requests: true,
      add_content_request_templates_from_template_library: true,
      reply_to_discussion_in_content_requests: true,
      edit_content_request_details: true,
      save_content_requests_as_templates: true,
      delete_content_requests_added_by_user: true,
      delete_content_requests_added_by_other_users: true,

      view_feedback_requests: true,
      add_new_feedback_requests: true,
      reply_to_discussion_in_feedback_requests: true,
      edit_feedback_request_details: true,
      delete_feedback_requests_added_by_user: true,
      delete_feedback_requests_added_by_other_users: true,

      view_approval_requests: true,
      add_new_approval_requests: true,
      reply_to_discussion_in_approval_requests: true,
      edit_approval_requests_details: true,
      delete_approval_requests_added_by_user: true,
      delete_approval_requests_added_by_other_users: true,

      view_change_requests: true,
      add_new_change_requests: true,
      reply_to_discussion_in_change_requests: true,
      edit_change_requests_details: true,
      delete_change_requests_added_by_user: true,
      delete_change_requests_added_by_other_users: true,

      view_view_notes: true,
      add_new_view_notes: true,
      add_view_note_templates_from_template_library: true,
      reply_to_discussion_in_view_notes: true,
      edit_view_notes_details: true,
      save_view_notes_as_templates: true,
      delete_notes_added_by_user: true,
      delete_notes_added_by_other_users: true,

      view_view_tutorials: true,
      add_new_view_tutorials: true,
      add_view_tutorials_templates_from_template_library: true,
      reply_to_discussion_in_view_tutorials: true,
      edit_view_tutorials_details: true,
      save_view_tutorials_as_templates: true,
      delete_tutorials_added_by_user: true,
      delete_tutorials_added_by_other_users: true,

      view_support_requests: true,
      add_new_support_requests: true,
      reply_to_discussion_in_support_requests: true,
      edit_support_requests_details: true,
      delete_support_requests_added_by_user: true,
      delete_support_requests_added_by_other_users: true,
    },
  });
  const {
    add_new_items_to_site_template,
    remove_items_from_site_template,
    edit_item_details_in_site_template,
  } = useUserPermissions();

  const { data: roles, isLoading: loadingRoles, error } = useDefaultRoles();

  const { mutate: addRole, isLoading: addingRole } = useAddRole(
    "/site-settings/roles/",
    true
  );
  const { mutate: updateRole, isLoading: updatingRole } = useUpdateRole();
  const { mutate: deleteRole, isLoading: deletingRole } = useDeleteRole(
    `/site-settings/roles${web ? `?web=${web}` : ""}`
  );
  const { mutate: addDefaultRole, isLoading: isAdding } = useAddDefaultRole();
  useEffect(() => {
    if (!loadingRoles && roles) {
      const tempRole = roles.find((item) => isEqual(item.id, id));
      if (tempRole)
        setRole({
          ...tempRole,
          app_permissions: parse(tempRole.app_permissions),
          plugin_permissions: parse(tempRole.plugin_permissions),
        });
    }
  }, [loadingRoles, roles]);

  function setAppPermission(key, value) {
    setRole({
      ...role,
      app_permissions: {
        ...role.app_permissions,
        [key]: value,
      },
    });
  }
  function setPluginPermission(key, value) {
    setRole({
      ...role,
      plugin_permissions: {
        ...role.plugin_permissions,
        [key]: value,
      },
    });
  }

  function saveRole(e) {
    e.preventDefault();
    if (!role.role_title) {
      notification({
        type: "error",
        message: "Please enter role title",
      });
      return;
    }
    if (isEqual(id, "add")) {
      addRole({
        data: { ...role, website_id: web, default_tem: 1 },
        // callback: (role_id) => addDefaultRole({ id: role_id, website_id: web }),
      });
    } else {
      if (isEqual(role.system_default, 1)) {
        const tempRole = roles.find((item) => isEqual(item.id, id));
        addRole({
          ...role,
          system_default: 0,
          role_title:
            role?.role_title !== tempRole.role_title
              ? role?.role_title
              : `Copy of ${role.role_title}`,
        });
        return;
      }
      const { id: role_id, ...rest } = role;
      updateRole({ ...rest, role_id });
    }
  }

  return (
    <>
      <RightMainContainer>
        <Loader
          loader="block"
          show={addingRole || updatingRole || deletingRole || isAdding}
        />
        <HeaderContainer>
          <IconWrapper>
            <BackArrow
              onClick={() =>
                navigate(`/site-settings/roles${web ? `?web=${web}` : ""}`)
              }
            />
            <PrimaryHeading>
              {isEqual(id, "add") ? "Add Role" : role.role_title}
            </PrimaryHeading>
          </IconWrapper>
          {add_new_items_to_site_template ||
          edit_item_details_in_site_template ? (
            <MainButton onClick={saveRole}>
              <SaveIcon />
              Save Changes
            </MainButton>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          <InputField
            label="Title"
            value={role.role_title}
            setValue={(value) => setRole({ ...role, role_title: value })}
            placeholder="Write Title Here"
          />
          <SelectField
            label="User Type"
            placeholder="User Type"
            value={role.user_role}
            setValue={(value) => setRole({ ...role, user_role: value })}
          >
            {objectValues(USERTYPE).map((role) => (
              <option value={role}>{role}</option>
            ))}
          </SelectField>
          <PrimaryHeading className="text-17 mt-2">
            App Permissions
          </PrimaryHeading>
          <Divider className="mt-3" />
          <div className="permission-card-list">
            <PrimaryHeading className="text-17">App</PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label="Log Into App"
                value={role.app_permissions.app_general_log_into_app}
                setValue={(value) =>
                  setAppPermission("app_general_log_into_app", value)
                }
              />
            </div>
            <Divider />
          </div>
          <div className="permission-card-list">
            <PrimaryHeading className="text-17">Users</PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label="View Users"
                value={role.app_permissions.app_users_view_users}
                setValue={(value) =>
                  setAppPermission("app_users_view_users", value)
                }
              />
              <CheckBox
                label="Edit Users"
                value={role.app_permissions.app_users_edit_users}
                setValue={(value) =>
                  setAppPermission("app_users_edit_users", value)
                }
                disabled={isEqual(
                  role.app_permissions.app_users_view_users,
                  false
                )}
              />
              <CheckBox
                label="Add New Users"
                value={role.app_permissions.app_users_add_new_users}
                setValue={(value) =>
                  setAppPermission("app_users_add_new_users", value)
                }
                disabled={isEqual(
                  role.app_permissions.app_users_view_users,
                  false
                )}
              />
              <CheckBox
                label="Delete Users"
                value={role.app_permissions.app_users_delete_users}
                setValue={(value) =>
                  setAppPermission("app_users_delete_users", value)
                }
                disabled={isEqual(
                  role.app_permissions.app_users_view_users,
                  false
                )}
              />
            </div>
            <Divider />
          </div>
          <div className="permission-card-list">
            <PrimaryHeading className="text-17">
              Template Library
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label="View Templates"
                value={role.app_permissions.view_templates}
                setValue={(value) => setAppPermission("view_templates", value)}
              />
              <CheckBox
                label="Add New Template"
                value={role.app_permissions.add_new_templates}
                setValue={(value) =>
                  setAppPermission("add_new_templates", value)
                }
                disabled={isEqual(role.app_permissions.view_templates, false)}
              />
              <CheckBox
                label="Edit Templates"
                value={role.app_permissions.edit_templates}
                setValue={(value) => setAppPermission("edit_templates", value)}
                disabled={isEqual(role.app_permissions.view_templates, false)}
              />
              <CheckBox
                label="Delete Templates"
                value={role.app_permissions.delete_templates}
                setValue={(value) =>
                  setAppPermission("delete_templates", value)
                }
                disabled={isEqual(role.app_permissions.view_templates, false)}
              />
            </div>
            <Divider />
          </div>
          <div className="permission-card-list">
            <PrimaryHeading className="text-17">Sites</PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label="View Sites"
                value={role.app_permissions.view_sites}
                setValue={(value) => setAppPermission("view_sites", value)}
              />
              <CheckBox
                label="Add New Site"
                value={role.app_permissions.add_new_site}
                setValue={(value) => setAppPermission("add_new_site", value)}
                disabled={isEqual(role.app_permissions.view_sites, false)}
              />
              <CheckBox
                label="Delete Site"
                value={role.app_permissions.delete_site}
                setValue={(value) => setAppPermission("delete_site", value)}
                disabled={isEqual(role.app_permissions.view_sites, false)}
              />
            </div>
            <Divider />
          </div>
          <div className="permission-card-list">
            <PrimaryHeading className="text-17">
              Default Site Template
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label="View Site Template"
                value={role.app_permissions.view_site_template}
                setValue={(value) =>
                  setAppPermission("view_site_template", value)
                }
              />
              <CheckBox
                label="Add New Items To Site Template"
                value={role.app_permissions.add_new_items_to_site_template}
                setValue={(value) =>
                  setAppPermission("add_new_items_to_site_template", value)
                }
                disabled={isEqual(
                  role.app_permissions.view_site_template,
                  false
                )}
              />
              <CheckBox
                label="Add Items From Template Library"
                value={role.app_permissions.add_items_from_template_library}
                setValue={(value) =>
                  setAppPermission("add_items_from_template_library", value)
                }
                disabled={isEqual(
                  role.app_permissions.view_site_template,
                  false
                )}
              />
              <CheckBox
                label="Edit Item Details In Site Template "
                value={role.app_permissions.edit_item_details_in_site_template}
                setValue={(value) =>
                  setAppPermission("edit_item_details_in_site_template", value)
                }
                disabled={isEqual(
                  role.app_permissions.view_site_template,
                  false
                )}
              />
              <CheckBox
                label="Remove Items From Site Template "
                value={role.app_permissions.remove_items_from_site_template}
                setValue={(value) =>
                  setAppPermission("remove_items_from_site_template", value)
                }
                disabled={isEqual(
                  role.app_permissions.view_site_template,
                  false
                )}
              />
            </div>
            <Divider />
          </div>
          <PrimaryHeading className="text-17 mt-2">
            Plugin Permissions
          </PrimaryHeading>
          <div className="permission-card-list">
            <PrimaryHeading className="text-17">Site Settings</PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label="View Site Settings"
                value={role.plugin_permissions.view_site_settings}
                setValue={(value) =>
                  setPluginPermission("view_site_settings", value)
                }
              />
              <CheckBox
                label="Add New Items To Site Settings"
                value={role.plugin_permissions.add_new_items_to_site_settings}
                setValue={(value) =>
                  setPluginPermission("add_new_items_to_site_settings", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_site_settings,
                  false
                )}
              />
              <CheckBox
                label="Add Items From Template Library"
                value={role.plugin_permissions.add_items_from_template_library}
                setValue={(value) =>
                  setPluginPermission("add_items_from_template_library", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_site_settings,
                  false
                )}
              />
              <CheckBox
                label="Edit Item Details In Site Settings"
                value={
                  role.plugin_permissions.edit_item_details_in_site_settings
                }
                setValue={(value) =>
                  setPluginPermission(
                    "edit_item_details_in_site_settings",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_site_settings,
                  false
                )}
              />
              <CheckBox
                label="Save Items As Templates"
                value={role.plugin_permissions.save_items_as_templates}
                setValue={(value) =>
                  setPluginPermission("save_items_as_templates", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_site_settings,
                  false
                )}
              />
              <CheckBox
                label="Remove Items From Site Settings"
                value={role.plugin_permissions.remove_items_from_site_settings}
                setValue={(value) =>
                  setPluginPermission("remove_items_from_site_settings", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_site_settings,
                  false
                )}
              />
            </div>
            <Divider />
          </div>
          <div className="permission-card-list">
            <PrimaryHeading className="text-17">Comments</PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label="View Comments"
                value={role.plugin_permissions.view_comments}
                setValue={(value) =>
                  setPluginPermission("view_comments", value)
                }
              />
              <CheckBox
                label="Add New Comments"
                value={role.plugin_permissions.add_new_comments}
                setValue={(value) =>
                  setPluginPermission("add_new_comments", value)
                }
                disabled={isEqual(role.plugin_permissions.view_comments, false)}
              />
              <CheckBox
                label="Reply To Discussion In Comments"
                value={role.plugin_permissions.reply_to_discussion_in_comments}
                setValue={(value) =>
                  setPluginPermission("reply_to_discussion_in_comments", value)
                }
                disabled={isEqual(role.plugin_permissions.view_comments, false)}
              />
              <CheckBox
                label="Edit Comment Details"
                value={role.plugin_permissions.edit_comment_details}
                setValue={(value) =>
                  setPluginPermission("edit_comment_details", value)
                }
                disabled={isEqual(role.plugin_permissions.view_comments, false)}
              />
              <CheckBox
                label="Delete Comments Added By User"
                value={role.plugin_permissions.delete_comments_added_by_user}
                setValue={(value) =>
                  setPluginPermission("delete_comments_added_by_user", value)
                }
                disabled={isEqual(role.plugin_permissions.view_comments, false)}
              />
              <CheckBox
                label="Delete Comments Added By Other Users"
                value={
                  role.plugin_permissions.delete_comments_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_comments_added_by_other_users",
                    value
                  )
                }
                disabled={isEqual(role.plugin_permissions.view_comments, false)}
              />
            </div>
            <Divider />
          </div>
          <div className="permission-card-list">
            <PrimaryHeading className="text-17">Pages</PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label="View Pages"
                value={role.plugin_permissions.view_pages}
                setValue={(value) => setPluginPermission("view_pages", value)}
              />
              <CheckBox
                label="Add Current Page"
                value={role.plugin_permissions.add_current_page}
                setValue={(value) =>
                  setPluginPermission("add_current_page", value)
                }
                disabled={isEqual(role.plugin_permissions.view_pages, false)}
              />
              <CheckBox
                label="Reply To Discussion In Pages"
                value={role.plugin_permissions.reply_to_discussion_in_pages}
                setValue={(value) =>
                  setPluginPermission("reply_to_discussion_in_pages", value)
                }
                disabled={isEqual(role.plugin_permissions.view_pages, false)}
              />
              <CheckBox
                label="Edit Page Details"
                value={role.plugin_permissions.edit_page_details}
                setValue={(value) =>
                  setPluginPermission("edit_page_details", value)
                }
                disabled={isEqual(role.plugin_permissions.view_pages, false)}
              />
              <CheckBox
                label="Delete Pages"
                value={role.plugin_permissions.delete_pages}
                setValue={(value) => setPluginPermission("delete_pages", value)}
                disabled={isEqual(role.plugin_permissions.view_pages, false)}
              />
            </div>
            <Divider />
          </div>
          <div className="permission-card-list">
            <PrimaryHeading className="text-17">Tasks</PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label="View Tasks"
                value={role.plugin_permissions.view_tasks}
                setValue={(value) => setPluginPermission("view_tasks", value)}
              />
              <CheckBox
                label="Add New Tasks"
                value={role.plugin_permissions.add_new_tasks}
                setValue={(value) =>
                  setPluginPermission("add_new_tasks", value)
                }
                disabled={isEqual(role.plugin_permissions.view_tasks, false)}
              />
              <CheckBox
                label="Add Task Templates From Template Library"
                value={
                  role.plugin_permissions
                    .add_task_templates_from_template_library
                }
                setValue={(value) =>
                  setPluginPermission(
                    "add_task_templates_from_template_library",
                    value
                  )
                }
                disabled={isEqual(role.plugin_permissions.view_tasks, false)}
              />
              <CheckBox
                label="Reply To Discussion In Tasks"
                value={role.plugin_permissions.reply_to_discussion_in_tasks}
                setValue={(value) =>
                  setPluginPermission("reply_to_discussion_in_tasks", value)
                }
                disabled={isEqual(role.plugin_permissions.view_tasks, false)}
              />
              <CheckBox
                label="Edit Task Details"
                value={role.plugin_permissions.edit_task_details}
                setValue={(value) =>
                  setPluginPermission("edit_task_details", value)
                }
                disabled={isEqual(role.plugin_permissions.view_tasks, false)}
              />
              <CheckBox
                label="Save Tasks As Templates"
                value={role.plugin_permissions.save_tasks_as_templates}
                setValue={(value) =>
                  setPluginPermission("save_tasks_as_templates", value)
                }
                disabled={isEqual(role.plugin_permissions.view_tasks, false)}
              />
              <CheckBox
                label="Delete Tasks Added By User"
                value={role.plugin_permissions.delete_tasks_added_by_user}
                setValue={(value) =>
                  setPluginPermission("delete_tasks_added_by_user", value)
                }
                disabled={isEqual(role.plugin_permissions.view_tasks, false)}
              />
              <CheckBox
                label="Delete Tasks Added By Other Users"
                value={
                  role.plugin_permissions.delete_tasks_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_tasks_added_by_other_users",
                    value
                  )
                }
                disabled={isEqual(role.plugin_permissions.view_tasks, false)}
              />
            </div>
            <Divider />
          </div>
          <div className="permission-card-list">
            <PrimaryHeading className="text-17">Checklists</PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label="View Checklists"
                value={role.plugin_permissions.view_checklists}
                setValue={(value) =>
                  setPluginPermission("view_checklists", value)
                }
              />
              <CheckBox
                label="Add New Checklists"
                value={role.plugin_permissions.add_new_checklists}
                setValue={(value) =>
                  setPluginPermission("add_new_checklists", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_checklists,
                  false
                )}
              />
              <CheckBox
                label="Add Checklist Templates From Template Library"
                value={
                  role.plugin_permissions
                    .add_checklist_templates_from_template_library
                }
                setValue={(value) =>
                  setPluginPermission(
                    "add_checklist_templates_from_template_library",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_checklists,
                  false
                )}
              />
              <CheckBox
                label="Reply To Discussion In Checklists"
                value={
                  role.plugin_permissions.reply_to_discussion_in_checklists
                }
                setValue={(value) =>
                  setPluginPermission(
                    "reply_to_discussion_in_checklists",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_checklists,
                  false
                )}
              />
              <CheckBox
                label="Edit Checklist Details"
                value={role.plugin_permissions.edit_checklist_details}
                setValue={(value) =>
                  setPluginPermission("edit_checklist_details", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_checklists,
                  false
                )}
              />
              <CheckBox
                label="Save Checklists As Templates"
                value={role.plugin_permissions.save_checklists_as_templates}
                setValue={(value) =>
                  setPluginPermission("save_checklists_as_templates", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_checklists,
                  false
                )}
              />
              <CheckBox
                label="Delete Checklists Added By User"
                value={role.plugin_permissions.delete_checklist_added_by_user}
                setValue={(value) =>
                  setPluginPermission("delete_checklist_added_by_user", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_checklists,
                  false
                )}
              />
              <CheckBox
                label="Delete Checklists Added By Other Users"
                value={
                  role.plugin_permissions.delete_checklist_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_checklist_added_by_other_users",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_checklists,
                  false
                )}
              />
            </div>
            <Divider />
          </div>
          <div className="permission-card-list">
            <PrimaryHeading className="text-17">Files</PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label="View Files"
                value={role.plugin_permissions.view_files}
                setValue={(value) => setPluginPermission("view_files", value)}
              />
              <CheckBox
                label="Add New Files"
                value={role.plugin_permissions.add_new_files}
                setValue={(value) =>
                  setPluginPermission("add_new_files", value)
                }
                disabled={isEqual(role.plugin_permissions.view_files, false)}
              />
              <CheckBox
                label="Reply To Discussion In Files"
                value={role.plugin_permissions.reply_to_discussion_in_files}
                setValue={(value) =>
                  setPluginPermission("reply_to_discussion_in_files", value)
                }
                disabled={isEqual(role.plugin_permissions.view_files, false)}
              />
              <CheckBox
                label="Edit File Details"
                value={role.plugin_permissions.edit_file_details}
                setValue={(value) =>
                  setPluginPermission("edit_file_details", value)
                }
                disabled={isEqual(role.plugin_permissions.view_files, false)}
              />
              <CheckBox
                label="Delete Files Added By User"
                value={role.plugin_permissions.delete_files_added_by_user}
                setValue={(value) =>
                  setPluginPermission("delete_files_added_by_user", value)
                }
                disabled={isEqual(role.plugin_permissions.view_files, false)}
              />
              <CheckBox
                label="Delete Files Added By Other Users"
                value={
                  role.plugin_permissions.delete_files_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_files_added_by_other_users",
                    value
                  )
                }
                disabled={isEqual(role.plugin_permissions.view_files, false)}
              />
            </div>
            <Divider />
          </div>
          <div className="permission-card-list">
            <PrimaryHeading className="text-17">
              Content Requests
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label="View Content Requests"
                value={role.plugin_permissions.view_content_requests}
                setValue={(value) =>
                  setPluginPermission("view_content_requests", value)
                }
              />
              <CheckBox
                label="Add New Content Requests"
                value={role.plugin_permissions.add_new_content_requests}
                setValue={(value) =>
                  setPluginPermission("add_new_content_requests", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_content_requests,
                  false
                )}
              />
              <CheckBox
                label="Add Content Request Templates From Template Library"
                value={
                  role.plugin_permissions
                    .add_content_request_templates_from_template_library
                }
                setValue={(value) =>
                  setPluginPermission(
                    "add_content_request_templates_from_template_library",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_content_requests,
                  false
                )}
              />
              <CheckBox
                label="Reply To Discussion In Content Requests"
                value={
                  role.plugin_permissions
                    .reply_to_discussion_in_content_requests
                }
                setValue={(value) =>
                  setPluginPermission(
                    "reply_to_discussion_in_content_requests",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_content_requests,
                  false
                )}
              />
              <CheckBox
                label="Edit Content Request Details"
                value={role.plugin_permissions.edit_content_request_details}
                setValue={(value) =>
                  setPluginPermission("edit_content_request_details", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_content_requests,
                  false
                )}
              />
              {/* <CheckBox
                label="Save Content Requests As Templates"
                value={
                  role.plugin_permissions.save_content_requests_as_templates
                }
                setValue={(value) =>
                  setPluginPermission(
                    "save_content_requests_as_templates",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_content_requests,
                  false
                )}
              /> */}
              <CheckBox
                label="Delete Content Requests Added By User"
                value={
                  role.plugin_permissions.delete_content_requests_added_by_user
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_content_requests_added_by_user",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_content_requests,
                  false
                )}
              />
              <CheckBox
                label="Delete Content Requests Added By Other Users"
                value={
                  role.plugin_permissions
                    .delete_content_requests_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_content_requests_added_by_other_users",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_content_requests,
                  false
                )}
              />
            </div>
            <Divider />
          </div>
          <div className="permission-card-list">
            <PrimaryHeading className="text-17">
              Feedback Requests
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label="View Feedback Requests"
                value={role.plugin_permissions.view_feedback_requests}
                setValue={(value) =>
                  setPluginPermission("view_feedback_requests", value)
                }
              />
              <CheckBox
                label="Add New Feedback Requests"
                value={role.plugin_permissions.add_new_feedback_requests}
                setValue={(value) =>
                  setPluginPermission("add_new_feedback_requests", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_feedback_requests,
                  false
                )}
              />
              <CheckBox
                label="Reply To Discussion In Feedback Requests"
                value={
                  role.plugin_permissions
                    .reply_to_discussion_in_feedback_requests
                }
                setValue={(value) =>
                  setPluginPermission(
                    "reply_to_discussion_in_feedback_requests",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_feedback_requests,
                  false
                )}
              />
              <CheckBox
                label="Edit Feedback Request Details"
                value={role.plugin_permissions.edit_feedback_request_details}
                setValue={(value) =>
                  setPluginPermission("edit_feedback_request_details", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_feedback_requests,
                  false
                )}
              />
              <CheckBox
                label="Delete Feedback Requests Added By User"
                value={
                  role.plugin_permissions.delete_feedback_requests_added_by_user
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_feedback_requests_added_by_user",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_feedback_requests,
                  false
                )}
              />
              <CheckBox
                label="Delete Feedback Requests Added By Other Users"
                value={
                  role.plugin_permissions
                    .delete_feedback_requests_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_feedback_requests_added_by_other_users",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_feedback_requests,
                  false
                )}
              />
            </div>
            <Divider />
          </div>
          <div className="permission-card-list">
            <PrimaryHeading className="text-17">
              Approval Requests
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label="View Approval Requests"
                value={role.plugin_permissions.view_approval_requests}
                setValue={(value) =>
                  setPluginPermission("view_approval_requests", value)
                }
              />
              <CheckBox
                label="Add New Approval Requests"
                value={role.plugin_permissions.add_new_approval_requests}
                setValue={(value) =>
                  setPluginPermission("add_new_approval_requests", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_approval_requests,
                  false
                )}
              />
              <CheckBox
                label="Reply To Discussion In Approval Requests"
                value={
                  role.plugin_permissions
                    .reply_to_discussion_in_approval_requests
                }
                setValue={(value) =>
                  setPluginPermission(
                    "reply_to_discussion_in_approval_requests",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_approval_requests,
                  false
                )}
              />
              <CheckBox
                label="Edit Approval Request Details"
                value={role.plugin_permissions.edit_approval_requests_details}
                setValue={(value) =>
                  setPluginPermission("edit_approval_requests_details", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_approval_requests,
                  false
                )}
              />
              <CheckBox
                label="Delete Approval Requests Added By User"
                value={
                  role.plugin_permissions.delete_approval_requests_added_by_user
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_approval_requests_added_by_user",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_approval_requests,
                  false
                )}
              />
              <CheckBox
                label="Delete Approval Requests Added By Other Users"
                value={
                  role.plugin_permissions
                    .delete_approval_requests_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_approval_requests_added_by_other_users",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_approval_requests,
                  false
                )}
              />
            </div>
            <Divider />
          </div>
          <div className="permission-card-list">
            <PrimaryHeading className="text-17">Change Requests</PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label="View Change Requests"
                value={role.plugin_permissions.view_change_requests}
                setValue={(value) =>
                  setPluginPermission("view_change_requests", value)
                }
              />
              <CheckBox
                label="Add New Change Requests"
                value={role.plugin_permissions.add_new_change_requests}
                setValue={(value) =>
                  setPluginPermission("add_new_change_requests", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_change_requests,
                  false
                )}
              />
              <CheckBox
                label="Reply To Discussion In Change Requests"
                value={
                  role.plugin_permissions.reply_to_discussion_in_change_requests
                }
                setValue={(value) =>
                  setPluginPermission(
                    "reply_to_discussion_in_change_requests",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_change_requests,
                  false
                )}
              />
              <CheckBox
                label="Edit Change Request Details"
                value={role.plugin_permissions.edit_change_requests_details}
                setValue={(value) =>
                  setPluginPermission("edit_change_requests_details", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_change_requests,
                  false
                )}
              />
              <CheckBox
                label="Delete Change Requests Added By User"
                value={
                  role.plugin_permissions.delete_change_requests_added_by_user
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_change_requests_added_by_user",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_change_requests,
                  false
                )}
              />
              <CheckBox
                label="Delete Change Requests Added By Other Users"
                value={
                  role.plugin_permissions
                    .delete_change_requests_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_change_requests_added_by_other_users",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_change_requests,
                  false
                )}
              />
            </div>
            <Divider />
          </div>
          <div className="permission-card-list">
            <PrimaryHeading className="text-17">Notes</PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label="View Notes"
                value={role.plugin_permissions.view_view_notes}
                setValue={(value) =>
                  setPluginPermission("view_view_notes", value)
                }
              />
              <CheckBox
                label="Add New Notes"
                value={role.plugin_permissions.add_new_view_notes}
                setValue={(value) =>
                  setPluginPermission("add_new_view_notes", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_view_notes,
                  false
                )}
              />
              <CheckBox
                label="Add Note Templates From Template Library"
                value={
                  role.plugin_permissions
                    .add_view_note_templates_from_template_library
                }
                setValue={(value) =>
                  setPluginPermission(
                    "add_view_note_templates_from_template_library",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_view_notes,
                  false
                )}
              />
              <CheckBox
                label="Reply To Discussion In Notes"
                value={
                  role.plugin_permissions.reply_to_discussion_in_view_notes
                }
                setValue={(value) =>
                  setPluginPermission(
                    "reply_to_discussion_in_view_notes",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_view_notes,
                  false
                )}
              />
              <CheckBox
                label="Edit Note Details"
                value={role.plugin_permissions.edit_view_notes_details}
                setValue={(value) =>
                  setPluginPermission("edit_view_notes_details", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_view_notes,
                  false
                )}
              />
              <CheckBox
                label="Save Notes As Templates"
                value={role.plugin_permissions.save_view_notes_as_templates}
                setValue={(value) =>
                  setPluginPermission("save_view_notes_as_templates", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_view_notes,
                  false
                )}
              />
              <CheckBox
                label="Delete Notes Added By User"
                value={role.plugin_permissions.delete_notes_added_by_user}
                setValue={(value) =>
                  setPluginPermission("delete_notes_added_by_user", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_view_notes,
                  false
                )}
              />
              <CheckBox
                label="Delete Notes Added By Other Users"
                value={
                  role.plugin_permissions.delete_notes_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_notes_added_by_other_users",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_view_notes,
                  false
                )}
              />
            </div>
            <Divider />
          </div>
          <div className="permission-card-list">
            <PrimaryHeading className="text-17">Tutorials</PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label="View Tutorials"
                value={role.plugin_permissions.view_view_tutorials}
                setValue={(value) =>
                  setPluginPermission("view_view_tutorials", value)
                }
              />
              <CheckBox
                label="Add New Tutorials"
                value={role.plugin_permissions.add_new_view_tutorials}
                setValue={(value) =>
                  setPluginPermission("add_new_view_tutorials", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_view_tutorials,
                  false
                )}
              />
              <CheckBox
                label="Add Tutorial Templates From Template Library"
                value={
                  role.plugin_permissions
                    .add_view_tutorials_templates_from_template_library
                }
                setValue={(value) =>
                  setPluginPermission(
                    "add_view_tutorials_templates_from_template_library",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_view_tutorials,
                  false
                )}
              />
              <CheckBox
                label="Reply To Discussion In Tutorials"
                value={
                  role.plugin_permissions.reply_to_discussion_in_view_tutorials
                }
                setValue={(value) =>
                  setPluginPermission(
                    "reply_to_discussion_in_view_tutorials",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_view_tutorials,
                  false
                )}
              />
              <CheckBox
                label="Edit Tutorial Details"
                value={role.plugin_permissions.edit_view_tutorials_details}
                setValue={(value) =>
                  setPluginPermission("edit_view_tutorials_details", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_view_tutorials,
                  false
                )}
              />
              <CheckBox
                label="Save Tutorials As Templates"
                value={role.plugin_permissions.save_view_tutorials_as_templates}
                setValue={(value) =>
                  setPluginPermission("save_view_tutorials_as_templates", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_view_tutorials,
                  false
                )}
              />
              <CheckBox
                label="Delete Tutorials Added By User"
                value={role.plugin_permissions.delete_tutorials_added_by_user}
                setValue={(value) =>
                  setPluginPermission("delete_tutorials_added_by_user", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_view_tutorials,
                  false
                )}
              />
              <CheckBox
                label="Delete Tutorials Added By Other Users"
                value={
                  role.plugin_permissions.delete_tutorials_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_tutorials_added_by_other_users",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_view_tutorials,
                  false
                )}
              />
            </div>
            <Divider />
          </div>
          <div className="permission-card-list">
            <PrimaryHeading className="text-17">
              Support Requests
            </PrimaryHeading>
            <div className="flex flex-col gap-7">
              <CheckBox
                label="View Support Requests"
                value={role.plugin_permissions.view_support_requests}
                setValue={(value) =>
                  setPluginPermission("view_support_requests", value)
                }
              />
              <CheckBox
                label="Add New Support Requests"
                value={role.plugin_permissions.add_new_support_requests}
                setValue={(value) =>
                  setPluginPermission("add_new_support_requests", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_support_requests,
                  false
                )}
              />
              <CheckBox
                label="Reply To Discussion In Support Requests"
                value={
                  role.plugin_permissions
                    .reply_to_discussion_in_support_requests
                }
                setValue={(value) =>
                  setPluginPermission(
                    "reply_to_discussion_in_support_requests",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_support_requests,
                  false
                )}
              />
              <CheckBox
                label="Edit Support Request Details"
                value={role.plugin_permissions.edit_support_requests_details}
                setValue={(value) =>
                  setPluginPermission("edit_support_requests_details", value)
                }
                disabled={isEqual(
                  role.plugin_permissions.view_support_requests,
                  false
                )}
              />
              <CheckBox
                label="Delete Support Requests Added By User"
                value={
                  role.plugin_permissions.delete_support_requests_added_by_user
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_support_requests_added_by_user",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_support_requests,
                  false
                )}
              />
              <CheckBox
                label="Delete Support Requests Added By Other Users"
                value={
                  role.plugin_permissions
                    .delete_support_requests_added_by_other_users
                }
                setValue={(value) =>
                  setPluginPermission(
                    "delete_support_requests_added_by_other_users",
                    value
                  )
                }
                disabled={isEqual(
                  role.plugin_permissions.view_support_requests,
                  false
                )}
              />
            </div>
          </div>

          {isEqual(id, "add") ||
          isEqual(role.system_status, 1) ||
          !remove_items_from_site_template || !web ? (
            ""
          ) : (
            <DeleteButton
              type="button"
              deleteMessage="You are about to delete this user role from your account. This action cannot be undone! All related settings and data will be permanently removed. Are you sure you want to do this?"
              deleteTitle="Delete This Role?"
              deleteButtonText="Confirm Delete"
              className="mt-3"
              style={{ left: "0" }}
              position="top"
              title="Delete Role"
              message="If you no longer need this user role, you can choose to delete it from your account. This will permanently remove the user role and all of its associated data."
              buttonText="Delete Role"
              onDelete={() => deleteRole(id)}
            />
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
