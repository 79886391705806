import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pluginList: [],
  loaded: false,
};

const pluginListSlice = createSlice({
  name: "pluginList",
  initialState,
  reducers: {
    getAllTemplatePluginList: (state, action) => {
      state.pluginList = action.payload;
      state.loaded = true;
    },
    addTemplatePluginList: (state, action) => {
      state.pluginList.unshift(action.payload);
    },
    deleteTemplatePluginList: (state, action) => {
      state.pluginList = state.pluginList.filter(
        (pluginlist) => Number(pluginlist.id) !== Number(action.payload)
      );
    },
    updateTemplatePluginList: (state, action) => {
      const index = state.pluginList.findIndex(
        (element) => Number(element.id) === Number(action.payload.pluginlist_id)
      );
      if (index !== -1) {
        for (let key in action.payload) {
          state.pluginList[index][key] = action.payload[key];
        }
      }
    },
  },
});

export const {
  getAllTemplatePluginList,
  addTemplatePluginList,
  deleteTemplatePluginList,
  updateTemplatePluginList,
} = pluginListSlice.actions;

export default pluginListSlice.reducer;
