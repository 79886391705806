export default function SecondaryButton(props) {
    return (
      <>
        <button
          onClick={props.onClick}
          className={`secondary-button transition-all`}
        >
          {props.children}
        </button>
      </>
    );
  }
  