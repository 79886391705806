import React, { useEffect, useState } from "react";
import { SaveIcon } from "allIcons";
import {
  AccordionBody,
  BodyContainer,
  HeaderContainer,
  Loader,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
  SelectField,
  ToggleCard,
} from "commonUI";
import { extractTextFromHtml, isEmpty, parse, useSearch } from "helpers";
import {
  useMaintenance,
  usePagesList,
  useUpdateMaintenanceSetting,
} from "hooks/maintenance";

export default function Maintenance() {
  const { data, isLoading } = useMaintenance();
  const { data: pagesList, isLoading: pagesLoading } = usePagesList();
  const { mutate: updateMaintenance, isLoading: isUpdating } =
    useUpdateMaintenanceSetting();
  const { web } = useSearch();

  const [inputValues, setInputValues] = useState({
    coming_soon: false,
    maintenance_mode: false,
    coming_soon_pageid: 0,
    maintenance_pageid: 0,
  });

  const handleInputChange = (key, value) => {
    if (key === "coming_soon" && value === true) {
      setInputValues((pre) => ({
        ...pre,
        maintenance_mode: false,
        [key]: value,
      }));
    } else if (key === "maintenance_mode" && value === true) {
      setInputValues((pre) => ({
        ...pre,
        coming_soon: false,
        [key]: value,
      }));
    } else {
      setInputValues((pre) => ({ ...pre, [key]: value }));
    }
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      setInputValues((pre) => parse(data?.maintenance_settings || "") || pre);
    }
  }, [data]);

  return (
    <>
      <RightMainContainer>
        <Loader show={isUpdating} loader="block" />
        <HeaderContainer>
          <PrimaryHeading>Maintenance</PrimaryHeading>
          <MainButton
            onClick={() =>
              updateMaintenance({
                data: inputValues,
                website_id: web,
              })
            }
            disabled={isLoading || pagesLoading}
          >
            <SaveIcon /> Save Changes
          </MainButton>
        </HeaderContainer>
        <BodyContainer>
          <ToggleCard
            label="Coming Soon Mode"
            description="Choose to enable or disable the website coming soon mode when your site is being developed and not yet ready for the public to see. If this is enabled, visitors to your site will not be able to view your website, but will instead see the coming soon layout or page that you select below."
            value={inputValues.coming_soon}
            setValue={(value) => handleInputChange("coming_soon", value)}
          />
          <AccordionBody show={inputValues.coming_soon}>
            <SelectField
              placeholder="Select Page"
              label="Select Coming Soon Page"
              description="Select a page to use as the screen your website visitors will see when trying to access your site whenever the coming soon mode is enabled."
              value={inputValues.coming_soon_pageid}
              setValue={(value) =>
                handleInputChange("coming_soon_pageid", value)
              }
            >
              {Array.isArray(pagesList?.Data)
                ? pagesList?.Data?.map((page) => (
                    <option value={page.page_id}>
                      {extractTextFromHtml(page.title)}
                    </option>
                  ))
                : ""}
            </SelectField>
          </AccordionBody>
          <ToggleCard
            label="Maintenance Mode"
            description="Choose to enable or disable the website maintenance mode feature. If this is enabled, anyone who visits your site while you are performing any maintenance such as updates to plugins or themes will see the page that you select below rather than the boring default message. This will reassure visitors that the site will be back soon, and help prevent them from thinking your site is broken."
            value={inputValues.maintenance_mode}
            setValue={(value) => handleInputChange("maintenance_mode", value)}
          />
          <AccordionBody show={inputValues.maintenance_mode}>
            <SelectField
              placeholder="Select Page"
              label="Select Maintenance Page"
              description="Select a page to use as the screen your website visitors will see when trying to access your site whenever the maintenance mode is enabled."
              value={inputValues.maintenance_pageid}
              setValue={(value) =>
                handleInputChange("maintenance_pageid", value)
              }
            >
              {Array.isArray(pagesList?.Data)
                ? pagesList?.Data?.map((page) => (
                    <option value={page.page_id}>
                      {extractTextFromHtml(page.title)}
                    </option>
                  ))
                : ""}
            </SelectField>
          </AccordionBody>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
