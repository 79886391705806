import { useEffect, useState } from "react";
import "assets/css/notification.css";
import {
  SecondaryHeading,
  ToggleCard,
  BodyContainer,
  RightMainContainer,
  HeaderContainer,
  PrimaryHeading,
  MainButton,
  Loader,
} from "commonUI";
import { SaveIcon } from "allIcons";
import { defaultNotificationSetting } from "./enum";
import {
  useNotifications,
  useUpdateNotificationSetting,
} from "api/siteSettings/notifications";
import { isEqual, parse, useSearch } from "helpers";
import { useUserDetails, useUserPermissions } from "hooks/user";

export const NotificationList = ({
  email,
  web,
  setEmail,
  setWeb,
  title,
  last,
}) => {
  return (
    <>
      <div className={`notification-list-card ${last ? "border-0" : ""}`}>
        <div className="flex items-center">
          <div className="w-full self-center">
            <SecondaryHeading className="text-13 font-semibold text-theme-secondary">
              {title ?? "Notification Title"}
            </SecondaryHeading>
          </div>
          <div className="flex items-center gap-28">
            <div className="">
              <ToggleCard value={email} setValue={setEmail} />
            </div>
            <div className="">
              <ToggleCard value={web} setValue={setWeb} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function Notifications() {
  const { web = null } = useSearch();
  const [notificationSetting, setNotificationSetting] = useState(
    defaultNotificationSetting
  );
  const { add_new_items_to_site_template, edit_item_details_in_site_template } =
    useUserPermissions();
  const handleChangeNotificationSetting = (key, value) => {
    setNotificationSetting((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const { data: notifications, isLoading: loadingNotification } =
    useNotifications();
  const { isLoading } = useUserDetails();
  const {
    mutate: updateNotificationSetting,
    isLoading: updatingNotificationSetting,
  } = useUpdateNotificationSetting();

  useEffect(() => {
    if (!loadingNotification) {
      let notificationData = notifications?.find((_) =>
        isEqual(_.website_id, web)
      );
      if (notificationData) {
        setNotificationSetting(parse(notificationData?.noti_settings ?? notificationData?.notifications_settings));
      }
    }
  }, [notifications, loadingNotification]);

  const handleSaveChanges = () => {
    let notificationData = notifications?.find((_) =>
      isEqual(_.website_id, web)
    );
    updateNotificationSetting({
      apiKey: notificationData?.apiKey || localStorage.getItem("apikey"),
      added_by: notificationData?.added_by || localStorage.getItem("user_id"),
      website_id: notificationData?.website_id || web,
      system_status: notificationData?.system_status || 0,
      notifications_settings: notificationSetting,
    });
  };

  return (
    <>
      <RightMainContainer>
        <Loader
          show={
            updatingNotificationSetting || (loadingNotification && !isLoading)
          }
          loader="block"
        />
        <HeaderContainer>
          <PrimaryHeading>Notifications</PrimaryHeading>
          {add_new_items_to_site_template ||
          edit_item_details_in_site_template ? (
            <MainButton
              disabled={loadingNotification || updatingNotificationSetting}
              onClick={handleSaveChanges}
            >
              <SaveIcon />
              Save Changes
            </MainButton>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          {/* 
          <div className="flex items-center justify-between mb-3">
            <PrimaryHeading className="text-17">
              An Action Is Completed
            </PrimaryHeading>
            <div>
              <div className="notification-detail-header">
                <h2 className="text-theme-secondary font-bold">Email</h2>
                <h2 className="text-theme-secondary font-bold">Website</h2>
              </div>
            </div>
          </div>
          <hr className="line" /> */}
          {/* 
          <NotificationList
            title="An Assigned User Views An Item For The First Time"
            last={true}
            email={
              notificationSetting.an_assigned_user_views_an_item_for_the_first_time_email
            }
            web={
              notificationSetting.an_assigned_user_views_an_item_for_the_first_time_website
            }
            setEmail={(value) =>
              handleChangeNotificationSetting(
                "an_assigned_user_views_an_item_for_the_first_time_email",
                value
              )
            }
            setWeb={(value) =>
              handleChangeNotificationSetting(
                "an_assigned_user_views_an_item_for_the_first_time_website",
                value
              )
            }
          /> */}
          {/* <div className="mb-2 mt-2">
            <PrimaryHeading className="text-17">
              Activity In A Discussion
            </PrimaryHeading>
          </div>
          <hr className="line mb-0" />

          <NotificationList
            title="A User Is @Mentioned"
            email={notificationSetting["a_user_is_@Mentioned_email"]}
            web={notificationSetting["a_user_is_@Mentioned_website"]}
            setEmail={(value) =>
              handleChangeNotificationSetting(
                "a_user_is_@Mentioned_email",
                value
              )
            }
            setWeb={(value) =>
              handleChangeNotificationSetting(
                "a_user_is_@Mentioned_website",
                value
              )
            }
          />
          <NotificationList
            title="A Reply Is Added"
            email={notificationSetting["a_reply_is_added_email"]}
            web={notificationSetting["a_reply_is_added_website"]}
            setEmail={(value) =>
              handleChangeNotificationSetting("a_reply_is_added_email", value)
            }
            setWeb={(value) =>
              handleChangeNotificationSetting("a_reply_is_added_website", value)
            }
          />
          <NotificationList
            title="An Item Is Added"
            last={true}
            email={notificationSetting["an_item_is_added_email"]}
            web={notificationSetting["an_item_is_added_website"]}
            setEmail={(value) =>
              handleChangeNotificationSetting("an_item_is_added_email", value)
            }
            setWeb={(value) =>
              handleChangeNotificationSetting("an_item_is_added_website", value)
            }
          /> */}
          <div className="flex items-center justify-between mb-3">
            <PrimaryHeading className="text-17">
              Changes To Item Details
            </PrimaryHeading>
            <div>
              <div className="notification-detail-header">
                <h2 className="text-theme-secondary font-bold">Email</h2>
                <h2 className="text-theme-secondary font-bold">Website</h2>
              </div>
            </div>
          </div>
          <hr className="line" />
          {/* <div className="mb-2">
            <PrimaryHeading className="text-17">
              Changes To Item Details
            </PrimaryHeading>
          </div>
          <hr className="line mb-0" /> */}
          <NotificationList
            title="The Title Is Updated"
            email={notificationSetting["the_title_is_updated_email"]}
            web={notificationSetting["the_title_is_updated_website"]}
            setEmail={(value) =>
              handleChangeNotificationSetting(
                "the_title_is_updated_email",
                value
              )
            }
            setWeb={(value) =>
              handleChangeNotificationSetting(
                "the_title_is_updated_website",
                value
              )
            }
          />
          <NotificationList
            title="A User Is Assigned"
            email={notificationSetting["a_user_is_assigned_email"]}
            web={notificationSetting["a_user_is_assigned_website"]}
            setEmail={(value) =>
              handleChangeNotificationSetting("a_user_is_assigned_email", value)
            }
            setWeb={(value) =>
              handleChangeNotificationSetting(
                "a_user_is_assigned_website",
                value
              )
            }
          />
          <NotificationList
            title="A User Is Unassigned"
            email={notificationSetting["a_user_is_unassigned_email"]}
            web={notificationSetting["a_user_is_unassigned_website"]}
            setEmail={(value) =>
              handleChangeNotificationSetting(
                "a_user_is_unassigned_email",
                value
              )
            }
            setWeb={(value) =>
              handleChangeNotificationSetting(
                "a_user_is_unassigned_website",
                value
              )
            }
          />
          <NotificationList
            title="The Status Is Updated"
            email={notificationSetting["the_status_is_updated_email"]}
            web={notificationSetting["the_status_is_updated_website"]}
            setEmail={(value) =>
              handleChangeNotificationSetting(
                "the_status_is_updated_email",
                value
              )
            }
            setWeb={(value) =>
              handleChangeNotificationSetting(
                "the_status_is_updated_website",
                value
              )
            }
          />
          <NotificationList
            title="The Priority Is Updated"
            email={notificationSetting["the_priority_is_updated_email"]}
            web={notificationSetting["the_priority_is_updated_website"]}
            setEmail={(value) =>
              handleChangeNotificationSetting(
                "the_priority_is_updated_email",
                value
              )
            }
            setWeb={(value) =>
              handleChangeNotificationSetting(
                "the_priority_is_updated_website",
                value
              )
            }
          />
          <NotificationList
            title="The Due Date Is Updated"
            last={true}
            email={notificationSetting["the_due_date_is_updated_email"]}
            web={notificationSetting["the_due_date_is_updated_website"]}
            setEmail={(value) =>
              handleChangeNotificationSetting(
                "the_due_date_is_updated_email",
                value
              )
            }
            setWeb={(value) =>
              handleChangeNotificationSetting(
                "the_due_date_is_updated_website",
                value
              )
            }
          />
          <NotificationList
            title="The Tags Is Updated"
            last={true}
            web={notificationSetting["the_tags_is_updated_website"]}
            email={notificationSetting["the_tags_is_updated_email"]}
            setEmail={(value) =>
              handleChangeNotificationSetting(
                "the_tags_is_updated_email",
                value
              )
            }
            setWeb={(value) =>
              handleChangeNotificationSetting(
                "the_tags_is_updated_website",
                value
              )
            }
          />
          {/* <div className="mb-2 mt-2">
            <PrimaryHeading className="text-17">
              Assignment Reminders
            </PrimaryHeading>
          </div>
          <hr className="line mb-0" />
          <NotificationList
            title="7 Days Before Due Date"
            email={notificationSetting["7_days_before_due_date_email"]}
            web={notificationSetting["7_days_before_due_date_website"]}
            setEmail={(value) =>
              handleChangeNotificationSetting(
                "7_days_before_due_date_email",
                value
              )
            }
            setWeb={(value) =>
              handleChangeNotificationSetting(
                "7_days_before_due_date_website",
                value
              )
            }
          />
          <NotificationList
            title="3 Days Before Due Date"
            email={notificationSetting["3_days_before_due_date_email"]}
            web={notificationSetting["3_days_before_due_date_website"]}
            setEmail={(value) =>
              handleChangeNotificationSetting(
                "3_days_before_due_date_email",
                value
              )
            }
            setWeb={(value) =>
              handleChangeNotificationSetting(
                "3_days_before_due_date_website",
                value
              )
            }
          />
          <NotificationList
            title="1 Day Before Due Date"
            email={notificationSetting["1_day_before_due_date_email"]}
            web={notificationSetting["1_day_before_due_date_website"]}
            setEmail={(value) =>
              handleChangeNotificationSetting(
                "1_day_before_due_date_email",
                value
              )
            }
            setWeb={(value) =>
              handleChangeNotificationSetting(
                "1_day_before_due_date_website",
                value
              )
            }
          />
          <NotificationList
            title="On Due Date"
            email={notificationSetting["on_due_date_email"]}
            web={notificationSetting["on_due_date_website"]}
            setEmail={(value) =>
              handleChangeNotificationSetting("on_due_date_email", value)
            }
            setWeb={(value) =>
              handleChangeNotificationSetting("on_due_date_website", value)
            }
          />
          <NotificationList
            title="Every Day After Due Date"
            last={true}
            email={notificationSetting["every_day_after_due_date_email"]}
            web={notificationSetting["every_day_after_due_date_website"]}
            setEmail={(value) =>
              handleChangeNotificationSetting(
                "every_day_after_due_date_email",
                value
              )
            }
            setWeb={(value) =>
              handleChangeNotificationSetting(
                "every_day_after_due_date_website",
                value
              )
            }
          /> */}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
