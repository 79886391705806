export default function LogoutIcon(props) {
  function HandleClick() {
    props.onClick && props.onClick();
  }
  return (
    <>
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`cursor-pointer ${props.className ?? ""}`}
        onClick={HandleClick}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.05566 14.4446C1.57129 14.9602 2.27051 15.25 3 15.25H7C7.19824 15.2473 7.3877 15.1675 7.52734 15.0276C7.66797 14.8875 7.74707 14.698 7.75 14.5C7.74707 14.302 7.66797 14.1125 7.52734 13.9724C7.3877 13.8325 7.19824 13.7527 7 13.75H3C2.66895 13.75 2.35059 13.6184 2.11621 13.384C1.88184 13.1494 1.75 12.8315 1.75 12.5V3.5C1.75 3.16846 1.88184 2.85059 2.11621 2.61621C2.35059 2.38184 2.66895 2.25 3 2.25H7C7.19824 2.24731 7.3877 2.16748 7.52734 2.02759C7.62012 1.93481 7.68652 1.82056 7.72168 1.69678C7.73926 1.6333 7.74902 1.56714 7.75 1.5C7.74707 1.302 7.66797 1.11255 7.52734 0.972412C7.3877 0.83252 7.19824 0.752686 7 0.75H3C2.27051 0.75 1.57129 1.03979 1.05566 1.55542C0.540039 2.07129 0.25 2.77075 0.25 3.5V12.5C0.25 13.2292 0.540039 13.929 1.05566 14.4446ZM13.6904 8.29004C13.7314 8.19897 13.752 8.09985 13.75 8C13.75 7.90039 13.7295 7.80151 13.6904 7.71021C13.6523 7.62085 13.5977 7.53955 13.5303 7.46997L11.0303 4.96997C10.8896 4.82959 10.6992 4.75073 10.5 4.75073C10.3018 4.75073 10.1104 4.82959 9.96973 4.96997C9.89941 5.03906 9.84277 5.12134 9.80469 5.2124C9.7666 5.30347 9.74609 5.40137 9.74609 5.5C9.74609 5.59888 9.7666 5.69653 9.80469 5.7876C9.84277 5.87866 9.89941 5.96118 9.96973 6.03003L11.1904 7.25H5C4.80078 7.25 4.61035 7.3291 4.46973 7.46973C4.3291 7.61035 4.25 7.80127 4.25 8C4.24902 8.09888 4.26758 8.19702 4.30469 8.28857C4.3418 8.38013 4.39648 8.46338 4.4668 8.53345C4.53711 8.60327 4.62012 8.65845 4.71191 8.6958C4.80273 8.73291 4.90137 8.75146 5 8.75H11.1904L9.96973 9.96997C9.91895 10.0193 9.87598 10.0754 9.8418 10.137C9.82812 10.1614 9.81543 10.1865 9.80469 10.2124C9.7666 10.3035 9.74609 10.4014 9.74609 10.5C9.74609 10.5989 9.7666 10.6965 9.80469 10.7876C9.84277 10.8787 9.89941 10.9612 9.96973 11.03C10.0391 11.1006 10.1211 11.1565 10.2129 11.1943C10.3037 11.2322 10.4014 11.251 10.5 11.25C10.5986 11.2505 10.6963 11.2312 10.7871 11.1936C10.8779 11.1558 10.9609 11.1001 11.0303 11.03L13.5303 8.53003C13.6006 8.46265 13.6553 8.38062 13.6904 8.29004Z"
          fill={props.fill ?? '#0066ff'}
        />
      </svg>
    </>
  );
}
