import { useDeferredValue, useEffect, useState } from "react";
import {
  ListIconWrapper,
  BodyContainer,
  MainButton,
  PrimaryHeading,
  HeaderContainer,
  ListCard,
  RightMainContainer,
  LoadingListCards,
  TemplateNotFound,
  PopOver,
  PopUpListing,
  AddFromTemplateLibrary,
  Loader,
  InputField,
  FiltersList,
} from "commonUI";
import { AddIcon, RolesIcon, CloudDownloadIcon } from "allIcons";
import {
  useAddDefaultRole,
  useAddRole,
  useCheckRoleBeforeDelete,
  useDefaultRoles,
  useDeleteDefaultRole,
  useDeleteRole,
  useRoles,
} from "api/usersModule/roles";
import { useUtilities, isEqual, useSearch, search, omit, parse } from "helpers";
import {
  libraryItemFiltering,
  libraryItemSorting,
} from "filterAndSort/templateLibrary";
import { useUserPermissions } from "hooks/user";

export default function RolesTab(props) {
  const { web = null } = useSearch();
  const { navigate } = useUtilities();
  const { data: roles, isLoading, error } = useDefaultRoles();
  const { data: rolesList, isLoading: loadingRoles } = useRoles();
  const { mutate: addDefaultRole, isLoading: isAdding } = useAddDefaultRole();
  const { mutate: removeDefaultRole, isLoading: removingDefaultRole } =
    useDeleteDefaultRole();
  const { mutate: addRoleToWeb, isLoading: addingRoleToWeb } = useAddRole(
    "",
    true
  );
  const { mutate: deleteRole, isLoading: deletingRole } = useDeleteRole();
  const {
    add_new_items_to_site_template,
    add_items_from_template_library,
    remove_items_from_site_template,
    edit_item_details_in_site_template,
  } = useUserPermissions();

  const {
    mutateAsync: checkRoleBeforeDelete,
    isLoading: checkingRoleBeforeDelete,
  } = useCheckRoleBeforeDelete();

  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [filterData, setFilterData] = useState({});
  const defferedSearch = useDeferredValue(searchText);

  const [addModal, setAddModal] = useState(false);
  const [addRole, setAddRole] = useState(false);

  useEffect(() => {
    setSearchResult(
      roles?.filter((role) => search(role.role_title, defferedSearch) && !isEqual(role.nick, 'owner') ) ?? []
    );
  }, [defferedSearch, roles]);

  const applySorting = (order, orderBy) => {
    setSearchResult((prevSearchResult) =>
      libraryItemSorting(order, orderBy, prevSearchResult, "role_title")
    );
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setSearchResult(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        roles,
        web ? "user_role" : "system_status"
      )
    );
  };
  const rolesTitle = roles?.map((role) => role.role_title);

  return (
    <>
      <RightMainContainer>
        <Loader
          show={
            isAdding ||
            removingDefaultRole ||
            addingRoleToWeb ||
            deletingRole ||
            checkingRoleBeforeDelete
          }
          loader="block"
        />
        <HeaderContainer>
          <PrimaryHeading>Roles</PrimaryHeading>
          <div className="relative">
            {add_new_items_to_site_template ? (
              <MainButton onClick={() => setAddModal(!addModal)}>
                <AddIcon />
                Add
              </MainButton>
            ) : (
              ""
            )}
            <PopOver
              toolTip={{ vertical: "top", right: "30px" }}
              open={addModal}
              onClose={() => {
                setAddModal(false);
                setAddRole(false);
              }}
              style={{
                top: "calc(100% + 10px)",
                right: "0",
                bottom: "auto",
              }}
            >
              {addRole ? (
                <AddFromTemplateLibrary
                  heading="Add Role From User Library"
                  isLoading={loadingRoles}
                  data={rolesList
                    .filter((ele) =>
                      web
                        ? !rolesTitle.includes(ele.role_title)
                        : !isEqual(ele.default_tem, "1")
                    )
                    .map((item) => ({
                      ...item,
                      title: item.role_title,
                      onClick: () => {
                        if (web) {
                          addRoleToWeb({
                            data: omit(["id"], {
                              ...item,
                              system_default: 0,
                              website_id: web,
                              app_permissions: parse(item.app_permissions),
                              plugin_permissions: parse(
                                item.plugin_permissions
                              ),
                            }),
                          });
                          return;
                        }
                        addDefaultRole({ id: item.id, website_id: web });
                      },
                      icon: (
                        <ListIconWrapper>
                          <RolesIcon fill="white" />
                        </ListIconWrapper>
                      ),
                    }))}
                  emptyTemplate={{
                    label: "Role",
                    linkLabel: "Role",
                    link: `/site-settings/roles/add${web ? `?web=${web}` : ""}`,
                  }}
                />
              ) : (
                <PopUpListing
                  data={[
                    add_items_from_template_library && {
                      title: "From Account",
                      icon: <CloudDownloadIcon />,
                      onClick: () => {
                        setAddRole(true);
                      },
                      arrow: true,
                    },
                    {
                      title: "Create New Role",
                      icon: <AddIcon />,
                      onClick: () => {
                        navigate(
                          `/site-settings/roles/add${web ? `?web=${web}` : ""}`
                        );
                      },
                    },
                  ].filter((_) => _)}
                />
              )}
            </PopOver>
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            searchIcon
            placeholder="Search"
            filter
            value={searchText}
            setValue={setSearchText}
            showTags={false}
            filterData={filterData}
            onApplyFilter={applyFilter}
            applySorting={applySorting}
            filterAddTitle={web ? "User Type" : "Library Source"}
            filterTitle={web ? "User Type" : "Library Source"}
            sortOptions={[
              {
                label: "Alphabetical By Title",
                value: "alphabetical-by-title",
              },
              {
                label: "Date Added",
                value: "date-added",
              },
            ]}
            filterList={
              web
                ? [
                    {
                      id: 1,
                      title: "Team Member",
                      user_role: "Team Member",
                    },
                    {
                      id: 2,
                      title: "Client",
                      user_role: "Client",
                    },
                  ]
                : [
                    {
                      id: 1,
                      title: "Official - Included By Default",
                      system_status: 1,
                    },
                    {
                      id: 2,
                      title: "Custom - Created By Users",
                      system_status: 0,
                    },
                  ]
            }
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          {isLoading ? (
            <LoadingListCards />
          ) : searchResult.length === 0 ? (
            <TemplateNotFound
              label="Role"
              linkLabel="Role"
              link={`/site-settings/roles/add${web ? `?web=${web}` : ""}`}
            />
          ) : (
            searchResult.map((iner, index) => {
              return (
                <ListCard
                  data={iner.role_title}
                  key={index}
                  index={index}
                  strictLink={true}
                  link={
                    edit_item_details_in_site_template
                      ? `/${props.from ?? "site-settings"}/roles/${iner.id}`
                      : ""
                  }
                  editIcon={edit_item_details_in_site_template}
                  icon={
                    <ListIconWrapper>
                      <RolesIcon fill="white" />
                    </ListIconWrapper>
                  }
                  deleteIcon={true}
                  crossIcon={remove_items_from_site_template}
                  onCross={async () => {
                    const result = await checkRoleBeforeDelete(iner.id).then(
                      (res) => {
                        if (
                          isEqual(res.forTemp, 0) &&
                          isEqual(res.forTemp, 0)
                        ) {
                          if (web) {
                            deleteRole(iner.id);
                            return true;
                          }
                          removeDefaultRole({
                            id: iner.id,
                            website_id: web,
                          });
                          return true;
                        } else {
                          return false;
                        }
                      }
                    );
                    return result;
                  }}
                  showModalonCross
                  deleteTitle={web ? "Delete This Role?" : "Remove This Role?"}
                  deleteButtonText={web ? "Confirm Delete" : "Confirm"}
                  showDeleteResult
                  deleteMessage={
                    web
                      ? "You are about to delete this user role from your site. This action cannot be undone! Any item using this role will revert to the system status. Are you sure you want to do this? "
                      : "You are about to remove this user role from your default site template. Are you sure you want to do this?"
                  }
                  deleteResponseMessage={
                    web
                      ? "This role cannot be removed from the site because one or more users are assigned to it on the site. To remove this role, please assign the users to another role first."
                      : "This role cannot be removed from the site template because one or more users are assigned to it in the site template. To remove this role, please assign the users to another role first."
                  }
                  deleteResponseTitle="Cannot Remove This Role"
                />
              );
            })
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
