export default function AssignmentIcon({ fill, ...props }) {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62823 2.35294H10.1176C11.0259 2.35294 11.7647 3.09176 11.7647 4V14.3529C11.7647 15.2612 11.0259 16 10.1176 16H1.64706C0.738824 16 0 15.2612 0 14.3529V4C0 3.09176 0.738824 2.35294 1.64706 2.35294H2.13647C2.46118 1.78824 3.06824 1.41176 3.76471 1.41176H3.88706C4.17882 0.588235 4.96 0 5.88235 0C6.80471 0 7.58588 0.588235 7.87765 1.41176H8C8.69647 1.41176 9.30353 1.78824 9.62823 2.35294ZM4.47059 2.82353H3.76471C3.50588 2.82353 3.29412 3.03529 3.29412 3.29412C3.29412 3.55294 3.50588 3.76471 3.76471 3.76471H8C8.25882 3.76471 8.47059 3.55294 8.47059 3.29412C8.47059 3.03529 8.25882 2.82353 8 2.82353H7.29412C6.90353 2.82353 6.58824 2.50824 6.58824 2.11765C6.58824 1.72706 6.27294 1.41176 5.88235 1.41176C5.49176 1.41176 5.17647 1.72706 5.17647 2.11765C5.17647 2.50824 4.86118 2.82353 4.47059 2.82353ZM10.1176 14.5882C10.2494 14.5882 10.3529 14.48 10.3529 14.3529V4C10.3529 3.86824 10.2494 3.76471 10.1176 3.76471H9.82118C9.61412 4.57412 8.87529 5.17647 8 5.17647H3.76471C2.88941 5.17647 2.15059 4.57412 1.94353 3.76471H1.64706C1.52 3.76471 1.41176 3.86824 1.41176 4V14.3529C1.41176 14.48 1.52 14.5882 1.64706 14.5882H10.1176ZM5.14849 10.8518C5.28496 10.9882 5.46849 11.0588 5.64732 11.0588C5.82614 11.0588 6.00967 10.9882 6.14614 10.8518L7.7932 9.20471C8.07085 8.93177 8.07085 8.48471 7.7932 8.20706C7.52026 7.92941 7.0732 7.92941 6.79555 8.20706L5.64732 9.3553L5.44026 9.14824C5.16732 8.87059 4.72026 8.87059 4.44261 9.14824C4.16496 9.42118 4.16496 9.86824 4.44261 10.1459L5.14849 10.8518Z"
        fill={fill ?? "white"}
      />
    </svg>
  );
}
