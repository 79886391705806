export default function FormFieldIcon(props) {
  return (
    <>
      <svg
        width="12"
        height="6"
        viewBox="0 0 12 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${props.className ?? ""}`}
      >
        <path
          d="M5.99969 5.99988C5.90107 6.00091 5.80325 5.9819 5.71216 5.94409C5.62107 5.90628 5.53863 5.85048 5.46973 5.77991L1.0097 1.31982C0.938947 1.25088 0.882697 1.16854 0.844299 1.07751C0.805902 0.986489 0.786133 0.88871 0.786133 0.789917C0.786133 0.691124 0.805902 0.593345 0.844299 0.502319C0.882697 0.411293 0.938947 0.328833 1.0097 0.259888C1.15033 0.119437 1.34098 0.0405273 1.53973 0.0405273C1.73848 0.0405273 1.92908 0.119437 2.0697 0.259888L5.99969 4.18982L9.92969 0.259888C10.0703 0.119437 10.261 0.0405273 10.4597 0.0405273C10.6585 0.0405273 10.8491 0.119437 10.9897 0.259888C11.1301 0.400513 11.209 0.591166 11.209 0.789917C11.209 0.988668 11.1301 1.1792 10.9897 1.31982L6.52972 5.77991C6.46048 5.85002 6.37794 5.90558 6.28693 5.94336C6.19591 5.98114 6.09824 6.00034 5.99969 5.99988Z"
          fill={props.fill ?? "white"}
        />
      </svg>
    </>
  );
}
