export default function RightArrow(props) {
  function HandleClick() {
    props.onClick && props.onClick();
  }
  return (
    <>
      <svg
        width="9"
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`cursor-pointer ${props.className ?? ""}`}
        onClick={HandleClick}
      >
        <path
          d="M1.45201 15.0002C1.21075 15.0002 0.963126 14.8989 0.778999 14.7029C0.404396 14.3043 0.404396 13.6624 0.778999 13.2705L6.20122 7.50022L0.778999 1.72995C0.404396 1.33806 0.404396 0.689414 0.778999 0.297522C1.1536 -0.101127 1.75043 -0.101127 2.12503 0.297522L8.22027 6.78401C8.59487 7.18266 8.59487 7.82455 8.22027 8.21644L2.12503 14.7029C1.9409 14.8989 1.69328 15.0002 1.45201 15.0002Z"
          fill={props.fill ?? "#4C5866"}
        />
      </svg>
    </>
  );
}
