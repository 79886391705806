export default function SearchIcon(props) {
  function HandleClick() {
    props.onClick && props.onClick();
  }
  return (
    <>
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${props.className ?? ""}`}
        onClick={HandleClick}
      >
        <path
          d="M11.8316 11.0108L9.30731 8.48653C10.0236 7.59606 10.4533 6.45782 10.4533 5.22665C10.4533 2.34618 8.10711 0 5.22665 0C2.34618 0 0 2.34618 0 5.22665C0 8.10711 2.34231 10.4533 5.22665 10.4533C6.45782 10.4533 7.59219 10.0274 8.48266 9.30731L11.0069 11.8316C11.1231 11.9439 11.2702 12.0019 11.4212 12.0019C11.5722 12.0019 11.7154 11.9439 11.8316 11.8316C12.0561 11.607 12.0561 11.2392 11.8316 11.0108ZM1.16148 5.22665C1.16148 2.985 2.985 1.16148 5.22665 1.16148C7.4683 1.16148 9.29182 2.985 9.29182 5.22665C9.29182 7.4683 7.4683 9.29182 5.22665 9.29182C2.985 9.29182 1.16148 7.4683 1.16148 5.22665Z"
          fill={props.fill ?? "#4C5866"}
        />
      </svg>
    </>
  );
}
