import { BackArrow, SaveIcon } from "allIcons";
import { useTags } from "api/siteSettings/Tags";
import {
  useDeletePlugin,
  usePlugins,
  useUpdatePlugin,
} from "api/templateLibrary/plugins";
import {
  AddComponent,
  BodyContainer,
  DeleteButton,
  DropDownCard,
  HeaderContainer,
  IconWrapper,
  InputField,
  Loader,
  MainButton,
  PopOver,
  PreviewCard,
  PrimaryHeading,
  RightMainContainer,
  SecondaryHeading,
  SelectField,
  ToggleCard,
} from "commonUI";
import { isEqual, parse, useSearch, useUtilities } from "helpers";
import { useUserPermissions } from "hooks/user";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const currencies = ["USD", "EUR", "GBP", "AUD", "CAD", "CHF", "SGD"];

export default function EditPlugin() {
  const { notification, navigate } = useUtilities();
  const { data: plugins, isLoading, error } = usePlugins();
  const { mutate: updatePlugin, isLoading: updatingPlugin } = useUpdatePlugin();
  const { mutate: deletePlugin, isLoading: deletePluginLoading } =
    useDeletePlugin("/template-library/plugins");

  const { backUrl } = useSearch();

  const [openTags, setOpenTags] = useState(false);
  const [inputValues, setInputValues] = useState({
    plugin_title: "",
    license_key: "",
    enable_license_fee_for_invoicing: false,
    license_fee: "",
    license_billing_frequency: "Yearly",
    tags: [],
    plugin_type: 0,
    currency: "USD",
  });
  const { data: tags } = useTags();
  const { id: pluginId } = useParams();
  const { edit_templates, delete_templates } = useUserPermissions();

  useEffect(() => {
    if (plugins && Array.isArray(plugins)) {
      const plugin = plugins.find((ele) => isEqual(ele.id, pluginId));
      if (plugin) {
        setInputValues({
          ...plugin,
          tags: plugin.tags ? parse(plugin.tags) : [],
          currency: plugin.currency || "USD",
        });
      }
    }
  }, [plugins]);

  const onInputChange = (key, value) => {
    setInputValues((pre) => ({ ...pre, [key]: value }));
  };

  const onSaveChanges = () => {
    if (!edit_templates) {
      notification({
        type: "error",
        message: "You don't have permission to edit this plugin",
      });
      return;
    }
    updatePlugin(inputValues);
  };

  return (
    <>
      <RightMainContainer>
        <Loader show={updatingPlugin || deletePluginLoading} loader="block" />
        <HeaderContainer>
          <IconWrapper>
            <BackArrow
              onClick={() => navigate(backUrl ?? "/template-library/plugins")}
            />
            <PrimaryHeading>{inputValues.plugin_title}</PrimaryHeading>
          </IconWrapper>
          <IconWrapper className="gap-14">
            <DeleteButton
              show={pluginId !== "add" && delete_templates}
              onDelete={() => deletePlugin(pluginId)}
              deleteTitle="Delete This Template?"
              deleteButtonText="Confirm Delete"
              deleteMessage="You are about to delete this template from your account. This action cannot be undone! All template content will be permanently removed. Are you sure you want to do this?"
            />
            <MainButton disabled={isLoading} onClick={onSaveChanges}>
              <SaveIcon /> Save Changes
            </MainButton>
          </IconWrapper>
        </HeaderContainer>
        <BodyContainer>
          {isEqual(inputValues.plugin_type, 1) ? (
            <>
              <div className="flex flex-row gap-10 justify-between items-end mt-1 w-full">
                <InputField
                  label="License Key"
                  description="Enter your premium plugin license key into the input field to
              store it in the template library alongside the associated plugin.
              This feature enables you to efficiently manage and retrieve your
              license keys, simplifying the process of configuring plugins on
              new websites. Once installed, you can conveniently copy and paste
              the license key here from the template library into the plugin
              settings."
                  value={inputValues.license_key}
                  className="flex-1"
                  setValue={(value) => onInputChange("license_key", value)}
                  placeholder="Enter License Key"
                />
                <MainButton
                  disabled={isLoading || !inputValues.license_key}
                  className="mb-3-5"
                  onClick={() => {
                    navigator.clipboard.writeText("License Key");
                    notification({
                      type: "SUCCESS",
                      message: "Copied on clipboard",
                    });
                  }}
                >
                  Copy
                </MainButton>
              </div>
              <ToggleCard
                label="Enable License Fee For Invoicing"
                description="Choose to enable or disable a license fee for this plugin. If
              enabled, this plugin license fee can be added to invoices to be
              billed to the website client."
                value={inputValues.enable_license_fee_for_invoicing}
                setValue={(value) =>
                  onInputChange("enable_license_fee_for_invoicing", value)
                }
              />
              {inputValues.enable_license_fee_for_invoicing ? (
                <>
                  <div className="flex items-center gap-15 w-full">
                    <SelectField
                      label="Currency"
                      placeholder="USD"
                      value={inputValues.currency}
                      setValue={(value) => onInputChange("currency", value)}
                      style={{ minWidth: "100px" }}
                    >
                      {currencies.map((currency) => (
                        <option value={currency}>{currency}</option>
                      ))}
                    </SelectField>
                    <InputField
                      label="License Fee"
                      placeholder="Enter License Fee in USD"
                      className="mt-2"
                      style={{ width: "calc(100% - 24px)" }}
                      value={inputValues.license_fee}
                      setValue={(value) => onInputChange("license_fee", value)}
                      type="number"
                    />
                  </div>
                  <SelectField
                    label="License Billing Frequency"
                    placeholder="Yearly"
                    value={inputValues.license_billing_frequency}
                    setValue={(value) =>
                      onInputChange("license_billing_frequency", value)
                    }
                  >
                    <option value="Yearly">Yearly</option>
                    <option value="Monthly">Monthly</option>
                  </SelectField>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          <div className="mt-2">
            <SecondaryHeading>Tags</SecondaryHeading>
            <div className="flex items-center gap-5">
              {inputValues.tags.map((tag) => (
                <PreviewCard
                  color={tag.color}
                  title={tag.text}
                  icon={true}
                  className="m-0"
                  onCross={() => {
                    setInputValues((pre) => ({
                      ...pre,
                      tags: pre.tags.filter((ele) => ele.id !== tag.id),
                    }));
                  }}
                />
              ))}
            </div>
            <AddComponent
              title="Add Tag"
              value={openTags}
              onClick={() => setOpenTags(true)}
            >
              <PopOver
                open={openTags}
                onClose={() => setOpenTags(false)}
                toolTip={{
                  vertical: isEqual(inputValues.plugin_type, 1)
                    ? "bottom"
                    : "top",
                  horizontal: "center",
                }}
                style={{
                  bottom: isEqual(inputValues.plugin_type, 1)
                    ? "calc(100% + 15px)"
                    : "auto",
                  top: isEqual(inputValues.plugin_type, 1)
                    ? "auto"
                    : "calc(100% + 15px)",
                  right: "auto",
                  left: "auto",
                }}
              >
                <DropDownCard
                  title="Tags"
                  listStyle={true}
                  className="relative-important"
                  selectedValues={inputValues.tags.map((item) => item.id)}
                  list={tags.map((ele) => ({
                    ...ele,
                    index: ele.id,
                    text: ele.tag_title,
                  }))}
                  onSelect={(item) => {
                    if (
                      inputValues.tags.find(
                        (i) => Number(i.id) === Number(item.id)
                      )
                    ) {
                      return;
                    }
                    setInputValues((pre) => ({
                      ...pre,
                      tags: [
                        ...pre.tags,
                        {
                          id: item.id,
                          text: item.tag_title,
                          color: item.color,
                        },
                      ],
                    }));
                  }}
                />
              </PopOver>
            </AddComponent>
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
