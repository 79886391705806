export default function CloudDownloadIcon(props) {
  return (
    <>
      <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className ?? ""}
      >
        <path
          d="M15.2345 5.21455C14.9891 3.50182 13.5109 2.18182 11.7273 2.18182C11.4218 2.18182 11.1218 2.22545 10.8218 2.30727C10.0036 0.9 8.48182 0 6.81818 0C4.26 0 2.18182 2.07818 2.18182 4.63636C2.18182 4.77273 2.18182 4.91455 2.20364 5.05636C0.845455 5.89091 0 7.37454 0 9C0 11.5582 2.07818 13.6364 4.63636 13.6364H13.3636C13.3964 13.6364 13.4345 13.6364 13.4673 13.6309C13.5218 13.6364 13.5764 13.6364 13.6364 13.6364C16.0418 13.6364 18 11.6782 18 9.27273C18 7.45091 16.8927 5.86364 15.2345 5.21455ZM13.6364 12C13.5982 12 13.56 11.9945 13.5218 11.9945L13.4127 11.9836H13.3691C13.3145 11.9836 13.26 11.9891 13.2109 12H4.63636C2.98364 12 1.63636 10.6527 1.63636 9C1.63636 7.80545 2.34 6.73091 3.43091 6.25091C3.79636 6.09273 3.99273 5.7 3.9 5.31818C3.84545 5.08364 3.81818 4.85455 3.81818 4.63636C3.81818 2.98364 5.16545 1.63636 6.81818 1.63636C8.07818 1.63636 9.21818 2.43818 9.64364 3.63273C9.72546 3.85636 9.9 4.03636 10.1291 4.12364C10.3527 4.20545 10.6036 4.18364 10.8164 4.06909C11.1055 3.9 11.4164 3.81818 11.7273 3.81818C12.7691 3.81818 13.6145 4.65273 13.6364 5.68909C13.6364 5.73273 13.6255 5.79273 13.6255 5.82545C13.6255 6.20727 13.8873 6.53454 14.2582 6.62182C15.4964 6.91091 16.3636 8.00182 16.3636 9.27273C16.3636 10.7782 15.1418 12 13.6364 12Z"
          fill={props.fill ?? "white"}
        />
        <path
          d="M11.1266 9.10918L8.94482 10.7455C8.94482 10.7455 8.91209 10.7728 8.89027 10.7783C8.85755 10.8055 8.82482 10.8219 8.79209 10.8328C8.683 10.8874 8.56846 10.9092 8.45391 10.9092C8.33937 10.9092 8.22482 10.8874 8.11573 10.8328C8.083 10.8219 8.05027 10.8055 8.01755 10.7783C7.99573 10.7728 7.97937 10.7565 7.963 10.7455L5.78118 9.10918C5.42118 8.83645 5.34482 8.32372 5.61755 7.96373C5.89027 7.60373 6.403 7.52736 6.763 7.80009L7.63573 8.45463V5.72736C7.63573 5.27463 8.00118 4.90918 8.45391 4.90918C8.90664 4.90918 9.27209 5.27463 9.27209 5.72736V8.45463L10.1448 7.80009C10.5048 7.52736 11.0175 7.60373 11.2903 7.96373C11.563 8.32372 11.4866 8.83645 11.1266 9.10918Z"
          fill={props.fill ?? "white"}
        />
      </svg>
    </>
  );
}
